import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgInfo: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.999 10.667c.925 0 1.675-.747 1.675-1.667S12.924 7.333 12 7.333c-.926 0-1.676.746-1.676 1.667 0 .92.75 1.667 1.676 1.667z"
        fill="currentColor"
      />
      <path
        d="M21.717 14.333C20.71 18.833 16.355 22 11.83 22c-1.34 0-1.675-.667-1.675-1.5v-2-5.167c0-1.166.67-1.833 1.675-1.833 1.006 0 1.676.5 1.676 1.833V18.5c1.005-.167 2.01-.667 2.68-1.5 1.508-1.167 2.514-3.167 2.514-5.167 0-1.166-.335-2.5-1.006-3.666-1.675-2.334-3.015-3-5.696-3-2.01 0-4.021.833-5.194 2.333-1.508 2-1.675 3.833-1.34 5.833.335 1.667 1.34 3 2.68 4 1.173.834 1.006 1.667.503 2.334-1.005 1.5-2.346.5-3.183-.167-1.843-1.5-3.016-3.667-3.351-6-.503-3.833.67-6.833 3.35-9.167.67-.666 1.509-1.166 2.346-1.5C9.15 2.333 10.491 2 12 2c3.853 0 6.2 1.667 8.042 3.833 1.843 2.667 2.346 5.5 1.676 8.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgInfo
