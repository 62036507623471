import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNoPets: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0-4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0-4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM8 11a2.5 2.5 0 10-2.5 2.5A2.51 2.51 0 008 11zm-2.5 1.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM19.5 9a2.5 2.5 0 102.5 2.5A2.51 2.51 0 0019.5 9zm0 4a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill="currentColor"
      />
      <path d="M5 20L20 6" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M12.5 10l-.5-.5S6 15 6.5 16m9.5-3s.5.5 1 1.5 1.5 3-1 4.5-4-1-4-1l1-1s-2.5 3-4.5 2.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgNoPets
