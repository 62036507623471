import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPrint: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.2 16.5H4.5c-.6 0-1-.4-1-1v-6c0-.6.4-1 1-1h15c.6 0 1 .4 1 1v6c0 .6-.4 1-1 1H18"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9 21.5H6.4c-.3 0-.6-.3-.6-.6v-.1l.6-4.9c.1-.5.5-.9 1-.9h9.1c.5 0 .9.4 1 .9l1 4.9c0 .4-.2.7-.6.7zm0 0c.1 0 .1 0 0 0z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9 21.5H6.4c-.3 0-.6-.3-.6-.6v-.1l.6-4.9c.1-.5.5-.9 1-.9h9.1c.5 0 .9.4 1 .9l1 4.9c0 .4-.2.7-.6.7zm0 0c.1 0 .1 0 0 0zM7 4.5v-1c0-.6.4-1 1-1h8c.6 0 1 .4 1 1v5M10.2 12h-3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgPrint
