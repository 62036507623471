import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgEmailCircle: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} r={12} fill="#373373" />
      <g clipPath="url(#email-circle_svg__clip0_4316_8234)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12.054L7.57 7.625h8.858l-4.43 4.429zm-.442 1.325a.624.624 0 00.884 0l5.174-5.173c0 .006.002.013.004.02a.084.084 0 01.005.024v7.5c0 .345-.28.625-.625.625H7a.625.625 0 01-.625-.625v-7.5a.08.08 0 01.005-.024c.002-.006.004-.013.004-.02l5.174 5.173z"
          fill="#fff"
        />
        <mask
          id="email-circle_svg__a"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x={6}
          y={7}
          width={12}
          height={10}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 12.054L7.57 7.625h8.858l-4.43 4.429zm-.442 1.325a.624.624 0 00.884 0l5.174-5.173c0 .006.002.013.004.02a.084.084 0 01.005.024v7.5c0 .345-.28.625-.625.625H7a.625.625 0 01-.625-.625v-7.5a.08.08 0 01.005-.024c.002-.006.004-.013.004-.02l5.174 5.173z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#email-circle_svg__a)" fill="#fff">
          <path d="M4.5 4.5h15v15h-15z" />
          <path d="M4.5 4.5h15v15h-15z" />
        </g>
      </g>
      <defs>
        <clipPath id="email-circle_svg__clip0_4316_8234">
          <path fill="#fff" transform="translate(4.5 4.5)" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgEmailCircle
