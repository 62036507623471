import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPintrestSmall: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0C3.6 0 0 3.6 0 8c0 3.3 2 6.2 5.1 7.4-.1-.8-.1-1.5 0-2.3.1-.6.9-4 .9-4-.2-.4-.2-.8-.2-1.2 0-1.1.6-1.9 1.4-1.9.6 0 1 .4 1 1v.1c0 .7-.4 1.7-.7 2.7-.2.6.2 1.3.8 1.4h.4c1.4 0 2.5-1.5 2.5-3.7 0-1.9-1.4-3.3-3.3-3.3C6 4.1 4.4 5.6 4.3 7.5v.1c0 .7.2 1.3.6 1.8.1.1.1.1.1.2-.1.3-.2.8-.2.9 0 .1-.1.2-.3.1-1-.5-1.6-1.9-1.6-3.1 0-2.5 1.8-4.8 5.3-4.8 2.8 0 4.9 2 4.9 4.6 0 2.8-1.7 5-4.1 5-.7 0-1.4-.3-1.8-.9l-.5 1.9c-.2.7-.6 1.4-1 2.1 4.2 1.3 8.7-1 10-5.2C17 6 14.7 1.5 10.5.1 9.7.1 8.8 0 8 0z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgPintrestSmall
