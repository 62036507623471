import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgReportListing: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x={3.5} y={4.5} width={17} height={15} rx={1.5} stroke="currentColor" />
      <path
        d="M12.56 7h-1.127v6.556h1.127V7zM11.996 14.696a.804.804 0 00-.826.813.833.833 0 10.826-.813z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgReportListing
