import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosDoors: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.937 13.468H16.64m4.288 7.032H5.613c-.843 0-1.609-.54-1.838-1.39L2.09 13.004c-.23-.696 0-1.469.46-1.932l6.738-6.955c.383-.386.843-.54 1.379-.618h10.26v8.114L22 13.39c0 2.395-.306 4.79-1.072 7.109z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.788 11.227h1.15V5.51h-5.361m2.144 5.718H5l5.283-5.332c.306-.231.613-.386.995-.386h.307m-1.455 7.96H2.473M18.937 11.227H7.91"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.937 8.445h-7.581c-.46 0-.92.232-1.226.541l-2.22 2.241M18.937 8.445v2.782"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosDoors
