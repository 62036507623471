import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBeds: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.365 16.536v-.5h-.873v-2.851h.873v2.851h.001v.5zM5.508 14.062h12.984v-.873H5.508v.873zm-1 2.474v-.5h-.873V7.964h.873v8.072h.001v.5zm16.651-4.148c-.218-.214-.499-.206-.794-.203H19.4c-.1-.003-.22-.006-.34.018-.024-.003-.044-.014-.067-.014H5.508V7.964c0-.552-.449-1-1-1h-.873a1 1 0 00-1 1v8.072a1 1 0 001 1h.873c.551 0 1-.448 1-1v-.974h12.984v.974c0 .552.45 1 1 1h.873c.551 0 1-.448 1-1v-3.12a.708.708 0 00-.206-.528z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.143 8.683c.514 0 .933.419.933.934a.935.935 0 01-1.868 0c0-.515.42-.934.934-.934zm0 2.867a1.936 1.936 0 001.933-1.933 1.936 1.936 0 00-1.934-1.934A1.936 1.936 0 006.21 9.617c0 1.066.868 1.933 1.934 1.933zM11.277 11.502a.5.5 0 00.5-.5V8.894c.498-.004.984-.013 1.456-.022 2.57-.045 4.788-.088 5.683.79.323.318.48.772.48 1.388a.5.5 0 001 0c0-.886-.262-1.593-.78-2.102-1.193-1.172-3.49-1.128-6.402-1.076a99.59 99.59 0 01-1.937.024.5.5 0 00-.5.5v2.606a.5.5 0 00.5.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgBeds
