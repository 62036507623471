import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosCruiseControl: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.792 13.153L9.52 10.847m3.235-5.638v1.794-1.794zm9.006 8.884h-1.837 1.837zm-16.175 0H3.749h1.836zm13.552-6.236l-1.312 1.281 1.312-1.281zM7.683 19.047L6.197 20.5c-3.585-3.503-3.585-9.226 0-12.814 3.584-3.588 9.442-3.503 13.114 0 3.585 3.502 3.585 9.226 0 12.814l-.35-.342-1.136-1.11H7.683zm0-9.909L6.197 7.686l1.486 1.452zM5.06 6.49L2 3.5l3.06 2.99z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.754 15.874c.724 0 1.311-.573 1.311-1.281s-.587-1.281-1.311-1.281-1.312.573-1.312 1.28c0 .709.588 1.282 1.312 1.282zM5.148 4.854v2.307H2.875"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosCruiseControl
