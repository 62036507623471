import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgKmSimple: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.28 5.78c3.705-3.707 9.735-3.706 13.44 0 3.706 3.705 3.706 9.735 0 13.44a.79.79 0 01-1.12 0 .792.792 0 010-1.12c3.089-3.088 3.089-8.112 0-11.2-3.088-3.089-8.112-3.089-11.2 0-3.089 3.088-3.089 8.112 0 11.2a.792.792 0 11-1.12 1.12c-3.706-3.705-3.706-9.735 0-13.44z"
        fill="currentColor"
      />
      <path
        d="M11.44 8.043a.774.774 0 01-.232-.56.784.784 0 01.232-.56.839.839 0 01.256-.17.795.795 0 011.096.731.777.777 0 01-.135.44.796.796 0 01-1.098.216.839.839 0 01-.119-.097zM8.755 15.314a.874.874 0 01.259.172.8.8 0 01.171.863.87.87 0 01-.171.259.81.81 0 01-.56.23.812.812 0 01-.562-.23.868.868 0 01-.171-.258.758.758 0 01-.061-.304.8.8 0 01.353-.658.886.886 0 01.138-.074.945.945 0 01.147-.044.857.857 0 01.31 0c.05.01.1.026.147.044zM17.576 13.06a.835.835 0 00.171-.256.777.777 0 00.061-.303.796.796 0 00-.948-.778.747.747 0 00-.285.12.795.795 0 00-.351.657.795.795 0 00.792.792.8.8 0 00.56-.232zM6.424 11.94a.817.817 0 011.12 0 .796.796 0 01.232.56.796.796 0 01-.792.792.8.8 0 01-.56-.232.795.795 0 01-.232-.56c0-.209.084-.412.232-.56zM15.393 15.27a.774.774 0 00-.407.216.793.793 0 00-.23.56.81.81 0 00.23.562.8.8 0 001.12 0 .801.801 0 00.232-.563.805.805 0 00-.23-.56.81.81 0 00-.716-.215zM9.112 8.513c.029.043.052.09.074.138a.754.754 0 01.06.301.813.813 0 01-.232.562.81.81 0 01-.56.23.806.806 0 01-.562-.23.814.814 0 01-.232-.562.754.754 0 01.06-.301.877.877 0 01.172-.26.826.826 0 011.122 0c.034.038.069.08.098.122zM16.52 9.1a.792.792 0 10-1.12-1.12l-.403.403-.006.005-.005.004a.072.072 0 00-.005.005.063.063 0 01-.004.006l-2.139 2.139a2.115 2.115 0 00-2.95 1.938c0 1.164.947 2.112 2.112 2.112a2.115 2.115 0 001.95-2.922l2.57-2.57z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgKmSimple
