import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgHistory: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M18.303 7.197C16.888 5.78 15.003 5 13 5s-3.887.78-5.302 2.197A7.435 7.435 0 005.77 10.5H4.75a.75.75 0 00-.615 1.18l1.75 2.5a.752.752 0 001.23 0l1.75-2.5a.75.75 0 00-.615-1.18h-.91c.295-.835.773-1.6 1.418-2.243C9.89 7.126 11.398 6.5 13 6.5s3.11.625 4.243 1.758S19 10.898 19 12.5s-.625 3.11-1.757 4.242S14.603 18.5 13 18.5s-3.11-.625-4.242-1.758a.75.75 0 00-1.06 1.06C9.113 19.22 10.998 20 13 20s3.888-.78 5.303-2.197c1.417-1.415 2.197-3.3 2.197-5.303s-.78-3.887-2.197-5.302z" />
        <path d="M13 7.5a.749.749 0 00-.75.75v4.25c0 .2.08.39.22.53l2 2c.148.147.338.22.53.22s.385-.072.53-.22a.75.75 0 000-1.06l-1.78-1.78V8.25A.749.749 0 0013 7.5z" />
      </g>
    </Icon>
  )
}
export default SvgHistory
