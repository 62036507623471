import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBoatsOther: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.333 10L22 11.5M15.333 10H6.444m8.89 0l.166 3m6.5-1.5s-1.452 2.426-2.667 3.5c-1.014.897-2.814 1.69-3.38 1.928a.943.943 0 01-.366.072H4a2 2 0 01-2-2v-2m20-1.5L15.5 13M2 13l1.487-2.51a1 1 0 01.86-.49h2.097M2 13h4m.444-3L6 13m9.5 0H6"
        stroke="#3e4153"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgBoatsOther
