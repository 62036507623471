import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosWagon: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.604 10.86l-.029-1.496M20.673 14.422c.838 0 1.243-.24 1.59-.598.174-.21.26-.48.231-.749 0-.389-.23-.538-.23-.987v-.54a3.558 3.558 0 00-.232-.957c-.318-.569-.636-1.886-1.33-1.946-.29-.03-7.403-.329-10.15.03-.926.12-3.355 1.527-3.818 1.826a.456.456 0 01-.26.09c-.607.09-3.788.778-4.309 1.048-.376.269-.607.718-.665 1.197v.419c0 .659.492 1.167 1.128 1.167h1.504M7.891 10.98c4.164 0 12.898-.21 13.39-.21"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.558 12.776l1.185-.24c.174-.06.348-.179.492-.299M11.303 11.998h.52M17.058 10.65l-.029-1.286M15.785 11.998h.52M8.18 14.572H17M5.52 15.5c.782 0 1.417-.657 1.417-1.467s-.635-1.466-1.417-1.466c-.783 0-1.417.656-1.417 1.466 0 .81.634 1.467 1.417 1.467z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52 15.5c.782 0 1.417-.657 1.417-1.467s-.635-1.466-1.417-1.466c-.783 0-1.417.656-1.417 1.466 0 .81.634 1.467 1.417 1.467z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52 15.5c.782 0 1.417-.657 1.417-1.467s-.635-1.466-1.417-1.466c-.783 0-1.417.656-1.417 1.466 0 .81.634 1.467 1.417 1.467z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52 15.5c.782 0 1.417-.657 1.417-1.467s-.635-1.466-1.417-1.466c-.783 0-1.417.656-1.417 1.466 0 .81.634 1.467 1.417 1.467zM18.302 15.5c.782 0 1.416-.657 1.416-1.467s-.634-1.466-1.416-1.466c-.783 0-1.418.656-1.418 1.466 0 .81.635 1.467 1.418 1.467z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.302 15.5c.782 0 1.416-.657 1.416-1.467s-.634-1.466-1.416-1.466c-.783 0-1.418.656-1.418 1.466 0 .81.635 1.467 1.418 1.467zM22.235 12.118s-.926-.06-.926-.689V10.8h.839"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosWagon
