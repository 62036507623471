import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLinkedIn: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 0C5.394 0 0 5.394 0 12s5.394 12 12 12 12-5.394 12-12c0-6.642-5.394-12-12-12zM8.514 16.917H5.872v-8.44h2.642zM7.193 7.303A1.55 1.55 0 015.65 5.76c0-.844.698-1.504 1.542-1.504.844 0 1.504.697 1.504 1.504 0 .845-.66 1.542-1.504 1.542zm11.156 9.614h-2.643v-4.11c0-.99-.036-2.238-1.357-2.238-1.358 0-1.578 1.064-1.578 2.165v4.183h-2.643v-8.44h2.533v1.138h.036c.367-.66 1.211-1.358 2.496-1.358 2.642 0 3.156 1.761 3.156 4.037z"
        fill="#4b4881"
      />
    </Icon>
  )
}
export default SvgLinkedIn
