import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgYachtBoat: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.5 20.5h3m0 0L4 13l2-1m.5 8.5H12M12 9v11.5M12 9l6 2.824M12 9l-6 3m6 8.5h5.5m3 0h-3m0 0l3-7.5-2.5-1.177m0 0V6.5h-2.5m0 0V4h-7v2.5m7 0h-7m0 0H6V12"
        stroke="#3e4153"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgYachtBoat
