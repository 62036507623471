import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgReviewOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.6 19.5c-.1 0-.1 0-.2-.1-.1-.101-.2-.202-.1-.303l.8-5.033-3.5-3.524c-.1-.1-.1-.201-.1-.402 0-.101.1-.202.3-.202l4.8-.704 2.1-4.53c.1-.101.2-.202.3-.202.1 0 .2.1.3.201l2.1 4.53 4.8.705c.1 0 .2.1.3.202 0 .1 0 .302-.1.402l-3.5 3.524.8 5.033c0 .101 0 .302-.1.302-.1.101-.2.101-.4 0l-4.3-2.416L7.6 19.4c.1.101 0 .101 0 .101z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgReviewOutline
