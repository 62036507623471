import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgForwardSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 18.5c0-.1.91-9.342 7.98-9.342h.404V6.044c0-.201.1-.402.303-.502.202-.1.404 0 .606.1l5.556 5.826c.201.201.201.603 0 .804l-5.657 6.027c-.202.201-.404.201-.606.1a.552.552 0 01-.303-.502v-3.315H11.06c-.607 0-1.213 0-1.819.1C6.01 15.387 5 18.5 5 18.5z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgForwardSolid
