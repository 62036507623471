import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPhotoShowcase: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M35.833 10H4.166a.834.834 0 01-.833-.833v-5c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834v5c0 .46-.373.833-.833.833zM35.833 28.333H4.166a.834.834 0 01-.833-.833v-15c0-.46.373-.833.833-.833h31.667c.46 0 .833.373.833.833v15c0 .46-.373.833-.833.833z"
          fill="#ececee"
        />
        <path
          d="M14.583 20H5.417A.417.417 0 015 19.583V13.75c0-.23.187-.417.417-.417h9.166c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417z"
          fill="#c5c6cb"
        />
        <path
          d="M35.833 36.667H4.166a.834.834 0 01-.833-.834v-5c0-.46.373-.833.833-.833h31.667c.46 0 .833.373.833.833v5c0 .46-.373.834-.833.834z"
          fill="#ececee"
        />
        <g fill="#37a864">
          <path d="M9.583 26.667H5.417A.417.417 0 015 26.25v-4.167c0-.23.187-.416.417-.416h4.166c.23 0 .417.186.417.416v4.167c0 .23-.187.417-.417.417zM16.25 26.667h-4.167a.417.417 0 01-.417-.417v-4.167c0-.23.187-.416.417-.416h4.166c.23 0 .417.186.417.416v4.167c0 .23-.187.417-.417.417zM22.916 26.667H18.75a.417.417 0 01-.417-.417v-4.167c0-.23.187-.416.417-.416h4.166c.23 0 .417.186.417.416v4.167c0 .23-.187.417-.417.417zM29.583 26.667h-4.166A.417.417 0 0125 26.25v-4.167c0-.23.187-.416.417-.416h4.166c.23 0 .417.186.417.416v4.167c0 .23-.187.417-.417.417z" />
        </g>
        <path
          d="M5.417 8.333h9.166c.23 0 .417-.186.417-.416V3.333H5v4.584c0 .23.187.416.417.416z"
          fill="#c5c6cb"
        />
      </g>
    </Icon>
  )
}
export default SvgPhotoShowcase
