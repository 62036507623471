import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNewCarsDealers: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.126 15.909c-.25 0-.494.073-.702.209-.208.136-.37.33-.465.556a1.217 1.217 0 00.274 1.35 1.287 1.287 0 001.377.268c.23-.093.428-.252.567-.456a1.223 1.223 0 00-.158-1.564 1.277 1.277 0 00-.893-.363zm0 1.916a.7.7 0 01-.383-.114.665.665 0 01-.105-1.041.694.694 0 01.752-.147.688.688 0 01.31.249.668.668 0 01-.086.854.697.697 0 01-.488.199zM17.638 15.909c-.25 0-.495.073-.702.209-.208.136-.37.33-.466.556a1.217 1.217 0 00.274 1.35 1.287 1.287 0 001.377.268c.231-.093.428-.252.567-.456a1.221 1.221 0 00-.157-1.564 1.278 1.278 0 00-.893-.363zm0 1.916a.7.7 0 01-.384-.114.665.665 0 01-.105-1.041.694.694 0 01.752-.147.688.688 0 01.31.249.668.668 0 01-.086.854.697.697 0 01-.487.199z"
        fill="currentColor"
      />
      <path
        d="M7.375 20.438H4.22V8.03c.114.02.229.031.344.033.36 0 .713-.09 1.025-.266.312-.175.572-.427.753-.732.183.304.443.556.754.73a2.095 2.095 0 002.049 0c.312-.174.571-.426.754-.73.182.304.442.556.754.73a2.095 2.095 0 002.048 0c.312-.174.572-.426.754-.73.182.304.442.556.754.73a2.095 2.095 0 002.048 0c.312-.174.572-.426.754-.73.224.374.564.667.97.836.407.17.859.207 1.288.106.43-.101.815-.335 1.099-.667a2 2 0 00.478-1.176.275.275 0 00-.012-.175l-1.147-2.812A.291.291 0 0019.421 3H3.933a.291.291 0 00-.266.177L2.52 5.989a.27.27 0 00-.013.162c.02.356.136.7.336.998.2.297.477.537.803.695v12.875a.276.276 0 00.084.199.287.287 0 00.203.082h3.442a.29.29 0 00.203-.082.278.278 0 000-.398.29.29 0 00-.203-.082zM3.08 6.129l1.047-2.567h15.1l1.046 2.567a1.446 1.446 0 01-.472.981c-.28.256-.651.395-1.034.39a1.501 1.501 0 01-1.021-.42 1.445 1.445 0 01-.442-.995.29.29 0 00-.094-.194.3.3 0 00-.404 0 .278.278 0 00-.087.194c-.011.379-.173.739-.45 1.003a1.502 1.502 0 01-1.036.412c-.387 0-.758-.148-1.036-.412a1.445 1.445 0 01-.45-1.003.289.289 0 00-.094-.194.3.3 0 00-.403 0 .278.278 0 00-.087.194c-.011.379-.173.739-.45 1.003a1.502 1.502 0 01-1.036.412c-.387 0-.758-.148-1.036-.412a1.445 1.445 0 01-.45-1.003.29.29 0 00-.094-.194.3.3 0 00-.404 0 .279.279 0 00-.087.194c-.01.379-.172.739-.45 1.003A1.502 1.502 0 018.12 7.5c-.386 0-.758-.148-1.035-.412a1.445 1.445 0 01-.45-1.003.289.289 0 00-.094-.194.3.3 0 00-.404 0 .278.278 0 00-.087.194c-.011.375-.17.731-.442.995a1.503 1.503 0 01-2.055.03 1.446 1.446 0 01-.473-.98z"
        fill="currentColor"
      />
      <path
        d="M21.327 11.47l-1.89-.803-.805-2.41a.282.282 0 00-.102-.14.29.29 0 00-.165-.055.3.3 0 00-.275.185l-.906 2.422-.488.223h-4.794a1.382 1.382 0 00-.803.28c-.23.174-.398.417-.477.691l-.332 1.324a1.11 1.11 0 00-.096-.122 1.137 1.137 0 00-.822-.349h-.871a.613.613 0 00-.437.193.588.588 0 00-.155.445v1.008a.572.572 0 00.17.42.592.592 0 00.425.173l.381-.004c-.21.263-.324.587-.325.92v2.87c0 .181.055.358.156.508.101.15.245.269.414.34v.774a.613.613 0 00.181.45.639.639 0 00.455.187h1.38a.62.62 0 00.579-.436.544.544 0 00.014-.228v-.672h5.286v.698a.612.612 0 00.181.45.638.638 0 00.454.188H19a.618.618 0 00.577-.436.544.544 0 00.014-.228v-.733a.937.937 0 00.444-.337.908.908 0 00.168-.524v-2.87c0-.332-.113-.655-.321-.917h.38a.603.603 0 00.424-.174.58.58 0 00.169-.419v-1.008a.581.581 0 00-.156-.445.602.602 0 00-.436-.193h-.693l1.755-.73a.287.287 0 00.127-.103.277.277 0 00-.125-.414zm-10.151.538a.773.773 0 01.272-.391c.13-.1.29-.156.454-.162h3.564l-.039.017a.278.278 0 00.002.51l1.754.787.637 1.749a21.093 21.093 0 01-3.347.312 24.264 24.264 0 01-3.904-.395l.607-2.427zm-2.693 2.354v-1.008c0-.026.003-.05.012-.075h.877a.565.565 0 01.528.366.541.541 0 01.032.225v.503l-1.449-.01zm2.682 6l-.03.044.007.032H9.765c-.01 0-.061-.004-.061-.075v-.699h1.461v.698zm7.852 0l-.03.043.008.032H17.66c-.01 0-.061-.003-.061-.075v-.698h1.418v.698zm.613-4.49v2.87a.357.357 0 01-.108.254.37.37 0 01-.26.106H9.502a.37.37 0 01-.26-.106.357.357 0 01-.107-.254v-2.87a.88.88 0 01.236-.632.915.915 0 01.619-.29c1.427.318 2.887.467 4.35.446a22.11 22.11 0 003.693-.297l.058.16c.02.054.056.101.104.135a.291.291 0 00.166.051h.004a.29.29 0 00.269-.196l.093-.287.03-.007h.048c.23.02.445.127.599.297a.88.88 0 01.227.62zm.634-2.595a.13.13 0 01.017.077l-.018 1.039H18.91l.37-1.136a.29.29 0 00.11.022l.873-.002zm-1.17-.973a.288.288 0 00-.162.173l-.588 1.808-.661-1.817a.28.28 0 00-.15-.161l-1.3-.582 1.301-.595a.283.283 0 00.148-.158l.662-1.77.589 1.76a.28.28 0 00.158.171l1.394.592-1.392.579z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgNewCarsDealers
