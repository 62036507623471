import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

/*
  We display just two items on mobile, and then 4 items for larger screens
  And we reverse the order of the items on mobile because we want to display the 
  location first. 
 */
type AutosCrossPromoListingAttributesListProps = {
  children: React.ReactNode
}

export const AutosCrossPromoListingAttributesList =
  styled.ul<AutosCrossPromoListingAttributesListProps>(
    ({ theme }) => `  
    max-width: fit-content;
    display: flex;
    gap: ${theme.spacing.defaultSmall};
    flex-wrap: wrap;
    
  
    ${MEDIA_QUERIES(theme).medium} {
      display: grid;
      grid-template-rows: none;
      grid-template-columns: repeat(2, 1fr);
      row-gap: ${theme.spacing.default};
      column-gap: ${theme.spacing.xLarge};
    }
  `
  )

type FlexRightSideAutosLogoContainerProps = {
  visibleAt: keyof ThemeProps['breakpoints']
}
export const FlexRightSideAutosLogoContainer = styled(Flex)<FlexRightSideAutosLogoContainerProps>(
  ({ theme, visibleAt }) => `
  display: none;
  align-items: flex-end;

  @media screen and (min-width: ${theme.breakpoints[visibleAt]}px) {
    display: flex;
  }
`
)
