import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPrintCircle: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} r={12} fill="#3E4153" />
      <path
        d="M8.375 14.813H7.312c-.375 0-.625-.25-.625-.626v-3.75c0-.374.25-.624.625-.624h9.375c.375 0 .625.25.625.624v3.75c0 .376-.25.626-.625.626h-.937"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.688 17.938H8.5a.403.403 0 01-.375-.375V17.5l.375-3.063c.063-.312.313-.562.625-.562h5.688c.312 0 .562.25.624.563l.626 3.062c0 .25-.125.438-.375.438zm0 0c.062 0 .062 0 0 0z"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.688 17.938H8.5a.403.403 0 01-.375-.375V17.5l.375-3.063c.063-.312.313-.562.625-.562h5.688c.312 0 .562.25.624.563l.626 3.062c0 .25-.125.438-.375.438zm0 0c.062 0 .062 0 0 0zM8.875 7.313v-.625c0-.375.25-.625.625-.625h5c.375 0 .625.25.625.625v3.125M10.875 12H9"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgPrintCircle
