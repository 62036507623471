import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const AttributeListItemFlex = styled(Flex)(
  ({ theme }) => `
    ${MEDIA_QUERIES(theme).medium} {
        & > svg {
            width: 2.0rem;
            height: 2.0rem;
        }    
    }

    ${MEDIA_QUERIES(theme).xLarge} {
        & > svg {
            width: 2.4rem;
            height: 2.4rem;
        }    
    }
`
)

export const AutosAttributeListFlex = styled(Flex)(
  ({ theme }) => `
    ${MEDIA_QUERIES(theme).medium} {
        margin: ${theme.spacing.mini} 0;     
    }
    `
)

export const AttributeListItemText = styled(BodyText)(
  ({ theme }) => `
    ${styleFromTypography(theme.typography.body.xSmall)}

    ${MEDIA_QUERIES(theme).mediumSmall} {
        ${styleFromTypography(theme.typography.body.small)}
    }  

    ${MEDIA_QUERIES(theme).xLarge} {
        ${styleFromTypography(theme.typography.body.medium)}   
    }    
`
)
