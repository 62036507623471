import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgDelete: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153" strokeLinecap="round" strokeLinejoin="round">
        <path d="M5 7v10c0 1.1.9 2 2 2h9c1.1 0 2-.9 2-2V7M11.5 10v6M14 10v6M14.5 6.5V4.7c0-.4-.3-.7-.7-.7H9.1c-.4 0-.7.3-.7.7V5M9 10v6M19 7H4z" />
      </g>
    </Icon>
  )
}
export default SvgDelete
