import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFuel: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#2d2f3b">
        <path d="M12 7.25a.75.75 0 01-.75.75h-4.5a.75.75 0 110-1.5h4.5a.75.75 0 01.75.75z" />
        <path
          clipRule="evenodd"
          d="M20.92 10.335L20.089 12h.162a.75.75 0 110 1.5H20V19c0 1.103-.897 2-2 2s-2-.897-2-2V9c0-.651-.419-1.206-1-1.413V20.25a.75.75 0 01-.75.75H3.75a.75.75 0 01-.75-.75V3.75A.75.75 0 013.75 3h10.5a.75.75 0 01.75.75v2.295A3.002 3.002 0 0117.5 9v10a.5.5 0 001 0v-7c0-.117.027-.231.079-.336l1-2a.751.751 0 011.342.671zM13.5 19.5h-9v-8h9zm0-9.5h-9V4.5h9z"
          fillRule="evenodd"
        />
      </g>
    </Icon>
  )
}
export default SvgFuel
