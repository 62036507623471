import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgEngine: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17 11.75h3v3h-2.5v3h-4l-1.5-1.5H7v-5.5l1.5-1.5H16l1 1z" stroke="#3e4153" />
      <path d="M20 9.25v8" stroke="#3e4153" strokeLinecap="round" strokeLinejoin="round" />
      <g fill="#3e4153">
        <path d="M4 13.25h-.5a.5.5 0 00.5.5zm.5-3a.5.5 0 00-1 0zm-1 6a.5.5 0 001 0zm3.5-3.5H4v1h3zm-2.5.5v-3h-1v3zm-1-3v6h1v-6zM12 6.25h.5a.5.5 0 00-.5-.5zm-3.5-.5a.5.5 0 000 1zm6.5 1a.5.5 0 000-1zm-2.5 2.5v-3h-1v3zm-.5-3.5H8.5v1H12zm-3.5 1H15v-1H8.5z" />
      </g>
    </Icon>
  )
}
export default SvgEngine
