import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFlag: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.5 6.4c.506-.1.91-.6 1.315-.9.303-.3.809-.5 1.213-.5.506 0 .91.1 1.214.5.606.5 1.314.7 2.123.7.81 0 1.517-.2 2.124-.7.202-.1.303-.4 1.011-.6v6c-1.011.2-1.416.3-1.82.6-.304.3-.81.5-1.214.5-.505 0-.91-.1-1.213-.4-.304-.2-.607-.4-.91-.5-.203-.1-.405-.1-.607-.2M7.5 19V4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgFlag
