import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgProfessional: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.933 9.733l1.903 2.067V8.333h-1.719M18.23 14.4l-7.364-8-7.365 8"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.864 22h5.647v-7.333l-5.646-6.133-5.647 6.133V22h1.903m9.083-14.866H13.81M6.077 16.6h-.859m.86 1.934h-.86m10.434-.467h.859M15.65 20h.86"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.146 17.333c.061.867.675 1.6 1.473 1.667.736.133 1.473-.133 1.902-.733a1.251 1.251 0 00-.245-1.667c-.061-.067-.184-.133-.245-.133-.369-.134-.737-.334-1.167-.467a6.22 6.22 0 01-1.166-.467c-.552-.2-.859-.933-.675-1.533.062-.133.062-.2.123-.267.491-.6 1.166-.866 1.902-.733.737 0 1.35.667 1.412 1.467M10.864 11.8v1.267m0 6v1.266m6.199-15c.86 0 1.534-.733 1.534-1.666 0-.934-.675-1.667-1.534-1.667-.86 0-1.534.733-1.534 1.667M14.056 5.4c0-.933.675-1.667 1.534-1.667.245 0 .491.067.736.2"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 3.533c0 1-.737 1.8-1.657 1.8h-1.289m-.491 0c-.92 0-1.718.667-1.964 1.6"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgProfessional
