import styled from 'styled-components'

import { RequiredPickMap } from '@/ui/typings/helpers'

import { DividerProps } from './Divider'

type DividerContainerProps = RequiredPickMap<
  DividerProps,
  'bottom' | 'size' | 'top'
>

export const DividerContainer = styled.hr<DividerContainerProps>(
  ({ $bottom, $size, $top, theme }) => `
  background-color: ${theme.colors.grey.light4};
  height: ${$size === 'small' ? '.1rem' : '.8rem'};
  margin-bottom: ${$bottom};
  margin-top: ${$top};
  width: 100%;
`
)
