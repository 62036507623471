import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNewPhoto: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2a1 1 0 00-1 1v18a1 1 0 001 1h18a1 1 0 001-1V3a1 1 0 00-1-1H3zm.333.833a.5.5 0 00-.5.5v17.334a.5.5 0 00.5.5h17.334a.5.5 0 00.5-.5V3.333a.5.5 0 00-.5-.5H3.333zm2.834 5a1.667 1.667 0 103.333 0 1.667 1.667 0 00-3.333 0zm2.5 0a.833.833 0 11-1.667 0 .833.833 0 011.667 0zm4.583.96L18.527 17H4.33l4.753-5.432 1.373 1.57 2.793-4.346zm-7.083 7.374h10.834l-3.75-5.834-2.691 4.187-1.476-1.687-2.917 3.334z"
        fill="#757575"
      />
      <mask
        id="new-photo_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={20}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 2a1 1 0 00-1 1v18a1 1 0 001 1h18a1 1 0 001-1V3a1 1 0 00-1-1H3zm.333.833a.5.5 0 00-.5.5v17.334a.5.5 0 00.5.5h17.334a.5.5 0 00.5-.5V3.333a.5.5 0 00-.5-.5H3.333zm2.834 5a1.667 1.667 0 103.333 0 1.667 1.667 0 00-3.333 0zm2.5 0a.833.833 0 11-1.667 0 .833.833 0 011.667 0zm4.583.96L18.527 17H4.33l4.753-5.432 1.373 1.57 2.793-4.346zm-7.083 7.374h10.834l-3.75-5.834-2.691 4.187-1.476-1.687-2.917 3.334z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#new-photo_svg__a)" fill="currentColor">
        <path d="M2 2h20v20H2z" />
        <path d="M2 2h20v20H2z" />
      </g>
    </Icon>
  )
}
export default SvgNewPhoto
