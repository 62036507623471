import { z } from 'zod';
import { JSON_LD_SCHEMA } from '../constants'

const ProductSeoPropsSchema = z.object({
  title: z.string(),
});

type ProductSeoProps = z.infer<typeof ProductSeoPropsSchema>;

export const productSeo = ({ title }: ProductSeoProps) => {
  return {
    '@context': JSON_LD_SCHEMA,
    '@type': 'Product',
    'name': title,
  }
}
