import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLink: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.214 12.602l2.308-2.308c1.304-1.304 1.304-3.511 0-4.816-1.305-1.304-3.512-1.304-4.816 0l-2.81 2.81c-1.304 1.304-1.404 3.31-.2 4.715m-2.71-1.806l-2.508 2.509c-1.304 1.304-1.304 3.511 0 4.816 1.305 1.304 3.512 1.304 4.816 0l2.81-2.81c1.304-1.304 1.404-3.31.2-4.715l-5.317.2z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgLink
