import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosManual: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.66 9.432c1.194 0 2.161-.984 2.161-2.197 0-1.214-.967-2.198-2.16-2.198-1.194 0-2.161.984-2.161 2.198 0 1.213.967 2.197 2.16 2.197zM4.66 21c1.194 0 2.161-.984 2.161-2.197 0-1.214-.967-2.198-2.16-2.198-1.194 0-2.161.984-2.161 2.198C2.5 20.016 3.467 21 4.66 21zM11.968 9.432c1.193 0 2.16-.984 2.16-2.197 0-1.214-.967-2.198-2.16-2.198s-2.16.984-2.16 2.198c0 1.213.967 2.197 2.16 2.197zM13.811 3.422c-1.08-.517-2.287-.582-3.368-.065M11.968 21c1.193 0 2.16-.984 2.16-2.197 0-1.214-.967-2.198-2.16-2.198s-2.16.984-2.16 2.198c0 1.213.967 2.197 2.16 2.197zM19.34 9.432c1.192 0 2.16-.984 2.16-2.197 0-1.214-.968-2.198-2.16-2.198-1.194 0-2.161.984-2.161 2.198 0 1.213.967 2.197 2.16 2.197z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.276 9.367v2.262c0 .776-.572 1.357-1.334 1.357H4.66m0-1.81v5.236-5.235zm7.307-1.615v5.235V9.56z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.968 7.816c.316 0 .572-.26.572-.581a.577.577 0 00-.572-.582.577.577 0 00-.572.582c0 .32.256.581.572.581z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAutosManual
