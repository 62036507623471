import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTrailerTravelTrailer: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <path d="M11 10.5h2v2h-2zM15.5 10.5h2v2h-2z" strokeLinejoin="round" />
        <circle cx={12} cy={16} r={1.5} />
      </g>
      <path
        d="M22 15a.5.5 0 00-1 0zm-1 2a.5.5 0 001 0zM4.5 10.5V10a.5.5 0 00-.5.5zm4 0H9a.5.5 0 00-.5-.5zm8-2H16a.5.5 0 00.5.5zM21 15v1h1v-1zm0 1v1h1v-1zm-18-.5v-6H2v6zm18.5 0H20v1h1.5zm-1.5 0h-6.5v1H20zm-.5-6V16h1V9.5zm-15 6H3v1h1.5zm.5.5v-5.5H4V16zm-.5-5h4v-1h-4zm6 4.5h-2v1h2zm-2 0h-4v1h4zm-.5-5V16h1v-5.5zM3.5 9H12V8H3.5zm10-1.5H15v-1h-1.5zM12 9h4.5V8H12zm4.5 0H19V8h-2.5zm-4-.5a1 1 0 011-1v-1a2 2 0 00-2 2zm8 1A1.5 1.5 0 0019 8v1a.5.5 0 01.5.5zM3 9.5a.5.5 0 01.5-.5V8A1.5 1.5 0 002 9.5zm12-2a1 1 0 011 1h1a2 2 0 00-2-2zm-13 8a1 1 0 001 1v-1z"
        fill="#3e4153"
      />
    </Icon>
  )
}
export default SvgTrailerTravelTrailer
