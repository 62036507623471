import { RadioButton, RadioButtonProps } from '@/ui/atoms/radio-button'
import { createSequentialId } from '@/ui/helpers/createSequentialId'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

import { FormControl } from '../form-control'
import { RadioFieldset } from './styled'

export type RadiosFieldProps = {
  /**
   * Gap between checkbox fields
   * @default 1.8rem
   */
  gap?: string
  /**
   * Legend that describes the radio group
   */
  legend: string
  /**
   * List of radio buttons to be rendered
   */
  radios: RadioButtonProps[]
  /**
   * Name of the radio group - It will link the radio buttons together
   */
  name: string
} & Omit<CommonInputFieldProps, 'label' | 'maxLength' | 'value'>

/**
 * @description Group of Radio inputs + Aria labelling & Error field.
 */
export const RadiosField = ({
  bottom,
  error,
  gap = '1.8rem',
  legend,
  name,
  radios,
  id = name,
  ...rest
}: RadiosFieldProps) => {
  const sequentialErrorId =
    error && createSequentialId(`${name}-radio-group-error-`)()

  return (
    <FormControl
      bottom={bottom}
      data-testid={createSequentialId('checkboxes-field-')()}
      error={error}
      errorId={sequentialErrorId}
      id={id}
      {...rest}
    >
      <RadioFieldset legend={legend} $gap={gap}>
        {radios?.map(({ id, ...radioProps }) => (
          <RadioButton
            name={name}
            key={`radio-${id}`}
            id={id}
            errorId={sequentialErrorId}
            {...radioProps}
          />
        ))}
      </RadioFieldset>
    </FormControl>
  )
}
