import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAdditionalOptions: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 21a9 9 0 100-18 9 9 0 000 18z" fill="#f8aa17" />
      <path
        clipRule="evenodd"
        d="M6.8 11.9l4.5 4.2c.3.3.7.4 1.1.4s.8-.1 1.1-.4l7.2-6.6c-.3-1-.8-1.9-1.4-2.7l-6.9 6.4L9 9.9c-.6-.5-1.5-.5-2.1 0-.7.5-.7 1.4-.1 2z"
        fill="#fff"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgAdditionalOptions
