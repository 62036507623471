import { type LanguageKey } from '@/domain/locale'
import GoogleMaps from '@/lib/google/GoogleMaps'
import { sendToLogger } from '@/utils/sendToLogger'

export const getGoogleMapsKey = async (
  languageKey: LanguageKey
): Promise<{ apiKey?: string; error?: unknown }> => {
  try {
    const google = GoogleMaps.getInstance(languageKey)
    await google.getGoogleMapsAPIKey()

    return { apiKey: google.apiKey }
  } catch (error) {
    sendToLogger(error, { fingerprint: ['MapProviderAPIKey'] })
    return { error }
  }
}
