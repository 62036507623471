import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCopy: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.586 2.134H9.375a1.504 1.504 0 00-1.502 1.503v2.496H3.377a1.504 1.504 0 00-1.502 1.503V20.63a1.504 1.504 0 001.502 1.502h10.996a1.504 1.504 0 001.502-1.502v-2.495h4.498a1.504 1.504 0 001.502-1.503V7.555l-5.29-5.42zm.289 1.729l3.191 3.27h-2.69a.501.501 0 01-.501-.5v-2.77zm-2.502 17.27H3.377a.503.503 0 01-.502-.502V7.636a.503.503 0 01.502-.503h6.498v3.5a1.503 1.503 0 001.501 1.5h3.499v8.498a.503.503 0 01-.502.502zM10.875 7.862l3.192 3.271h-2.691a.501.501 0 01-.501-.5V7.861zm9.498 9.274h-4.498v-5.581l-5.29-5.422H8.874V3.637a.503.503 0 01.502-.503h6.5v3.498a1.502 1.502 0 001.501 1.501h3.499v8.5a.503.503 0 01-.502.503z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgCopy
