import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgConditionUsed: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.25 5.75v4.375H8M19.25 17v-4.375l-3.75.625" />
        <path d="M17.539 9.083a6.974 6.974 0 00-1.709-2.846 5.932 5.932 0 00-2.722-1.567 5.5 5.5 0 00-3.06.1 6.037 6.037 0 00-2.634 1.745L4.25 9.847m15 3.056l-3.164 3.332a6.038 6.038 0 01-2.635 1.744 5.5 5.5 0 01-3.059.101 5.932 5.932 0 01-2.722-1.567 6.973 6.973 0 01-1.709-2.846" />
      </g>
    </Icon>
  )
}
export default SvgConditionUsed
