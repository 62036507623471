import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosConvertible: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.71 10.459l.368-.547M18.664 10.401l.123-.288M11.4 8.5c-1.44.691-3.28 1.93-3.74 2.218a.669.669 0 01-.336.115c-2.82.375-4.383.663-4.904.864-.522.26-.859.75-.92 1.297v.893c0 .374.306.662.705.662h1.073M20.779 14.434a2.64 2.64 0 00.98-.374c.369-.288.736-.576.736-1.037v-.173c.03-.23-.092-.49-.276-.634l-.337-.173-.03-1.094c0-.202-.154-.346-.368-.375h-5.272c-2.146-.029-4.291.115-6.406.375M20.87 11.755h1.012"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.592 13.253h.46c.398 0 .735-.288.735-.662V12.504M13.637 11.697h.675M7.66 14.607h9.042M4.871 15.5c.83 0 1.502-.632 1.502-1.412 0-.779-.672-1.411-1.502-1.411-.829 0-1.501.632-1.501 1.412 0 .78.672 1.411 1.501 1.411zM18.082 15.5c.83 0 1.502-.632 1.502-1.412 0-.779-.673-1.411-1.502-1.411-.83 0-1.502.632-1.502 1.412 0 .78.672 1.411 1.502 1.411z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosConvertible
