import {
  CATEGORIES,
  isAllCategoriesExceptVerticals,
  isAnyJobsCategory,
  isAnyServicesCategory,
} from '@kijiji/category'
import ShippingIcon from '@kijiji/icons/src/icons/Shipping'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger, ShowWhenSmaller } from '@/components/shared/breakpoint'
import { ImageYAMS } from '@/components/shared/image/ImageYAMS'
import { FavouriteButton } from '@/components/shared/listing/FavouriteButton'
import { AttributeList } from '@/components/srp/listing-card/attribute-list'
import { AutosBadges } from '@/components/srp/listing-card/autos-badges'
import { AutosListingCard } from '@/components/srp/listing-card/AutosListingCard'
import { ListingDetails } from '@/components/srp/listing-card/listing-details'
import { ListingImageBadge } from '@/components/srp/listing-card/listing-image-badge'
import { ListingPrice } from '@/components/srp/listing-card/listing-price'
import { ListingPriceVariant } from '@/components/srp/listing-card/listing-price/ListingPrice'
import { AdditionalOptions } from '@/components/srp/listing-card/listing-updates'
import { RealEstateAttributesList } from '@/components/srp/listing-card/real-estate-attributes/RealEstateAttributes'
import {
  ListingBadge,
  ListingCardBody,
  ListingCardContainer,
  ListingCardDescription,
  ListingCardDetails,
  ListingCardHeading,
  ListingCardLink,
  ListingCardLogoContainer,
  ListingCardTopData,
  ListingCardWithDescription,
  ListingImageBase,
  ListingRightSide,
  ShippingBadge,
} from '@/components/srp/listing-card/styled'
import {
  type BadgeIconType,
  type ListingCardPrice,
  type ListingCardProps,
} from '@/components/srp/listing-card/types'
import {
  getCarsVehiclesAttributes,
  shouldShowPriceDrop,
  SRP_ATTRIBUTES,
} from '@/components/srp/search-list/domain'
import { getRealEstateListingAttributes } from '@/components/srp/search-list/getRealEstateListingAttributes'
import { highlightKeywords } from '@/components/srp/search-list/highlightKeywords'
import { Showcase } from '@/components/srp/showcase'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { getSearchCategoryFromSearchQuery } from '@/domain/category/getSearchCategoryFromSearchQuery'
import { getListingBadgeLabel } from '@/domain/listings/getListingBadgeLabel'
import { getListingProximityLabel } from '@/domain/srp/getListingProximityLabel'
import { isSeeAllTopAdsView } from '@/domain/srp/isSeeAllTopAdsView'
import { trackEventListingClick } from '@/domain/srp/tracking/trackEventListingClick'
import { truncateText } from '@/domain/srp/truncateText'
import { getAttributesCanonicalValuesDictionary } from '@/features/attributes/utils/getAttributesCanonicalValuesDictionary'
import { useGetSearchKeyword } from '@/hooks/keywords/useGetSearchKeywords'
import { useGetSearchResultsData } from '@/hooks/srp'
import { useLocale } from '@/hooks/useLocale'
import { useSearchListCrossPromoConfig } from '@/hooks/useSearchListCrossPromoConfig'
import { isAutosListing } from '@/types/search'
import { BodyText } from '@/ui/atoms/body-text'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'

export const ListingBadgeIcons: { [x in BadgeIconType]: ReactNode } = {
  shippedBySeller: <ShippingIcon size="xsm" aria-hidden />,
}

export const ListingCard: FC<ListingCardProps> = ({
  item,
  isTopAd,
  isMobile,
  index,
  isMapCard = false,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.SRP, TRANSLATION_KEYS.LISTING])

  const { query } = useRouter()
  const { colors, spacing } = useTheme()
  const { routeLocale } = useLocale()
  const { keyword: globalKeyword } = useGetSearchKeyword()
  const { data } = useGetSearchResultsData()
  const { searchQuery, results } = data || {}
  const category = getSearchCategoryFromSearchQuery(searchQuery?.category)

  const { mainListings: listings = [] } = results || {}
  const { listingsWithCrossPromotion } = useSearchListCrossPromoConfig(listings, category)
  const {
    adSource,
    sortingDate,
    description,
    id,
    imageUrls,
    location: { name: locationName, nearestIntersection },
    title,
    url,
    price,
    categoryId,
    flags,
    attributes,
  } = item
  const attributesDictionary = getAttributesCanonicalValuesDictionary(attributes?.all)

  const itemDescription = description ?? ''

  const isHighlight = !!flags?.highlight

  const logoSrc = attributesDictionary[SRP_ATTRIBUTES.LOGO]?.[0]

  const listingCardWidth = isHighlight ? 500 : 400

  const isSeeAllTopAd = isSeeAllTopAdsView(query)

  const isInTopAdsSection = isTopAd || isSeeAllTopAd

  const { additionalOptions, realEstateAttributes, rentalBadge, virtualTour } =
    getRealEstateListingAttributes(attributesDictionary, nearestIntersection, categoryId, t)

  const { carfax, carsVehiclesAttributes, cpo, dealerUpdates, isNew } = getCarsVehiclesAttributes(
    attributesDictionary,
    categoryId,
    routeLocale,
    t
  )
  const hasFinancingOption = !!('financingAvailable' in flags && flags?.financingAvailable)
  const msrp = (price && 'msrp' in price && isNew && price.msrp) || undefined
  const amount = (price && 'amount' in price && price.amount) || undefined
  const originalAmount = (price && 'originalAmount' in price && price.originalAmount) || undefined
  const surcharges = (price && 'surcharges' in price && price.surcharges) || undefined
  const hasPriceDropValue = shouldShowPriceDrop(!!flags?.priceDrop, originalAmount)

  const cardPrice: ListingCardPrice = {
    amount: amount,
    original: hasPriceDropValue ? originalAmount : undefined,
    type: price?.type,
    surcharges,
    msrp,
  }

  /** We want to hide listing price for any services or jobs category */
  const shouldHideListingPrice = isAnyServicesCategory(categoryId) || isAnyJobsCategory(categoryId)

  /** Testing use of AVIF only in Buy & Sell category */
  const yamsImageExtension = category.id === CATEGORIES.BUY_AND_SELL_CATEGORY_ID ? 'AVIF' : 'WEBP'

  /**
   * Elements will show up in the listing in different placements depending on the screen size
   * render functions avoids duplication of components definition
   *  */
  const renderInlineAttributes = (testId: string) => {
    const shouldShowInlineAttributes = !!carsVehiclesAttributes && carsVehiclesAttributes.length > 0
    return (
      <>
        {shouldShowInlineAttributes && (
          <AttributeList attributes={carsVehiclesAttributes} listingId={id} testId={testId} />
        )}
      </>
    )
  }
  /**
   * Adds real estate attributes (icons + value) to the listing card
   * Only for the long term rentals (LTRs) category (categoryId = 37)
   * @param {string} testId - data-testId for the list of attributes
   * @returns {ReactNode | null} - list of real estate attributes or null if not LTRs category
   */
  const renderRealEstateAttributes = (testId: string) => {
    return categoryId === CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID ? (
      <RealEstateAttributesList
        attributes={realEstateAttributes}
        listingId={id}
        testId={testId}
      ></RealEstateAttributesList>
    ) : null
  }

  const renderRealEstateBadges = (testId: string) => {
    if (!rentalBadge && !virtualTour) return null

    return (
      <Flex data-testid={testId} gap={spacing.defaultSmall}>
        {virtualTour && (
          <ListingBadge bgColor={colors.grey.primary}>
            {t('srp:listing.attributes.virtual_tour')}
          </ListingBadge>
        )}

        {rentalBadge && (
          <ListingBadge data-testid="listing-rental-badge" bgColor={colors.violet.primary}>
            {rentalBadge}
          </ListingBadge>
        )}
      </Flex>
    )
  }

  const renderLogo = (testId: string) => {
    if (!logoSrc) return null

    return (
      <ListingCardLogoContainer>
        <ImageYAMS data-testid={testId} src={logoSrc} fill alt="" />
      </ListingCardLogoContainer>
    )
  }

  const renderShippedBySellerBadge = (testId: string) => {
    if (!isShippedBySeller) return null

    return (
      <ShippingBadge as="div" data-testid={testId}>
        {ListingBadgeIcons.shippedBySeller}
        <span>{t(`listing:flag.shipping`)}</span>
      </ShippingBadge>
    )
  }

  const renderAutosBadge = (testId: string) => {
    if (!carfax && !cpo && !hasFinancingOption) return null

    return (
      <AutosBadges testId={testId}>
        {carfax && <AutosBadges.CarFax />}
        {cpo && <AutosBadges.CertifiedPreOwned />}
        {hasFinancingOption && <AutosBadges.Financing />}
      </AutosBadges>
    )
  }

  const getListingCardContent = () => {
    const showListingCardWithDescription =
      itemDescription && isAllCategoriesExceptVerticals(categoryId)

    if (showListingCardWithDescription) {
      return (
        <ListingCardWithDescription
          color={colors.grey.light1}
          data-testid="listing-description"
          size="medium"
        >
          {highlightKeywords(id, itemDescription, globalKeyword)}
        </ListingCardWithDescription>
      )
    }
    return (
      <ListingCardDescription
        color={colors.grey.light1}
        data-testid="listing-description"
        size="medium"
      >
        {highlightKeywords(id, itemDescription, globalKeyword)}
      </ListingCardDescription>
    )
  }

  const showcaseImages = flags?.showcase ? item.imageUrls?.slice(1) : []
  const hasShowcaseImages = showcaseImages.length > 0

  const isShippedBySeller = !!flags?.shippedBySeller

  /** If is a conventional listing, then find all the values needed to render it */
  const imageBadge = getListingBadgeLabel({
    categoryId: categoryId,
    categorySpecificBadge: !!flags?.categorySpecificBadge,
  })

  /**
   * Distance
   * Should return the proximity value from the user in meters
   */
  const proximity = getListingProximityLabel(item.location)

  const handleTrackListingClick = () => {
    trackEventListingClick({
      listing: item,
      position: index,
      isSeeAllTopAd,
      keywords: globalKeyword,
      photoshowcaseClick: hasShowcaseImages,
    })
  }

  const isCrossPromotionCard = listingsWithCrossPromotion?.includes(item.id)

  if (isAutosListing(item)) {
    return (
      <AutosListingCard
        item={item}
        price={cardPrice}
        isCrossPromotionCard={isCrossPromotionCard}
        handleTrackListingClick={handleTrackListingClick}
        isTopAd={isTopAd}
        isMobile={isMobile}
        index={index}
      />
    )
  }

  return (
    <ListingCardContainer
      data-testid="listing-card"
      data-listingid={id}
      {...(imageBadge ? { 'data-badge': imageBadge } : {})}
    >
      <ListingCardTopData isHighlight={isHighlight}>
        <ListingImageBase
          badge={imageBadge ? <ListingImageBadge imageBadge={imageBadge} /> : null}
          data-testid={`listing-card-image-container${isHighlight ? '-highlight' : ''}`}
          imageComponent={
            <ImageYAMS
              alt={truncateText(itemDescription, 100) || title}
              data-testid="listing-card-image"
              src={imageUrls[0]}
              width={listingCardWidth}
              height={listingCardWidth}
              priority={isInTopAdsSection && index === 0}
              extension={yamsImageExtension}
              loading={index < 2 ? 'eager' : 'lazy'}
            />
          }
          size={isHighlight ? 'large' : 'medium'}
        />
        <ListingCardDetails
          justifyContent="space-between"
          media={{
            medium: {
              gap: spacing.default,
            },
            large: {
              gap: '5.6rem',
            },
          }}
        >
          <ListingCardBody>
            {shouldHideListingPrice ? null : (
              <ListingPrice
                displayInlinePrice={isHighlight}
                msrp={msrp}
                priceAmount={cardPrice.amount}
                priceOriginal={cardPrice.original}
                type={cardPrice.type}
                surcharges={cardPrice.surcharges}
                isCategoryFreeStuff={categoryId === CATEGORIES.GIVE_AWAY_CATEGORY_ID}
                variant={isMapCard ? ListingPriceVariant.Autos : undefined}
              />
            )}

            {!isMapCard && (
              <>
                <ListingCardHeading
                  color={colors.grey.primary}
                  data-testid="listing-title"
                  forwardedAs="h3"
                  size="medium"
                >
                  <ListingCardLink
                    data-testid="listing-link"
                    href={url}
                    onClick={handleTrackListingClick}
                  >
                    {highlightKeywords(id, title, globalKeyword)}
                  </ListingCardLink>
                </ListingCardHeading>

                <ListingDetails
                  sortingDate={sortingDate}
                  locationName={locationName}
                  proximity={proximity}
                  isShippedBySeller={isShippedBySeller}
                  listingAdSource={adSource}
                  isMobileFormat={false}
                />

                {itemDescription && getListingCardContent()}
              </>
            )}

            <ShowAtOrLarger breakpoint="medium">
              <Flex gap={spacing.defaultSmall} flexDirection="column">
                {renderInlineAttributes('attribute-list-non-mobile')}
                {renderRealEstateAttributes('re-attribute-list-non-mobile')}
                {!isMapCard && renderRealEstateBadges('listing-rental-badge-non-mobile')}
                {renderAutosBadge('listing-autos-badges-non-mobile')}
                {renderShippedBySellerBadge('shipping-badge')}
              </Flex>
            </ShowAtOrLarger>

            {isMapCard && (
              <BodyText
                color={colors.grey.light1}
                size="xSmall"
                style={{ display: 'flex', gap: 4, alignItems: 'center' }}
              >
                {item.location.address}
              </BodyText>
            )}
          </ListingCardBody>

          <ListingRightSide>
            <FavouriteButton listingId={id} />
            <ShowAtOrLarger breakpoint="medium">
              {renderLogo('logo-container-non-mobile')}
            </ShowAtOrLarger>
          </ListingRightSide>
        </ListingCardDetails>
      </ListingCardTopData>

      <ShowWhenSmaller breakpoint="medium">
        <Flex flexDirection="column" gap={spacing.defaultSmall}>
          {renderAutosBadge('listing-autos-badges-mobile')}
          {renderInlineAttributes('attribute-list-mobile')}
          {renderRealEstateAttributes('re-attribute-list-mobile')}
          {!isMapCard && renderRealEstateBadges('listing-rental-badge-mobile')}
          {renderLogo('logo-container-mobile')}
        </Flex>
      </ShowWhenSmaller>

      {isMapCard && (
        <>
          <Divider color={colors.grey.light4} style={{ marginLeft: '-1.6rem', width: '106%' }} />
          {renderRealEstateBadges('listing-rental-badge-non-mobile')}
        </>
      )}

      {!isMapCard && (!!dealerUpdates.length || !!additionalOptions?.length) && (
        <AdditionalOptions
          options={[...dealerUpdates, ...(additionalOptions || [])]}
          isDealerUpdates={!!dealerUpdates.length}
          listingId={id}
        />
      )}

      {!!showcaseImages?.length && (
        <Showcase
          seoUrl={url}
          showcaseImages={showcaseImages}
          handleListingCardClick={handleTrackListingClick}
          baseAltText={truncateText(itemDescription, 100)}
        />
      )}
    </ListingCardContainer>
  )
}
