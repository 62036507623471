import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPersonalOutdoorSpace: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#personal-outdoor-space_svg__clip0_1184_745)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.953 20.037c-.655.637-1.275.96-1.847.96H8.894c-.572 0-1.193-.323-1.847-.96-1.283-1.251-2.018-3.073-2.018-4.996v-.788H18.97v.788c0 .162-.012.321-.023.48H8.11a.508.508 0 00-.514.502c0 .277.23.502.514.502H18.82c-.276 1.355-.922 2.59-1.867 3.512zM6.11 8.139a2.69 2.69 0 01-.758-.97c.395.052.889.179 1.274.475.369.285.591.692.722 1.034-.38-.084-.852-.241-1.238-.539zm5.843-4.805c.212.311.435.75.448 1.238.014.487-.18.955-.374 1.296-.204-.335-.415-.795-.428-1.283-.014-.487.17-.932.354-1.25zm5.58 4.35c.39-.301.873-.438 1.256-.499-.135.336-.363.741-.74 1.031-.39.301-.881.44-1.274.503.145-.34.382-.744.759-1.035zm2.454 6.09c0-.007.005-.015.005-.023a.508.508 0 00-.515-.501h-4.834a.466.466 0 00.03-.147v-1.647c0-.178.077-.35.21-.471l1.313-1.189c.007-.007.009-.016.016-.023.428-.006 1.59-.086 2.476-.77 1.094-.844 1.291-2.241 1.299-2.3a.493.493 0 00-.113-.385.517.517 0 00-.368-.18c-.06-.002-1.529-.077-2.61.76-.868.67-1.193 1.684-1.295 2.1-.037.02-.074.035-.106.063l-1.313 1.19c-.341.31-.538.749-.538 1.205v1.647c0 .053.015.1.03.147h-1.13c.001-.016.01-.03.01-.046V6.915c.273-.353.905-1.284.876-2.37-.038-1.362-1.109-2.365-1.155-2.408a.494.494 0 00-.372-.136.517.517 0 00-.36.163c-.042.044-1.01 1.089-.973 2.448.03 1.07.67 1.964.955 2.311v6.28c0 .017.007.03.009.047h-1.252a.466.466 0 00.03-.147v-1.646c0-.456-.197-.897-.54-1.207L8.53 9.123c-.056-.34-.312-1.529-1.266-2.265-1.09-.84-2.621-.724-2.687-.72a.517.517 0 00-.366.196.493.493 0 00-.096.398c.012.055.277 1.36 1.356 2.193.79.61 1.774.78 2.258.828.013.013.018.03.031.043l1.312 1.189c.135.121.21.294.21.472v1.646c0 .053.016.1.032.147H4.62a.51.51 0 00-.386.18.487.487 0 00-.234.41v1.201c0 2.188.846 4.268 2.32 5.705C7.174 21.58 8.04 22 8.894 22h6.212c.854 0 1.72-.421 2.574-1.254C19.154 19.31 20 17.23 20 15.041V13.84c0-.023-.01-.043-.013-.066z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="personal-outdoor-space_svg__clip0_1184_745">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgPersonalOutdoorSpace
