import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTrailerFifthWheel: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <path
          d="M4.5 10.643h2v2.143h-2zM8.5 10.643h2v2.143h-2zM17.5 10.643h1v.047h-1zM12.5 10.643h2v4.238h-2z"
          strokeLinejoin="round"
        />
        <circle cx={5.75} cy={16.083} r={1.25} />
        <circle cx={9.25} cy={16.083} r={1.25} />
        <path d="M7 16.5h1" strokeLinejoin="round" />
      </g>
      <path
        d="M17.5 12.238v-.5a.5.5 0 00-.5.5zm3 1.048a.5.5 0 101 0zm-3.5 4.19a.5.5 0 001 0zm-6.5-8.905H10a.5.5 0 00.5.5zM3 14.43V10.57H2v3.858zm14.5 1.5h-7v1h7zM4.5 9.07h1v-1h-1zM7.071 7.5H8.93v-1H7.07zM5.5 9.071h5v-1h-5zm5 0H18v-1h-7.5zm10 2.5v.667h1v-.667zm.5.167h-3.5v1H21zm-4 .5v4.19h1v-4.19zm3.5 0v1.048h1v-1.048zm-3.5 4.19v1.048h1V16.43zM8.929 7.5C9.52 7.5 10 7.98 10 8.571h1A2.071 2.071 0 008.929 6.5zM18 9.071a2.5 2.5 0 012.5 2.5h1a3.5 3.5 0 00-3.5-3.5zm-12-.5C6 7.98 6.48 7.5 7.071 7.5v-1A2.071 2.071 0 005 8.571zm-3 2a1.5 1.5 0 011.5-1.5v-1a2.5 2.5 0 00-2.5 2.5zM2 14.43a2.5 2.5 0 002.5 2.5v-1a1.5 1.5 0 01-1.5-1.5z"
        fill="#3e4153"
      />
    </Icon>
  )
}
export default SvgTrailerFifthWheel
