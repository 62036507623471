import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgServices: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M19.676 7.15a.5.5 0 00-.82-.178l-2.99 2.974-1.515-.301-.28-1.49 3.007-2.992a.499.499 0 00-.169-.82 4.858 4.858 0 00-6.283 6.371c-5.474 3.976-5.647 4.15-5.712 4.215a2.972 2.972 0 004.202 4.201c.062-.062.208-.208 3.148-4.204a.502.502 0 00-.283-.784.499.499 0 00-.523.191c-2.556 3.474-2.99 4.024-3.05 4.09a2.017 2.017 0 01-2.787 0 1.97 1.97 0 01-.008-2.78c.093-.084.859-.677 5.933-4.36a.5.5 0 00.15-.635 3.857 3.857 0 014.07-5.59l-2.589 2.576a.5.5 0 00-.139.447l.393 2.08a.499.499 0 00.394.398l2.107.42a.489.489 0 00.45-.136l2.566-2.552a3.853 3.853 0 01-5.519 4.054.5.5 0 10-.438.898 4.856 4.856 0 006.685-6.093z" />
        <path d="M6.996 17.63a.625.625 0 100-1.25.625.625 0 000 1.25z" />
      </g>
    </Icon>
  )
}
export default SvgServices
