import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgStats: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3 16l5-5 4.5 4.5L20 8h-5.1M20 13.1V8"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgStats
