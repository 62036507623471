import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSecureOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M10.5 14.75a.748.748 0 01-.53-.22l-2.25-2.25a.75.75 0 111.06-1.06l1.72 1.72 5.22-5.22a.75.75 0 111.06 1.06l-5.75 5.75a.747.747 0 01-.53.22z" />
        <path
          clipRule="evenodd"
          d="M5 3h14a1 1 0 011 1v5.456c0 5.14-3.188 9.74-8 11.544A12.33 12.33 0 014 9.456V4a1 1 0 011-1zm7 16.382c3.91-1.709 6.5-5.628 6.5-9.926V4.5h-13v4.956c0 4.298 2.59 8.217 6.5 9.926z"
          fillRule="evenodd"
        />
      </g>
    </Icon>
  )
}
export default SvgSecureOutline
