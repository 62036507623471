import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFacebookSmall: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.2 0H.8C.4 0 0 .3 0 .8v12.5c0 .4.3.8.8.8h6.7V8.6H5.7V6.5h1.8V5c0-1.8 1.1-2.8 2.7-2.8.5 0 1.1 0 1.6.1v1.8h-1.1c-.9 0-1 .4-1 1v1.3h2.1l-.3 2.1H9.7v5.4h3.6c.4 0 .8-.3.8-.8V.8c-.1-.4-.4-.8-.9-.8z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgFacebookSmall
