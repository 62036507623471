import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMoveInDate: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.643 13.584l-2.486 2.562-.827-.828a.5.5 0 00-.707.707l1.186 1.186a.5.5 0 00.354.147c.137-.024.264-.055.358-.152l2.84-2.926a.5.5 0 00-.718-.696z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.99 18.618c0 .827-.673 1.5-1.5 1.5h-11c-.828 0-1.5-.673-1.5-1.5v-7.5h11.469a.5.5 0 000-1H4.989v-2.5c0-.827.673-1.5 1.5-1.5H9v1.5a.5.5 0 101 0v-1.5h4.029v1.5a.5.5 0 101 0v-1.5h2.462c.827 0 1.5.673 1.5 1.5v11zm-1.5-13.5h-2.462v-1.5a.5.5 0 00-1 0v1.5H10v-1.5a.5.5 0 00-1 0v1.5H6.49a2.504 2.504 0 00-2.5 2.5v11c0 1.378 1.121 2.5 2.5 2.5h11c1.378 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.122-2.5-2.5-2.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgMoveInDate
