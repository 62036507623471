import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotificationNoImage: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="notification-no-image_svg__a"
        height={48}
        maskUnits="userSpaceOnUse"
        width={48}
        x={0}
        y={0}
      >
        <circle cx={24} cy={24} fill="#fff" r={24} />
      </mask>
      <mask
        id="notification-no-image_svg__b"
        height={18}
        maskUnits="userSpaceOnUse"
        width={20}
        x={14}
        y={15}
      >
        <path
          clipRule="evenodd"
          d="M15 27.064c0 .282.234.518.533.538l13.411-.001c.292-.026.519-.26.519-.537v-9.597a.482.482 0 00-.148-.351.402.402 0 00-.319-.114H15.459a.433.433 0 00-.311.114.482.482 0 00-.148.35zm15.463 0c0 .807-.644 1.48-1.467 1.536H15.5c-.856-.055-1.5-.73-1.5-1.536v-9.597c0-.41.169-.804.465-1.081a1.403 1.403 0 011.068-.382l13.43-.001c.36-.025.743.11 1.035.383.296.277.465.67.465 1.08zm2.738-7.615c.225.02.44.13.591.312a.88.88 0 01.204.647l-.943 10.783a.88.88 0 01-.314.602.88.88 0 01-.647.204l-14.634-1.28a.501.501 0 01-.455-.54.502.502 0 01.542-.456l14.521 1.27.924-10.56-1.437-.157a.5.5 0 01.11-.994z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <circle cx={24} cy={24} fill="#d2d3d7" r={24} />
      <g fill="#3e4153" mask="url(#notification-no-image_svg__a)">
        <path
          clipRule="evenodd"
          d="M15 27.064c0 .282.234.518.533.538l13.411-.001c.292-.026.519-.26.519-.537v-9.597a.482.482 0 00-.148-.351.402.402 0 00-.319-.114H15.459a.433.433 0 00-.311.114.482.482 0 00-.148.35zm15.463 0c0 .807-.644 1.48-1.467 1.536H15.5c-.856-.055-1.5-.73-1.5-1.536v-9.597c0-.41.169-.804.465-1.081a1.403 1.403 0 011.068-.382l13.43-.001c.36-.025.743.11 1.035.383.296.277.465.67.465 1.08zm2.738-7.615c.225.02.44.13.591.312a.88.88 0 01.204.647l-.943 10.783a.88.88 0 01-.314.602.88.88 0 01-.647.204l-14.634-1.28a.501.501 0 01-.455-.54.502.502 0 01.542-.456l14.521 1.27.924-10.56-1.437-.157a.5.5 0 01.11-.994z"
          fillRule="evenodd"
        />
        <g mask="url(#notification-no-image_svg__b)">
          <path d="M12 12h24v24H12z" />
          <path d="M12 12h24v24H12z" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgNotificationNoImage
