import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgDesktopBrand: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.564 15.125H2V4.5h18.195v7.188l-2.631 3.437zm-1.504 2.5H6.135h9.925zm-5.79-2.266v2.032-2.032zm1.655 0v.86-.86zm7.143-2.109H22v6.25h-2.932v-6.25z"
        stroke="#3E4153"
        strokeWidth={0.677}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.496 15.047a.46.46 0 00.452-.469.46.46 0 00-.452-.469.46.46 0 00-.451.47.46.46 0 00.451.468zM20.496 16.453a.46.46 0 00.452-.469.46.46 0 00-.452-.468.46.46 0 00-.451.468.46.46 0 00.451.47zM20.496 17.937a.46.46 0 00.452-.469.46.46 0 00-.452-.468.46.46 0 00-.451.468.46.46 0 00.451.47zM4.706 7.547a.384.384 0 00.376-.39.384.384 0 00-.376-.391.384.384 0 00-.376.39c0 .216.168.39.376.39zM7.264 7.547a.384.384 0 00.376-.39.384.384 0 00-.376-.391.384.384 0 00-.376.39c0 .216.168.39.376.39zM9.82 7.547a.384.384 0 00.376-.39.384.384 0 00-.376-.391.384.384 0 00-.377.39c0 .216.169.39.377.39zM12.376 7.547a.384.384 0 00.376-.39.384.384 0 00-.376-.391.384.384 0 00-.376.39c0 .216.168.39.376.39zM14.933 7.547a.384.384 0 00.376-.39.384.384 0 00-.376-.391.384.384 0 00-.376.39c0 .216.168.39.376.39zM4.706 10.203a.384.384 0 00.376-.39.384.384 0 00-.376-.391.384.384 0 00-.376.39c0 .216.168.391.376.391zM7.264 10.203a.384.384 0 00.376-.39.384.384 0 00-.376-.391.384.384 0 00-.376.39c0 .216.168.391.376.391zM9.82 10.203a.384.384 0 00.376-.39.384.384 0 00-.376-.391.384.384 0 00-.377.39c0 .216.169.391.377.391zM4.706 12.86a.384.384 0 00.376-.391.384.384 0 00-.376-.391.384.384 0 00-.376.39c0 .216.168.391.376.391z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgDesktopBrand
