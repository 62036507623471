import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAccessibleWashroom: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.546 3.511a.144.144 0 01.107-.05h8.693c.05 0 .085.026.106.05.042.046.061.11.054.167l-1.116 7.74H8.7L7.493 3.69a.222.222 0 01.052-.179zm9.267 8.048h-.44l1.118-7.758a1.155 1.155 0 00-1.146-1.3H7.654A1.156 1.156 0 006.51 3.822l1.205 7.736H7.26a.489.489 0 00-.496.48v3.47c.068 1.458.76 2.537 2.081 3.22.743.332.878.629.868 1.06a.488.488 0 00.484.492c.26 0 .502-.204.509-.469.022-.896-.424-1.498-1.418-1.942-.98-.507-1.48-1.293-1.531-2.382V12.52h8.56v1.136h-6.013a.487.487 0 00-.497.48c0 .265.222.48.497.48h6.013v.862a2.912 2.912 0 01-1.541 2.4c-.876.426-1.454.92-1.43 1.936v.725H8.659a.488.488 0 00-.496.48c0 .266.222.481.496.481h6.756a.488.488 0 00.497-.48.487.487 0 00-.497-.48H14.34v-.737c-.011-.429.073-.673.898-1.074A3.892 3.892 0 0017.31 15.5V12.04a.489.489 0 00-.497-.48z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.536 6.197a.897.897 0 00.739-.398h1.021a.488.488 0 00.497-.48.489.489 0 00-.497-.48h-1.021a.899.899 0 00-.74-.397c-.5 0-.907.393-.907.877 0 .485.408.878.908.878z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAccessibleWashroom
