import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgElectrfuel: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.825 2.804c.264.114.421.39.386.675l-.726 5.806H19.1a.649.649 0 01.499 1.063L11.43 20.15a.649.649 0 01-1.141-.495l.725-5.806H4.4a.649.649 0 01-.499-1.064l8.169-9.802a.649.649 0 01.756-.18zm-7.041 9.748h5.966a.649.649 0 01.644.729l-.543 4.34 5.865-7.04H11.75a.649.649 0 01-.644-.728l.543-4.34-5.865 7.039z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgElectrfuel
