import { SRP_LISTING_LIMIT } from '@/constants/pageSettings'

type getCurrentPageFromPaginationProps = {
  offset?: number
  limit?: number
}

export const getCurrentPageFromPagination = ({
  offset = 0,
  limit = SRP_LISTING_LIMIT,
}: getCurrentPageFromPaginationProps) => (offset === 0 && limit === 0 ? 0 : offset / limit) + 1
