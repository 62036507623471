import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPaddleBoat: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153" strokeLinecap="round" strokeLinejoin="round">
        <path d="M15.333 5L22 6.5M15.333 5l.445 3m-.445-3h-1.777M22 6.5L15.778 8M22 6.5s-1.452 2.426-2.667 3.5c-1.014.897-2.814 1.69-3.38 1.928a.943.943 0 01-.366.072H4a2 2 0 01-2-2V8m13.778 0h-2.222M2 8l1.487-2.51a1 1 0 01.86-.49h2.097M2 8h2.667m8.889 0V5m0 3H6.889m6.667-3h-4.89m0 0L6.89 8m1.778-3H6.444m.445 3H4.667m0 0l1.777-3M11 6.5l-3.182 9.056m0 0L4.5 18.5l3 1.5zM13 15h6M2 15h2" />
      </g>
    </Icon>
  )
}
export default SvgPaddleBoat
