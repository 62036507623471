import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgStartDate: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.22 20.154V9.179M5.78 4.154H2.494C1.697 4.154 1 4.872 1 5.692v14.77C1 21.282 1.697 22 2.494 22h21.012c.797 0 1.494-.718 1.494-1.538V5.692c0-.82-.697-1.538-1.494-1.538H5.78zM1.1 8.974h21.809-21.81zm1.991 4.308h21.81H3.09zM1.1 17.59h21.809-21.81zm4.78 4.307V9.18v12.718zm4.78-1.743V9.179v10.975zm4.78 1.743v-11.18 11.18z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.577 6.308c.896 0 1.593-.923 1.593-2.154C8.17 2.924 7.473 2 6.577 2s-1.594.923-1.594 2.154h1.494M9.764 6.308c.896 0 1.593-.923 1.593-2.154C11.357 2.924 10.66 2 9.764 2S8.17 2.923 8.17 4.154h1.494M13.05 6.308c.896 0 1.593-.923 1.593-2.154 0-1.23-.697-2.154-1.593-2.154s-1.594.923-1.594 2.154h1.494M16.237 6.308c.896 0 1.593-.923 1.593-2.154 0-1.23-.697-2.154-1.593-2.154s-1.593.923-1.593 2.154h1.493M19.523 6.308c.896 0 1.593-.923 1.593-2.154 0-1.23-.697-2.154-1.593-2.154s-1.593.923-1.593 2.154h1.494"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.88 8.564h4.78v4.923H5.88V8.564z" fill="currentColor" />
    </Icon>
  )
}
export default SvgStartDate
