import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAppliances: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.007 20.075a.486.486 0 01-.497.475H5.49a.486.486 0 01-.497-.475V3.925c0-.262.222-.475.497-.475h13.02c.274 0 .497.213.497.475v16.15zM18.51 2.5H5.49C4.668 2.5 4 3.14 4 3.925v16.15c0 .785.668 1.425 1.49 1.425h13.02c.822 0 1.49-.64 1.49-1.425V3.925c0-.786-.668-1.425-1.49-1.425z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.122c-2.42 0-4.387-1.882-4.387-4.197 0-2.314 1.967-4.196 4.386-4.196 2.418 0 4.387 1.882 4.387 4.196 0 2.315-1.968 4.197-4.386 4.197zm0-9.343c-2.967 0-5.38 2.308-5.38 5.146s2.413 5.147 5.38 5.147c2.966 0 5.379-2.309 5.379-5.147s-2.413-5.146-5.38-5.146z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.782c-1.078 0-1.956-.84-1.956-1.871 0-1.032.878-1.872 1.956-1.872 1.079 0 1.955.84 1.955 1.872S13.08 15.78 12 15.78zm0-4.693c-1.627 0-2.95 1.265-2.95 2.822 0 1.555 1.323 2.82 2.95 2.82 1.626 0 2.948-1.265 2.948-2.82 0-1.557-1.322-2.822-2.948-2.822zM7.18 6.048c0-.14.12-.254.267-.254H8a.26.26 0 01.265.254c0 .14-.12.255-.265.255h-.554a.261.261 0 01-.266-.255zM8 7.253c.694 0 1.259-.54 1.259-1.205 0-.664-.565-1.204-1.258-1.204h-.554c-.694 0-1.26.54-1.26 1.204 0 .664.566 1.205 1.26 1.205H8zM11.095 6.71c.382 0 .693-.296.693-.662 0-.366-.31-.664-.693-.664-.382 0-.692.298-.692.664 0 .366.31.663.692.663zM13.86 6.71c.382 0 .692-.296.692-.662 0-.366-.31-.664-.693-.664-.382 0-.693.298-.693.664 0 .366.31.663.693.663zM16.622 6.71c.384 0 .694-.296.694-.662 0-.366-.31-.664-.694-.664-.382 0-.692.298-.692.664 0 .366.31.663.692.663z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAppliances
