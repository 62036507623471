import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCategories: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M7 11a3.99 3.99 0 110-7.982A3.99 3.99 0 017 11zm0-6.98A2.99 2.99 0 107 10a2.99 2.99 0 000-5.98zM17 11a3.99 3.99 0 110-7.982A3.99 3.99 0 0117 11zm0-6.98A2.99 2.99 0 1017 10a2.99 2.99 0 000-5.98zM7 20.98A3.99 3.99 0 117 13a3.99 3.99 0 010 7.98zM7 14a2.99 2.99 0 100 5.98A2.99 2.99 0 007 14zM17 20.98A3.99 3.99 0 1117 13a3.99 3.99 0 010 7.98zM17 14a2.99 2.99 0 100 5.98A2.99 2.99 0 0017 14z" />
      </g>
    </Icon>
  )
}
export default SvgCategories
