import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUsedCarsDealers: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.826 16.269c-.36 0-.65.286-.65.639 0 .353.29.639.65.639.359 0 .65-.286.65-.64a.645.645 0 00-.65-.638zm-1.192.639c0-.646.534-1.17 1.192-1.17.657 0 1.19.524 1.19 1.17a1.18 1.18 0 01-1.19 1.17 1.18 1.18 0 01-1.192-1.17zM17.965 16.269c-.36 0-.65.286-.65.639 0 .353.29.639.65.639.36 0 .65-.286.65-.64a.645.645 0 00-.65-.638zm-1.191.639c0-.646.533-1.17 1.191-1.17s1.191.524 1.191 1.17a1.18 1.18 0 01-1.191 1.17 1.18 1.18 0 01-1.191-1.17z"
        fill="currentColor"
      />
      <path
        d="M20.44 12.722h-.821c-.187.002-.37.05-.532.142a1.073 1.073 0 00-.392.381l-.384-1.338a1.261 1.261 0 00-.452-.648 1.298 1.298 0 00-.753-.26h-4.549a1.3 1.3 0 00-.756.264 1.263 1.263 0 00-.45.652l-.32 1.245a1.051 1.051 0 00-.085-.109 1.075 1.075 0 00-.774-.329H9.35a.576.576 0 00-.411.183.555.555 0 00-.146.42v.952a.54.54 0 00.16.396.561.561 0 00.4.164l.36-.004a1.4 1.4 0 00-.307.87v2.71c0 .17.052.337.147.479a.884.884 0 00.39.321v.685a.58.58 0 00.171.425.599.599 0 00.429.177h1.302a.583.583 0 00.545-.412.515.515 0 00.013-.215v-.589h4.985v.614a.579.579 0 00.17.425.599.599 0 00.428.177h1.263a.583.583 0 00.545-.412.514.514 0 00.013-.216v-.646a.883.883 0 00.418-.318.858.858 0 00.16-.495v-2.71a1.4 1.4 0 00-.304-.866h.36a.567.567 0 00.398-.165.548.548 0 00.16-.396v-.951a.547.547 0 00-.147-.42.568.568 0 00-.412-.183zm-8.568-.668a.73.73 0 01.257-.37.751.751 0 01.428-.153h4.55a.75.75 0 01.427.152.73.73 0 01.257.37l.677 2.355a20.065 20.065 0 01-3.614.314c-1.202.007-2.402-.1-3.583-.32l.601-2.348zm-2.539 2.223v-.952c0-.025.004-.049.012-.071h.827a.532.532 0 01.497.346.51.51 0 01.03.212v.474l-1.366-.01zm2.53 5.621l-.03.04.008.03h-1.298c-.01 0-.058-.003-.058-.07v-.614h1.377v.614zm7.403 0l-.029.04.008.03h-1.259c-.01 0-.058-.003-.058-.07v-.614h1.338v.614zm.577-4.196v2.71c0 .09-.036.177-.101.241a.35.35 0 01-.245.1h-9.204a.35.35 0 01-.244-.1.337.337 0 01-.102-.24v-2.71a.832.832 0 01.223-.597c.15-.163.36-.26.583-.273 1.345.299 2.722.44 4.101.42 1.4.015 2.797-.124 4.165-.417h.046c.217.02.42.12.564.281.145.161.222.37.214.585zm.597-1.397h-1.372v-.476a.553.553 0 01.154-.4.574.574 0 01.396-.176h.822l.001-.001a.12.12 0 01.016.072l-.017.981z"
        fill="currentColor"
      />
      <path
        d="M8.762 16.632a3.243 3.243 0 01-1.98.665c-.425 0-.847-.082-1.24-.241a3.24 3.24 0 01-1.051-.69 3.177 3.177 0 01-.703-1.031 3.13 3.13 0 01.703-3.468 3.24 3.24 0 011.051-.689c.393-.16.815-.241 1.24-.24h2.235l-1.135.748a.269.269 0 00-.115.17.261.261 0 00.115.273.273.273 0 00.302-.002l1.841-1.215a.268.268 0 00.12-.22.262.262 0 00-.12-.22L8.184 9.255a.274.274 0 00-.374.073.262.262 0 00.072.368l1.075.71H6.782c-.496-.001-.988.094-1.447.28-.459.187-.876.46-1.227.805-.351.344-.63.753-.82 1.204a3.654 3.654 0 000 2.842c.19.45.469.86.82 1.204.351.345.768.618 1.227.805.459.186.95.281 1.447.28.837.003 1.651-.27 2.311-.774a.267.267 0 00.104-.177.261.261 0 00-.134-.265.275.275 0 00-.3.021z"
        fill="currentColor"
      />
      <path
        d="M4.744 8.075v1.534c0 .07.029.138.08.188a.273.273 0 00.382 0c.05-.05.079-.117.079-.188V8.251a1.96 1.96 0 001.291-.22c.294-.165.54-.404.71-.691.172.286.417.524.711.69a1.972 1.972 0 001.931 0c.294-.166.54-.404.711-.69.172.286.417.524.711.69a1.973 1.973 0 001.931 0c.294-.166.54-.404.711-.69.172.286.417.524.711.69a1.973 1.973 0 001.931 0c.295-.166.54-.404.711-.69.171.287.416.526.71.69a1.961 1.961 0 001.295.22l.057 3.489c0 .07.03.136.08.185.05.049.119.076.19.076h.004a.273.273 0 00.19-.08.263.263 0 00.076-.19l-.058-3.656c.305-.148.565-.373.753-.652.189-.279.299-.602.319-.936a.26.26 0 00-.011-.163l-1.082-2.656a.274.274 0 00-.251-.167H5.014a.274.274 0 00-.25.167L3.681 6.323a.255.255 0 00-.012.154c.019.336.128.661.317.942.189.28.45.507.757.656zm.453-4.044h14.237l.987 2.424a1.366 1.366 0 01-.446.928 1.416 1.416 0 01-1.937-.03 1.365 1.365 0 01-.417-.94.273.273 0 00-.088-.183.283.283 0 00-.38.001.263.263 0 00-.083.182c-.01.358-.163.698-.424.948-.262.25-.612.389-.977.389s-.715-.14-.976-.39a1.366 1.366 0 01-.425-.947.273.273 0 00-.089-.183.283.283 0 00-.38.001.263.263 0 00-.082.182c-.01.358-.163.698-.425.948a1.415 1.415 0 01-1.953 0 1.365 1.365 0 01-.424-.948.273.273 0 00-.089-.183.283.283 0 00-.38.001.263.263 0 00-.082.182c-.01.358-.163.698-.425.948a1.415 1.415 0 01-1.953 0 1.366 1.366 0 01-.424-.948.273.273 0 00-.089-.183.283.283 0 00-.38.001.263.263 0 00-.083.182c-.01.355-.16.692-.416.94a1.416 1.416 0 01-1.938.029 1.367 1.367 0 01-.445-.927l.986-2.424zM7.17 19.969H5.277V18.64a.263.263 0 00-.079-.188.273.273 0 00-.382 0 .263.263 0 00-.08.188v1.593a.26.26 0 00.08.188.27.27 0 00.19.078H7.17c.072 0 .14-.028.191-.078a.263.263 0 000-.375.273.273 0 00-.19-.078z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgUsedCarsDealers
