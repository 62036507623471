import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLinkCircle: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} r={12} fill="#3E4153" />
      <path
        d="M14.634 12.376l1.442-1.442c.815-.815.815-2.195 0-3.01s-2.195-.815-3.01 0L11.31 9.68c-.815.815-.878 2.07-.125 2.947m-1.693-1.129l-1.568 1.568c-.815.815-.815 2.195 0 3.01s2.195.815 3.01 0l1.756-1.756c.815-.815.878-2.07.125-2.947l-3.323.125z"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgLinkCircle
