import styled from 'styled-components'

import { Label } from '@/ui/atoms/label'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'

const RADIO_SIZE = '2rem'
const SELECTED_DOT_SIZE = '.8rem'

export const RadioButtonContainer = styled.div`
  display: flex;
  position: relative;
`

export const RadioLabel = styled(Label)(
  ({ theme }) => `
  ${TRANSITION_STYLE}
  cursor: pointer;

  &::before {
    ${TRANSITION_STYLE}
    content: '';
    align-items: center;
    background-color: ${theme.colors.white};
    border-radius: ${RADIO_SIZE};
    border: 0.1rem solid ${theme.colors.grey.light1};
    box-sizing: border-box;
    display: flex;
    height: ${RADIO_SIZE};
    justify-content: center;
    left: 0;
    position: absolute;
    width: ${RADIO_SIZE};
  }

  // RADIO SELECTED DOT

  &::after {
    ${TRANSITION_STYLE}
    content: '';
    align-items: center;
    background-color: ${theme.colors.purple.light1};
    border-radius: ${theme.borderRadius.rounded};
    height: ${SELECTED_DOT_SIZE};
    justify-content: center;
    left: calc(${RADIO_SIZE}/2 - ${SELECTED_DOT_SIZE}/2);
    position: absolute;
    top: calc(${SELECTED_DOT_SIZE}/2 + .2rem);
    visibility: hidden;
    width: ${SELECTED_DOT_SIZE};
  }

  &:hover::before{
    background-color: ${theme.colors.purple.light5};
  }
`
)

export const RadioInput = styled.input(
  ({ theme }) => `
  height: ${RADIO_SIZE};
  margin-right: ${theme.spacing.defaultSmall};
  opacity: 0;
  width: ${RADIO_SIZE};

  // Checked Style

  &:checked + label{
    &::before {
      background-color: ${theme.colors.purple.light4};
      border-color: ${theme.colors.purple.light1};
    }

    &::after {
      visibility: visible;
    }
  }

  &:not([aria-invalid="true"]) {
    // Focus Style

    &:focus + label::before {
      border-color: ${theme.colors.purple.dark1};
      box-shadow: 0px 0px .5rem rgba(55, 51, 115, 0.5);
    }   
  }

  // Disabled Styles

  &:disabled + label {
    cursor: not-allowed;

    &::before {
      background-color: ${theme.colors.grey.light4};
      border-color: ${theme.colors.grey.light3};
    }
  }

  &:disabled:checked + label::before {
    color: ${theme.colors.grey.light2};
  }

  // Error Styles

  &[aria-invalid="true"] {
    & + label::after {
      background-color: ${theme.colors.red.light2};
    }

    & + label::before {
      background-color: ${theme.colors.red.light5};
      border-color: ${theme.colors.red.light2};
    }

    &:focus + label::before {
      border-color: ${theme.colors.red.primary};
      box-shadow: 0 0 .5rem rgba(241, 69, 79, 0.5);
    }

    &:hover + label::before {
      background-color: ${theme.colors.red.light4};
    }
  }
`
)
