import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCommunity: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M15.06 16.23a.489.489 0 00-.59.38.5.5 0 00.38.6c1.59.33 2.15.64 2.15 1.73V20H3v-1.06c0-1.09.56-1.4 2.15-1.73l3.45-.72A.49.49 0 009 16v-1.85a.51.51 0 00-.28-.45A3.07 3.07 0 016.94 11V9a3 3 0 012.91-3h.31a3 3 0 012.9 3v2a3.06 3.06 0 01-1.77 2.73.501.501 0 00.046.926.51.51 0 00.384-.016A4.11 4.11 0 0014.06 11V9a4 4 0 00-3.9-4h-.31a4 4 0 00-3.91 4v2A4.08 4.08 0 008 14.46v1.14l-3.06.63C3.7 16.49 2 16.85 2 18.94v1.56a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-1.56c0-2.09-1.7-2.45-2.94-2.71z" />
        <path d="M19.23 13.48l-2.83-.58v-1a3.84 3.84 0 001.93-3.3V6.79A3.74 3.74 0 0014.65 3h-.29a3.61 3.61 0 00-2 .62.5.5 0 10.55.83A2.64 2.64 0 0114.36 4h.29a2.75 2.75 0 012.68 2.79V8.6a2.86 2.86 0 01-1.64 2.53.49.49 0 00-.29.45v1.72a.49.49 0 00.4.49l3.22.67c1.49.31 2 .59 2 1.59V17H19.5a.51.51 0 00-.5.5.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.45c0-1.98-1.6-2.32-2.77-2.57z" />
      </g>
    </Icon>
  )
}
export default SvgCommunity
