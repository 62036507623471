import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVisa: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <clipPath id="visa_svg__a">
        <path d="M3 9h18v5.84H3z" />
      </clipPath>
      <rect fill="#373373" height={14} rx={2} width={22} x={1} y={5} />
      <g clipPath="url(#visa_svg__a)">
        <path
          d="M10.802 14.742H9.334l.918-5.641h1.468zM8.098 9.101l-1.4 3.88-.166-.835-.494-2.537S5.978 9.1 5.34 9.1H3.026L3 9.196s.708.148 1.536.645l1.277 4.902h1.53L9.679 9.1zm11.553 5.641h1.348l-1.176-5.641h-1.18c-.546 0-.678.42-.678.42l-2.19 5.221h1.53l.307-.838h1.867l.172.838zm-1.617-1.995l.772-2.111.434 2.11zm-2.145-2.29l.21-1.211S15.452 9 14.778 9c-.73 0-2.46.319-2.46 1.868 0 1.457 2.031 1.475 2.031 2.241s-1.822.629-2.423.146l-.219 1.266s.656.319 1.658.319 2.515-.52 2.515-1.932c0-1.466-2.05-1.603-2.05-2.241s1.43-.556 2.059-.21z"
          fill="#fff"
        />
      </g>
    </Icon>
  )
}
export default SvgVisa
