import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutos: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M18.88 9.235l-.542-2.429a.5.5 0 00-.976.218l.515 2.307a29.35 29.35 0 01-6.13.584 26.519 26.519 0 01-5.978-.59l1.061-3.77c.169-.578.837-.64 1.116-.64h5.843a.5.5 0 100-1H7.946a2.016 2.016 0 00-2.077 1.364L4.753 9.241a2.17 2.17 0 00-1.903 2.174v7.5a.947.947 0 001 1h2a1.068 1.068 0 00.825-.39.838.838 0 00.175-.658v-1.952h10v2a1.046 1.046 0 001.13 1h1.874a1.063 1.063 0 00.821-.388.84.84 0 00.175-.66v-7.452a2.174 2.174 0 00-1.97-2.18zm-15.03 2.18a1.213 1.213 0 011.306-1.213c2.156.525 4.372.765 6.59.713a27.96 27.96 0 006.794-.713 1.214 1.214 0 011.31 1.213v4.5h-16zm2 7.5h-2v-2h2zm14 0h-1.87c-.07 0-.126-.02-.13-.005v-1.995h2z" />
        <path d="M16.35 14.915a.5.5 0 00.5-.5v-1c0-.462.037-.5.5-.5h1a.5.5 0 000-1h-1a1.319 1.319 0 00-1.5 1.5v1a.5.5 0 00.5.5zM6.85 13.415v1a.5.5 0 001 0v-1a1.319 1.319 0 00-1.5-1.5h-1a.5.5 0 100 1h1c.462 0 .5.038.5.5z" />
      </g>
    </Icon>
  )
}
export default SvgAutos
