import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosMake: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.638 17.675c1.953-2.508 2.646-5.895 2.898-7.776m-8.82 1.819c.568 2.445 1.702 5.769 4.284 7.587M14.835 9.21c-.945-.25-1.89-.376-2.835-.376-1.574 0-3.15.314-4.598 1.004m0 .062L5.386 8.896M16.6 9.899l2.016-1.003"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.906 11.906c-1.197-.627-2.583-1.003-3.906-.94-.882 0-1.7.125-2.52.376"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 4.632l-1.07 1.756M12 6.952a11.345 11.345 0 00-6.614 1.944C5.575 11.216 6.583 18.678 12 21.5c5.417-2.822 6.425-10.221 6.614-12.604C16.661 7.579 14.331 6.952 12 6.952zm0 4.013v10.347-10.347zM11.874 2.5v2.07V2.5zM7.969 3.629l.188.815-.188-.815zM4 4.632L5.008 6.45 4 4.632zm12.032-1.003l-.252.815.252-.815z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosMake
