import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWebsiteUrl: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M35.833 36.667H4.166a.834.834 0 01-.833-.834V4.167c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834v31.666c0 .46-.373.834-.833.834z"
          fill="#ececee"
        />
        <path
          d="M25.994 26.877h-2.553a10.421 10.421 0 01-1.7 2.766c1.757-.396 3.258-1.455 4.253-2.766zm-5.588 2.961c.877-.781 1.617-1.795 2.15-2.961h-2.15zm0-3.783h2.482c.37-1.035.586-2.148.628-3.316h-3.11zm3.922-3.316a11.847 11.847 0 01-.579 3.315l2.784.001a7.452 7.452 0 00.976-3.316zm-6.07 6.904c-.68-.788-1.27-1.74-1.7-2.766h-2.552c.993 1.311 2.497 2.37 4.252 2.766zm1.335-2.766h-2.15c.535 1.168 1.273 2.18 2.15 2.961zm0-4.138h-3.11c.041 1.168.26 2.281.628 3.316h2.482zm-7.104 0a7.496 7.496 0 00.977 3.316l2.783-.003a11.897 11.897 0 01-.578-3.315h-3.182zm9.252-7.725c.68.788 1.272 1.74 1.7 2.766h2.553c-.995-1.311-2.496-2.37-4.253-2.766zm-1.335 2.766h2.15c-.533-1.168-1.273-2.18-2.15-2.961zm0 4.138h3.11a11.018 11.018 0 00-.628-3.318h-2.482zm7.103 0a7.459 7.459 0 00-.976-3.318l-2.784.003c.339 1.032.542 2.149.58 3.315zM14.006 17.78h2.552a10.466 10.466 0 011.7-2.766c-1.755.396-3.259 1.455-4.252 2.766zm5.587-2.961c-.875.781-1.615 1.793-2.15 2.961h2.15zm0 3.781H17.11a11.067 11.067 0 00-.628 3.318h3.11zm-3.922 3.318c.038-1.166.24-2.283.578-3.315l-2.783-.003a7.51 7.51 0 00-.977 3.318zm12.662.41a8.333 8.333 0 11-16.667 0 8.333 8.333 0 018.333-8.333 8.332 8.332 0 018.334 8.334z"
          fill="#37a864"
        />
        <path
          d="M36.666 10H3.333V4.167c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834z"
          fill="#6f7280"
        />
        <g fill="#fff">
          <path d="M7.5 6.667a.834.834 0 11-1.668-.001.834.834 0 011.668 0zM10.833 6.667a.834.834 0 11-1.668-.001.834.834 0 011.668 0zM14.167 6.667a.834.834 0 11-1.668-.001.834.834 0 011.668 0z" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgWebsiteUrl
