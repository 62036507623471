import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUnitTypeApartment: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.919 5.632V23h-5.821m5.82-17.368h1.456V3.316H20.92m0 2.316h-4.002m-13.462 0H2V3.316h1.455m0 2.316V23h5.821M3.456 5.632h3.638M3.455 3.316V1H20.92v2.316m-17.464 0H20.92M15.098 23v-3.474H9.276V23m5.822 0H9.276M7.094 5.632v1.736m0-1.736h5.093m0 0v1.736m0-1.736h4.73m0 0v1.736"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.704 10.263h2.823v2.316H5.704v-2.316zM15.582 10.263h2.823v2.316h-2.823v-2.316zM5.704 14.895h2.823v2.316H5.704v-2.316zM10.644 10.263h2.822v2.316h-2.822v-2.316zM10.644 14.895h2.822v2.316h-2.822v-2.316zM15.582 14.895h2.823v2.316h-2.823v-2.316z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgUnitTypeApartment
