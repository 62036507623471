import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosModel: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.518 13.792l4.982 4.992L20.286 20l-3.704-3.712M5.276 11.552c-1.917-.448-3.13-2.368-2.683-4.352.447-1.92 2.363-3.136 4.343-2.688.447.128.895.32 1.278.576"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.707 16.032a12.209 12.209 0 003.833-3.84.962.962 0 000-1.152c-2.044-2.496-4.28-4.8-6.835-6.848a.957.957 0 00-1.15 0 12.209 12.209 0 00-3.832 3.84.961.961 0 000 1.152c2.044 2.496 4.28 4.8 6.835 6.784.319.32.83.32 1.15.064z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.002 11.04l.895.896c.702.576 1.724.576 2.363-.128.703-.64.767-1.664.128-2.368l-.383-.384-2.492 2.496m-1.852-1.92l-.383-.384c-.64-.704-.575-1.728.127-2.368.64-.704 1.661-.704 2.364-.128l1.022 1.024m-.639-.64L8.725 9.632"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosModel
