import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const AdditionalOptionsContainer = styled.div(
  ({ theme }) => `
  align-items: flex-start;
  display: none;
  margin-top: ${theme.spacing.defaultSmall};

  & > p {
    line-height: ${theme.spacing.large};
    white-space: nowrap;
  }

  ${MEDIA_QUERIES(theme).medium}{
    display: flex;
  }
`
)

export const AdditionalOptionsList = styled.ul(
  ({ theme }) => `
  column-gap: ${theme.spacing.mini};
  display: flex;
  flex-wrap: wrap;

  & li {
    align-items: center;
    display: flex;
  }

  & svg {
    color: ${theme.colors.yellow.primary};
    height: ${theme.spacing.large};
  }
`
)
