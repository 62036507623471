import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING } from '@/components/shared/page-container/styled'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export type SearchListContainerProps = {
  gap?: Record<
    Extract<keyof ThemeProps['breakpoints'], 'small' | 'xLarge'>,
    keyof ThemeProps['spacing']
  >
}

export const SearchListContainer = styled.ul<SearchListContainerProps>(
  ({ theme, gap }) => `
  display: flex;
  flex-direction: column;
  gap: ${gap ? theme.spacing[gap.small] : theme.spacing.mini};

  ${
    gap?.xLarge
      ? `${MEDIA_QUERIES(theme).xLarge} {
    gap: ${theme.spacing[gap.xLarge]}
  }`
      : ''
  };

  & a {
    text-decoration: none;
  }
  & a:hover h3, 
  & a:focus h3 {
    text-decoration: underline;
  }

`
)

export const TopAdsContainer = styled.div(
  ({ theme }) => `
  background: ${theme.colors.purple.light5};
  padding: ${theme.spacing.default};
  margin: 0 -${PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING}; 

  ${MEDIA_QUERIES(theme).medium}{
    margin: 0;
  }
`
)

export const PaginationLinkContainer = styled(Flex)(
  ({ theme }) => `
  flex-direction: column;

  ${MEDIA_QUERIES(theme).medium}{
    flex-direction: row;
    justifyContent: space-between;
  }
`
)

export const CasBackfillsPanelContainer = styled.div(
  ({ theme }) => `
  background: ${theme.colors.purple.primary}};
  border-radius: ${theme.borderRadius.small};
  box-shadow: 0px 1px 5px rgba(52, 55, 70, 0.1);
  padding: ${theme.spacing.default};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.3rem;

  ${MEDIA_QUERIES(theme).medium}{
    padding: ${theme.spacing.large} ${theme.spacing.default};
    flex-direction: row;
    justifyContent: space-between;
    align-items: center;
  }
`
)

export const HighlightedKeyword = styled.strong`
  font-weight: bold;
`
