import dynamic from 'next/dynamic'
import { FC } from 'react'
import { useTheme } from 'styled-components'

import { Flex } from '@/ui/atoms/flex'

import {
  BreadcrumbChevronContainer,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbText,
} from './styled'

const ChevronLeftSmallIcon = dynamic(
  () => import('@kijiji/icons/src/icons/ChevronLeftSmall'),
  { ssr: false }
)

type Crumb = {
  label: string
  href?: string
}

export type BreadcrumbsProps = {
  /**
   * aria-label of the breadcrumb
   * Accessibility requirement
   */
  label: string
  /**
   * A list of objects corresponding to the breadcrumbs to display, in order.
   * The objects include `name`, the name of the breadcrumb; and optionally
   * `url`, the URL the breadcrumb should link to.
   */
  crumbs: Crumb[]
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ crumbs = [], label }) => {
  const { colors } = useTheme()
  if (!crumbs.length) return null

  return (
    <Flex as="nav" aria-label={label} data-testid="breadcrumbs-nav">
      <BreadcrumbList>
        {crumbs.map((crumb, index) => {
          const isLastItem = index + 1 === crumbs.length
          const ariaCurrent = isLastItem ? 'location' : undefined

          return (
            <Flex as="li" key={`crumb-${index}`} alignItems="center">
              {!crumb.href ? (
                <BreadcrumbText
                  aria-current={ariaCurrent}
                  color={colors.grey.light1}
                  size="xSmall"
                  weight="medium"
                >
                  {crumb.label}

                  {/* Don't render icon on the last item */}
                  {!isLastItem && (
                    <BreadcrumbChevronContainer>
                      <ChevronLeftSmallIcon aria-hidden />
                    </BreadcrumbChevronContainer>
                  )}
                </BreadcrumbText>
              ) : (
                <BreadcrumbLink
                  aria-current={ariaCurrent}
                  data-testid={`breadcrumb-link-${index}`}
                  href={crumb.href}
                  size="xSmall"
                  variant="secondary"
                  weight="medium"
                >
                  {crumb.label}

                  {/* Don't render icon on the last item */}
                  {!isLastItem && (
                    <BreadcrumbChevronContainer>
                      <ChevronLeftSmallIcon aria-hidden />
                    </BreadcrumbChevronContainer>
                  )}
                </BreadcrumbLink>
              )}
            </Flex>
          )
        })}
      </BreadcrumbList>
    </Flex>
  )
}
