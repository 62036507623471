import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFurnished: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.795 11.887c-.12.209-.282.365-.492.475a.5.5 0 00-.271.441v2.98l-16.065.044v-3.024a.499.499 0 00-.27-.441 1.183 1.183 0 01-.492-.475 1.335 1.335 0 01-.187-.685c0-.37.136-.682.415-.956.279-.274.597-.406.974-.406h.237c.123 0 .214.039.307.13.094.091.133.181.133.301v2.656c0 .792.657 1.436 1.464 1.436h11.036c.807 0 1.464-.644 1.464-1.436V10.27c0-.12.04-.21.134-.302a.41.41 0 01.306-.13h.104c.377 0 .696.133.975.407.278.274.415.586.415.956 0 .245-.061.468-.187.685zM12 11.983c.062 0 .12-.014.175-.034.055.02.113.034.175.034h5.68v.944c0 .242-.2.437-.446.437H6.548a.442.442 0 01-.446-.437v-.944H12zm9.287-2.443a2.35 2.35 0 00-1.695-.7h-.104c-.39 0-.746.148-1.026.424a1.385 1.385 0 00-.432 1.007v.713h-5.52V6.998c0-.276.227-.5.508-.5h4.893c.634 0 1.208.392 1.431.975.1.258.391.39.655.293a.497.497 0 00.299-.643A2.555 2.555 0 0017.91 5.5h-4.893c-.393 0-.747.15-1.018.39a1.53 1.53 0 00-1.018-.39H6.089a2.53 2.53 0 00-2.37 1.587.494.494 0 00.287.646c.26.101.558-.026.66-.281a1.52 1.52 0 011.423-.953h4.893c.28 0 .509.224.509.5v3.985H6.102v-.713c0-.381-.15-.731-.431-1.007a1.438 1.438 0 00-1.027-.423h-.237c-.652 0-1.222.236-1.694.699-.474.465-.713 1.023-.713 1.662 0 .416.107.812.319 1.178.162.28.372.514.63.698v3.25c0 .134.054.26.15.354a.513.513 0 00.36.146h.001l1.08-.003v1.176c0 .275.227.499.509.499.28 0 .509-.224.509-.5v-1.178l12.727-.034v1.213c0 .275.228.499.51.499.28 0 .509-.224.509-.5v-1.215l1.238-.004a.504.504 0 00.508-.5v-3.203c.258-.184.47-.418.631-.698.212-.366.319-.762.319-1.178 0-.64-.24-1.197-.713-1.662z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgFurnished
