import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNew: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 16h16m-8-8v16"
        stroke="#F3F3F9"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgNew
