import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFire: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <linearGradient
        id="fire_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={11.99}
        x2={11.99}
        y1={4}
        y2={19.99}
      >
        <stop offset={0.229} stopColor="#f1454f" />
        <stop offset={0.755} stopColor="#f8aa17" />
        <stop offset={1} stopColor="#bb3c4d" />
      </linearGradient>
      <linearGradient
        id="fire_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={7.295}
        x2={7.295}
        y1={7.513}
        y2={10.535}
      >
        <stop offset={0} stopColor="#f1454f" />
        <stop offset={0.516} stopColor="#f8aa17" />
        <stop offset={1} stopColor="#f1454f" />
      </linearGradient>
      <linearGradient
        id="fire_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={11.874}
        x2={11.874}
        y1={6.85}
        y2={19.966}
      >
        <stop offset={0} stopColor="#f8aa17" />
        <stop offset={0.49} stopColor="#ffd255" />
        <stop offset={1} stopColor="#f8aa17" stopOpacity={0.35} />
      </linearGradient>
      <linearGradient
        id="fire_svg__e"
        gradientUnits="userSpaceOnUse"
        x1={11.806}
        x2={11.806}
        y1={11.755}
        y2={23.221}
      >
        <stop offset={0.176} stopColor="#f8aa17" />
        <stop offset={0.536} stopColor="#feeed1" />
        <stop offset={0.874} stopColor="#ffd255" />
      </linearGradient>
      <clipPath id="fire_svg__a">
        <path d="M6 4h12v16H6z" />
      </clipPath>
      <g clipPath="url(#fire_svg__a)">
        <path
          d="M15.555 10h0l-.003.01.242.061s-.4 1.578.4 1.75l-.056.244h.003l.005.001.012.003a.628.628 0 00.155.009.79.79 0 00.356-.103c.181-.103.335-.282.433-.565.236.508.52 1.265.61 2.116.124 1.187-.132 2.547-1.437 3.688h0l-.006.004.17.184-.17-.183h0-.001l-.002.003-.007.006a1.315 1.315 0 00-.098.103 1.184 1.184 0 00-.182.264c-.042.09-.114.297.038.47.028.032.06.057.094.078-.797.574-2.472 1.594-4.369 1.594h-.017 0-.005l-.02.002-.084.001c-.076 0-.188-.002-.33-.012a5.89 5.89 0 01-1.16-.209 6.352 6.352 0 01-2.614-1.487.54.54 0 00.24-.008.32.32 0 00.23-.272.5.5 0 00-.066-.285 1.769 1.769 0 00-.184-.273l-.017-.022-.021-.017h0-.001l-.005-.005a1.893 1.893 0 01-.113-.103 4.557 4.557 0 01-1.066-1.595c-.387-.995-.494-2.402.637-4.11.039.23.103.493.209.738.195.455.566.914 1.23.906v0h.012l-.016-.25.017.25h.001l.002-.001h.005l.011-.002a.736.736 0 00.137-.032.837.837 0 00.292-.17c.215-.192.374-.523.353-1.063l-.001-.016-.003-.015h0v-.001l-.001-.01a2.59 2.59 0 01-.017-.238c-.004-.172.003-.43.05-.763.092-.651.339-1.59.958-2.729.174.058.349.097.517.09a.75.75 0 00.57-.286c.143-.176.234-.423.293-.732.06-.312.092-.711.098-1.212h0v-.003h-.25.25v0-.054a12.93 12.93 0 00-.07-1.244 14.928 14.928 0 012 1.603c.586.564 1.133 1.208 1.479 1.882.345.672.48 1.352.283 2.01z"
          fill="url(#fire_svg__b)"
          stroke="#f1454f"
          strokeWidth={0.5}
        />
        <path
          d="M7.319 8.623h0l.002-.002c.147-.103.253-.257.33-.412.06-.122.104-.248.135-.358.246 1.368-.679 2.279-.995 2.545-.087-.656.047-1.099.199-1.376a1.376 1.376 0 01.303-.377.542.542 0 01.02-.016l.005-.004z"
          fill="url(#fire_svg__c)"
          stroke="#f1454f"
          strokeWidth={0.15}
        />
        <path
          d="M12.916 19.841c.671-.12 1.187-1.008 1.187-1.008 2.542-1.762 2.233-2.93 2.233-2.93-.93.32-.723-.794-.723-.794.62-1.591.052-1.763.052-1.763-1.665 1.06-1.549-1.167-1.549-1.167.61-3.057-1.145-4.751-1.705-5.204-.065-.053-.157-.001-.155.082.009.384-.02 1.201-.398 1.57 0 0-1.82 1.458-2.026 4.626 0 0-.103 1.55-1.548 1.352 0 0-.594-.358-.788-.896-.011-.033-.07-.03-.073.004-.054.656-.108 2.71 1.158 3.967 0 0 2.155 1.75 2.335 2.214 0 0 .774.186 2.013-.04z"
          fill="url(#fire_svg__d)"
        />
        <path
          d="M11.045 19.907s.426.2 1.561-.026c0 0 .258-.849 1.394-1.949 0 0 1.187-1.776-.903-2.797 0 0-.958-.635-.524-3.135.016-.089-.08-.149-.147-.09-.473.408-1.674 1.629-2.013 3.742 0 0-.083.873-1.004.903-.066.002-.117.061-.1.125.1.379.447 1.508 1.13 1.968 0 0 .8.968.619 1.246z"
          fill="url(#fire_svg__e)"
        />
      </g>
    </Icon>
  )
}
export default SvgFire
