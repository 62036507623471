import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWarningOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.7 17.342L13.712 4.185a2.053 2.053 0 00-3.584 0L3.139 17.342a1.841 1.841 0 00.05 1.827 2.008 2.008 0 001.742.965h13.977a2.007 2.007 0 001.743-.965 1.841 1.841 0 00.049-1.827zm-.906 1.312a1.015 1.015 0 01-.886.48H4.931a1.016 1.016 0 01-.886-.48.84.84 0 01-.023-.843l6.989-13.157a1.055 1.055 0 011.818 0l6.988 13.157a.839.839 0 01-.023.843z"
        fill="currentColor"
      />
      <path
        d="M12.477 7.812H11.35v6.555h1.127V7.812zM11.913 15.508a.804.804 0 00-.826.813.833.833 0 10.826-.813z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgWarningOutline
