import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPets: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M12.52 9.46A.79.79 0 0012 9.3a.83.83 0 00-.6.26L7 14.1A3.55 3.55 0 007 19l.15.15a3.32 3.32 0 002.23.85 3.26 3.26 0 002.39-1l.22-.21.21.2.15.15A3.32 3.32 0 0017 19a3.52 3.52 0 000-4.92zm3.77 8.82a2.32 2.32 0 01-3.26.1l-.34-.33.75-.74a.5.5 0 00-.354-.854.51.51 0 00-.356.144l-1.13 1.14a.1.1 0 000 .05l-.5.49a2.32 2.32 0 01-3.26.1l-.1-.1a2.54 2.54 0 010-3.49L12 10.35l4.28 4.4a2.52 2.52 0 01.01 3.53zM9 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0-4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM15 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0-4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM8 11a2.5 2.5 0 10-2.5 2.5A2.51 2.51 0 008 11zm-2.5 1.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM18.5 8.5A2.5 2.5 0 1021 11a2.51 2.51 0 00-2.5-2.5zm0 4a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" />
      </g>
    </Icon>
  )
}
export default SvgPets
