import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgJobType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.9 13.827c3.082-.203 5.734-.88 8.028-1.962m-11.684 1.962c-3.083-.203-5.807-.947-8.172-2.03m-.072 0v-4.06c0-.203.215-.406.43-.406h19.14c.215 0 .43.203.43.406v4.06M7.233 7.331V4.218C7.233 3.541 7.806 3 8.595 3h7.025c.717 0 1.29.541 1.29 1.218v3.113H7.233z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 11.797v8.797c0 .203.215.406.43.406h19.14c.215 0 .43-.203.43-.406v-8.797"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.491 9.429v.609m-5.663 4.127v1.421c0 .474-.43.88-.931.88h-1.721c-.502 0-.932-.338-.932-.88v-2.774c0-.474.43-.88.932-.88h1.72c.502 0 .932.339.932.812v1.421zm5.663-.88V18.7v-5.413z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgJobType
