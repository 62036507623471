import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosEnginecc: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.865 10.88a3.977 3.977 0 00-3.533 0M19.883 17.431c1.446 0 2.617-1.235 2.617-2.759 0-1.523-1.171-2.758-2.617-2.758-1.445 0-2.616 1.235-2.616 2.758 0 1.524 1.171 2.759 2.616 2.759zM19.883 10.466c-1.57 0-3.074 1.034-3.663 2.551m-5.365-2.896c-2.42 1.586-4.58-.552-4.58-.552s.59 1.862 2.094 2.414c.327.138.655.069.982-.07m.915 4h3.402c.197 0 .458-.068.59-.275a2.86 2.86 0 00.915-2.07v-2.55m-4.187-.69c.131.413.523.69.916.62h3.075c.785 0 1.177-.896.785-1.793-.196-.62-.785-1.034-1.44-.965-1.308 0-3.597 1.172-3.336 2.138z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.883 14.672l-3.598-7.241s-.785-1.655-2.617-.552m5.038 2.345v-.896c-.785 0-1.44.413-1.44.827m-6.15 3.655l-.588 1.448m1.963-1.448l-.59 1.448m-3.728-.482c-.196-.07-.458-.07-.72-.138-1.177-.276-2.878-.69-4.187-.966-.981-.207-1.7-.413-1.766-.413.065.482.13.896.262 1.31l.785.207 5.037 1.172.981.207 1.113-.827h3.532l.524-1.449"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.612 13.776c-.131.276-.196.62-.196.966 0 1.517 1.177 2.758 2.616 2.758 1.374 0 2.486-1.103 2.617-2.552m-4.318-2.275a2.58 2.58 0 011.767-.69c1.112 0 2.028.69 2.42 1.655l-4.187-.965z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosEnginecc
