import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosSunroof: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22 13.69l-3.302-4.384M11.906 22c-3.49 0-6.415 0-6.415-2.1V4.1C5.49 2 8.038 2 11.717 2c3.68 0 6.604 0 6.604 2.1v15.89c0 2.01-2.925 2.01-6.415 2.01zM2 13.69l3.302-4.384L2 13.69z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.019 2.274l.377 5.662m.755 4.201v4.201c0 .365-.283.73-.66.73H8.415c-.377 0-.66-.365-.66-.73v-6.027c0-.366 1.98-1.096 4.15-1.096 1.416 0 2.737.274 3.963.822l.283 2.1zm-8.396 1.096h8.396-8.396zm1.037-9.68s0 1.095-1.792 1.095l1.792-1.095zm6.227 0s0 1.095 1.792 1.095L15.02 3.553zM8.792 2.274l-.377 5.662.377-5.662z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosSunroof
