import { HTMLAttributes, ReactNode } from 'react'

import { AutosListingImageWrapper, ListingImageBadgeWrapper } from './styled'

export type AutosListingImageProps = {
  /**
   * Size of the image
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'xLarge' | 'xxLarge'
  /**
   * Content of the badge on the side of the image
   * If nothing is passed, no badge will be shown
   * The library already controls text & icon size
   */
  badge?: ReactNode | string
  /**
   * The <img /> tag
   * It will have default styling to fit in the container.
   */
  imageComponent: ReactNode
} & HTMLAttributes<HTMLDivElement>

export const AutosListingImage = ({
  imageComponent,
  size = 'xLarge',
  badge,
  ...rest
}: AutosListingImageProps) => {
  return (
    <AutosListingImageWrapper hasImg={!!imageComponent} size={size} {...rest}>
      {badge && <ListingImageBadgeWrapper>{badge}</ListingImageBadgeWrapper>}
      {imageComponent}
    </AutosListingImageWrapper>
  )
}
