import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgHomepageGallery: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M35.833 36.667H4.166a.834.834 0 01-.833-.834V4.167c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834v31.666c0 .46-.373.834-.833.834z"
          fill="#ececee"
        />
        <path
          d="M36.666 10H3.333V4.167c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834z"
          fill="#6f7280"
        />
        <path
          d="M7.5 6.667a.834.834 0 11-1.668 0 .834.834 0 011.668 0zM10.833 6.667a.834.834 0 11-1.668-.001.834.834 0 011.668 0zM14.167 6.667a.834.834 0 11-1.668-.001.834.834 0 011.668 0z"
          fill="#fff"
        />
        <path
          d="M32.916 15H7.083a.417.417 0 01-.417-.417v-.833c0-.23.187-.417.417-.417h25.833c.23 0 .417.187.417.417v.833c0 .23-.187.417-.417.417z"
          fill="#c5c6cb"
        />
        <path
          d="M12.916 25H7.083a.417.417 0 01-.417-.417V18.75c0-.23.187-.417.417-.417h5.833c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417z"
          fill="#37a864"
        />
        <g fill="#c5c6cb">
          <path d="M21.25 25h-5.833a.417.417 0 01-.417-.417V18.75c0-.23.187-.417.417-.417h5.833c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417zM19.583 30h-12.5a.417.417 0 01-.417-.417v-.833c0-.23.187-.417.417-.417h12.5c.23 0 .416.187.416.417v.833c0 .23-.186.417-.416.417zM29.583 25H23.75a.417.417 0 01-.417-.417V18.75c0-.23.187-.417.417-.417h5.833c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417zM32.083 18.333a.417.417 0 00-.417.417v5.833c0 .23.187.417.417.417h4.583v-6.667zM29.583 33.333h-22.5a.417.417 0 01-.417-.416v-.834c0-.23.187-.416.417-.416h22.5c.23 0 .416.186.416.416v.834c0 .23-.186.416-.416.416z" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgHomepageGallery
