import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUnitTypeTownhouse: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.597 8.5V23H5V8.5m14.597 0H21l-8.5-7H12l-8.5 7H5m14.597 0H5m6 13.95V19h2.758v3.45m0-11.383h2.92V13h-2.92v-1.933zm-5.839 0h2.92V13h-2.92v-1.933z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.84 15H7.92L8 17h2.84v-2zM16.679 15h-2.92v2h2.92v-2z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgUnitTypeTownhouse
