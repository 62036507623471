import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBarrierFree: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.538 20.491V5.172l6.25-1.526V20.49h-6.25zm12.96 0h-.763V4.776a.503.503 0 00-.501-.505h-1.945a.503.503 0 00-.501.505c0 .279.224.504.501.504h1.444V20.49H13.79V3.004a.502.502 0 00-.62-.49L5.92 4.286c-.016.004-.029.013-.043.019a.498.498 0 00-.158.09c-.018.015-.035.03-.051.047a.515.515 0 00-.066.099c-.01.018-.021.034-.03.053a.5.5 0 00-.036.182V20.49H4.501a.502.502 0 00-.501.505c0 .278.224.504.501.504H19.5a.503.503 0 00.501-.504.503.503 0 00-.501-.505z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.198 11.66c-.386 0-.7.314-.7.704 0 .388.314.704.7.704.386 0 .7-.316.7-.704a.702.702 0 00-.7-.704z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgBarrierFree
