import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBirthday: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="birthday_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="currentColor" d="M0 0h31.997v31.997H0z" />
      </mask>
      <g mask="url(#birthday_svg__a)">
        <path
          d="M6.306 27.998a.904.904 0 01-.693-.306 1.03 1.03 0 01-.281-.72v-6.358c0-.597.21-1.105.63-1.524.42-.42.927-.63 1.524-.63h.512v-5.538c0-.596.21-1.104.63-1.524.42-.42.928-.63 1.524-.63h5.18V8.785c-.384-.25-.702-.533-.955-.851a1.75 1.75 0 01-.379-1.123 1.929 1.929 0 01.595-1.405L15.998 4l1.405 1.405a1.929 1.929 0 01.595 1.405c0 .43-.127.805-.38 1.123a3.987 3.987 0 01-.953.851v1.985h5.178c.597 0 1.105.21 1.525.629.42.42.63.928.63 1.524v5.538h.512c.596 0 1.105.21 1.524.63.42.42.63.927.63 1.524v6.358a.985.985 0 01-.307.72.985.985 0 01-.719.306H6.306zm3.026-9.538h13.332v-5.538a.799.799 0 00-.23-.59.8.8 0 00-.59-.23H10.151a.8.8 0 00-.59.23.799.799 0 00-.23.59v5.538zm-2.667 8.205h18.666v-6.051a.798.798 0 00-.231-.59.8.8 0 00-.59-.23H7.486a.8.8 0 00-.59.23.798.798 0 00-.23.59v6.05z"
          fill="#3E4153"
        />
      </g>
    </Icon>
  )
}
export default SvgBirthday
