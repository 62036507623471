import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSwitch: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.5 5.767l-.32-.385a.5.5 0 000 .77l.32-.385zm15 0l.32.384a.5.5 0 00-.32-.884v.5zm-15 12.466l-.32-.384a.5.5 0 00.32.884v-.5zm15 0l.32.385a.5.5 0 000-.77l-.32.385zm-2.408-2.65a.5.5 0 00-.639.768l.64-.769zm-.639 4.533a.5.5 0 00.64.768l-.64-.768zM7.547 3.884a.5.5 0 10-.64-.77l.64.77zm-.64 4.533a.5.5 0 10.64-.77l-.64.77zM4.5 6.267h15v-1h-15v1zm14.68-.885l-15 12.467.64.769 15-12.467-.64-.769zM4.5 18.733h15v-1h-15v1zm15.32-.884l-2.728-2.267-.639.77 2.727 2.266.64-.77zm-.64 0l-2.727 2.267.64.768 2.727-2.266-.64-.77zM4.82 6.15l2.727-2.266-.64-.77L4.18 5.382l.64.77zm-.64 0l2.728 2.267.639-.77L4.82 5.383l-.64.77z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSwitch
