import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosUsed: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.882 3.922h5.789M16.06 5.772l2.823-1.85L16.059 2M5.258 12.89l1.13-3.274M18.46 17.872H5.54M18.46 15.38h-.918c-.777 0-1.342.641-1.342 1.353v.57M3.352 15.38h3.106c.777 0 1.341.641 1.341 1.353v.57"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.741 12.89c1.06 0 1.907.854 1.907 1.921v5.125H3.352V14.81c0-1.067.847-1.921 1.906-1.921"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.505 10.612h1.412c.777 0 1.412.64 1.412 1.424v.925H2.505a.485.485 0 01-.494-.498v-1.281c-.07-.356.212-.57.494-.57zM21.495 10.612h-1.412c-.776 0-1.412.64-1.412 1.424v.925h2.824a.485.485 0 00.494-.498v-1.281c.07-.356-.212-.57-.494-.57zM5.753 22H3.846a.559.559 0 01-.564-.57v-1.494h2.965v1.495c.07.284-.142.57-.494.57zM20.083 22h-1.906a.559.559 0 01-.565-.57v-1.494h2.965v1.495c.07.284-.212.57-.494.57z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.742 12.89l-1.13-3.346c-.212-.711-.918-1.21-1.623-1.21h-4.024"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.882 3.922a4.523 4.523 0 00-4.518 4.555c0 2.562 1.977 4.555 4.518 4.555 1.06 0 1.977-.356 2.753-.925"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosUsed
