import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosBodyType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 4.508V2M7.625 5.887L6.75 4.445m9.563 1.442l.875-1.442M12 19.492V22m-4.375-3.95l-.875 1.442m9.563-1.442l.875 1.442m-2.625-9.028L14.5 9.335m6.813 4.89c.437-.187.687-.564.687-1.002v-.627a.806.806 0 00-.25-.627l-.313-.188-.062-.94c0-.44-.063-.816-.313-1.192-.562-.564-1.437-1.442-2.25-1.505-.375-.063-5.687-.063-6.875.063-1.187.125-4.124 2.32-4.75 2.696-.125.063-.187.125-.312.125-.75.126-3.438.564-3.938.815-.5.314-.874.815-.937 1.38v.877c0 .377.313.69.625.69h2.25m4.25-.063h7.813"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.438 10.813c1.437-.063 3.437-.188 5.937-.188.688 0 1.438-.125 2.125-.25a.91.91 0 00.563-.375c.312-.563.5-1.125.562-1.75m1.75 2.875h.938M6.188 15.688a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75zM18.438 15.625a1.312 1.312 0 100-2.625 1.312 1.312 0 000 2.625zM13 12.25h.625m-11.563.813h.813-.813z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosBodyType
