import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTruckCamper: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <circle cx={17.228} cy={16.438} r={1.365} />
        <circle cx={7.819} cy={16.438} r={1.365} />
        <g strokeLinejoin="round">
          <path d="M18.669 16.314H20a1 1 0 001-1v-1.176m-3.696-1.74L15.51 9.35m1.793 3.046h-6.105m6.105 0h2.128m-3.92-3.046h-4.313v3.046m4.312-3.046l.677-1.502a.5.5 0 00-.027-.462l-.65-1.082H10.023m1.176 6.092h-5.88m-1.569 0V7.305a1 1 0 011-1h1.352M3.75 12.397h1.568m-1.568 0v1.306m2.698 2.61H4.75a1 1 0 01-1-1v-1.61m5.344 2.61h6.778M5.318 12.398v1.306H3.75m15.682-1.306l.31.086A1.717 1.717 0 0121 14.138m-1.568-1.74v1.305l1.568.435M6.102 6.305V6a1 1 0 011-1h1.92a1 1 0 011 1v.305m-3.92 0h3.92M6.21 8.11h2.92v1.612H6.21z" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgTruckCamper
