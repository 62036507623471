import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBumpUp: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M5 11.667h30V5H5zm30 0v.833l.001-.833zm0 1.666H5a1.67 1.67 0 01-1.667-1.666V5A1.67 1.67 0 015 3.333h30A1.67 1.67 0 0136.666 5v6.667A1.67 1.67 0 0135 13.333zM35.833 25H4.166a.834.834 0 01-.833-.833v-8.334c0-.46.373-.833.833-.833h31.667c.46 0 .833.373.833.833v8.334c0 .46-.373.833-.833.833z"
          fill="#ececee"
        />
        <path
          d="M14.583 23.333H5.417A.417.417 0 015 22.917v-5.834c0-.23.187-.416.417-.416h9.166c.23 0 .417.186.417.416v5.834c0 .23-.187.416-.417.416z"
          fill="#c5c6cb"
        />
        <path
          d="M35.833 36.667H4.166a.834.834 0 01-.833-.834V27.5c0-.46.373-.833.833-.833h31.667c.46 0 .833.373.833.833v8.333c0 .46-.373.834-.833.834z"
          fill="#ececee"
        />
        <path
          d="M14.583 35H5.417A.417.417 0 015 34.583V28.75c0-.23.187-.417.417-.417h9.166c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417z"
          fill="#c5c6cb"
        />
        <path
          d="M32.256 13.577l-5.834-5.833a.814.814 0 00-.271-.18.839.839 0 00-.908.18l-5.832 5.833a.834.834 0 001.178 1.179l4.41-4.41v18.821a.834.834 0 001.667 0V10.346l4.411 4.41a.83.83 0 001.179 0 .834.834 0 000-1.179z"
          fill="#37a864"
        />
      </g>
    </Icon>
  )
}
export default SvgBumpUp
