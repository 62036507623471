import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTrailerParkModel: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.375 21.42v-3.25h3.25v3.25h-3.25zm17.417 0v-3.25h3.25v3.25h-3.25z"
        stroke="#3E4153"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
      <circle cx={27.417} cy={26.92} r={2.417} stroke="#3E4153" strokeWidth={1.5} />
      <path
        d="M12.417 26.92a2.417 2.417 0 11-4.833 0 2.417 2.417 0 014.833 0z"
        stroke="#3E4153"
        strokeWidth={1.5}
      />
      <path
        d="M12.375 26.92h3.958m8.709 0h-3.959m-4.75 0h4.75m-4.75 0v-9.5h4.75v4.75m0 4.75v-4.75m0 0h1.584"
        stroke="#3E4153"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.292 25.337a.75.75 0 10-1.5 0h1.5zm-1.5 3.167a.75.75 0 101.5 0h-1.5zm-.834-13.459v.75a.75.75 0 00.577-1.23l-.577.48zM29 10.295l.576-.48a.75.75 0 00-.576-.27v.75zm-22.167 0v-.75a.75.75 0 00-.624.334l.624.416zm-3.166 4.75l-.624-.416a.75.75 0 00.624 1.166v-.75zm30.125 10.292v1.583h1.5v-1.583h-1.5zm0 1.583v1.584h1.5V26.92h-1.5zm-28.584-.5V15.045h-1.5V26.42h1.5zm29.334-.25h-2.375v1.5h2.375v-1.5zm-2.375 0h-2.375v1.5h2.375v-1.5zm-.75-11.125V26.92h1.5V15.045h-1.5zM7.625 26.17H4.958v1.5h2.667v-1.5zM4.458 15.795H19.5v-1.5H4.458v1.5zm15.042 0h7.125v-1.5H19.5v1.5zm7.125 0h5.542v-1.5h-5.542v1.5zm5.542 0h.791v-1.5h-.791v1.5zm1.368-1.23l-3.959-4.75-1.152.96 3.958 4.75 1.153-.96zM29 9.545H6.833v1.5H29v-1.5zM6.21 9.88l-3.167 4.75 1.248.832 3.166-4.75L6.21 9.88zm-2.543 5.916h.791v-1.5h-.791v1.5zm.041 10.625c0 .69.56 1.25 1.25 1.25v-1.5a.25.25 0 01.25.25h-1.5z"
        fill="#3E4153"
      />
    </Icon>
  )
}
export default SvgTrailerParkModel
