import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconEllipsis: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 4" {...props}>
      <circle cx={2} cy={2} r={2} fill="currentColor" />
      <circle cx={10} cy={2} r={2} fill="currentColor" />
      <circle cx={18} cy={2} r={2} fill="currentColor" />
    </Icon>
  )
}
export default SvgIconEllipsis
