import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgConditionNew: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153" strokeLinejoin="round">
        <path
          d="M10.885 5.68c.065.2.136.413.213.63.446 1.265 1.129 2.83 2.044 3.746.916.916 2.482 1.599 3.746 2.045.218.077.43.148.631.213a23.27 23.27 0 00-.631.212c-1.264.447-2.83 1.13-3.746 2.045-.915.916-1.598 2.482-2.044 3.746-.042.118-.082.234-.12.348a18.42 18.42 0 00-.081-.312c-.34-1.265-.913-2.852-1.843-3.782-.472-.472-1.13-.871-1.82-1.204a17.537 17.537 0 00-2.15-.845 25.896 25.896 0 00-.757-.233c.179-.058.365-.12.555-.188 1.264-.446 2.83-1.129 3.746-2.045.915-.915 1.598-2.481 2.044-3.745.078-.218.148-.43.213-.632z"
          fill="#3e4153"
        />
        <path d="M18.698 6.106c-.153-.395-.362-.798-.65-1.086-.235-.235-.55-.422-.856-.57a6.685 6.685 0 00-.51-.218c.427-.185.875-.429 1.184-.738.323-.323.576-.8.763-1.244.187.445.44.92.763 1.244.323.324.8.576 1.244.763-.445.187-.92.44-1.244.763-.286.286-.516.69-.694 1.086z" />
      </g>
    </Icon>
  )
}
export default SvgConditionNew
