import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotificationDraft: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="notification-draft_svg__a"
        height={16}
        maskUnits="userSpaceOnUse"
        width={16}
        x={16}
        y={16}
      >
        <path
          clipRule="evenodd"
          d="M30.83 21.885l-.778.777-4.714-4.714.777-.777a.583.583 0 01.826 0l3.888 3.888a.584.584 0 010 .826zm-8.695 8.694a.587.587 0 01-.387.17l-4.675.223.605-4.634 2.263 2.263a.495.495 0 00.707 0 .5.5 0 000-.707l-2.628-2.628 6.611-6.61 4.714 4.713zm9.401-10.227l-3.888-3.888a1.585 1.585 0 00-2.24 0l-8.188 8.188a1.592 1.592 0 00-.45.916l-.766 5.868A.502.502 0 0016.5 32h.024l5.273-.251c.395-.02.766-.184 1.045-.463l8.694-8.694a1.585 1.585 0 000-2.24z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <circle cx={24} cy={24} fill="#ffb92e" r={24} />
      <g fill="#3e4153">
        <path
          clipRule="evenodd"
          d="M30.83 21.885l-.778.777-4.714-4.714.777-.777a.583.583 0 01.826 0l3.888 3.888a.584.584 0 010 .826zm-8.695 8.694a.587.587 0 01-.387.17l-4.675.223.605-4.634 2.263 2.263a.495.495 0 00.707 0 .5.5 0 000-.707l-2.628-2.628 6.611-6.61 4.714 4.713zm9.401-10.227l-3.888-3.888a1.585 1.585 0 00-2.24 0l-8.188 8.188a1.592 1.592 0 00-.45.916l-.766 5.868A.502.502 0 0016.5 32h.024l5.273-.251c.395-.02.766-.184 1.045-.463l8.694-8.694a1.585 1.585 0 000-2.24z"
          fillRule="evenodd"
        />
        <g mask="url(#notification-draft_svg__a)">
          <path d="M12 12h24v24H12z" />
          <path d="M12 12h24v24H12z" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgNotificationDraft
