import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUnitTypeBasement: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.885 5H2v16h17v-5.053h-5.885v-5.052h-5.23V5zM13 2.5l7.555 7.637m0 0L17 10.5m3.555-.363L21 6.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgUnitTypeBasement
