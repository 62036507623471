import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAvailable: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.5 12.5l3 3M16.5 9.5l-6 6"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={12} cy={12} r={10} stroke="currentColor" />
    </Icon>
  )
}
export default SvgAvailable
