import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconCategoryBuyAndSell: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.004 48.004" {...props}>
      <circle cx={24} cy={24} r={24} fill="currentColor" />
      <path
        fill="#373373"
        d="M34.562 14.006h-7.081a.625.625 0 000 1.25h6.456v6.456a4.187 4.187 0 01-1.055 3.097l-9.944 9.875a2.194 2.194 0 01-1.56.647h-.001a2.193 2.193 0 01-1.561-.647l-5.047-5.049a2.828 2.828 0 01-.009-3.989c.068-.064 6.713-6.425 8.204-7.917a.625.625 0 00-.884-.883c-1.481 1.481-8.117 7.833-8.194 7.907a4.078 4.078 0 000 5.766l5.046 5.049a3.433 3.433 0 002.445 1.013 3.436 3.436 0 002.443-1.01l9.983-9.918a5.421 5.421 0 001.384-3.94V14.63a.625.625 0 00-.625-.625z"
      />
      <path
        fill="#373373"
        d="M30.188 21.505a2.5 2.5 0 10-.002 0zm-1.25-2.5a1.25 1.25 0 111.25 1.25 1.25 1.25 0 01-1.25-1.25zm-6.36 4.79l.783-.784a1.847 1.847 0 00-2.516-.234l-.646-.646-.557.557.64.64a1.861 1.861 0 00.157 2.578c.791.79 1.644.653 2.785.103.894-.44 1.368-.516 1.74-.145a.956.956 0 01-.076 1.451 1.12 1.12 0 01-1.72.014l-.776.777a2.019 2.019 0 002.847.179l.646.646.557-.557-.646-.646a1.918 1.918 0 00-.11-2.695c-.716-.716-1.479-.722-2.827-.076-.852.385-1.278.536-1.705.11a.84.84 0 01.02-1.286.93.93 0 011.404.014z"
      />
    </Icon>
  )
}
export default SvgIconCategoryBuyAndSell
