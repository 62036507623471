import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUnitTypeHouse: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.2 13.121l2.253-2.36m0 0v-6.49h3.379v2.95m-3.38 3.54l3.38-3.54m-3.38 3.54v10.621h5.632M6.832 7.221L11.9 2.5l8.448 7.92M22.6 12.53l-2.252-2.112m0 0v10.963h-5.632m-5.632 0v-5.67a2 2 0 012-2h1.632a2 2 0 012 2v5.67m-5.632 0h5.632"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.653 8.99c0 1.05-.807 1.861-1.753 1.861-.946 0-1.752-.81-1.752-1.86s.806-1.86 1.752-1.86 1.753.81 1.753 1.86z"
        stroke="#3E4153"
      />
    </Icon>
  )
}
export default SvgUnitTypeHouse
