import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgStarFilled: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="star-filled_svg__a" height={16} maskUnits="userSpaceOnUse" width={17} x={4} y={4}>
        <path
          clipRule="evenodd"
          d="M7.28 20a.344.344 0 01-.213-.073.39.39 0 01-.145-.372l.87-5.316-3.682-3.765a.394.394 0 01-.093-.391.371.371 0 01.294-.259L9.4 9.049l2.275-4.837C11.735 4.083 11.862 4 12 4s.264.083.325.212l2.276 4.837 5.088.775c.136.021.25.122.293.259a.394.394 0 01-.092.391l-3.68 3.765.868 5.316a.39.39 0 01-.145.372.348.348 0 01-.382.029l-4.55-2.51-4.552 2.511A.363.363 0 017.28 20z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#star-filled_svg__a)">
        <path d="M0 0h24v24H0z" fill="#f8aa17" />
      </g>
    </Icon>
  )
}
export default SvgStarFilled
