import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgOther: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.768 5.036c-2.143.714-3.84 2.321-4.643 4.375m9.107 9.553c2.143-.714 3.84-2.321 4.643-4.375"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.446 12.804a.804.804 0 100-1.608.804.804 0 000 1.608zM16.554 12.804a.804.804 0 100-1.608.804.804 0 000 1.608zM12 12.804a.804.804 0 100-1.608.804.804 0 000 1.608z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgOther
