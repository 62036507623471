import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMale: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="male_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#male_svg__a)">
        <path
          d="M19.5 4.5v5h-1V6.233l-5.129 5.084c.33.48.6.986.812 1.52.211.533.317 1.087.317 1.663 0 1.394-.485 2.576-1.454 3.546-.97.97-2.15 1.454-3.544 1.454-1.394 0-2.576-.485-3.546-1.454-.97-.97-1.456-2.15-1.456-3.544 0-1.394.485-2.576 1.455-3.546C6.925 9.986 8.105 9.5 9.5 9.5c.576 0 1.127.105 1.654.315.527.209 1.028.48 1.504.814L17.787 5.5H14.5v-1h5zm-10.003 6c-1.106 0-2.048.39-2.828 1.172-.78.782-1.169 1.726-1.169 2.831 0 1.106.39 2.048 1.172 2.828.782.78 1.726 1.169 2.831 1.169 1.106 0 2.048-.39 2.828-1.172.78-.782 1.169-1.726 1.169-2.831 0-1.106-.39-2.048-1.172-2.828-.782-.78-1.726-1.169-2.831-1.169z"
          fill="#3E4153"
        />
      </g>
    </Icon>
  )
}
export default SvgMale
