import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { TextLink } from '@/ui/atoms/text-link'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const BreadcrumbList = styled.ol(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.mini};
  list-style: none;
  padding: 0;
`
)

export const BreadcrumbLink = styled(TextLink)(
  ({ theme }) => `
  align-items: center;
  display: flex;
  gap: ${theme.spacing.mini};

  & svg {
    height: auto;
    width: ${theme.spacing.large};
  }

  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body.small)}
  }
`
)

export const BreadcrumbText = styled(BodyText)(
  ({ theme }) => `
  align-items: center;
  display: flex;
  gap: ${theme.spacing.mini};
  
  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body.small)}
  }
`
)

export const BreadcrumbChevronContainer = styled.div(
  ({ theme }) => `
  width: ${theme.spacing.large};
  height: ${theme.spacing.large}; 
  
  & svg {
    height: auto;
    width: ${theme.spacing.large};
  }
`
)
