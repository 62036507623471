import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgShareListings: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.203 17.071l-.022-14.07-3.89 3.624M16.085 6.612L12.181 3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.308 11H6.5A2.5 2.5 0 004 13.5v5.286a2.5 2.5 0 002.5 2.5h11a2.5 2.5 0 002.5-2.5V13.5a2.5 2.5 0 00-2.5-2.5h-1.808"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgShareListings
