import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgDelivery: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.479 4h-9a.5.5 0 100 1H15v4h-1.5a.5.5 0 100 1h2a.499.499 0 00.5-.5V5h4.479a.522.522 0 01.521.52l-.021 12.94a.522.522 0 01-.52.522h-.709a.5.5 0 000 1h.709a1.522 1.522 0 001.52-1.52L22 5.522A1.523 1.523 0 0020.479 4z"
        fill="currentColor"
      />
      <path
        d="M16.444 16.172a18.337 18.337 0 00-1.34-1.653 4.347 4.347 0 00-2.027-.938l-.711-.895.093-.04c.294-.127.53-.356.666-.646a1.052 1.052 0 00-.097-.827L8.955 2.292a.5.5 0 00-.91.417l4.074 8.882-.06.139-4.877 2.133-.146-.056L2.96 4.303a.5.5 0 00-.92.394L4 9.267a.48.48 0 00-.021.106v9.104a1.523 1.523 0 001.521 1.52h2a.491.491 0 00.31-.116c.162.393.307.71.368.84a2.288 2.288 0 002.13 1.277h4.595a1.372 1.372 0 001.258-.68c.216-.438.268-.938.149-1.412a1.803 1.803 0 001.536-.456c.163-.177.63-.828-.203-1.765-.352-.396-.782-.964-1.199-1.513zM5.5 18.998a.522.522 0 01-.521-.521v-6.928l1.138 2.653a1.106 1.106 0 001.465.577l.257-.112.122.679a3.348 3.348 0 00-.831 1.708 4.86 4.86 0 00.35 1.944H5.5zm10.816-.128a4.024 4.024 0 01-.845-.971.5.5 0 10-.858.515l.002.002c.774 1.292.848 2.106.676 2.41-.027.047-.097.172-.388.172h-4.594a1.285 1.285 0 01-1.22-.69 8.927 8.927 0 01-.969-3.112c.093-.5.34-.957.707-1.308a.499.499 0 00.164-.465l-.21-1.168 2.656-1.162a.484.484 0 00.046.09l.926 1.165a.5.5 0 00.312.183c.607.09 1.183.33 1.676.696.444.493.862 1.01 1.25 1.55.429.564.871 1.147 1.249 1.572.169.19.26.373.214.424a.863.863 0 01-.794.097z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgDelivery
