import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCalender: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.5 5h-2.468V3.5a.5.5 0 10-1 0V5h-4.028V3.5a.5.5 0 00-1 0V5H6.5A2.503 2.503 0 004 7.5v11A2.503 2.503 0 006.5 21h11a2.503 2.503 0 002.5-2.5v-11A2.503 2.503 0 0017.5 5zM19 18.5a1.502 1.502 0 01-1.5 1.5h-11A1.502 1.502 0 015 18.5V11h10.5a.5.5 0 000-1H5V7.5A1.502 1.502 0 016.5 6h2.504v1.5a.5.5 0 101 0V6h4.028v1.5a.5.5 0 001 0V6H17.5A1.502 1.502 0 0119 7.5v11z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgCalender
