import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosSeatsSimple: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.01 5.5c0 1.38-1.12 2.5-2.503 2.5a2.502 2.502 0 01-2.504-2.5c0-1.38 1.121-2.5 2.504-2.5a2.502 2.502 0 012.503 2.5zm-1.502 0a1.002 1.002 0 00-2.003 0 1.002 1.002 0 002.003 0zM18.873 17.945l-1.159-3.41A2.266 2.266 0 0015.569 13h-2.05a.013.013 0 01-.012-.008L12.368 9.96a2.254 2.254 0 00-4.22 1.58l1.687 4.49a2.266 2.266 0 002.122 1.47h1.997c.005 0 .01.003.012.008l.668 1.964a2.253 2.253 0 003.007 1.351c1.106-.462 1.617-1.746 1.232-2.878zm-1.812 1.494a.752.752 0 01-1.006-.449l-.667-1.964a1.515 1.515 0 00-1.434-1.027h-1.997a.768.768 0 01-.716-.495l-1.686-4.49A.746.746 0 0110.258 10c.259-.001.577.15.703.486l1.138 3.03c.22.588.791.983 1.42.983h2.05c.327 0 .618.208.723.519l1.159 3.409c.14.41-.035.865-.39 1.012z"
        fill="currentColor"
      />
      <path
        d="M12.26 18.5h-.942c-1.076 0-2.046-.646-2.414-1.607l-2.45-6.41a.751.751 0 00-1.404.534l2.45 6.411C8.09 18.966 9.624 20 11.319 20h.943a.75.75 0 100-1.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAutosSeatsSimple
