import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFreebies: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.5 11a.51.51 0 00-.5.5V14h-7.5V9h7a.5.5 0 000-1h-4.33a4.22 4.22 0 001.36-.82 2.32 2.32 0 00.72-1.69 2.45 2.45 0 00-1.576-2.322A2.52 2.52 0 0014.67 3a2.17 2.17 0 00-1.61.76A5.82 5.82 0 0012 5.8a5.64 5.64 0 00-1-2A2.21 2.21 0 009.39 3a2.48 2.48 0 00-2.58 2.5 2.37 2.37 0 00.73 1.69c.398.353.86.628 1.36.81H4.51A1.52 1.52 0 003 9.51v10A1.51 1.51 0 004.51 21h15A1.5 1.5 0 0021 19.5v-8a.5.5 0 00-.5-.5zm-6.68-6.6a1.21 1.21 0 01.89-.4c.4.002.784.16 1.07.44a1.47 1.47 0 01.42 1.05 1.35 1.35 0 01-.42 1 6.54 6.54 0 01-3.19 1.17 7.27 7.27 0 011.23-3.26zm-6 1.08a1.48 1.48 0 01.43-1.05A1.52 1.52 0 019.31 4a1.23 1.23 0 01.89.41 7.27 7.27 0 011.18 3.23 6.57 6.57 0 01-3.2-1.17 1.4 1.4 0 01-.37-.99h.01zM4.51 9h7v5H4V9.51A.51.51 0 014.51 9zM4 19.5V15h7.5v5h-7a.5.5 0 01-.5-.5zm15.5.5h-7v-5H20v4.5a.5.5 0 01-.5.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgFreebies
