import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSettingsSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21 11c0-.3-.3-.5-.6-.5h-.7c-.3 0-.5-.2-.6-.5-.2-.6-.4-1.2-.7-1.7-.1-.2-.1-.6.1-.8L19 7c.2-.2.2-.6 0-.8-.4-.5-.8-.9-1.3-1.3-.2-.2-.6-.2-.8 0l-.5.5c-.2.2-.5.2-.8.1-.5-.3-1.1-.5-1.7-.7-.3-.1-.5-.3-.5-.6v-.6c0-.3-.2-.6-.6-.6H11c-.3 0-.6.3-.6.6v.7c0 .3-.2.5-.5.6-.6.2-1.2.4-1.7.7-.2.1-.6.1-.8-.1L7.1 5c-.2-.2-.6-.2-.8 0-.5.4-.9.8-1.3 1.3-.2.2-.2.6 0 .8l.5.5c.2.2.2.5.1.8-.3.5-.5 1.1-.7 1.7-.1.3-.3.5-.6.5h-.7c-.3 0-.6.2-.6.6V13c0 .3.3.6.6.6h.7c.3 0 .5.2.6.5.2.6.4 1.2.7 1.7.1.2.1.6-.1.8l-.5.5c-.2.2-.2.6 0 .8.4.5.8.9 1.3 1.3.2.2.6.2.8 0l.5-.5c.2-.2.5-.2.8-.1.5.3 1.1.5 1.7.7.3.1.5.3.5.6v.7c0 .3.2.6.6.6H13c.3 0 .6-.3.6-.6v-.7c0-.3.2-.5.5-.6.6-.2 1.2-.4 1.7-.7.2-.1.6-.1.8.1l.4.3c.2.2.6.2.8 0 .5-.4.9-.8 1.3-1.3.2-.2.2-.6 0-.8l-.5-.5c-.2-.2-.2-.5-.1-.8.3-.5.5-1.1.7-1.7.1-.3.3-.5.6-.5h.7c.3 0 .6-.2.6-.6V11H21zm-8.9 5.4c-2.5 0-4.4-2-4.4-4.4 0-2.4 2-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4 0 2.4-1.9 4.4-4.4 4.4z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSettingsSolid
