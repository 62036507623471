import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUnitType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.322 13.873l-5.38-4.612a.956.956 0 00-1.253 0l-5.38 4.612a.52.52 0 00-.064.715.48.48 0 00.69.068l5.382-4.613 5.38 4.613a.48.48 0 00.69-.068.52.52 0 00-.065-.715z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.668 18.082c0-.36.284-.653.632-.653.347 0 .63.294.63.653v1.338h-1.262v-1.338zm5.128-2.92c-.27 0-.49.227-.49.508v3.631a.18.18 0 01-.177.184h-2.218v-1.403c0-.92-.724-1.668-1.611-1.668-.89 0-1.613.748-1.613 1.668v1.403h-2.194a.18.18 0 01-.177-.184v-3.596a.5.5 0 00-.49-.508.5.5 0 00-.49.508V19.3c0 .065.026.121.036.184H4.48V4.515h6.858v3.016a.5.5 0 00.49.508c.272 0 .49-.228.49-.508V4.008a.499.499 0 00-.49-.508H3.99a.499.499 0 00-.49.508v15.984c0 .28.219.508.49.508H18.13c.639 0 1.158-.538 1.158-1.199v-3.63a.498.498 0 00-.49-.508z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.18 6.467a.3.3 0 00-.295-.305h-.817a.3.3 0 00-.294.305v.845a.3.3 0 00.294.305h.817a.3.3 0 00.294-.305v-.845zM9.826 6.467a.3.3 0 00-.294-.305h-.817a.3.3 0 00-.295.305v.845a.3.3 0 00.295.305h.817a.3.3 0 00.294-.305v-.845zM7.18 9.439a.3.3 0 00-.295-.305h-.817a.3.3 0 00-.294.305v.845a.3.3 0 00.294.305h.817a.3.3 0 00.294-.305V9.44zM9.532 9.134h-.817a.3.3 0 00-.295.304v.846a.3.3 0 00.295.304h.817a.3.3 0 00.294-.304v-.846a.3.3 0 00-.294-.304zM7.18 12.41a.3.3 0 00-.295-.304h-.817a.3.3 0 00-.294.304v.846a.3.3 0 00.294.304h.817a.3.3 0 00.294-.304v-.846zM6.885 15.077h-.817a.3.3 0 00-.294.305v.845a.3.3 0 00.294.305h.817a.3.3 0 00.294-.305v-.845a.3.3 0 00-.294-.305z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgUnitType
