import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWiFiAndMore: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.693 30a.985.985 0 01-.72-.306.985.985 0 01-.306-.72V13.692c0-.275.102-.515.306-.719a.985.985 0 01.72-.306h7.282c.275 0 .515.102.72.306a.985.985 0 01.305.72v15.281a.985.985 0 01-.306.72.984.984 0 01-.72.306h-7.281zm3.64-11.564c.297 0 .554-.11.774-.33.22-.22.33-.477.33-.773 0-.295-.11-.553-.33-.773-.22-.22-.477-.33-.773-.33-.296 0-.554.11-.773.33-.22.22-.33.478-.33.773 0 .296.11.554.33.773.22.22.477.33.773.33zM11.653 9.01l-.944-.943a8.214 8.214 0 012.623-1.546A8.783 8.783 0 0116.334 6c1.04 0 2.04.174 3.003.52a8.213 8.213 0 012.622 1.547l-.943.943a7.369 7.369 0 00-4.682-1.677 7.367 7.367 0 00-4.682 1.677zM7.867 5.277l-.959-1.01a13.864 13.864 0 014.373-2.676 14.078 14.078 0 015.053-.924c1.733 0 3.409.312 5.027.937a13.208 13.208 0 014.332 2.73l-.893.943a12.203 12.203 0 00-3.918-2.441A12.566 12.566 0 0016.334 2c-1.583 0-3.1.279-4.549.836a12.203 12.203 0 00-3.918 2.441zm5.134 23.39h6.666V14H13v14.667z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgWiFiAndMore
