import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgXCircle: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C5.394 0 0 5.394 0 12c0 6.605 5.394 12 12 12 6.605 0 12-5.395 12-12 0-6.606-5.395-12-12-12zm5.36 6l-4.372 5.081L17.742 18h-3.497l-3.2-4.66L7.034 18H6l4.584-5.328L6 6h3.496l3.032 4.412L16.323 6h1.036zm-6.255 6.066l.464.664 3.164 4.526h1.59l-3.876-5.546-.464-.664L9 6.78H7.41l3.695 5.286z"
        fill="#3E4153"
      />
    </Icon>
  )
}
export default SvgXCircle
