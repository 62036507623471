import { type AutosSurcharges, ListingPriceType } from '@kijiji/generated/graphql-types'
import { formatPrice } from '@kijiji/money'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ListingSurcharges } from '@/components/shared/listing-surcharges/ListingSurcharges'
import {
  AutosListingOriginalPriceText,
  AutosListingPriceContainer,
  AutosListingPriceText,
  ListingPriceContainer,
  ListingPriceText,
} from '@/components/srp/listing-card/listing-price/styled'
import { type ListingCardPrice } from '@/components/srp/listing-card/types'
import { ROUTE_LOCALE, useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'

export enum ListingPriceVariant {
  Default = 'default',
  Autos = 'autos',
}

export type ListingPriceProps = {
  /**
   * Defines if list of prices should be displayed inline
   * @default false
   */
  displayInlinePrice?: boolean
  /**
   * Price input by the user when they are posting a car
   * This should be the retail value of the car in cents
   */
  msrp?: number
  /**
   * Listing price in cents
   */
  priceAmount?: number
  /**
   * Listing price drop in cents
   */
  priceOriginal?: number
  /**
   * Taxes or luxury surcharge applied to the listing
   */
  surcharges?: AutosSurcharges
  /**
   * Type of listing price
   */
  type?: ListingCardPrice['type']
  /**
   * Style variant of the listing price
   */
  variant?: ListingPriceVariant
  /**
   * Is the category of the listing "Free Stuff"
   */
  isCategoryFreeStuff?: boolean
  /**
   * Location of the current listing
   */
}

export const ListingPrice: FC<ListingPriceProps> = ({
  displayInlinePrice,
  msrp,
  priceAmount,
  priceOriginal,
  surcharges,
  type,
  variant = ListingPriceVariant.Default,
  isCategoryFreeStuff,
}) => {
  const hasPriceDrop = !!priceOriginal
  const { routeLocale } = useLocale()
  const { t } = useTranslation('listing')
  const { colors } = useTheme()

  // If there is no price type, there is nothing to do.
  if (!type && !isCategoryFreeStuff) {
    return null
  }

  const getPrice = () => {
    /**
     * If price is not a fixed amount - display the type of price instead of the $ value
     * Free and Giveaway are the same
     */
    if ((type && type === ListingPriceType.Free) || isCategoryFreeStuff)
      return t('price.types.give_away')
    if (type && type !== ListingPriceType.Fixed) return t(`price.types.${type.toLowerCase()}`)

    /**
     * Return formatted $ value depending on locale
     * If the type is not defined as give_away or contact and there is no amount, should show as $0
     */
    return formatPrice(priceAmount || 0, {
      isFrench: routeLocale === ROUTE_LOCALE.fr,
      suppressCents: variant === ListingPriceVariant.Autos,
    })
  }

  const getOriginalPrice = () => {
    /**
     * If there is no price - don't show any price drop
     */
    if (!priceAmount || !hasPriceDrop) {
      return null
    }

    return formatPrice(priceOriginal, {
      isFrench: routeLocale === ROUTE_LOCALE.fr,
      suppressCents: variant === ListingPriceVariant.Autos,
    })
  }

  /**
   * What the retail price would be from a dealership.
   * When a car is posted, the seller has to enter the price that would cost to buy retail.
   */
  const getMSRPValue = () => {
    /**
     * The MSRP value should only be displayed if:
     * - There is aMSRP value AND
     * - If it is a new car
     *
     * In all other cases it shouldn't be displayed
     */
    if (!msrp) return null

    const formattedMSRP = formatPrice(msrp, {
      isFrench: routeLocale === ROUTE_LOCALE.fr,
      suppressCents: variant === ListingPriceVariant.Autos,
    })
    return t('price.msrp', { price: formattedMSRP })
  }

  const originalPrice = getOriginalPrice()
  const MSRPValue = getMSRPValue()

  if (variant === ListingPriceVariant.Autos) {
    return (
      <AutosListingPriceContainer
        data-testid="autos-listing-price-container"
        hasPriceDrop={hasPriceDrop}
      >
        <AutosListingPriceText
          color={hasPriceDrop ? colors.red.dark1 : colors.grey.primary}
          data-testid="autos-listing-price"
          size="large"
          weight="bold"
        >
          {getPrice()}
        </AutosListingPriceText>

        {!!originalPrice && (
          <AutosListingOriginalPriceText
            color={colors.grey.primary}
            data-testid="autos-listing-original-price"
            size="xSmall"
          >
            {originalPrice}
          </AutosListingOriginalPriceText>
        )}

        <ListingSurcharges surcharges={surcharges} />

        {!!MSRPValue && (
          <BodyText color={colors.grey.light1} data-testid="autos-listing-msrp-value" size="xSmall">
            {MSRPValue}
          </BodyText>
        )}
      </AutosListingPriceContainer>
    )
  }

  return (
    <ListingPriceContainer
      displayInlinePrice={displayInlinePrice}
      hasPriceDrop={hasPriceDrop}
      data-testid="listing-price-container"
    >
      <ListingPriceText
        color={hasPriceDrop ? colors.red.dark1 : colors.green.dark1}
        data-testid="listing-price"
        size="large"
        weight="medium"
      >
        {getPrice()}
      </ListingPriceText>

      {!!originalPrice && (
        <ListingPriceText
          color={colors.grey.primary}
          data-testid="listing-price-original"
          size="large"
          weight="medium"
        >
          {originalPrice}
        </ListingPriceText>
      )}

      {!!MSRPValue && (
        <BodyText color={colors.grey.light1} data-testid="listing-msrp-value" size="medium">
          {MSRPValue}
        </BodyText>
      )}

      <ListingSurcharges surcharges={surcharges} />
    </ListingPriceContainer>
  )
}
