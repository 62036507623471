import { type ListingLocation } from '@kijiji/generated/graphql-types'

export type RealEstateSeoAttributes = {
  bedrooms?: string
  bathrooms?: string
  squareFootage?: string
  petsAllowed?: boolean
}

/**
 * @param attributes from Anvil, varying by category and specific listing
 * @returns structured markup required for vehicle & car schema types
 */
export const getRealEstateStructuredMarkup = (
  attributes: RealEstateSeoAttributes,
  location: ListingLocation
) => {
  const { bedrooms, bathrooms, squareFootage, petsAllowed } = attributes

  return {
    numberOfBedrooms: bedrooms,
    numberOfBathroomsTotal: bathrooms,
    petsAllowed,
    ...(squareFootage && {
      floorSize: {
        '@type': 'QuantitativeValue',
        value: squareFootage,
        unitCode: 'FTK',
      },
    }),
    numberOfRooms: bedrooms,
    address: location.address ?? '',
  }
}
