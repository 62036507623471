import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFishingBoat: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153" strokeLinecap="round" strokeLinejoin="round">
        <path d="M15.137 13.534s-.538 1.572-1.194 1.989H10m5.137-1.989H22l-1.511 3.908A4 4 0 0116.758 20H10.39a4 4 0 01-3.522-2.105l-.88-1.635a.5.5 0 01.44-.737H10m5.137-1.989L10 13.5v2.023M3.205 15.125V3.15L12.5 6v4m-9.295 5.126s-.919.823-1.037 1.59c-.127.828 1.037 2.155 1.037 2.155s1.111-1.249 1.08-2.154c-.023-.694-1.08-1.59-1.08-1.59zm0 0l-1.193-1.193m1.193 1.193l1.193-1.193" />
        <path d="M19.511 13.534l-1.59-3.58h-3.58m0 0L11 10v1.744m3.341-1.79v1.79m0 1.79v-1.79M11 13v-1.255m3.341 0H11M15.534 9.955V8.364" />
      </g>
    </Icon>
  )
}
export default SvgFishingBoat
