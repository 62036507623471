import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosParkingAssist: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.75 10.186c0 2.246-1.555 3.973-4.232 3.973h-1.554l.086 4.318H8.718L8.632 6.818c0-.259.173-.432.432-.432h3.454c2.591-.086 4.232 1.469 4.232 3.8zm-5.786-1.64v3.368h1.554c.864.086 1.727-.518 1.814-1.382v-.346c0-.863-.691-1.64-1.555-1.727h-.345l-1.468.086z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosParkingAssist
