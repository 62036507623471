import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosStockNumber: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.188 15.932H2V4h1.188m2.31 5.763V6.576m2.575 2.441v-2.44m2.64 3.186V6.576m2.574 4.882V6.576m2.64 4.882V6.576m4.885 9.356H22V4h-1.188m-2.244 9.424V6.576M11.307 20l-1.782-3.119c1.386-.813 1.848-2.644 1.056-4.067a2.862 2.862 0 00-3.96-1.085c-1.387.813-1.849 2.644-1.057 4.068.462.745 1.188 1.288 1.98 1.423"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosStockNumber
