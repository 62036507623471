import styled from 'styled-components'

import { ListItem } from '@/ui/atoms/list-item'

export const RealEstateListItem = styled(ListItem)`
  & > p {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

export const VerticalDivider = styled.span(
  ({ theme }) => `
      width: 0.1rem;
      height: ${theme.spacing.default};
      background: ${theme.colors.grey.light2};
      margin-left: ${theme.spacing.default};
      margin-right: ${theme.spacing.defaultSmall};
  `
)
