import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgRealEstateBackground: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#real-estate-background_svg__clip0_662_280)">
        <path
          d="M24 48.011c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z"
          fill="#F3F3F9"
        />
        <path
          d="M33.424 26.568h-.002a.625.625 0 00-.625.623l-.017 5.576-4.968-.001V28.04a3.75 3.75 0 00-7.5 0v4.724h-5.05l.028-5.568a.625.625 0 00-.622-.628h-.003a.625.625 0 00-.625.622l-.03 6.195a.625.625 0 00.625.628l18.768.004a.626.626 0 00.625-.623l.019-6.2a.625.625 0 00-.623-.626zM21.562 28.04a2.5 2.5 0 015 0v4.723h-5V28.04z"
          fill="currentColor"
        />
        <path
          d="M36.302 23.866L24.456 12.982a.625.625 0 00-.846 0l-4.549 4.18v-2.524a.624.624 0 00-.625-.625h-3.75a.625.625 0 00-.624.625v7.032a.584.584 0 00.014.071l-2.312 2.125a.625.625 0 00.846.92l11.424-10.494 11.422 10.494a.625.625 0 10.846-.92zm-20.99-8.603h2.5v2.97a.56.56 0 00.013.064l-2.513 2.31v-5.344z"
          fill="currentColor"
        />
        <path
          d="M26.64 19.716a2.578 2.578 0 10-5.156 0 2.578 2.578 0 005.156 0zm-3.907 0a1.328 1.328 0 112.657 0 1.328 1.328 0 01-2.657 0z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="real-estate-background_svg__clip0_662_280">
          <path fill="#fff" transform="translate(0 .011)" d="M0 0h48.004v48.004H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgRealEstateBackground
