import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgColorSimple: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.584 26.046l-6.969-6.97a2.112 2.112 0 01-.461-.683 2.004 2.004 0 010-1.542c.102-.246.256-.471.461-.677l7.41-7.401-3.84-3.739 1.04-1.064 12.207 12.232c.203.204.355.43.455.676.1.248.15.505.15.773 0 .267-.05.525-.15.772-.1.247-.253.473-.458.68l-6.942 6.942c-.206.206-.431.36-.677.462a2.003 2.003 0 01-1.542 0 2.11 2.11 0 01-.684-.462zM13.022 9.77l-7.286 7.287a.806.806 0 00-.257.615h15.086a.809.809 0 00-.256-.615L13.022 9.77zm12.735 16.89c-.629 0-1.153-.218-1.571-.656-.42-.438-.628-.974-.628-1.61 0-.48.102-.944.308-1.392.206-.448.473-.867.8-1.259l1.091-1.338 1.16 1.338c.32.392.585.811.794 1.26.209.447.313.91.313 1.391 0 .636-.22 1.172-.662 1.61-.44.438-.976.657-1.605.657z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgColorSimple
