import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPasswordSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.5 20h-13c-.3 0-.5-.2-.5-.5v-8c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5v8c0 .3-.2.5-.5.5z"
        fill="currentColor"
      />
      <path
        d="M8.2 12.5V9.1C8.2 7 10 5 12 5c2 0 3.8 1.8 3.8 4.2v3.2"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </Icon>
  )
}
export default SvgPasswordSolid
