import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTwitterOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.165 4.5a9.083 9.083 0 01-2.616 1.276 3.733 3.733 0 00-6.55 2.5v.833a8.883 8.883 0 01-7.5-3.775s-3.334 7.5 4.166 10.833a9.7 9.7 0 01-5.833 1.667c7.5 4.167 16.667 0 16.667-9.583-.001-.233-.023-.464-.067-.692A6.434 6.434 0 0021.165 4.5v0z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgTwitterOutline
