import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVirtualTour: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#virtual-tour_svg__clip0_1948_1594)" fill="currentColor">
        <path d="M10.994 22h-.955c-2.58-.293-4.97-1.564-6.5-3.616C.196 14.083 1.056 7.73 5.452 4.21c2.103-1.662 4.683-2.444 7.359-2.15 2.58.293 4.97 1.563 6.499 3.616.19.195.095.489-.096.684-.191.196-.478.098-.669-.098-1.433-1.857-3.536-3.03-5.83-3.225-2.389-.196-4.682.489-6.69 2.053-3.918 3.127-4.778 8.894-1.815 12.706 1.433 1.857 3.536 3.03 5.83 3.226 2.389.195 4.682-.49 6.69-2.053 1.72-1.368 2.962-3.323 3.344-5.474.096-.293.287-.39.574-.39.286.097.382.293.382.586-.478 2.346-1.816 4.496-3.727 6.06C15.486 21.218 13.288 22 10.994 22z" />
        <path d="M7.84 9.88c0 .782-.381 1.173-.764 1.27.478.098.956.587.956 1.467 0 1.173-.765 1.857-2.007 1.857s-2.007-.782-2.007-2.053h1.147c0 .587.382.88.86.88s.765-.293.765-.782c0-.586-.383-.88-1.052-.88h-.286v-1.075h.382c.573 0 .86-.195.86-.684 0-.489-.191-.684-.669-.684-.382 0-.669.293-.669.88H4.018c0-1.271.765-1.955 1.911-1.955 1.243 0 1.912.782 1.912 1.759zM12.81 9.978h-1.242c0-.49-.287-.782-.765-.782-.573 0-.955.488-.955 1.759.19-.196.668-.489 1.146-.489 1.147 0 1.912.782 1.912 1.955 0 1.27-.86 2.053-2.007 2.053-.67 0-1.147-.196-1.53-.684-.477-.587-.668-1.467-.668-2.444 0-2.248.669-3.323 2.198-3.323 1.051.097 1.911.782 1.911 1.955zM9.943 12.42c0 .489.382.978.86.978s.86-.391.86-.978a.866.866 0 00-.86-.88.866.866 0 00-.86.88zM17.875 11.346c0 1.955-.764 3.226-2.198 3.226-1.433 0-2.198-1.271-2.198-3.226s.765-3.225 2.198-3.225c1.434 0 2.198 1.173 2.198 3.225zm-3.154 0c0 1.27.287 2.053.956 2.053.67 0 .956-.782.956-2.053 0-1.27-.287-2.052-.956-2.052s-.956.684-.956 2.052zM21.125 9.391c0 .782-.573 1.368-1.338 1.368-.764 0-1.338-.586-1.338-1.368 0-.782.574-1.368 1.338-1.368.67 0 1.338.586 1.338 1.368zm-2.007 0c0 .391.287.587.574.587.382 0 .669-.294.669-.587s-.287-.586-.67-.586c-.286-.098-.573.195-.573.586zM22.08 16.233c-.19 0-.382-.098-.382-.293l-1.051-2.248-2.198 1.075c-.192.098-.478 0-.67-.195-.095-.196 0-.49.192-.685l2.58-1.27c.191-.098.478 0 .67.195l1.242 2.64c.095.195 0 .488-.192.684 0 0-.095.097-.19.097z" />
      </g>
      <defs>
        <clipPath id="virtual-tour_svg__clip0_1948_1594">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgVirtualTour
