import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgChatSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="chat-solid_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 20.1c4.97 0 9-3.828 9-8.55C21 6.828 16.97 3 12 3s-9 3.828-9 8.55a8.21 8.21 0 001.498 4.725.517.517 0 01.065.474l-1.28 3.482c-.13.35.158.71.527.649 1.294-.214 3.417-.655 4.749-1.26a.54.54 0 01.397-.021c.951.324 1.976.501 3.044.501z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.1c4.97 0 9-3.828 9-8.55C21 6.828 16.97 3 12 3s-9 3.828-9 8.55a8.21 8.21 0 001.498 4.725.517.517 0 01.065.474l-1.28 3.482c-.13.35.158.71.527.649 1.294-.214 3.417-.655 4.749-1.26a.54.54 0 01.397-.021c.951.324 1.976.501 3.044.501z"
        fill="currentColor"
      />
      <path
        d="M8.559 19.62l.497 1.091-.497-1.092zm.397-.021l-.387 1.135.387-1.135zM3.81 20.88l.196 1.184-.196-1.184zm.688-4.605l-.984.687.984-.687zM19.8 11.55c0 4.002-3.433 7.35-7.8 7.35v2.4c5.575 0 10.2-4.308 10.2-9.75h-2.4zM12 4.2c4.367 0 7.8 3.348 7.8 7.35h2.4c0-5.442-4.625-9.75-10.2-9.75v2.4zm-7.8 7.35C4.2 7.548 7.633 4.2 12 4.2V1.8c-5.575 0-10.2 4.308-10.2 9.75h2.4zm1.281 4.037A7.01 7.01 0 014.2 11.55H1.8a9.41 9.41 0 001.714 5.412l1.967-1.375zM4.41 20.645l1.28-3.482-2.252-.828-1.28 3.482 2.252.828zm3.653-2.118c-1.174.534-3.156.955-4.448 1.17l.392 2.367c1.297-.215 3.56-.675 5.05-1.353l-.994-2.184zM12 18.9a8.21 8.21 0 01-2.656-.437l-.775 2.271A10.61 10.61 0 0012 21.3v-2.4zm-2.944 1.811a.66.66 0 01-.487.023l.775-2.27a1.739 1.739 0 00-1.282.063l.994 2.184zm-6.9-.894c-.432 1.177.535 2.465 1.85 2.247l-.392-2.368c.577-.096.97.473.795.949l-2.252-.828zm1.358-2.855a.683.683 0 01-.077-.627l2.252.828a1.718 1.718 0 00-.208-1.576l-1.967 1.375z"
        fill="currentColor"
        mask="url(#chat-solid_svg__a)"
      />
    </Icon>
  )
}
export default SvgChatSolid
