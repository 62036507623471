import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgKayak: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153" strokeLinecap="round" strokeLinejoin="round">
        <path d="M10 6.5l.5-3.5L8 4zm0 0l1.288 3.864L14 18.5m0 0v3l2.5-1zM2.215 11.172s5.167-.717 8.548-.77c3.747-.06 9.437.757 10.025.843.024.003.032.03.017.05m-18.59-.123s2.092 2.855 4.231 3.88c3.304 1.582 6.348 1.601 9.706.228 2.214-.906 4.365-3.613 4.653-3.985m-18.59-.123s1.682-.481 4.064-.249c1.485.145 2.516 1.539 5.221 1.577 2.5.035 3.306-1.265 4.891-1.385 2.683-.203 4.414.18 4.414.18M4 19h6M19 19h3" />
      </g>
    </Icon>
  )
}
export default SvgKayak
