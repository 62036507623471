import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBikeGender: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.85 20.425c1.86.15 3.456-1.342 3.588-3.43.133-2.087-1.196-3.877-3.056-4.026-1.86-.149-3.455 1.342-3.588 3.43-.067.82.133 1.64.531 2.311"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.116 18.934c1.065 0 1.927-.968 1.927-2.162s-.862-2.163-1.927-2.163c-1.064 0-1.927.969-1.927 2.163s.863 2.162 1.927 2.162zM4.79 14.013L2.2 10.956M16.884 20.5c1.835 0 3.322-1.669 3.322-3.728s-1.487-3.728-3.322-3.728-3.322 1.67-3.322 3.728c0 2.059 1.487 3.728 3.322 3.728zM19.21 14.088l2.591-2.908M15.156 6.333c.697 0 1.263-.634 1.263-1.416 0-.783-.566-1.417-1.263-1.417s-1.262.634-1.262 1.417c0 .782.565 1.416 1.262 1.416z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.525 10.882L2 13.566m8.173-5.368c1.594.596 3.123 4.1 3.123 4.1-.665 2.983-2.924 7.233-2.924 7.233l.93-6.71L8.38 9.837c-.731-.82-.798-2.162-.067-2.983l.067-.074c1.13-.97 2.525-1.566 3.986-1.79.466-.074.93.299.997.82.133.746.399 1.79.532 2.461l3.588 1.491h-4.585c-.598-1.044-.864-2.237-.93-3.43M10.77 8.421s.133-.522 1.329-.298m.332-4.548h2.724M7.316 9.54h.73m-.93 10.886h-4.12M7.05 18.86H2M16.884 18.934c1.064 0 1.927-.968 1.927-2.162s-.863-2.163-1.927-2.163-1.927.969-1.927 2.163.863 2.162 1.927 2.162z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.076 11.404L22 10.956l-.399 3.28M13.23 12.82l1.396-1.566c.398-.447.465-1.043-.067-1.342m-2.392 0h1.395l-.797 1.193"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgBikeGender
