import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPasswordError: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={6} cy={6} r={5.5} stroke="#f1454f" />
    </Icon>
  )
}
export default SvgPasswordError
