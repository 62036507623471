import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPasswordConfirmed: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={6} cy={6} fill="#d2d3d7" r={6} />
      <circle cx={6} cy={6} fill="#37a864" r={6} />
      <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
        <path d="M3 6l2 2M9 4L5 8" />
      </g>
    </Icon>
  )
}
export default SvgPasswordConfirmed
