import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.854 10.65h1.398c.088 0 .175-.09.175-.18V3.18c0-.09-.087-.18-.175-.18H6.408m-1.923.09h-1.31c-.088 0-.175.09-.175.18v7.29c0 .09.087.18.175.18h3.757m13.98-6.12V3.18c0-.09-.087-.18-.174-.18H13.66c-.087 0-.175.09-.175.18v3.96m.088 2.07v1.35c0 .09.087.18.175.18h7.077c.088 0 .175-.09.175-.18V6.6m-3.408 6.75h-3.844c-.088 0-.175.09-.175.18v7.29c0 .09.087.18.175.18h1.398m1.835-.09h3.757c.087 0 .175-.09.175-.18v-7.29c0-.09-.088-.18-.175-.18h-1.31m-9 4.23v-3.96c0-.09-.088-.18-.176-.18H3.175c-.088 0-.175.09-.175.18v1.44m0 1.89v3.87c0 .09.087.18.175.18h7.077c.088 0 .175-.09.175-.18v-1.35"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgType
