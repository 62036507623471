import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotificationKijiji: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={24} cy={24} fill="#373373" r={24} />
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M18.115 22v5.982c0 .117.09.212.202.212h1.412a.207.207 0 00.201-.212v-6.22a.216.216 0 00-.07-.155.194.194 0 00-.158-.048l-1.412.236a.209.209 0 00-.175.21M26.485 22v5.982c.001.117.09.211.202.212h1.418a.207.207 0 00.202-.212v-6.22c-.002-.06-.028-.119-.073-.158s-.104-.056-.162-.046l-1.412.237a.209.209 0 00-.175.21M34.86 22v5.982c0 .117.09.211.2.212h1.413a.207.207 0 00.202-.212v-6.22c-.002-.06-.028-.118-.073-.157s-.102-.056-.16-.047l-1.412.237a.209.209 0 00-.17.204z"
          fill="#fff"
        />
        <path
          d="M19.025 17.485c-.69 0-1.25.59-1.25 1.316 0 .727.56 1.316 1.25 1.316s1.25-.589 1.25-1.316c0-.349-.132-.683-.366-.93a1.22 1.22 0 00-.884-.385"
          fill="#f8aa17"
        />
        <path
          d="M23.214 16c-.69 0-1.25.59-1.25 1.316s.56 1.316 1.25 1.316 1.25-.59 1.25-1.316c0-.349-.132-.684-.366-.93a1.22 1.22 0 00-.884-.386z"
          fill="#f1454f"
        />
        <path
          d="M27.402 17.485c-.69 0-1.25.59-1.25 1.316 0 .727.56 1.316 1.25 1.316.691 0 1.25-.589 1.25-1.316 0-.726-.559-1.316-1.25-1.316"
          fill="#1477d4"
        />
        <path
          d="M31.592 16c-.69 0-1.25.59-1.25 1.316s.56 1.316 1.25 1.316 1.25-.59 1.25-1.316c0-.349-.132-.684-.366-.93a1.22 1.22 0 00-.884-.386z"
          fill="#37a864"
        />
        <path
          d="M35.782 20.117c.69 0 1.25-.589 1.25-1.316 0-.726-.56-1.315-1.25-1.315s-1.25.589-1.25 1.315c0 .727.56 1.316 1.25 1.316z"
          fill="#9b44ad"
        />
        <path
          d="M13.795 23.677l2.668-3.282a.222.222 0 00.034-.226.203.203 0 00-.181-.128H14.53a.199.199 0 00-.157.079l-2.314 2.945c-.07.09-.195.078-.195-.07v-6.18a.218.218 0 00-.059-.15.197.197 0 00-.142-.063l-1.409.223c-.11 0-.2.093-.201.21v10.947c0 .117.09.212.201.212h1.409a.196.196 0 00.142-.062.218.218 0 00.06-.15v-3.36c0-.174.105-.222.21-.066l2.492 3.548c.038.057.1.09.166.09h1.729a.2.2 0 00.182-.118.221.221 0 00-.021-.223l-2.837-3.98c-.066-.095-.066-.095.009-.196zM24.117 20.942v-.694a.217.217 0 00-.07-.16.195.195 0 00-.16-.05l-1.412.236a.209.209 0 00-.174.21v7.91c0 .197-.01.396-.029.593a1.462 1.462 0 01-.122.477.753.753 0 01-.272.314.85.85 0 01-.467.115c-.075 0-.15-.005-.225-.014h-.026l-.108-.017-.105-.023-.05-.013c-.044-.017-.092-.011-.131.015s-.064.071-.067.12c-.01.12-.16 1.183-.17 1.279s.033.168.168.21c.086.026 1.142.302 2.151-.156a1.86 1.86 0 00.78-.704c.197-.312.33-.665.388-1.035a7.36 7.36 0 00.1-1.242v-7.368zM32.323 20.2l-1.45-.163a.177.177 0 00-.14.049.197.197 0 00-.061.142v8.165c0 .198-.01.396-.029.593a1.466 1.466 0 01-.122.477.753.753 0 01-.272.315.849.849 0 01-.467.115c-.075 0-.15-.006-.225-.015h-.026l-.108-.016-.105-.024-.05-.013c-.045-.017-.096-.01-.135.02s-.063.076-.063.127c-.01.118-.16 1.182-.17 1.278-.009.096.033.168.168.21.086.027 1.142.302 2.151-.156A1.86 1.86 0 0032 30.6c.197-.312.33-.665.388-1.034a7.36 7.36 0 00.101-1.243v-7.928a.188.188 0 00-.165-.195zM37.504 21.65h-.145v.388h-.087v-.388h-.145v-.092h.377zM38.033 22.033h-.088v-.297l-.013.04-.1.26h-.074l-.1-.26-.013-.04v.297h-.084v-.475h.101l.123.303.01.027.01-.027.125-.303h.1z"
          fill="#fff"
        />
      </g>
    </Icon>
  )
}
export default SvgNotificationKijiji
