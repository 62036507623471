import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPhoneBrand: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.084 2.5h7.832c.618 0 1.099.5 1.099 1.143v16.714c0 .643-.481 1.143-1.1 1.143H8.085c-.619 0-1.1-.5-1.1-1.143V3.643c0-.643.481-1.143 1.1-1.143z"
        stroke="currentColor"
        strokeWidth={0.687}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.557 4.286h2.817M9.046 5.929h7.832-7.832zM7.122 17.285h7.832-7.832zm5.153-9.572l-2.542 5.357 2.542-5.357zm1.236 1.857l-2.473 5.357 2.473-5.357z"
        stroke="currentColor"
        strokeWidth={0.687}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.93 20.428c.532 0 .962-.447.962-1 0-.552-.43-1-.961-1-.532 0-.962.448-.962 1 0 .553.43 1 .962 1z"
        fill="currentColor"
      />
      <path
        d="M3 11.286c.412.285.893.428 1.443.5m16.557-.5c-.412.285-.893.428-1.443.5m.481 3.785a2.362 2.362 0 00-.962-.428M4.168 8c.275.143.48.286.756.357L4.168 8zm-.206 7.571c.275-.214.618-.357.962-.428l-.962.428zM19.832 8c-.275.143-.48.286-.756.357L19.832 8z"
        stroke="currentColor"
        strokeWidth={0.687}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgPhoneBrand
