import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAllVehicles: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.432 17.063H2.555v-5.625c0-1.205.925-1.929 2.08-1.929 0 0 2.542.803 7.243.803 4.7 0 7.473-.803 7.473-.803 1.156 0 2.081.724 2.081 1.928v6.188-.563z"
        fill="#F3F3F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.877 19.875h-2.076c-.077 0-.14-.022-.145-.006v-2.244h2.221v2.25zm-17.767 0h2.221v-2.25h-2.22v2.25zm0-8.437c0-.972.764-1.34 1.45-1.365.502.145 3.018.802 7.318.802 4.334 0 7.038-.667 7.544-.802.687.022 1.455.39 1.455 1.365V16.5H3.11v-5.062zM19.8 8.985l-.602-2.733a.555.555 0 10-1.083.246l.57 2.595c-.995.22-3.39.657-6.807.657-3.466 0-5.74-.447-6.638-.663l1.18-4.242c.186-.65.928-.72 1.239-.72h6.488a.56.56 0 00.555-.563.56.56 0 00-.555-.562H7.659c-1.15 0-2.035.586-2.307 1.535L4.113 8.992C2.863 9.202 2 10.165 2 11.437v8.438C2 20.548 2.446 21 3.11 21h2.221c.356 0 .707-.168.916-.438a.951.951 0 00.195-.741v-2.196h11.104v2.25c0 .641.54 1.125 1.255 1.125h2.08c.354 0 .704-.166.913-.436a.948.948 0 00.194-.743v-8.384c0-1.297-.899-2.268-2.189-2.452z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.99 15.375a.56.56 0 00.556-.563v-1.125c0-.52.042-.562.555-.562h1.11a.56.56 0 00.556-.563.56.56 0 00-.555-.562h-1.11c-1.121 0-1.666.552-1.666 1.687v1.125c0 .31.248.563.555.563zM6.442 13.687v1.125c0 .31.248.563.555.563a.56.56 0 00.555-.563v-1.125c0-1.135-.545-1.687-1.666-1.687h-1.11a.56.56 0 00-.555.562c0 .31.248.563.555.563h1.11c.513 0 .556.043.556.562z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAllVehicles
