import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosColour: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.467 7.674V2H7.533v5.674m1.867 0h6.933c.4 0 .667.283.667.709v2.199c0 .709-.4 1.418-1 1.702l-1.867.993a1.147 1.147 0 00-.533.993v1.63c0 .923.467 2.27.467 3.618 0 1.347-.6 2.482-2 2.482s-2-1.064-2-2.482c0-1.419.466-2.695.466-3.617v-1.56c0-.426-.2-.78-.533-.993l-2-.993c-.6-.355-1-.993-1-1.703V8.454c0-.426.267-.71.667-.71l1.733-.07zm7.4 2.624c-.133.07-7.467 0-7.467 0H16.8z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.333 3.844c-.6-.213-1.533-.213-1.8.496-.133.355 0 .78-.266 1.064a.717.717 0 01-1.067.071c-.534-.425-.267-1.418-1-1.56-1.467-.355-1.733.638-2.667.142-.6-.355-1.133-.993-1.866-1.064h-.134M9.4 6.255V7.39m1.933-.78v1.064M12 20.581c.331 0 .6-.285.6-.638 0-.352-.269-.638-.6-.638-.332 0-.6.286-.6.638 0 .353.268.638.6.638z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosColour
