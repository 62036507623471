import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTime: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.095 16.51c-.333-.752-.667-1.503-.833-2.255-.334-1.336-.334-2.756-.084-4.176A9.905 9.905 0 014.43 5.486C8.015 1.31 14.351.809 18.52 4.4c4.169 3.591 4.67 9.938 1.084 14.114-3.585 4.176-9.922 4.677-14.09 1.086M12.017 8.242v3.841M15.352 15.09l-3.335-3.006"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgTime
