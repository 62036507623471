import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconCalculator: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x={0.433}
        y={0.433}
        width={12.974}
        height={17.133}
        rx={1.567}
        stroke="currentColor"
        strokeWidth={0.867}
      />
      <rect
        x={2.683}
        y={2.683}
        width={8.133}
        height={2.508}
        rx={0.567}
        stroke="currentColor"
        strokeWidth={0.867}
      />
      <rect
        x={2.683}
        y={7.183}
        width={1.383}
        height={1.383}
        rx={0.692}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.867}
      />
      <rect
        x={2.683}
        y={10.558}
        width={1.383}
        height={1.383}
        rx={0.692}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.867}
      />
      <rect
        x={2.683}
        y={13.834}
        width={1.383}
        height={1.383}
        rx={0.692}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.867}
      />
      <rect
        x={6.058}
        y={7.183}
        width={1.383}
        height={1.383}
        rx={0.692}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.867}
      />
      <rect
        x={6.058}
        y={10.558}
        width={1.383}
        height={1.383}
        rx={0.692}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.867}
      />
      <rect
        x={6.034}
        y={13.834}
        width={4.758}
        height={1.383}
        rx={0.692}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.867}
      />
      <rect
        x={9.433}
        y={10.558}
        width={1.383}
        height={1.383}
        rx={0.692}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.867}
      />
      <rect
        x={9.433}
        y={7.183}
        width={1.383}
        height={1.383}
        rx={0.692}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.867}
      />
    </Icon>
  )
}
export default SvgIconCalculator
