import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosAutomatic: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20 7.6H5.496v4.073H20V7.6zM5.495 8.4H4v2.4h1.495V8.4zM14.841 11.818v8.728c0 .8-.673 1.454-1.495 1.454H12.15c-.823 0-1.496-.654-1.496-1.454v-7.273M10.654 7.382V3.455c0-.8.673-1.455 1.496-1.455h1.196c.822 0 1.495.655 1.495 1.455v2.109"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.692 5.345a.738.738 0 00.748-.727.738.738 0 00-.748-.727.738.738 0 00-.748.727c0 .402.335.727.748.727zM6.692 15.382a.738.738 0 00.748-.727.738.738 0 00-.748-.728.738.738 0 00-.748.728c0 .401.335.727.748.727zM6.692 20.4a.738.738 0 00.748-.727.738.738 0 00-.748-.728.738.738 0 00-.748.728c0 .401.335.727.748.727z"
        fill="currentColor"
      />
      <path
        d="M12.748 5.564V3.673M12.748 20.255V14.8"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosAutomatic
