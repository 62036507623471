import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMessengerCircle: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#messenger-circle_svg__clip0_4316_8264)">
        <path
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
          fill="#0084FF"
        />
        <path
          d="M12 4.418c-4.35 0-7.875 3.297-7.875 7.364 0 2.314 1.141 4.378 2.926 5.728v2.818l2.687-1.49a8.39 8.39 0 002.262.308c4.35 0 7.875-3.297 7.875-7.364S16.349 4.418 12 4.418zm.83 9.875l-2.037-2.115-3.923 2.168 4.3-4.563 2.037 2.115L17.13 9.73l-4.3 4.564z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="messenger-circle_svg__clip0_4316_8264">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgMessengerCircle
