import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMetrics: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x={2.5} y={13.5} width={5} height={8} rx={1.5} stroke="currentColor" />
      <rect x={9.5} y={2.5} width={5} height={19} rx={1.5} stroke="currentColor" />
      <rect x={16.5} y={7.5} width={5} height={14} rx={1.5} stroke="currentColor" />
    </Icon>
  )
}
export default SvgMetrics
