import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTopAd: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M35.833 13.333H4.166a.834.834 0 01-.833-.833V4.167c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834V12.5c0 .46-.373.833-.833.833z"
          fill="#37a864"
        />
        <path
          d="M14.583 11.667H5.417A.417.417 0 015 11.25V5.417c0-.23.187-.417.417-.417h9.166c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417z"
          fill="#d9efdf"
        />
        <path
          d="M35.833 26.667H4.166a.834.834 0 01-.833-.834V17.5c0-.46.373-.833.833-.833h31.667c.46 0 .833.373.833.833v8.333c0 .46-.373.834-.833.834z"
          fill="#ececee"
        />
        <path
          d="M14.583 25H5.417A.417.417 0 015 24.583V18.75c0-.23.187-.417.417-.417h9.166c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417zM36.25 15.833H3.75a.417.417 0 01-.417-.416v-.834c0-.23.187-.416.417-.416h32.5c.23 0 .416.186.416.416v.834c0 .23-.186.416-.416.416z"
          fill="#c5c6cb"
        />
        <path
          d="M35.833 36.667H4.166a.834.834 0 01-.833-.834v-6.666c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834v6.666c0 .46-.373.834-.833.834z"
          fill="#ececee"
        />
        <path
          d="M14.583 36.667H5.417A.417.417 0 015 36.25v-5.833c0-.23.187-.417.417-.417h9.166c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417z"
          fill="#c5c6cb"
        />
      </g>
    </Icon>
  )
}
export default SvgTopAd
