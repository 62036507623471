import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgImageAlt: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V6a1 1 0 00-1-1H7zm.5 1a.5.5 0 00-.5.5v21a.5.5 0 00.5.5h21a.5.5 0 00.5-.5v-21a.5.5 0 00-.5-.5h-21zm5.5 8a2 2 0 110-4 2 2 0 010 4zm0-1a1 1 0 100-2 1 1 0 000 2zm6.5.15L25.832 23H8.796l5.704-6.519 1.648 1.884L19.5 13.15zM11 22h13l-4.5-7-3.23 5.023L14.5 18 11 22zm-2.962 7.807l.025-.18h1.042a.5.5 0 00.422.4l20.92 2.94a.5.5 0 00.564-.425l2.887-20.547a.5.5 0 00-.425-.564l-2.922-.411v-.998l3.56.5a1 1 0 01.851 1.13L31.94 33.165a1 1 0 01-1.13.851l-21.92-3.08a1 1 0 01-.851-1.13z"
        fill="#757575"
      />
      <mask
        id="image-alt_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={6}
        y={5}
        width={29}
        height={30}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 5a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V6a1 1 0 00-1-1H7zm.5 1a.5.5 0 00-.5.5v21a.5.5 0 00.5.5h21a.5.5 0 00.5-.5v-21a.5.5 0 00-.5-.5h-21zm5.5 8a2 2 0 110-4 2 2 0 010 4zm0-1a1 1 0 100-2 1 1 0 000 2zm6.5.15L25.832 23H8.796l5.704-6.519 1.648 1.884L19.5 13.15zM11 22h13l-4.5-7-3.23 5.023L14.5 18 11 22zm-2.962 7.807l.025-.18h1.042a.5.5 0 00.422.4l20.92 2.94a.5.5 0 00.564-.425l2.887-20.547a.5.5 0 00-.425-.564l-2.922-.411v-.998l3.56.5a1 1 0 01.851 1.13L31.94 33.165a1 1 0 01-1.13.851l-21.92-3.08a1 1 0 01-.851-1.13z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#image-alt_svg__a)" fill="currentColor">
        <path d="M0 0h40v40H0z" />
        <path d="M0 0h40v40H0z" />
      </g>
    </Icon>
  )
}
export default SvgImageAlt
