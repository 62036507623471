import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgDownPointing: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <linearGradient
        id="down-pointing_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={12}
        x2={12}
        y1={6}
        y2={18.25}
      >
        <stop offset={0.659} stopColor="#eb001b" />
        <stop offset={1} stopColor="#bb3c4d" />
      </linearGradient>
      <linearGradient
        id="down-pointing_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={12.015}
        x2={12.069}
        y1={6.785}
        y2={14.512}
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={0.47} stopColor="#ed1c24" stopOpacity={0.5} />
        <stop offset={1} stopColor="#ed1c24" />
      </linearGradient>
      <clipPath id="down-pointing_svg__a">
        <path d="M5 6h14v12.25H5z" />
      </clipPath>
      <g clipPath="url(#down-pointing_svg__a)">
        <path
          d="M5.545 6h12.91c.42 0 .685.45.468.817l-6.41 11.158a.533.533 0 01-.933 0L5.078 6.817A.54.54 0 015.545 6z"
          fill="url(#down-pointing_svg__b)"
        />
        <path
          d="M6.991 6.885H17.04c.327 0 .529.367.373.65l-4.993 8.93a.42.42 0 01-.731 0l-5.071-8.93c-.171-.283.046-.65.357-.65z"
          fill="url(#down-pointing_svg__c)"
        />
      </g>
    </Icon>
  )
}
export default SvgDownPointing
