import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSailboat: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18 21.25L19.5 18H12m0 0v-1.75M12 18l-1.764-.059M12 2.75v1m0 0l6 12.5h-6m0-12.5v12.5m-1.764 1.691L4.5 17.75l1.5 3.5H3h18m-10.764-3.309V15.25m0-10v1m0 0L6 15.25h4.236m0-9v9"
        stroke="#3e4153"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgSailboat
