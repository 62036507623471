import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUnitTypeCondo: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.68 1H6v22h12.68V1z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.28 22.313V20.25h3.962v2.063M8.5 3.75h2.572v1.375H8.5V3.75zm4.95 0H16v1.375h-2.55V3.75zM8.5 7.875h2.572V9.25H8.5V7.875zm4.95 0H16V9.25h-2.55V7.875zM8.5 12h2.572v1.375H8.5V12zm0 4.125h2.572V17.5H8.5v-1.375zM13.45 12H16v1.375h-2.55V12zm0 4.125H16V17.5h-2.55v-1.375z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgUnitTypeCondo
