import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVideoSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.45 18H3.55c-.9 0-1.6-.8-1.6-1.7V7.7c0-1 .7-1.7 1.6-1.7h10.9c.9 0 1.6.8 1.6 1.7v8.5c0 1-.8 1.8-1.6 1.8zM17.05 8.95v6l4.6 2c.2.1.4 0 .4-.2v-9.5c0-.2-.2-.3-.4-.2l-4.6 1.9z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgVideoSolid
