import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLightning: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <linearGradient
        id="lightning_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={11.779}
        x2={11.779}
        y1={6.514}
        y2={17.826}
      >
        <stop offset={0.299} stopColor="#ffd255" />
        <stop offset={0.475} stopColor="#fff" />
        <stop offset={0.638} stopColor="#ffd255" />
      </linearGradient>
      <clipPath id="lightning_svg__a">
        <path d="M7 4h10v16H7z" />
      </clipPath>
      <g clipPath="url(#lightning_svg__a)">
        <path
          d="M12.297 10.4l-.279-.003m.279.003l-.279-.003m.279.003l-.056.11-.223-.113m.279.003l-.279-.003m4.526.285L8.61 18.35l3.45-5.418.246-.384H7.44l6.822-7.156-2.467 4.893-.181.36.402.002z"
          fill="url(#lightning_svg__b)"
          stroke="#f8aa17"
          strokeWidth={0.5}
        />
      </g>
    </Icon>
  )
}
export default SvgLightning
