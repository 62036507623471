import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPayments: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.875 9.17V6.25H4.125v2.92h15.75zm0 8.66v-5.836H4.125v5.836h15.75zM19.222 4.5c.24 0 .477.051.696.152.219.1.415.247.576.432.328.349.51.819.506 1.307v11.218c.008.499-.174.981-.506 1.342a1.711 1.711 0 01-.58.413c-.22.095-.455.14-.692.136H4.778a1.653 1.653 0 01-.691-.136 1.71 1.71 0 01-.58-.413A1.93 1.93 0 013 17.609V6.39a1.882 1.882 0 01.507-1.307c.16-.185.356-.332.575-.432.22-.1.456-.152.696-.152h14.444z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgPayments
