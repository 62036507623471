import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosCoupe: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.96 12.921h.899c.403 0 .805-.13 1.177-.357l.124-.066M14.596 12.27h.557M20.914 11.75h1.083M9.146 11.262c1.114 0 2.88-.163 4.397-.195 3.778-.13 7.37-.651 7.37-.651"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.613 14.711l2.075-.455c.526-.163.867-.684.805-1.27-.093-1.073-.681-2.05-1.58-2.603 0 0-2.973-1.66-4.087-1.757-1.766-.13-3.902-.227-5.017.065-.65.163-2.973 1.367-4.181 1.985-.465.26-.991.39-1.517.456a11.736 11.736 0 00-3.469.813c-.402.163-.681.52-.805.944l-.31 1.009c-.093.293.062.585.31.65.03 0 .062.033.093.033l2.477.26M8.743 14.841h7.959M15.835 10.676l-.093-1.106"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.956 15.85c.838 0 1.517-.714 1.517-1.594 0-.88-.68-1.595-1.517-1.595-.838 0-1.518.714-1.518 1.595 0 .88.68 1.594 1.518 1.594zM18.095 15.85c.838 0 1.518-.714 1.518-1.594 0-.88-.68-1.595-1.518-1.595s-1.517.714-1.517 1.595c0 .88.68 1.594 1.517 1.594z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.956 15.85c.838 0 1.517-.714 1.517-1.594 0-.88-.68-1.595-1.517-1.595-.838 0-1.518.714-1.518 1.595 0 .88.68 1.594 1.518 1.594zM18.095 15.85c.838 0 1.518-.714 1.518-1.594 0-.88-.68-1.595-1.518-1.595s-1.517.714-1.517 1.595c0 .88.68 1.594 1.517 1.594z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosCoupe
