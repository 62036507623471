import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNewNotification: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M15.5 17H5.24l.002-.005c.866-2.149 1.343-3.333 1.343-5.995v-.582C6.585 7.38 8.964 5 12 5s5.414 2.38 5.414 5.418V11c0 2.669.468 3.828 1.319 5.933l.027.067zM12 20c-1.224 0-2.267-.937-2.76-2h5.578c-.508 1.295-1.491 2-2.818 2zm7.964-2.685l-.304-.757c-.829-2.053-1.246-3.083-1.246-5.558v-.582c0-3.262-2.318-5.876-5.43-6.338.002-.028.016-.052.016-.08 0-.551-.449-1-1-1s-1 .449-1 1c0 .028.014.052.016.08-3.113.462-5.431 3.076-5.431 6.338V11c0 2.468-.424 3.522-1.271 5.621l-.278.694A.499.499 0 004.5 18h3.674c.531 1.517 1.96 3 3.826 3 1.846 0 3.26-1.119 3.864-3H19.5a.5.5 0 00.464-.685z"
        fill="#3e4153"
        fillRule="evenodd"
      />
      <circle cx={19} cy={6} fill="#1477d4" r={4.25} stroke="#fff" strokeWidth={1.5} />
    </Icon>
  )
}
export default SvgNewNotification
