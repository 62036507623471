import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosBackground: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
        fill="#F3F3F9"
      />
      <path
        d="M32.78 19.4l-.677-3.036a.625.625 0 00-1.22.272l.643 2.884c-2.521.512-5.09.756-7.663.73a33.147 33.147 0 01-7.472-.737l1.327-4.713c.21-.722 1.046-.8 1.395-.8h7.304a.625.625 0 100-1.25h-7.304a2.52 2.52 0 00-2.597 1.705l-1.394 4.952a2.712 2.712 0 00-2.379 2.718V31.5a1.183 1.183 0 001.25 1.25h2.5a1.334 1.334 0 001.032-.486 1.047 1.047 0 00.218-.824V29h12.5v2.5a1.31 1.31 0 001.413 1.25h2.342a1.326 1.326 0 001.027-.484 1.048 1.048 0 00.218-.826v-9.315A2.717 2.717 0 0032.78 19.4zm-18.787 2.725a1.517 1.517 0 011.632-1.517c2.695.658 5.465.957 8.238.892a34.947 34.947 0 008.492-.892 1.518 1.518 0 011.638 1.517v5.625h-20v-5.625zm2.5 9.375h-2.5V29h2.5v2.5zm17.5 0h-2.337c-.087 0-.159-.025-.163-.006V29h2.5v2.5z"
        fill="currentColor"
      />
      <path
        d="M29.618 26.5a.624.624 0 00.625-.625v-1.25c0-.578.047-.625.625-.625h1.25a.625.625 0 100-1.25h-1.25a1.65 1.65 0 00-1.875 1.875v1.25a.626.626 0 00.625.625zM17.743 24.625v1.25a.625.625 0 101.25 0v-1.25a1.648 1.648 0 00-1.875-1.875h-1.25a.625.625 0 000 1.25h1.25c.578 0 .625.047.625.625z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAutosBackground
