import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconCategoryCommunity: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.004 48.004" {...props}>
      <circle cx={24} cy={24} r={24} fill="currentColor" />
      <path
        fill="#373373"
        d="M27.823 28.036a.625.625 0 10-.257 1.223c1.982.418 2.686.809 2.686 2.168v1.329h-17.5v-1.329c0-1.36.704-1.75 2.684-2.167l4.317-.892a.625.625 0 00.499-.612v-2.313a.625.625 0 00-.355-.564 3.848 3.848 0 01-2.224-3.423v-2.421a3.714 3.714 0 013.635-3.78h.388a3.714 3.714 0 013.635 3.78v2.421a3.848 3.848 0 01-2.224 3.423.625.625 0 10.54 1.128 5.105 5.105 0 002.934-4.55v-2.422a4.964 4.964 0 00-4.885-5.03h-.388a4.964 4.964 0 00-4.885 5.03v2.421a5.088 5.088 0 002.579 4.364v1.427l-3.821.789c-1.553.328-3.679.776-3.679 3.391v1.954a.625.625 0 00.625.625h18.75a.625.625 0 00.625-.625v-1.954c0-2.615-2.126-3.063-3.679-3.391z"
      />
      <path
        fill="#373373"
        d="M33.033 24.6l-3.531-.729v-1.273a4.79 4.79 0 002.407-4.097v-2.26a4.675 4.675 0 00-4.6-4.735h-.363a4.49 4.49 0 00-2.51.766.625.625 0 00.695 1.038 3.252 3.252 0 011.815-.554h.362a3.424 3.424 0 013.351 3.485v2.26a3.551 3.551 0 01-2.052 3.157.625.625 0 00-.355.564v2.159a.625.625 0 00.499.612l4.027.832c1.856.391 2.474.733 2.474 1.982v1.199h-1.875a.625.625 0 000 1.25h2.5a.625.625 0 00.625-.625v-1.824c0-2.474-2.003-2.897-3.469-3.206z"
      />
    </Icon>
  )
}
export default SvgIconCategoryCommunity
