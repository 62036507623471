import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosDamaged: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.741 12.182c1.06 0 1.907.873 1.907 1.965v5.242H3.352v-5.242c0-1.092.847-1.965 1.906-1.965 0 0 1.2.364 3.46.582M5.33 12.182s1.2.364 3.458.582M18.742 12.182s-1.2.364-3.46.582"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33 12.182l1.129-3.421c.211-.728.917-1.238 1.623-1.238h.847M18.742 12.182l-1.13-3.421c-.211-.728-.917-1.238-1.623-1.238h-.847M18.46 17.278H5.54M18.46 14.73h-.918c-.777 0-1.342.655-1.342 1.383v.582M3.352 14.73h3.106c.777 0 1.341.655 1.341 1.383v.582M2.505 9.853h1.412c.777 0 1.412.655 1.412 1.456v.946H2.505a.49.49 0 01-.494-.51v-1.31c-.07-.364.212-.582.494-.582z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.495 9.853h-1.412c-.776 0-1.412.655-1.412 1.456v.946h2.824a.49.49 0 00.494-.51v-1.31c.07-.364-.212-.582-.494-.582zM5.823 21.5H3.917a.565.565 0 01-.565-.582v-1.53h2.965v1.53c0 .29-.212.582-.494.582zM20.083 21.5h-1.906a.565.565 0 01-.565-.582v-1.53h2.965v1.53c.07.29-.14.582-.494.582zM12.459 9.998h-.918c-.424 0-.706-.291-.706-.728V3.228c0-.437.282-.728.706-.728h.918c.423 0 .706.291.706.728V9.27a.718.718 0 01-.706.728zM12.035 14.22c.624 0 1.13-.521 1.13-1.164 0-.644-.506-1.165-1.13-1.165-.623 0-1.13.521-1.13 1.165 0 .643.507 1.164 1.13 1.164z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosDamaged
