import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFrameSize: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.5 5.018h18.933M4.723 6.596L2.5 5.018 4.723 3.5m14.554 0L21.5 5.018l-2.223 1.518M11.664 14.186l1.213.06-.607.669h-.606c-.27 0-.404-.182-.404-.365 0-.243.202-.364.404-.364zM18.534 8.054h-2.156a.627.627 0 00-.607.607v2.186"
        stroke="currentColor"
        strokeWidth={0.643}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.256 10.786V9.329h1.078l-1.078 1.457zm0 0l.876 2.61c.134.486 1.145 1.7 2.088 3.4l.068.062c.134.303-.068.728-.404.85-.337.12-.809-.061-.944-.365l-.135-.182c-.336-.668-1.819-2.61-2.088-3.4l-.202-.607-3.908 3.4M7.419 8.722l.674 2.064m.539-2.004l.673 1.943m7.749.122H8.093m-.135-.061l.337.971-5.053 4.98c-.203.303-.135.667.202.91.067.06.202.121.337.121h5.39"
        stroke="currentColor"
        strokeWidth={0.643}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.46 20.5c1.28-.182 2.357-.971 2.83-2.003m-4.65-2.793l-.876-2.55-3.57 3.339h3.772l.674-.79zm1.954-.183l3.975-3.339H9.977l1.01 3.157.607.182zm3.032 1.579v-.243.243zm-.337 1.336l.068-.06-.068.06zM10.854 17.525c.26 0 .472-.19.472-.425s-.211-.425-.472-.425c-.26 0-.471.19-.471.425s.211.425.471.425zM7.418 8.721H5.666c0-.364.202-1.517 1.01-1.517.81 0 1.752.364 3.033.485 1.28.122 1.751.425 1.482 1.032H9.978m.876 8.38l-1.55 2.55 1.55-2.55zM8.226 19.59l1.078.06 1.011.06-2.089-.12zM19.142 9.632c.521 0 .944-.38.944-.85s-.423-.85-.944-.85c-.52 0-.943.38-.943.85s.422.85.943.85z"
        stroke="currentColor"
        strokeWidth={0.643}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.853 18.74c1.005 0 1.82-.734 1.82-1.64 0-.905-.815-1.64-1.82-1.64-1.004 0-1.819.735-1.819 1.64 0 .906.815 1.64 1.82 1.64z"
        stroke="currentColor"
        strokeWidth={0.643}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgFrameSize
