import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBowRiderBoat: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3 16.7L4.5 18H18l4-5.5M3 16.7l-.774-.503a.496.496 0 01.142-.895L3.5 15l2-.5 5-1M3 16.7l3-.65m16-3.55h-4m4 0s-3 2-4 2.25c-.758.19-3 .25-3 .25m3-2.5L15.5 10l-5 3.5m7.5-1l-7.5 1M5 14.5s2.5-2.085 2.5-3S6 7 6 7m0 0l7.5-2.5M6 7L2.5 8" />
      </g>
    </Icon>
  )
}
export default SvgBowRiderBoat
