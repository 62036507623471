import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVisibilityLow: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M5 4.5a2 2 0 00-2 2v11a2 2 0 002 2h14a2 2 0 002-2v-11a2 2 0 00-2-2zm2.5 8.571c-.621 0-1.125.48-1.125 1.072v2.143c0 .591.504 1.071 1.125 1.071s1.125-.48 1.125-1.071v-2.143c0-.592-.504-1.072-1.125-1.072zm3.094-2.142c0-.592.503-1.072 1.125-1.072.621 0 1.125.48 1.125 1.072v5.357c0 .591-.504 1.071-1.125 1.071-.622 0-1.125-.48-1.125-1.071zm5.578-4.286c-.621 0-1.125.48-1.125 1.071v8.572c0 .591.504 1.071 1.125 1.071s1.125-.48 1.125-1.071V7.714c0-.591-.504-1.071-1.125-1.071z"
        fill="#f1454f"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgVisibilityLow
