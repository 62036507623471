import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLocationSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.6 4.2A8.07 8.07 0 0012 2a8.07 8.07 0 00-5.6 2.2A7.91 7.91 0 004 10c0 2.19 1.3 4.87 3.87 8a38.328 38.328 0 003.8 3.93.51.51 0 00.66 0 38.326 38.326 0 003.8-3.93C18.7 14.87 20 12.19 20 10a7.91 7.91 0 00-2.4-5.8zM12 13a3.5 3.5 0 110-7 3.5 3.5 0 010 7z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgLocationSolid
