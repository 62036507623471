import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgInstagramCircle: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#instagram-circle_svg__clip0_4316_8279)">
        <path
          d="M12 24C5.374 24 0 18.626 0 12S5.374 0 12 0s12 5.374 12 12-5.374 12-12 12z"
          fill="url(#instagram-circle_svg__paint0_linear_4316_8279)"
        />
        <path
          d="M12 5.285c2.187 0 2.446.007 3.31.048.799.036 1.233.17 1.521.283.382.149.656.326.941.612.286.285.463.559.612.94.113.289.245.723.283 1.522.039.864.048 1.123.048 3.31 0 2.186-.007 2.445-.048 3.31-.036.799-.17 1.233-.283 1.521a2.545 2.545 0 01-.612.94c-.285.286-.559.464-.94.613-.289.113-.723.245-1.522.283-.864.038-1.123.048-3.31.048-2.186 0-2.445-.007-3.31-.048-.799-.036-1.233-.17-1.521-.283a2.545 2.545 0 01-.94-.612 2.545 2.545 0 01-.613-.941c-.113-.288-.245-.722-.283-1.522-.038-.864-.048-1.123-.048-3.31 0-2.186.007-2.445.048-3.309.036-.799.17-1.233.283-1.521.149-.382.327-.656.612-.941.286-.286.56-.463.941-.612.288-.113.722-.245 1.522-.283.864-.041 1.123-.048 3.31-.048zm0-1.476c-2.225 0-2.503.01-3.377.05-.87.04-1.466.178-1.99.382a3.954 3.954 0 00-1.449.943 4.032 4.032 0 00-.945 1.452c-.202.52-.341 1.116-.382 1.99-.04.873-.05 1.151-.05 3.376 0 2.225.01 2.503.05 3.377.04.871.178 1.466.382 1.99.208.535.49.993.945 1.447.456.456.912.734 1.452.945.521.202 1.116.341 1.99.382.873.04 1.152.05 3.377.05 2.224 0 2.503-.01 3.376-.05.872-.04 1.467-.178 1.99-.382.535-.208.994-.49 1.447-.945.456-.456.735-.912.946-1.452.201-.521.34-1.116.381-1.99.041-.873.05-1.152.05-3.377 0-2.224-.009-2.503-.05-3.376-.04-.872-.177-1.467-.381-1.99a4.004 4.004 0 00-.946-1.447 4.032 4.032 0 00-1.452-.946c-.52-.201-1.116-.34-1.99-.381-.87-.039-1.149-.048-3.374-.048z"
          fill="#fff"
        />
        <path
          d="M12 7.793a4.208 4.208 0 10.001 8.415 4.208 4.208 0 000-8.415zm0 6.938a2.731 2.731 0 110-5.462 2.731 2.731 0 010 5.462zM16.373 8.613a.984.984 0 100-1.969.984.984 0 000 1.969z"
          fill="#fff"
        />
      </g>
      <defs>
        <linearGradient
          id="instagram-circle_svg__paint0_linear_4316_8279"
          x1={3.515}
          y1={20.485}
          x2={20.485}
          y2={3.515}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD521" />
          <stop offset={0.055} stopColor="#FFD020" />
          <stop offset={0.124} stopColor="#FEC01E" />
          <stop offset={0.2} stopColor="#FCA71B" />
          <stop offset={0.282} stopColor="#FA8316" />
          <stop offset={0.368} stopColor="#F85510" />
          <stop offset={0.456} stopColor="#F51E09" />
          <stop offset={0.5} stopColor="#F30005" />
          <stop offset={0.503} stopColor="#F20007" />
          <stop offset={0.597} stopColor="#E1003B" />
          <stop offset={0.688} stopColor="#D30067" />
          <stop offset={0.776} stopColor="#C70088" />
          <stop offset={0.859} stopColor="#BF00A0" />
          <stop offset={0.936} stopColor="#BB00AF" />
          <stop offset={1} stopColor="#B900B4" />
        </linearGradient>
        <clipPath id="instagram-circle_svg__clip0_4316_8279">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgInstagramCircle
