import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWrap: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.5 21.375a1.933 1.933 0 01-1.423-.576 1.933 1.933 0 01-.577-1.424v-1.192a.784.784 0 01.808-.808H7.5V4.148c0-.09.034-.15.102-.18.068-.028.137-.02.207.025l.745.494a.395.395 0 00.215.073c.07 0 .141-.024.216-.073l.877-.57a.395.395 0 01.215-.072c.07 0 .14.024.215.073l.877.569a.394.394 0 00.215.073c.07 0 .142-.024.216-.073l.877-.57a.394.394 0 01.215-.072c.07 0 .141.024.216.073l.877.569A.395.395 0 0014 4.56c.07 0 .141-.024.216-.073l.876-.57a.395.395 0 01.216-.072c.069 0 .14.024.215.073l.877.569a.395.395 0 00.216.073c.069 0 .14-.024.215-.073l.877-.57a.394.394 0 01.215-.072c.07 0 .141.024.216.073l.877.569a.394.394 0 00.215.073c.069 0 .14-.024.215-.073l.744-.494c.071-.045.14-.053.208-.024.068.029.102.088.102.18v15.226c0 .565-.192 1.039-.577 1.424a1.933 1.933 0 01-1.423.576h-12zm12-1c.283 0 .52-.095.712-.287a.968.968 0 00.288-.713v-14h-11v12h8.192a.784.784 0 01.808.808v1.192c0 .284.096.521.288.713.191.192.429.287.712.287zm-8.116-12.5h4.347c.142 0 .261.048.357.144a.484.484 0 01.143.356.484.484 0 01-.143.357.484.484 0 01-.357.143h-4.347a.484.484 0 01-.356-.143.484.484 0 01-.144-.357c0-.142.048-.26.144-.356a.484.484 0 01.356-.144zm0 3h4.347c.142 0 .261.048.357.144a.484.484 0 01.143.357.484.484 0 01-.143.356.484.484 0 01-.357.144h-4.347a.484.484 0 01-.356-.144.484.484 0 01-.144-.356c0-.143.048-.262.144-.357a.484.484 0 01.356-.143zm7-1.73a.738.738 0 01-.539-.23.739.739 0 01-.23-.54c0-.206.077-.386.23-.539a.738.738 0 01.54-.23c.206 0 .386.077.539.23.153.153.23.333.23.54a.739.739 0 01-.23.539.739.739 0 01-.54.23zm0 3a.738.738 0 01-.539-.23.74.74 0 01-.23-.54c0-.206.077-.386.23-.539a.738.738 0 01.54-.23c.206 0 .386.077.539.23.153.153.23.333.23.54a.74.74 0 01-.23.539.739.739 0 01-.54.23zM6.5 20.375h10v-2h-11v1c0 .284.096.521.287.713.192.192.43.287.713.287z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgWrap
