import { type AutosListing } from '@kijiji/generated/graphql-types'
import { type FC, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { ClientRender } from '@/components/shared/client-render'
import { ImageYAMS } from '@/components/shared/image/ImageYAMS'
import { FavouriteButton } from '@/components/shared/listing/FavouriteButton'
import { AutosCrossPromoListingAttributes } from '@/components/srp/listing-card/autos-x-promo-listing-card/AutosCrossPromoListingAttributes'
import { AutosCrossPromoListingBottomBar } from '@/components/srp/listing-card/autos-x-promo-listing-card/AutosCrossPromoListingBottomBar'
import { AutosCrossPromoListingRightSide } from '@/components/srp/listing-card/autos-x-promo-listing-card/AutosCrossPromoListingRightSide'
import { GetMediaFavouriteButtonSize } from '@/components/srp/listing-card/get-media-favourite-button-size'
import { ListingPrice } from '@/components/srp/listing-card/listing-price'
import { ListingPriceVariant } from '@/components/srp/listing-card/listing-price/ListingPrice'
import {
  AutosListingCardContainer,
  AutosListingCardHeading,
  AutosListingImageBase,
  ListingCardBody,
  ListingCardDetails,
  ListingCardLink,
  ListingCardTopData,
} from '@/components/srp/listing-card/styled'
import { type ListingCardPrice } from '@/components/srp/listing-card/types'
import { SRP_ATTRIBUTES } from '@/components/srp/search-list/domain'
import { highlightKeywords } from '@/components/srp/search-list/highlightKeywords'
import { autosListingMainImageUrlRuleSize } from '@/constants/listings'
import { useGetSearchKeyword } from '@/hooks/keywords/useGetSearchKeywords'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { getSearchPageType } from '@/lib/ga/utils/getSearchPageType'
import { sendToLogger } from '@/utils/sendToLogger'

export type AutosCrossPromoListingCardProps = {
  /**
   * Auto Listing Item
   */
  item: AutosListing
  /**
   * Listing price
   */
  price: ListingCardPrice
  /**
   * Listing attributes
   */
  attributes: Record<string, string[]>
  /**
   * Retail price of listing
   * It will only have a value if the car is new
   */
  msrp?: number
  isMobile?: boolean
}

/**
 * `getUrlWithUtm` is a function that appends xpromo UTM parameters to a given URL.
 *
 * @function
 * @param {string} plainUrl - The original URL to which UTM parameters will be appended.
 * @param {string} searchPageType - The type of the search page. This is used to set the `utm_content` parameter.
 * @returns {URL} Returns a new URL object with the UTM parameters appended.
 */
const getUrlWithUtm = (plainUrl: string, searchPageType: string): URL => {
  const url = new URL(plainUrl)
  url.searchParams.append('utm_source', 'kijiji')
  url.searchParams.append('utm_medium', 'referral')
  url.searchParams.append('utm_content', `web_${searchPageType}`)
  url.searchParams.append('utm_campaign', 'xpromo_srplisting_delr_priceBadge')

  return url
}

/*
   Component that is used to render a listing card on the SRP for a x-promo listing for Kijiji Autos.
*/
export const AutosCrossPromoListingCard: FC<AutosCrossPromoListingCardProps> = ({
  item,
  attributes,
  msrp,
  isMobile,
  price,
}) => {
  const { colors, spacing } = useTheme()
  const { keyword: globalKeyword } = useGetSearchKeyword()

  const { id, imageUrls, title } = item
  const logoSrc = attributes[SRP_ATTRIBUTES.LOGO]?.[0]
  const url = item.moveUrl || item.url

  const searchPageType = useMemo(() => getSearchPageType(globalKeyword), [globalKeyword])

  if (!url) {
    sendToLogger('Tried to render cross promotion card without KA Vip url.', {
      extra: { variables: { listingId: id } },
      fingerprint: ['RenderListingCard'],
    })
    return null
  }

  const handleTrackListingClick = () => {
    trackEvent({
      action: GA_EVENT.CrossPromoBadgeClick,
      label: `badge=Kijiji Autos`,
    })
  }

  return (
    <AutosListingCardContainer data-testid="autos-cross-promo-listing-card">
      <ListingCardTopData>
        <AutosListingImageBase
          data-testid="autos-cross-promo-listing-card-image-container"
          imageComponent={
            <ImageYAMS
              alt={title}
              data-testid="autos-cross-promo-listing-card-image"
              src={imageUrls?.[0]}
              width={autosListingMainImageUrlRuleSize}
              height={300}
            />
          }
          size="xLarge"
        />

        <ListingCardDetails justifyContent="space-between" gap={spacing.mini}>
          <ListingCardBody>
            <ListingPrice
              displayInlinePrice
              msrp={msrp}
              priceAmount={price.amount}
              priceOriginal={price.original}
              type={price.type}
              surcharges={price.surcharges}
              variant={ListingPriceVariant.Autos}
            />

            <AutosListingCardHeading
              data-testid="autos-cross-promo-listing-title"
              forwardedAs="h3"
              color={colors.grey.primary}
              size="small"
              weight={isMobile ? 'medium' : 'regular'}
            >
              <ListingCardLink
                onClick={handleTrackListingClick}
                data-testid="autos-cross-promo-listing-card-link"
                href={getUrlWithUtm(url, searchPageType).toString()}
                target="_blank"
                variant="grey"
              >
                {highlightKeywords(id, title, globalKeyword)}
              </ListingCardLink>
            </AutosListingCardHeading>

            <AutosCrossPromoListingAttributes
              attributes={attributes}
              locationName={item.location.name}
            />
          </ListingCardBody>

          <AutosCrossPromoListingRightSide>
            <ClientRender>
              <GetMediaFavouriteButtonSize>
                {({ size }) => <FavouriteButton colorScheme="purple" listingId={id} size={size} />}
              </GetMediaFavouriteButtonSize>
            </ClientRender>
          </AutosCrossPromoListingRightSide>
        </ListingCardDetails>
      </ListingCardTopData>

      <AutosCrossPromoListingBottomBar logoSrc={logoSrc} />
    </AutosListingCardContainer>
  )
}
