import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgEditAlt: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.8 19.2l-5.3.3.8-5.9c0-.2.1-.5.3-.6l8.2-8.2c.4-.4 1.1-.4 1.5 0l3.9 3.9c.4.4.4 1.1 0 1.5l-8.7 8.7c-.2.2-.5.3-.7.3zM5.6 13.5l2.7 2.7M12.6 5.9l5.5 5.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgEditAlt
