import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

type BreakpointProps = {
  breakpoint: keyof ThemeProps['breakpoints']
  display?: 'block' | 'flex'
}

/**
 * Display the element only when the screen is at or larger than the specified breakpoint.
 * @param breakpoint The breakpoint to use.
 * @param display The display value to use. Defaults to `block`.
 */
export const ShowAtOrLarger = styled.div<BreakpointProps>(
  ({ theme, breakpoint, display = 'block' }) => {
    return `
    display: none;

    @media screen and (min-width: ${theme.breakpoints[breakpoint]}px) {
      display: ${display};
    }
  `
  }
)

/**
 * Display the element only when the screen is smaller than the specified breakpoint.
 * @param breakpoint The breakpoint to use.
 * @param display The display value to use. Defaults to `block`.
 */
export const ShowWhenSmaller = styled.div<BreakpointProps>(
  ({ theme, breakpoint, display = 'block' }) => {
    return `
    display: none;

    // Allow to be used with "ShowAtOrLarger" without showing duplicated elements for the same breakpoints
    @media screen and (max-width: ${theme.breakpoints[breakpoint] - 1}px) {
      display: ${display};
    }
  `
  }
)

/**
 * Hide the element only when the screen is at or larger than the specified breakpoint.
 * @param breakpoint The breakpoint to use.
 * @param display The display value to use. Defaults to `block`.
 */
export const HideAtOrLarger = styled.div<BreakpointProps>(
  ({ theme, breakpoint, display = 'block' }) => {
    return `
  display: ${display};
  
  @media screen and (min-width: ${theme.breakpoints[breakpoint]}px) {
    display: none;
  }
  `
  }
)
