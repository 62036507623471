import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTicketType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.328 14.302l.209-.209m2.015-6.698a1.813 1.813 0 01-.347-2.093L13.91 3 3 13.954l2.293 2.302c.695-.419 1.53-.28 2.085.349.556.558.695 1.395.348 2.093L10.019 21 21 10.046l-2.293-2.372c-.695.419-1.599.28-2.155-.279zm-8.2 3.559l-4.17 4.185 4.17-4.185zm3.405-3.42l-.695.699.695-.698zm4.865 4.396l3.127-3.14-3.127 3.14z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.88 9.767l2.016.768 1.668-1.395-.14 2.162 1.808 1.186-2.085.559-.556 2.092-1.182-1.813-2.154.14 1.39-1.675-.765-2.024z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgTicketType
