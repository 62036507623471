import { type ParsedUrlQuery } from 'querystring'

const SEE_ALL_TOP_ADS_EXPECTED_VALUE = 'y'

/**
 * Returns true if the user is on a "See All Top Ads" view
 *
 * @param {ParsedUrlQuery} query - Route query parameters
 */
export const isSeeAllTopAdsView = (query: ParsedUrlQuery) => {
  /** Looking for the parameter named "gpTopAds" to have the expected value */
  const { gpTopAds } = query

  return gpTopAds === SEE_ALL_TOP_ADS_EXPECTED_VALUE
}
