import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosSuv: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.742 14.589l-1.446-.154a.981.981 0 01-.796-.986v-.678h.56v-.833c0-.647.472-1.202 1.092-1.294 1.091-.123 3.156-.247 4.07-.308.383-.031.737-.216 1.032-.494l1.917-1.91a1.441 1.441 0 011.033-.432h8.435c.413 0 .796.185 1.062.493.678.77 1.239 1.634 1.681 2.558.088.185.118.37.118.586v2.25c0 .586-.442 1.079-1.003 1.14h-.56M16.542 15.051H8.284M12.62 11.723h.53M17.22 11.723h.502"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.364 16.5c.896 0 1.622-.759 1.622-1.695S6.26 13.11 5.364 13.11c-.896 0-1.623.759-1.623 1.695S4.468 16.5 5.364 16.5z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.364 16.5c.896 0 1.622-.759 1.622-1.695S6.26 13.11 5.364 13.11c-.896 0-1.623.759-1.623 1.695S4.468 16.5 5.364 16.5zM18.135 16.5c.896 0 1.622-.759 1.622-1.695s-.726-1.695-1.622-1.695c-.896 0-1.622.759-1.622 1.695s.726 1.695 1.622 1.695z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.135 16.5c.896 0 1.622-.759 1.622-1.695s-.726-1.695-1.622-1.695c-.896 0-1.622.759-1.622 1.695s.726 1.695 1.622 1.695zM9.876 10.582h12.359M13.8 8.363v2.158M18.4 8.363v2.158M3.152 11.753s.059.987-.708.987H2.03M22.264 12a1.301 1.301 0 01-.914-.77l-.06-.494"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosSuv
