import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgForSaleBackground: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#for-sale-background_svg__clip0_636_260)">
        <path
          d="M24 48.004c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24c0 13.254 10.745 24 24 24z"
          fill="#F3F3F9"
        />
        <path
          d="M34.563 14.01H27.48a.625.625 0 100 1.25h6.456v6.455a4.189 4.189 0 01-1.054 3.098l-9.945 9.875a2.193 2.193 0 01-1.56.646 2.193 2.193 0 01-1.562-.647L14.77 29.64a2.828 2.828 0 01-.008-3.99c.067-.063 6.713-6.425 8.204-7.916a.625.625 0 10-.884-.884c-1.482 1.482-8.118 7.834-8.194 7.907a4.078 4.078 0 00-.001 5.766l5.047 5.05a3.433 3.433 0 002.444 1.012 3.436 3.436 0 002.444-1.01l9.983-9.917a5.42 5.42 0 001.383-3.941v-7.082a.625.625 0 00-.624-.625z"
          fill="currentColor"
        />
        <path
          d="M30.188 21.509a2.5 2.5 0 10.002-5 2.5 2.5 0 00-.004 5h.002zm-1.25-2.5a1.25 1.25 0 111.25 1.25h-.001a1.25 1.25 0 01-1.25-1.25zM22.578 23.798l.783-.784a1.847 1.847 0 00-2.516-.233l-.646-.647-.557.557.639.64a1.862 1.862 0 00.158 2.578c.79.79 1.644.653 2.785.103.894-.44 1.368-.515 1.74-.144a.956.956 0 01-.076 1.45 1.119 1.119 0 01-1.72.014l-.776.777a2.018 2.018 0 002.846.18l.647.645.557-.557-.646-.646a1.918 1.918 0 00-.11-2.695c-.716-.715-1.48-.722-2.827-.076-.852.385-1.278.537-1.705.11a.84.84 0 01.02-1.285.929.929 0 011.404.013z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="for-sale-background_svg__clip0_636_260">
          <path fill="#fff" transform="translate(0 .004)" d="M0 0h48.004v48.004H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgForSaleBackground
