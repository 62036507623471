import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotificationExpiring: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} fill="#ac60bf" r={10} />
      <path
        clipRule="evenodd"
        d="M9.845 8.857h4.285c-.105.738-.493 1.395-1.068 1.78l-.09.066c-.21.15-.475.355-.656.613l-.617.004c-.2-.287-.522-.507-.773-.676-.575-.398-.97-1.082-1.081-1.787zm3.661 3.483c-.417-.283-.52-.418-.531-.482.028-.124.379-.375.495-.458l.085-.062c.796-.533 1.334-1.468 1.442-2.508a.797.797 0 00-.204-.606.67.67 0 00-.496-.224H9.702a.672.672 0 00-.496.224.804.804 0 00-.203.613 3.54 3.54 0 001.493 2.551c.335.226.513.385.53.459-.03.12-.379.37-.494.45l-.093.069c-.793.549-1.33 1.488-1.436 2.52a.798.798 0 00.202.605c.134.144.31.224.497.224h4.578c.204 0 .398-.091.531-.25a.733.733 0 00.169-.588c-.106-1.023-.642-1.962-1.474-2.537z"
        fill="#fff"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgNotificationExpiring
