import { HTMLAttributes, ReactNode, useEffect, useState } from 'react'

import { HiddenStyles } from './styled'

export type VisuallyHiddenProps = HTMLAttributes<HTMLSpanElement> & {
  children: ReactNode
}

/**
 * @description Visually Hidden hides text visually but keep it readable for people using assistive technologies
 *
 * @param children -
 * @param delegated - Anything passed as a prop will be passed to the span
 */

export const VisuallyHidden = ({
  children,
  ...delegated
}: VisuallyHiddenProps) => {
  const [forceShow, setForceShow] = useState(false)

  useEffect(() => {
    // This allows to press the ALT button to visualize the hidden text, only works on development enviroment
    if (process.env.NODE_ENV !== 'production') {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Alt') {
          setForceShow(true)
        }
      }
      const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'Alt') {
          setForceShow(false)
        }
      }
      window.addEventListener('keydown', handleKeyDown)
      window.addEventListener('keyup', handleKeyUp)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
        window.removeEventListener('keyup', handleKeyUp)
      }
    }
  }, [])

  if (forceShow) {
    return <>{children}</>
  }

  return <HiddenStyles {...delegated}>{children}</HiddenStyles>
}
