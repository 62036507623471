import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSendWaiting: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M5.407 12.446c.061.028.13.043.203.043h7.398a.49.49 0 100-.979H5.61a.493.493 0 00-.203.044L2.084 4.27c-.344-.753.432-1.528 1.19-1.19l18.193 8.1a.896.896 0 010 1.638l-18.193 8.1c-.758.337-1.534-.438-1.19-1.19z"
        fill="#1477d4"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgSendWaiting
