import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSms: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.12 4.476a87.641 87.641 0 00-18.24 0C1.84 4.59 1 5.546 1 6.58v9.838c0 1.035.84 1.99 1.88 2.105a87.682 87.682 0 0018.24 0c1.04-.114 1.88-1.07 1.88-2.105V6.58c0-1.036-.84-1.99-1.88-2.105z"
        fill="#F66429"
      />
      <mask
        id="sms_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={1}
        y={4}
        width={22}
        height={15}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.12 4.476a87.641 87.641 0 00-18.24 0C1.84 4.59 1 5.546 1 6.58v9.838c0 1.035.84 1.99 1.88 2.105a87.682 87.682 0 0018.24 0c1.04-.114 1.88-1.07 1.88-2.105V6.58c0-1.036-.84-1.99-1.88-2.105z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#sms_svg__a)">
        <path fill="currentColor" d="M0 0h24v24H0z" />
        <path fill="currentColor" d="M0 0h24v24H0z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.004 8.835c1.078 0 1.804.704 1.826 1.749h-.968c-.011-.583-.286-.869-.88-.869-.583 0-.847.341-.847.748 0 .429.22.605 1.045.803 1.32.308 1.815.77 1.815 1.661 0 1.078-.836 1.694-1.947 1.694-1.287 0-2.002-.726-2.024-1.804h.968c.01.583.385.924 1.078.924.638 0 .957-.319.957-.748 0-.462-.242-.682-1.155-.891-1.155-.264-1.705-.671-1.705-1.584 0-.88.638-1.683 1.837-1.683zm9.009 0c1.199 0 1.793.858 1.793 2.079V14.5h-.946v-3.432c0-.858-.396-1.309-1.067-1.309-.715 0-1.254.616-1.276 1.694V14.5h-.946v-3.432c0-.858-.396-1.309-1.067-1.309-.726 0-1.276.638-1.276 1.771v2.97h-.946V8.956h.847l.055.759c.253-.484.781-.88 1.54-.88.814 0 1.353.407 1.617 1.056.286-.55.814-1.056 1.672-1.056zm6.776 1.749c-.022-1.045-.748-1.749-1.826-1.749-1.2 0-1.837.803-1.837 1.683 0 .913.55 1.32 1.705 1.584.913.209 1.155.429 1.155.891 0 .429-.32.748-.957.748-.693 0-1.067-.341-1.078-.924h-.968c.022 1.078.737 1.804 2.024 1.804 1.11 0 1.947-.616 1.947-1.694 0-.891-.495-1.353-1.815-1.661-.825-.198-1.045-.374-1.045-.803 0-.407.264-.748.847-.748.594 0 .869.286.88.869h.968z"
          fill="#fff"
        />
      </g>
    </Icon>
  )
}
export default SvgSms
