import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTwitter: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 0C5.394 0 0 5.394 0 12s5.394 12 12 12 12-5.394 12-12S18.606 0 12 0zm7.486 7.963c0 .037-.037.11-.11.22-.33.44-.697.845-1.174 1.175-.074.037-.184.11-.184.183 0 2.349-.587 4.66-2.312 6.349-2.165 2.165-5.43 2.752-8.33 1.945a6.802 6.802 0 01-1.615-.66c-.183-.11-.33-.184-.513-.294-.037-.037-.074-.074-.037-.074s.073-.036.183 0a6.035 6.035 0 002.423-.293c.403-.147 1.32-.44 1.578-.844l.036-.037c-.11-.037-.22-.037-.33-.037-.77-.22-1.835-.623-2.312-1.945-.037-.073 0-.11.073-.11.66.037.881 0 1.101-.036-.11-.037-.257-.074-.367-.11-.99-.367-1.724-1.358-1.835-2.386 0-.11-.036-.22-.036-.33 0-.073.036-.11.11-.073.514.256 1.064.33 1.137.33-.11-.11-.256-.22-.366-.33-.845-.808-1.175-2.46-.514-3.487.073-.073.11-.073.147 0 1.578 1.798 3.486 2.606 5.834 2.973.074 0 .074 0 .074-.074-.074-.403-.074-.807.037-1.21s.293-.808.587-1.138a2.77 2.77 0 01.954-.77c.367-.184.77-.294 1.21-.331.441-.037.882.037 1.249.22.293.11.587.294.807.514l.147.147c.037.037.073.036.11.036a6.732 6.732 0 001.688-.66c.037 0 .037-.037.073 0 .037.036 0 .036 0 .073-.11.33-.257.624-.477.88-.147.184-.477.588-.697.625.477-.074.99-.22 1.431-.404.184-.073.22-.11.22-.037 0-.036 0 0 0 0z"
        fill="#4b4881"
      />
    </Icon>
  )
}
export default SvgTwitter
