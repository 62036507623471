import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPhoneSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.078 14.702c-.502-.6-1.506-.5-1.908.2-.602 1-.903 1.3-1.204 1.3-2.21-.3-6.928-5-7.129-7.2 0-.1 0-.3 1.406-1.2.703-.4.803-1.3.2-1.8-2.61-2.4-2.71-3-3.513-3-1.607 0-3.113 4-2.912 5.7.502 4 8.835 12.3 12.65 12.3 1.707 0 5.322-1.3 5.322-2.8.1-.8-.502-.9-2.912-3.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgPhoneSolid
