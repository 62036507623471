import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMotorcycleSport: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <circle cx={18.288} cy={14.813} r={1.688} />
        <circle cx={5.488} cy={14.813} r={1.688} />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="M14.729 11.688l1.384-1.876-.625-1.25-2.073.173a2.723 2.723 0 00-2.302 1.703L4.646 9.13a.755.755 0 00-.301 1.48L11.113 12zm0 0l-1.141 1.996a1 1 0 01-.868.504H9.994a1 1 0 01-.742-.33l-2.485-2.75L5.3 15M20.538 12.845s-1.062-1.467-3.198-.87-2.194 2.828-2.194 2.828M18.3 15l-2.04-5.364M12.675 7h2.316l.752 1.563m0 0L16.8 7.5l1 2-1.54.136m-.517-1.073l.517 1.073" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgMotorcycleSport
