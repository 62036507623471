import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosBluetooth: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.994 9.137L12 12l3.006-2.863L12 6.274v11.452l3.006-2.863L12 12l-3.006 2.863"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20.998c-3.494.082-6.419-2.781-6.5-6.299V9.3c.081-3.517 3.006-6.38 6.5-6.298 3.494-.082 6.419 2.781 6.5 6.298v5.4c-.081 3.599-3.006 6.38-6.5 6.298z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosBluetooth
