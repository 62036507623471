import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgScreenSize: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.831 6.982h11.184c.265 0 .53.202.53.539v8.891c0 .27-.199.539-.53.539h-.86m-1.92 0H5.053a.526.526 0 01-.53-.539V7.521c0-.27.199-.54.53-.54h.86"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.794 5.5h16.412c.463 0 .794.337.794.808v11.384c0 .471-.33.808-.794.808H3.794c-.463 0-.794-.337-.794-.808V6.24c0-.404.397-.741.794-.741zM7.434 14.997l9.132-6.062"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.426 12.505l-.992 2.492m6.485-6.062h2.647-2.647zm1.654 2.492l.993-2.492-.993 2.492zm-5.492 3.57H7.434h2.647z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgScreenSize
