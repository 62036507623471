import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMapApp: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.487 2H6.513A4.518 4.518 0 002 6.513v10.974A4.518 4.518 0 006.513 22h10.974A4.518 4.518 0 0022 17.487V6.513A4.518 4.518 0 0017.487 2zM21 17.487A3.517 3.517 0 0117.487 21H6.513A3.517 3.517 0 013 17.487V6.513A3.517 3.517 0 016.513 3h10.974A3.517 3.517 0 0121 6.513v10.974z"
        fill="currentColor"
      />
      <path
        d="M12.002 8a2 2 0 10.006 4 2 2 0 00-.006-4zm0 3a1 1 0 11.006-1.999A1 1 0 0112.002 11z"
        fill="currentColor"
      />
      <path
        d="M11.986 5A4.755 4.755 0 007 9.939c0 3.034 1.698 6.246 4.659 8.814l.328.285.328-.287c2.96-2.585 4.657-5.796 4.657-8.812A4.756 4.756 0 0011.986 5zm0 12.705C9.445 15.38 8 12.57 8 9.939A3.772 3.772 0 0111.986 6a3.772 3.772 0 013.986 3.939c0 2.616-1.448 5.427-3.987 7.766z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgMapApp
