import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgProfileSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.667 15.6l-3.445-.675v-1.238c1.445-.787 2.334-2.362 2.334-3.937V7.5c0-2.475-2-4.5-4.334-4.5h-.444C9.333 3 7.444 5.025 7.444 7.5v2.25c0 1.575.89 3.15 2.334 3.938v1.237l-3.445.675C4.89 15.938 3 16.275 3 18.637v1.8c0 .338.333.563.556.563h16.888c.334 0 .556-.225.556-.563v-1.8c0-2.362-1.889-2.7-3.333-3.037z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgProfileSolid
