import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosKms: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.697 19.271a9.604 9.604 0 019.263 1.46c4.404-3.305 5.39-9.684 2.05-14.141-3.34-4.458-9.567-5.457-13.97-2.076C1.636 7.82.65 14.198 3.99 18.656c.607.769 1.29 1.537 2.05 2.075M14.316 9.28c-1.443-1-3.341-.923-4.784.153"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.038 16.735a1.91 1.91 0 001.898-1.922 1.91 1.91 0 00-1.898-1.921 1.91 1.91 0 00-1.898 1.921 1.91 1.91 0 001.898 1.922z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.86 14.045l4.632-5.841-5.695 4.688m5.011 3.074l.304.154-.304-.154zm.911-3.305h.304-.304zm-3.34-5.84l.152-.308-.152.307zm-3.34-1.23v.307-.308zM8.696 6.82l-.151-.308.151.307zM6.267 9.28l-.303-.154.304.154zm-.834 3.381h-.304.304zm.835 3.305l-.304.154.304-.154zM10.14 21.5h3.872-3.872z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosKms
