import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUnitTypeDuplex: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.916 13.32V23h3.088m-3.088-9.68V3.64m0 9.68h3.088m13.122 0V23H10.091m10.035-9.68V3.64m0 9.68H10.091M3.916 3.64h16.21m-16.21 0h18.526m-18.526 0H1.6V1.5a.5.5 0 01.5-.5h19.842a.5.5 0 01.5.5v2.14m-2.316 0h2.316M7.004 23v-6.16h3.087V23m-3.087 0h3.087m-3.087-9.68V6.28h3.087v7.04m-3.087 0h3.087m3.088-7.04h4.053v3.404h-4.053V6.28zm0 10.56h4.053v3.265h-4.053V16.84z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgUnitTypeDuplex
