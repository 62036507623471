import { useTranslation } from 'next-i18next'

import { AutosBadgeBase } from '@/components/srp/listing-card/autos-badges/AutosBadgeBase'
import { type BadgeSize } from '@/ui/atoms/badge'

type AutosBadgeCarFaxProps = {
  variant?: 'text' | 'badge'
  size?: BadgeSize
}

export const AutosBadgeContactless = ({ size }: AutosBadgeCarFaxProps) => {
  const { t } = useTranslation('srp')

  return <AutosBadgeBase size={size} label={t('listing.autos_badge.contactless')} />
}
