import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgListing: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17 4.333c1.005-1.006 2.995-3.006 4-2 .5.5.5 1.5 0 2-.354.353-.63 1.006-1 1.5"
        stroke="currentColor"
      />
      <path
        d="M9.793 20.793l-7.086-7.086a1 1 0 010-1.414l8.459-8.459a1 1 0 01.804-.288l7.127.698A1 1 0 0120 5.24v6.347a1 1 0 01-.293.707l-8.5 8.5a1 1 0 01-1.414 0z"
        stroke="currentColor"
      />
      <path
        d="M9.883 9.182a.373.373 0 00-.533.521l.277.284c-.536.71-.462 1.526.13 2.132.641.657 1.364.566 2.302.1.647-.314 1.003-.366 1.26-.102.253.258.243.63-.083.948-.245.24-.498.328-.749.27-.287-.066-.616-.1-.826.106-.222.216-.225.582.043.736.573.326 1.227.306 1.865-.153l.282.289a.373.373 0 00.534-.52l-.277-.284c.54-.693.59-1.542-.074-2.221-.575-.59-1.213-.647-2.324-.111-.641.297-.948.41-1.239.113-.263-.27-.242-.586.033-.855a.65.65 0 01.47-.2c.304.007.656.055.874-.158.213-.208.214-.561-.045-.707-.55-.31-1.116-.26-1.637.101l-.283-.289z"
        fill="currentColor"
      />
      <circle cx={17.15} cy={7.45} r={0.5} stroke="currentColor" strokeWidth={0.5} />
      <path
        d="M17.137 7.813a.5.5 0 00-.274-.961l.274.961zm-.637-.48l-.137.48.137-.48zm4.5-5l.354-.354-.354.354zm-4.137 4.519a2.6 2.6 0 00-.225.074l-.022.008.004-.001a.423.423 0 01.26 0l.004.001-.022-.008a2.616 2.616 0 00-.224-.074l-.275.961c.077.023.118.038.143.047.006.002.037.015.062.023a.58.58 0 00.364 0 1.617 1.617 0 01.206-.07l-.275-.961zm-.226 0a.354.354 0 01-.164-.084.174.174 0 01-.043-.095c-.023-.125.007-.347.141-.666.263-.623.81-1.348 1.283-1.82l-.707-.708c-.533.532-1.17 1.362-1.497 2.14-.16.381-.279.822-.204 1.233.04.216.133.427.3.603.165.174.378.29.617.358l.274-.961zm1.217-2.666c.515-.515 1.093-1.092 1.654-1.429.278-.167.507-.247.688-.256.156-.008.3.035.45.185l.708-.707a1.553 1.553 0 00-1.209-.477c-.412.021-.806.19-1.152.398-.687.413-1.356 1.089-1.846 1.58l.707.706zm2.793-1.5c.135.135.228.37.228.647 0 .275-.093.511-.229.646l.708.707c.365-.365.521-.88.521-1.353 0-.475-.156-.989-.521-1.354l-.707.707z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgListing
