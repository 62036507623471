import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgDropdownExpanded: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.854 9.646l4.5 4.5a.5.5 0 01-.708.708L12.5 10.707l-4.146 4.147a.5.5 0 01-.708-.708l4.5-4.5a.5.5 0 01.708 0z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgDropdownExpanded
