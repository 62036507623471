import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosSeats: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.36 7.053l2.714-.149c.227-.521.302-1.117.302-1.713 0-.893-.302-1.787-.754-2.532-.528-.223-1.13-.223-1.658.075a22.354 22.354 0 00-.905 3.202c-.15.372 0 .745.302 1.117zM7.657 15.84a1.05 1.05 0 001.056-1.042 1.05 1.05 0 00-1.056-1.043c-.582 0-1.055.467-1.055 1.043 0 .575.473 1.042 1.055 1.042z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.582 18.298c-.679-.224-1.282-.67-1.583-1.266-1.13-2.681-1.96-5.436-2.412-8.34a.745.745 0 01.376-.82c.679-.447 1.433-.521 2.187-.372.226.074.376.223.527.372.603 1.043 2.94 4.84 3.242 6.33.15.596.15 1.266-.076 1.862-.15.223-.301.447-.452.596 0 0 6.784-.15 9.423-.15.301 0 .527.15.753.373.905.893 1.357 2.085 1.433 3.351 0 .67-.528 1.266-1.206 1.266H9.24c-.377 0-.754-.149-.98-.447m4.297-1.564h1.734-1.734zm3.995 0h1.659-1.659z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosSeats
