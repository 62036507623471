import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFile: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.5 9v10a2 2 0 01-2 2h-11a2 2 0 01-2-2V5a2 2 0 012-2h7m6 6l-6-6m6 6h-4a2 2 0 01-2-2V3"
        stroke="currentColor"
      />
    </Icon>
  )
}
export default SvgFile
