import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotificationSavedSearch: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={24} cy={24} fill="#56c17c" r={24} />
      <path
        clipRule="evenodd"
        d="M29 31.59l-4.731-3.012a.503.503 0 00-.538 0L19 31.59V16.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5zM28.5 15h-9c-.827 0-1.5.672-1.5 1.5v16a.502.502 0 00.769.422L24 29.592l5.231 3.33A.503.503 0 0030 32.5v-16c0-.828-.673-1.5-1.5-1.5z"
        fill="#fff"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgNotificationSavedSearch
