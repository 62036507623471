import DownPointingIcon from '@kijiji/icons/src/icons/DownPointing'
import FireIcon from '@kijiji/icons/src/icons/Fire'
import LightningIcon from '@kijiji/icons/src/icons/Lightning'
import { useTranslation } from 'next-i18next'
import { type ReactNode } from 'react'

import { type ListingBadgeLabel } from '@/types/listings'
import { BadgeContent } from '@/ui/atoms/listing-image/styled'

export const ImageBadgeIcons: { [x in ListingBadgeLabel]: ReactNode } = {
  featured: <FireIcon size="xsm" aria-hidden />,
  urgent: <LightningIcon size="xsm" aria-hidden />,
  reduced: <DownPointingIcon size="xsm" aria-hidden />,
}

type ListingImageBadgeProps = {
  imageBadge: ListingBadgeLabel
}
export const ListingImageBadge = ({ imageBadge }: ListingImageBadgeProps) => {
  const { t } = useTranslation('listing')

  return (
    <BadgeContent data-testid={`listing-badge-${imageBadge}`}>
      {ImageBadgeIcons[imageBadge]}
      <span>{t(`badges.${imageBadge}`)}</span>
    </BadgeContent>
  )
}
