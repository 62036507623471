import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSell: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M20.398 10.69l-1.101-1.1c-.388-.388-.906-.603-1.457-.603s-1.068.215-1.456.603l-7.186 7.18a.753.753 0 00-.22.53v2.95c0 .415.335.75.75.75h2.953c.2 0 .39-.08.53-.22l7.187-7.18a2.058 2.058 0 000-2.91zm-1.061 1.85L12.37 19.5h-1.892v-1.89l6.966-6.96c.21-.21.578-.21.79 0l1.102 1.1a.56.56 0 010 .79z" />
        <path d="M7.003 17.5H4.501v-13h8.448l1.561 1.56v1.19a.75.75 0 101.501 0v-1.5c0-.2-.08-.39-.22-.53l-2.002-2a.75.75 0 00-.53-.22H3.75A.75.75 0 003 3.75v14.5c0 .415.335.75.75.75h3.253a.75.75 0 100-1.5z" />
        <path d="M12.258 6.25H6.753a.75.75 0 100 1.5h5.505a.75.75 0 100-1.5zM12.258 9.25H6.753a.75.75 0 100 1.5h5.505a.75.75 0 100-1.5zM8.255 12.25H6.753a.75.75 0 100 1.5h1.502a.75.75 0 100-1.5z" />
      </g>
    </Icon>
  )
}
export default SvgSell
