import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ListItem } from '@/ui/atoms/list-item'
import { Spacing } from '@/ui/atoms/spacing'
import { List } from '@/ui/molecules/list'
import { Direction } from '@/ui/typings/direction'

export type AttributeListProp = {
  attributes: string[]
  listingId: string
  testId?: string
}

export const AttributeList: FC<AttributeListProp> = ({
  attributes,
  listingId,
  testId = 'attribute-list',
}) => {
  const { spacing } = useTheme()

  return (
    <>
      <Spacing mTop={spacing.defaultSmall} />
      <List variant={Direction.horizontal} fontSize="small" hasDivider data-testid={testId}>
        {attributes.map((attribute, index) => (
          <ListItem
            key={`${listingId}-${attribute}-${index}`}
            size="small"
            secondaryText={attribute}
          />
        ))}
      </List>
    </>
  )
}
