import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosOther: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.332 14.56h8.835M21.967 11.982c.354.091.56.425.53.789 0 .697-.5 1.304-1.178 1.425"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.298 14.56H2.825c-.736 0-1.325-.606-1.325-1.365v-.151c0-.303.177-1.243.707-1.456.471-.181 3.357-.515 4.093-.636a.866.866 0 00.295-.122c.56-.364 3.593-2.092 4.388-2.183.942-.121 4.181-.273 5.094.03.913.304 3.681 1.91 4.211 1.91h.28a1.4 1.4 0 011.4 1.4v0"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.652 15.5c.797 0 1.443-.665 1.443-1.486 0-.82-.646-1.486-1.443-1.486s-1.443.666-1.443 1.486.646 1.486 1.443 1.486z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.652 15.5c.797 0 1.443-.665 1.443-1.486 0-.82-.646-1.486-1.443-1.486s-1.443.666-1.443 1.486.646 1.486 1.443 1.486zM18.492 15.5c.797 0 1.443-.665 1.443-1.486 0-.82-.646-1.486-1.443-1.486s-1.443.666-1.443 1.486.646 1.486 1.443 1.486z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.492 15.5c.797 0 1.443-.665 1.443-1.486 0-.82-.646-1.486-1.443-1.486s-1.443.666-1.443 1.486.646 1.486 1.443 1.486z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosOther
