import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSport: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.363 17.918c-.48 0-.863.409-.863.919s.384.918.863.918h5.082c.48 0 .863-.408.863-.918s-.383-.919-.863-.919H12.72v-1.734c3.165-.409 5.658-3.062 5.85-6.429C20.773 9.551 22.5 7.51 22.5 5.061V2.918c0-.51-.384-.918-.863-.918H2.363c-.48 0-.863.408-.863.918v2.143c0 2.45 1.726 4.388 3.932 4.694.191 3.265 2.589 6.02 5.657 6.429l-1.726 1.734zM4.377 7.51C3.61 7 3.13 6.082 3.13 5.061V3.837h2.302L4.377 7.51zm1.055 12.653c-.48 0-.864.408-.864.919 0 .51.384.918.864.918H18.28c.48 0 .863-.408.863-.918s-.384-.919-.863-.919H5.43z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.473 3.837h2.301V5.06c0 1.02-.48 1.94-1.247 2.45"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgSport
