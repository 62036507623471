import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPinterestOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.432 21.39a.625.625 0 11-.514-1.13l-.006-.016C18.31 19.043 20.75 15.81 20.75 12a8.751 8.751 0 00-17.502 0 8.735 8.735 0 003.973 7.324c.183.105.392.166.617.166.532 0 .983-.332 1.164-.8.223-.657.405-1.299.497-1.69.126-.53.411-1.831.548-2.46.017-.08.028-.16.028-.245 0-.15-.03-.294-.078-.427-.186-.488-.512-1.425-.512-2.015 0-1.297.64-2.353 1.576-2.353.796 0 1.25.847 1.25 1.562 0 .8-.468 1.836-.73 2.944-.22.928.44 1.745 1.355 1.745 1.659 0 3.125-1.97 3.125-4.689 0-1.917-1.712-3.437-4.062-3.437-2.655 0-4.375 1.93-4.375 4.375 0 .762.273 1.459.625 1.875.162.191.3.298.24.518-.042.161-.06.198-.178.39-.12.195-.238.301-.438.22-1.222-.5-1.5-1.81-1.5-3.316 0-2.407 1.78-5.312 5.938-5.312 2.97 0 5 2.229 5 4.687 0 3.277-1.887 5.938-4.062 5.938-.583 0-1.18-.12-1.624-.345a.614.614 0 00-.84.366c-.15.59-.326 1.284-.385 1.496-.08.287-.198.58-.33.867a2.493 2.493 0 01-2.222 1.367c-.457 0-.882-.13-1.25-.344C3.833 18.628 2 15.531 2 12 2 6.477 6.477 2 12 2s10 4.477 10 10c0 4.316-2.738 7.988-6.568 9.39zm-2.807-.64a.625.625 0 110 1.25.625.625 0 010-1.25z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgPinterestOutline
