import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgGolfCart: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <circle cx={2.917} cy={2.917} r={2.417} transform="matrix(-1 0 0 1 9 13.917)" />
        <circle cx={1.25} cy={1.25} r={0.75} transform="matrix(-1 0 0 1 7.333 15.583)" />
        <circle cx={2.917} cy={2.917} r={2.417} transform="matrix(-1 0 0 1 21.5 13.917)" />
        <circle cx={1.25} cy={1.25} r={0.75} transform="matrix(-1 0 0 1 19.834 15.583)" />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="M20.666 15.167V12.25h-7.083v3.333h-5M16.084 17.25h-7.5M3.583 17.25H1.5v-1.667h2.083M2.75 15.167V12.25l3.75-.833 1-7.167m0 0H5m2.5 0h10.25m2.25 0h-2.25m0 0v8M6.5 11.417l1.25 1.562m2.084 2.604L7.75 12.98m0 0l2.292-4.48L9 8.084l2.084.834M19.417 12.25v-2.5m0 0l.083-2h1.691a.5.5 0 01.447.724L21 9.75zM17.334 7.25h-.667a1 1 0 00-1 1v2.333h-1.083a1 1 0 00-1 1v.667" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgGolfCart
