import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSettingsOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.052 3.947c-.77 0-1.438.616-1.508 1.396l-.105 1.152-.254.114c-.243.109-.479.232-.706.368l-.216.13-1.198-.552c-.716-.33-1.585-.06-1.97.602L4.149 8.79c-.385.663-.183 1.545.46 1.996l.943.66-.014.262a5.635 5.635 0 000 .582l.014.261-.942.66c-.645.452-.845 1.336-.461 1.998l.948 1.633c.385.663 1.254.93 1.969.602l1.198-.552.216.13c.227.136.463.26.706.368l.254.114.105 1.152c.07.782.74 1.396 1.508 1.396h1.896c.77 0 1.438-.616 1.508-1.396l.105-1.152.254-.114c.243-.109.479-.232.706-.368l.216-.13 1.198.552c.716.33 1.585.06 1.97-.602l.947-1.633c.385-.664.183-1.546-.46-1.998l-.943-.66.014-.26a5.646 5.646 0 000-.583l-.014-.261.942-.66c.646-.452.846-1.336.461-1.997l-.948-1.634c-.385-.663-1.254-.93-1.969-.602l-1.198.552-.216-.13a6.62 6.62 0 00-.706-.368l-.254-.114-.105-1.152c-.07-.782-.74-1.396-1.508-1.396h-1.896zm-2.457 1.31C8.71 4.006 9.774 3 11.052 3h1.896c1.275 0 2.343 1.002 2.457 2.258l.055.606c.112.056.223.114.332.174l.743-.342c1.148-.529 2.555-.114 3.194.987l.948 1.634c.638 1.098.3 2.52-.737 3.245l-.518.363a6.3 6.3 0 010 .15l.518.363c1.034.724 1.376 2.144.737 3.245l-.948 1.634c-.638 1.098-2.044 1.517-3.194.987l-.743-.342c-.11.06-.22.118-.332.174l-.055.606C15.29 19.995 14.226 21 12.948 21h-1.896c-1.275 0-2.343-1.002-2.457-2.258l-.055-.606a7.598 7.598 0 01-.332-.174l-.743.342c-1.148.529-2.555.114-3.194-.987l-.948-1.634c-.638-1.098-.3-2.52.737-3.245l.518-.363a6.518 6.518 0 010-.15l-.518-.363c-1.034-.724-1.376-2.144-.737-3.245l.948-1.634c.638-1.098 2.044-1.517 3.194-.987l.743.342c.11-.06.22-.118.332-.174l.055-.606z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.889a3.111 3.111 0 100 6.222 3.111 3.111 0 000-6.222zM8 12a4 4 0 118 0 4 4 0 01-8 0z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSettingsOutline
