import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMykijiji: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        d="M15.262 12.39v5.826c0 3.204 2.026 6.408 5.21 7.864.29.292.58.874.58 1.166v6.698l-.29.291c-.29 0-.29.292-.579.292l-10.131 2.039c-4.632.582-6.368 1.456-6.368 4.66v3.204h41.104v-3.204c0-3.204-1.737-4.078-6.08-4.952l-10.13-2.039c-.29 0-.29 0-.58-.291l-.289-.291v-6.7c0-.582.29-1.165.869-1.456 3.184-1.457 5.21-4.66 5.21-7.865V12.39"
        fill="currentColor"
      />
      <path
        d="M38.998 33.362l-8.973-1.747V28.41c3.763-2.04 6.079-6.117 6.079-10.195V12.39c0-6.408-5.21-11.651-11.29-11.651h-1.158c-6.367 0-11.288 5.243-11.288 11.651v5.826c0 4.078 2.315 8.156 6.078 10.195v3.204l-8.973 1.747C5.71 34.236.789 35.11.789 41.227v4.66c0 .875.868 1.457 1.447 1.457h44c.867 0 1.446-.582 1.446-1.456v-4.66c0-6.118-4.92-6.992-8.684-7.866z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgMykijiji
