import { z } from 'zod';
import { type ImageJsonLd } from 'next-seo';
import { JSON_LD_SCHEMA } from '../constants';
import { getImageBySize } from '@kijiji/image';

export type ImagesListProps = Pick<ImageJsonLd, 'creditText' | 'contentUrl'> & {
  url: string;
  name: string;
  description?: string | null;
};

const imagesListPropsSchema = z.object({
  contentUrl: z.string().url().optional(),
  creditText: z.string(),
  url: z.string().url(),
  name: z.string(),
  description: z.string().optional().nullable(),
});

const listingSchema = z.object({
  imageUrls: z.array(z.string().url().optional()),
  url: z.string().url(),
  title: z.string(),
  description: z.string().optional().nullable(),
});

export const imageSeoJsonLdPropsSchema = z.object({
  listings: z.array(listingSchema),
  platformName: z.string(),
});

export type ImageSeoJsonLdProps = z.infer<typeof imageSeoJsonLdPropsSchema>;


/**
 * Transforms an array of images to schema.org ImageObject format.
 * This function is used to generate structured data for images, which can improve
 * search engine understanding and representation of image content.
 *
 * @see https://developers.google.com/search/docs/appearance/structured-data/image-license-metadata#structured-data
 *
 * @param images - An array of image objects containing properties like contentUrl, url, name, description, and creditText.
 * @returns An array of transformed image objects in schema.org ImageObject format.
 * @throws Will throw an error if any image object doesn't match the expected schema.
 *
 * @example
 * const images = [
 *   {
 *     contentUrl: 'https://example.com/image1.jpg',
 *     url: 'https://example.com/1',
 *     name: "Image 1 Title",
 *     description: "Description of Image 1",
 *     creditText: "Photographer 1"
 *   },
 *   {
 *     contentUrl: 'https://example.com/image2.jpg',
 *     url: 'https://example.com/2',
 *     name: "Image 2 Title",
 *     description: "Description of Image 2",
 *     creditText: "Photographer 2"
 *   }
 * ];
 * const result = imageSeo(images);
 *
 * // result will be:
 * [
 *   {
 *     "@context": "https://schema.org",
 *     "@type": "ImageObject",
 *     "contentUrl": "https://example.com/image1.jpg",
 *     "url": "https://example.com/1",
 *     "mainEntityOfPage": "https://example.com/1",
 *     "name": "Image 1 Title",
 *     "description": "Description of Image 1",
 *     "creditText": "Photographer 1"
 *   },
 *   {
 *     "@context": "https://schema.org",
 *     "@type": "ImageObject",
 *     "contentUrl": "https://example.com/image2.jpg",
 *     "url": "https://example.com/2",
 *     "mainEntityOfPage": "https://example.com/2",
 *     "name": "Image 2 Title",
 *     "description": "Description of Image 2",
 *     "creditText": "Photographer 2"
 *   }
 * ]
 */
export const imageSeo = (images: ImagesListProps[]) => {
  images.forEach(image => {
    imagesListPropsSchema.parse(image);
  });

  return images
    .filter(image => image.contentUrl)
    .map(image => ({
      "@context": JSON_LD_SCHEMA,
      "@type": "ImageObject",
      "contentUrl": getImageBySize(image.contentUrl!, 960),
      "creditText": image.creditText,
      "url": image.url,
      "mainEntityOfPage": image.url,
      "name": image.name,
      ...(image.description && { description: image.description }),
    }));
};

/**
 * Generates ImageObject structured data for a list of listings.
 *
 * @param props - Object containing listings and platform name.
 * @param props.listings - Array of listing objects with image URLs, title, and description.
 * @param props.platformName - Name of the platform to be used as credit text.
 * @returns An array of ImageObject structured data, or null if no valid images are found.
 */
export const imageSeoJsonLd = ({ listings, platformName }: ImageSeoJsonLdProps) => {
  const imagesList: ImagesListProps[] = listings
    .filter((listing) => listing.imageUrls[0] && listing.imageUrls[0].trim() !== '')
    .map((listing) => ({
      contentUrl: listing.imageUrls[0]!,
      creditText: platformName,
      url: listing.url,
      name: listing.title,
      ...(listing.description && { description: listing.description }),
    }));

  if (imagesList.length === 0) {
    return null;
  }

  return imageSeo(imagesList);
};
