import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgConditionDamaged: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <path
          d="M11.978 13.828h-.835c-.386 0-.643-.266-.643-.663V7.663c0-.398.257-.663.643-.663h.835c.386 0 .643.265.643.663v5.502a.654.654 0 01-.643.663zM11.593 17.672c.568 0 1.028-.475 1.028-1.06 0-.586-.46-1.061-1.028-1.061-.568 0-1.028.475-1.028 1.06 0 .586.46 1.061 1.028 1.061z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        />
        <circle cx={11.5} cy={12.5} r={9} />
      </g>
    </Icon>
  )
}
export default SvgConditionDamaged
