import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAge: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.453 5.883l.73 1.311m.875 10.267V19.5h7.81v-2.039c0-1.238-.948-2.184-2.19-2.184h-3.43c-1.168-.073-2.19.946-2.19 2.184zM12 14.04c1.387 0 2.555-1.165 2.555-2.549 0-1.383-1.168-2.548-2.555-2.548-1.387 0-2.555 1.165-2.555 2.548.073 1.384 1.168 2.549 2.555 2.549zm-6.423.291a1.96 1.96 0 001.97-1.966 1.96 1.96 0 00-1.97-1.966 1.96 1.96 0 00-1.971 1.966 1.96 1.96 0 001.97 1.966zm12.846 0a1.96 1.96 0 001.971-1.966 1.96 1.96 0 00-1.97-1.966 1.96 1.96 0 00-1.971 1.966 1.96 1.96 0 001.97 1.966zM3.752 15.422c-.95 0-1.752.801-1.752 1.748v1.602h4.234v-1.31c0-.802.365-1.53.948-2.04h-3.43zm16.496 0h-3.43c.583.51.948 1.238.948 2.04v1.31H22V17.17c0-.947-.73-1.748-1.752-1.748zM12 4.5v1.456V4.5zm2.774.728l-.146.583.146-.583zm2.773.655l-.73 1.311.73-1.31zm-8.32-.655l.218.583-.219-.583z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAge
