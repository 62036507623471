import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgItemType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.525 10.831l1.889-.998c.493-.307.739-.921.41-1.382l-.082-.077c-.328-.46-.985-.69-1.478-.384l-1.888.999c-.493.307-.74.92-.411 1.381m4.68 5.605l1.89-.998c.492-.307.738-.921.41-1.382l-.082-.077c-.329-.46-.986-.614-1.56-.307l-1.89.998c-.41.23-.738.922-.41 1.382l.082.077m1.725-.844c.246.23.492.46.821.614m-4.024-5.682c.247.23.493.461.822.614l-.822-.614z"
        stroke="currentColor"
        strokeWidth={0.821}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.549 10.6c1.314 2.228 2.546 4.377 4.024 6.604.82 1.151.41 2.687-.822 3.455-1.313.69-2.956.307-3.695-.922-.41-.614-.739-1.228-1.15-1.919m1.643-7.217l3.038-4.837c.329-.46.493-1.075.41-1.612V3l-.985.537c-.574.23-.985.615-1.313 1.152l-2.382 3.839m1.232 2.073l-6.405 10.365-1.807-.998 4.024-6.526 3.038-4.914m-.082-.077l-2.463-4.146c-.74-1.151-2.382-1.612-3.695-.921-1.232.768-1.643 2.226-.822 3.455a275.55 275.55 0 013.942 6.526l3.038-4.914z"
        stroke="currentColor"
        strokeWidth={0.821}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.575 6.301v-2.84c.082-.23.246-.307.41-.307h.411c.164 0 .41.076.41.307v1.228m.657 1.612H3M5.463 19.2H3M4.232 6.455v14.357m8.868-4.606l-1.806-.998 1.724-2.841 1.807.998-1.725 2.84z"
        stroke="currentColor"
        strokeWidth={0.821}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgItemType
