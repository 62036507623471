import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosNavigation: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.48 10.81l8.33-8.33c.64-.64 1.74-.64 2.38 0l8.33 8.33c.64.64.64 1.74 0 2.38l-8.33 8.33c-.64.64-1.74.64-2.38 0l-8.33-8.33c-.64-.64-.64-1.648 0-2.38z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.254 15.661V10.17h5.492"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.373 8.156l2.014 1.922-2.014 2.013"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosNavigation
