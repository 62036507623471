import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosNew: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.31 11.571l1.127-3.357A1.738 1.738 0 018.057 7h3.38M18.69 11.571l-.422-1.428M16.296 3v1.643M16.296 7.428v1.643M14.042 6.072h.916M17.704 6.072h.916M2.493 9.572h1.408c.775 0 1.409.642 1.409 1.428v.929H2.493a.485.485 0 01-.493-.5v-1.286c0-.357.211-.571.493-.571zM21.507 9.572H20.1c-.775 0-1.409.642-1.409 1.428v.929h2.817a.485.485 0 00.493-.5v-1.286c0-.357-.211-.571-.493-.571zM18.479 16.857H5.52M18.479 14.357h-.916c-.774 0-1.338.643-1.338 1.357v.572M3.409 14.357h3.098c.775 0 1.338.643 1.338 1.357v.572"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.592 18.929H3.409v-5.143c0-1.072.845-1.929 1.901-1.929 0 0 2.324.715 6.62.715s6.83-.715 6.83-.715c1.057 0 1.902.857 1.902 1.929l-.07 5.143z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.873 21h-1.9a.56.56 0 01-.564-.572v-1.5h2.957v1.5c.07.286-.21.572-.493.572zM20.17 21h-1.902a.56.56 0 01-.564-.572v-1.5h2.958v1.5c.07.286-.211.572-.493.572z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosNew
