import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgJobOfferedBy: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.174 11.833c1.565-.666 2.609-2.25 2.609-3.916V4.833c0-2.416-2-4.333-4.522-4.333h-.435C9.304.5 7.304 2.417 7.304 4.833V8c0 1.667 1.044 3.167 2.609 3.917v2.75c-4.435.25-7.913 3.75-7.913 8v.833h20v-.833c0-4.25-3.478-7.75-7.913-8"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.087 15.25l4 3.833 4-3.833m-1.478 5.416h3.13"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgJobOfferedBy
