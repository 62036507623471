import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVacationRentalsBackground: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#vacation-rentals-background_svg__clip0_235_643)">
        <path
          d="M24 48.026c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z"
          fill="#F3F3F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.736 34.032h5.663a.625.625 0 110 1.25h-9.375a6.24 6.24 0 01-5.114-2.678.613.613 0 01-.163-.258 6.193 6.193 0 01-.973-3.314 6.238 6.238 0 0110.568-4.503l1.177-3.756a4.373 4.373 0 00-2.833.448.105.105 0 01-.028.011.12.12 0 00-.024.01.61.61 0 01-.138.056l-.155.028a.676.676 0 01-.114-.013l-.009-.003H21.2a.596.596 0 01-.09-.042.51.51 0 01-.29-.233l-.01-.01a5.058 5.058 0 00-2.898-2.034 5.238 5.238 0 00-3.566.339.626.626 0 01-.83-.884 12.469 12.469 0 0113.695-5.331l.03.01.027.008.006.002a12.418 12.418 0 017.538 5.736.625.625 0 01-1.078.634 11.167 11.167 0 00-5.002-4.516c.885 2.1 1.128 4.416.696 6.654a6.47 6.47 0 013.31-.048 6.944 6.944 0 013.673 2.578.625.625 0 01-1.034.703 5.762 5.762 0 00-2.956-2.073 5.503 5.503 0 00-3.54.358.619.619 0 01-.268.064.02.02 0 00-.008.003.019.019 0 01-.01.003.622.622 0 01-.161-.021l-.015-.008-.016-.002a.606.606 0 01-.1-.055.635.635 0 01-.101-.056.61.61 0 01-.074-.085.623.623 0 01-.085-.108l-.005-.007a4.418 4.418 0 00-2.306-1.803l-1.423 4.539a.064.064 0 01-.017.025c-.006.006-.013.013-.016.022a6.176 6.176 0 01-1.54 8.36zm-7.03-16.4a6.446 6.446 0 012.523.152c1.066.279 2.04.83 2.828 1.6a10.31 10.31 0 014.181-5.36 11.212 11.212 0 00-9.531 3.609zm8.394-1.01a8.973 8.973 0 00-1.808 3.029 6.282 6.282 0 013.191.038l.024.008h.004l.004.002a6.282 6.282 0 012.712 1.476c.237-1.703.354-4.728-1.29-6.652A8.971 8.971 0 0024.1 16.62zm-1.844 13.096a8.516 8.516 0 01-.597 3.545 4.963 4.963 0 001.781-6.566 4.769 4.769 0 00-2.103-.456c.55 1.08.864 2.266.92 3.477zm-2.993-5.672c.445.285.855.622 1.222 1.004a6.702 6.702 0 011.52-.02 5.002 5.002 0 00-2.742-.984zm-.26 1.346a9.186 9.186 0 00-1.756-1.019 4.943 4.943 0 00-3.097 5.74 7.434 7.434 0 013.482-4.13c.439-.236.897-.434 1.37-.59zm-2.975 3.739a6.503 6.503 0 00-1.043 2.822 4.967 4.967 0 005.037 1.978c.473-.965 1.956-4.526-.056-7.515a7.048 7.048 0 00-1.736.665 6.504 6.504 0 00-2.202 2.05zm11.746-.1H35.9a.625.625 0 110 1.25h-8.125a.625.625 0 010-1.25z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="vacation-rentals-background_svg__clip0_235_643">
          <path fill="#fff" transform="translate(0 .026)" d="M0 0h48.004v48.004H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgVacationRentalsBackground
