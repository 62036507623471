import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgProfileType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.486 5.597L3.472 12.29a2.9 2.9 0 00-.001 4.133L7.79 20.7a2.444 2.444 0 003.428 0l9.31-9.203a2.903 2.903 0 00.852-2.34l-.563-5.98-5.483-.577"
        fill="#F3F3F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.505 22a3.038 3.038 0 01-2.143-.877l-4.32-4.274A3.482 3.482 0 012 14.357c0-.94.37-1.824 1.043-2.49l7.02-6.701a.611.611 0 01.858.015.595.595 0 01-.015.848l-7.014 6.694a2.308 2.308 0 00.006 3.278l4.32 4.275c.344.34.8.526 1.287.526.485 0 .942-.187 1.286-.526l9.309-9.204c.495-.49.743-1.168.677-1.86l-.517-5.491-4.99-.525a.6.6 0 11.127-1.192l5.484.577c.288.03.513.255.54.54l.564 5.981a3.493 3.493 0 01-1.028 2.818l-9.309 9.204A3.042 3.042 0 019.505 22z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.688 8.243a1.224 1.224 0 001.715 0 1.19 1.19 0 000-1.695 1.224 1.224 0 00-1.715-.001 1.193 1.193 0 000 1.696zm.859 1.55a2.43 2.43 0 01-1.716-.702 2.385 2.385 0 010-3.392 2.448 2.448 0 013.43.001 2.385 2.385 0 010 3.392c-.473.467-1.094.7-1.714.7z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgProfileType
