import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTabletBrand: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.597 4h10.808c.559 0 .993.421.993.962v14.076c0 .54-.434.962-.993.962H6.597c-.559 0-.993-.421-.993-.962V4.962c0-.54.434-.962.993-.962zM5.725 17.354H15.91M8.023 6.707h10.186H8.023z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.938 19.278c.343 0 .622-.27.622-.601a.612.612 0 00-.622-.602.612.612 0 00-.62.602c0 .332.277.601.62.601z"
        fill="#3E4153"
      />
      <path
        d="M14.236 11.159l-2.298 4.57M3.056 8.573c.248.12.435.24.683.3l-.683-.3zM2 11.399c.373.24.807.361 1.304.421L2 11.4zm.87 3.61c.248-.181.559-.302.87-.362l-.87.361zm18.074-6.437c-.248.12-.435.24-.683.3l.683-.3zM22 11.399c-.373.24-.808.361-1.304.421L22 11.4zm-.87 3.61c-.248-.181-.559-.302-.87-.362l.87.361zM11.938 8.27l-2.236 4.572 2.236-4.572zm1.18 1.564l-2.298 4.572 2.298-4.572z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgTabletBrand
