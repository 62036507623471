import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { BodyText } from '@/ui/atoms/body-text'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'
import { ListingImage } from '@/ui/atoms/listing-image'
import { AutosListingImage } from '@/ui/atoms/listing-image/AutosListingImage'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

type ListingCardLinkProps = {
  variant?: 'default' | 'grey'
}
// eslint-disable-next-line @kijiji/prefer-link-custom
export const ListingCardLink = styled.a<ListingCardLinkProps>(
  ({ theme, variant }) => `
  text-decoration: initial;
  color: ${variant === 'grey' ? theme.colors.grey.primary : theme.colors.purple.primary};
  text-decoration: underline;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${zIndexRegistry.CARD};
  }

  &:visited {
    color: ${theme.colors.violet.primary};
  }
`
)

export const BaseListingCardContainer = styled.section(
  ({ theme }) => `
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.medium};
    border: .1rem solid ${theme.colors.grey.light4};
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.defaultSmall};
    position: relative;
    width: 100%;


    &:hover {
      filter: drop-shadow(0 .2rem ${theme.spacing.mini} rgba(0, 0, 0, 0.2));

      & ${ListingCardHeading} {
        color: ${theme.colors.purple.primary};
        text-decoration: underline;
      }

      & ${ListingCardLink} {
        color: ${theme.colors.purple.primary};
      }
    } 
  `
)

export const ListingCardContainer = styled(BaseListingCardContainer)(
  ({ theme }) => `
  ${MEDIA_QUERIES(theme).medium}{
    gap: ${theme.spacing.default};
    padding: ${theme.spacing.default};
  }
`
)

export const AutosListingCardContainer = styled(BaseListingCardContainer)(
  ({ theme }) => `  
  ${MEDIA_QUERIES(theme).medium}{
    padding: ${theme.spacing.default};
  }
  
  &:hover {
    & ${ListingCardHeading} {
      text-decoration: none;
    }
  } 
`
)

type ListingCardTopDataProps = {
  isHighlight?: boolean
  marginBottom?: keyof ThemeProps['spacing']
}

export const ListingImageBase = styled(ListingImage)`
  flex-shrink: 0;

  & > div {
    z-index: ${zIndexRegistry.FEATURED_LISTING_BADGE};
  }
`

export const AutosListingImageBase = styled(AutosListingImage)`
  flex-shrink: 0;

  & > div {
    z-index: ${zIndexRegistry.FEATURED_LISTING_BADGE};
  }
`

const topDataHighlightStyle = (theme: ThemeProps) => `
  display: flex;
  flex-direction: column;
  width: 100%;

  /* Update image size for highlight */
  & ${ListingImageBase} {
    height: 20.8rem;
    margin-bottom: ${theme.spacing.default};
    width: 100%;

  }

  ${MEDIA_QUERIES(theme).medium}{
    & ${ListingImageBase} {
      height: 24rem;
      margin-bottom: 0;
      width: 24rem;
    }
  }
`

const autosTopDataHighlightStyle = (theme: ThemeProps) => `
  display: flex;
  width: 100%;
  gap: ${theme.spacing.default};

  /* Update image size for highlight */
  & ${ListingImageBase} {
    height: 20.8rem;
    margin-bottom: ${theme.spacing.default};
    width: 100%;
  }
  ${MEDIA_QUERIES(theme).medium}{
    & ${ListingImageBase} {
      height: 24rem;
      margin-bottom: 0;
      width: 24rem;
    }
  }`

const topDataStyle = (theme: ThemeProps) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.default};
  width: 100%;
`

export const ListingCardTopData = styled.div<ListingCardTopDataProps>(
  ({ theme, isHighlight, marginBottom }) => `
    ${isHighlight ? topDataHighlightStyle(theme) : topDataStyle(theme)}
    ${marginBottom ? `margin-bottom: ${theme.spacing[marginBottom]};` : ''}

  // Position favourite button in card
  & > span {
    display: flex;
    justify-content: space-between;
  }

  ${MEDIA_QUERIES(theme).medium}{
    ${topDataStyle(theme)}
  }
`
)

export const AutosListingCardTopData = styled.div<ListingCardTopDataProps>(
  ({ theme, isHighlight, marginBottom }) => `
  ${isHighlight ? autosTopDataHighlightStyle(theme) : topDataStyle(theme)}
  ${marginBottom ? `margin-bottom: ${theme.spacing[marginBottom]};` : ''}

  // Position favourite button in card
  & > span {
    display: flex;
    justify-content: space-between;
  }

  ${MEDIA_QUERIES(theme).medium}{
    ${topDataStyle(theme)}
  }
`
)

export const ListingCardBody = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.mini};
  min-width: 0;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium}{
    gap: ${theme.spacing.defaultSmall};
  }
`
)

export const ListingCardDetails = styled(Flex)(
  () => `
  min-width: 0;
  width: 100%;
`
)

const BaseListingCardLogoContainer = styled.div(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.small};
  position: relative;
  `
)

export const ListingCardLogoContainer = styled(BaseListingCardLogoContainer)(
  ({ theme }) => `
  height: 4.5rem;
  width: 10.4rem;

  ${MEDIA_QUERIES(theme).medium}{
    height: 4.5rem;
    width: 9rem;
  }
`
)

export const AutosListingCardLogoContainer = styled(BaseListingCardLogoContainer)(
  ({ theme }) => `
  height: ${theme.spacing.large};
  width: 4.8rem;

  ${MEDIA_QUERIES(theme).medium}{
    height: 2.8rem;
    width: 6.8rem;
  }
`
)

export const ListingCardHeading = styled(BodyText)(
  ({ theme }) => `
  padding: 0.2rem; /* To make the a11y selectable border box visible  */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body.large)};
    text-overflow: ellipsis;
    white-space: nowrap;
    display: revert;
  }
`
)

export const AutosListingCardHeading = styled(ListingCardHeading)(
  ({ theme }) => `
  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.headline.medium)};
  }


  ${MEDIA_QUERIES(theme).large}{
    ${styleFromTypography(theme.typography.headline.large)};
  }
`
)

export const ListingCardDescription = styled(BodyText)(
  ({ theme }) => `
  display: none;

  ${MEDIA_QUERIES(theme).medium} {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
)

export const ListingCardWithDescription = styled(BodyText)`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const ListingRightSide = styled.div(
  ({ theme }) => `
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${theme.spacing.defaultSmall};
`
)

export const ListingBadge = styled(BodyText)<{ bgColor: string }>(
  ({ theme, bgColor }) => `
  color: ${theme.colors.white};
  background-color: ${bgColor};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing.mini} ${theme.spacing.default};
`
)

export const ShippingBadge = styled(BodyText)(
  ({ theme }) => `
  ${styleFromTypography(theme.typography.body['xSmall'])}
  color: ${theme.colors.grey.light1};
  display: flex;
  gap: 0.5rem;

  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body['small'])}
  }
`
)

export const AutosCrossPromoListingBottomBarWrapper = styled(Flex)(
  ({ theme }) => `
  margin-top: ${theme.spacing.defaultSmall};
  padding-top: ${theme.spacing.defaultSmall};
  border-top: 0.1rem solid ${theme.colors.grey.light4};
`
)

//On mobile, the bottom bar should always have a min-height of 2.4rem
export const AutosListingCardBottomData = styled(Flex)(
  ({ theme }) => `
  position: relative;
  min-height: ${theme.spacing.large};

  ${MEDIA_QUERIES(theme).medium}{
    min-height: unset;
  }
`
)

type AutosListingCardDividerProps = {
  showDivider: boolean
}

export const AutosListingCardDivider = styled(Divider)<AutosListingCardDividerProps>(
  ({ theme, showDivider }) => `

  ${MEDIA_QUERIES(theme).medium}{
    display: ${showDivider ? 'block' : 'none'}
  }
`
)

//TODO: Build a simple icon button component
// eslint-disable-next-line @kijiji/prefer-button-component
export const ExpandDescriptionButton = styled.button(
  ({ theme }) => `
  align-items: center;
  border-left: solid 0.1rem ${theme.colors.grey.light4};
  bottom: -${theme.spacing.mini};
  right: -${theme.spacing.defaultSmall};
  display: flex;
  height: ${theme.spacing.large};
  justify-content: center;
  position: absolute;
  width: ${theme.spacing.husky};
  z-index: ${zIndexRegistry.CARD_ELEMENTS};
  background-color: ${theme.colors.white};

  ${MEDIA_QUERIES(theme).medium}{
    right: -${theme.spacing.default};
    height: ${theme.spacing.xLarge};
    bottom: -${theme.spacing.defaultSmall};
  }

  > svg {
    margin: 0;
  }
`
)

export const AutosCardDescriptionContainer = styled.div<{ showDescription: boolean }>(
  ({ theme, showDescription }) => `
  display: ${showDescription ? 'block' : 'none'};
  width: 100%;
  padding-top: ${theme.spacing.default};
  `
)
