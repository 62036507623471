import { CommonInputProps } from '@/ui/typings/commonTextInput'

import { RadioButtonContainer, RadioInput, RadioLabel } from './styled'

export type RadioButtonProps = {
  /**
   * Label
   */
  label: string
} & Omit<CommonInputProps, 'maxLength'>

/**
 * @description Simple radio component
 */
export const RadioButton = ({
  errorId,
  id,
  label,
  ...rest
}: RadioButtonProps) => {
  return (
    <RadioButtonContainer>
      <RadioInput
        aria-describedby={errorId}
        aria-invalid={!!errorId}
        data-testid={`radio-input-${id}`}
        id={id}
        type="radio"
        {...rest}
      />
      <RadioLabel htmlFor={id}>{label}</RadioLabel>
    </RadioButtonContainer>
  )
}
