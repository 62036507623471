import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgEmail: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.987 5.345l.002-.002-.002-.002A1.47 1.47 0 0020.527 4H3.473a1.47 1.47 0 00-1.46 1.34l-.003.003.002.002c-.004.043-.013.084-.013.129v13.052A1.475 1.475 0 003.474 20h17.052A1.476 1.476 0 0022 18.526V5.474c0-.045-.01-.086-.013-.13zm-11.257 9.25a1.95 1.95 0 001.253.453c.488-.003.96-.178 1.33-.495l1.743-1.85L20.25 19H3.741l5.266-6.234 1.723 1.828zm5.018-2.626L21 6.393v11.946l-5.252-6.37zM20.526 5a.466.466 0 01.303.117l-8.199 8.709a.98.98 0 01-1.216.041l-8.242-8.75A.466.466 0 013.474 5h17.052zm-12.21 7.034L3 18.327V6.392l5.317 5.642z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgEmail
