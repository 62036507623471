import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCurbsidePickup: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.729 13.359a1.573 1.573 0 00-1.006-1.477c-.12-.06-1.22-.53-1.71-.738l-.883-3.702a2.881 2.881 0 00-2.844-1.978H10.51a.501.501 0 00-.5.5V11H9.008V3.5a.499.499 0 00-.5-.5H3.502a.5.5 0 00-.5.5V11H2.5a.502.502 0 00-.501.505l.06 6.062a1.44 1.44 0 001.442 1.44h2.457a2.53 2.53 0 002.476 1.979A2.536 2.536 0 0010.906 19h2.542a.501.501 0 000-1h-2.523a2.529 2.529 0 00-2.497-2.086 2.534 2.534 0 00-2.49 2.093H3.501a.442.442 0 01-.44-.445L3.005 12h7.504a.501.501 0 00.5-.5V6.464h4.276a1.896 1.896 0 011.887 1.265l.925 3.891a.5.5 0 00.29.345c.001 0 1.724.732 1.888.812.364.182.386.192.458.685l.001.025h-.691a.501.501 0 000 1h.758L21 17.42a.602.602 0 01-.603.601h-.365l-.011.002a2.536 2.536 0 00-2.694-2.089 2.54 2.54 0 00-2.346 2.472 2.535 2.535 0 002.233 2.575 2.542 2.542 0 002.785-1.966c.011 0 .021.006.033.006h.365A1.612 1.612 0 0022 17.386l-.271-4.027zM8.432 16.914A1.539 1.539 0 019.94 18.75a1.535 1.535 0 01-2.097 1.119 1.537 1.537 0 01-.95-1.42 1.537 1.537 0 011.539-1.535zM4.002 4h2.003v2h-.5a.5.5 0 100 1h1a.5.5 0 00.501-.5V4h1.001v7H4.002V4zM17.52 20a1.547 1.547 0 01-1.427-.953 1.542 1.542 0 011.125-2.104 1.546 1.546 0 011.847 1.514A1.544 1.544 0 0117.519 20z"
        fill="currentColor"
      />
      <path
        d="M12.964 11.505a.5.5 0 00.5.5L16.5 12a.501.501 0 00.482-.633l-.83-3.007a.5.5 0 00-.483-.367h-.001l-2.17.006a.5.5 0 10.002 1h.002l1.787-.005.554 2.007-2.38.003a.5.5 0 00-.499.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgCurbsidePickup
