import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgShield: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 20.486c1.732-.538 3.179-1.601 4.34-3.189a10.653 10.653 0 002.035-5.314H12V3.53L6.004 5.764a.683.683 0 00-.32.248.644.644 0 00-.113.373v5.093c0 .14.018.308.054.505H12v8.503zm0 1.014c-.098 0-.2-.009-.307-.027a1.555 1.555 0 01-.3-.08c-2.11-.808-3.785-2.177-5.028-4.108-1.243-1.931-1.865-4.021-1.865-6.27v-4.61c0-.365.105-.696.316-.993.211-.297.48-.512.809-.646l5.77-2.152A1.77 1.77 0 0112 2.5c.195 0 .397.038.606.114l5.769 2.152c.328.134.598.35.809.646.21.297.316.628.316.993v4.61c0 2.249-.622 4.339-1.865 6.27-1.243 1.931-2.919 3.3-5.027 4.107a1.555 1.555 0 01-.301.081c-.107.018-.21.027-.307.027z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgShield
