import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBackOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.5 18.458s-1.01-3.122-4.242-3.726c-.505-.101-1.111-.101-1.819-.101h-2.222v3.324a.554.554 0 01-.303.503c-.202.101-.404 0-.606-.1l-5.656-6.044c-.203-.201-.203-.604 0-.805l5.555-5.842c.202-.202.404-.202.606-.1.202.1.303.301.303.503v3.122h.404c7.07-.1 7.98 9.166 7.98 9.266z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgBackOutline
