import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosPushStart: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 21.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.36 9.323h3.281c.518 0 .864.432.864.863v.173a.865.865 0 01-.864.864h-3.282a.865.865 0 01-.863-.864v-.173c0-.431.431-.863.863-.863z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.59 5.005c-1.295-.432-2.59-.519-3.886-.346-4.059.691-6.822 4.664-6.045 8.723.69 4.059 4.664 6.822 8.723 6.045 4.059-.69 6.822-4.663 6.045-8.723-.173-.863-.518-1.727-.95-2.59"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosPushStart
