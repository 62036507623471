import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBrowse: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="browse_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={20}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm9.048-10a9.048 9.048 0 11-18.095 0 9.048 9.048 0 0118.095 0zm-9.049-6.19c.263 0 .476-.427.476-.953s-.213-.952-.476-.952-.476.426-.476.952.213.953.476.953zm0 6.666a.476.476 0 100-.952.476.476 0 000 .952zM20.095 12c0 .263-.427.476-.953.476s-.952-.213-.952-.476.426-.476.952-.476.953.213.953.476zm-8.096 8.095c.263 0 .476-.426.476-.952s-.213-.953-.476-.953-.476.427-.476.953.213.952.476.952zM5.81 12c0 .263-.426.476-.952.476s-.953-.213-.953-.476.427-.476.953-.476.952.213.952.476zm3.865.528l-2.75-5.16c-.379-.709-.135-.98.545-.602l4.942 2.74c.68.376 1.538 1.256 1.916 1.967l2.75 5.159c.38.71.136.98-.545.602l-4.942-2.74c-.68-.376-1.537-1.256-1.916-1.966zm-1.08-4.05l1.92 3.602c.293.549 1.02 1.295 1.537 1.582l3.355 1.86-1.92-3.601c-.293-.55-1.02-1.295-1.537-1.582l-3.355-1.86z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#browse_svg__a)">
        <path fill="currentColor" d="M1.047 1.048h22.857v22.857H1.047z" />
      </g>
    </Icon>
  )
}
export default SvgBrowse
