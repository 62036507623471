import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCloseCircle2: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12zm19.091 0a9.09 9.09 0 11-18.182 0 9.09 9.09 0 0118.182 0zm-5.877-4.5A.91.91 0 0116.5 8.786L13.286 12l3.214 3.214a.91.91 0 01-1.286 1.286L12 13.286 8.786 16.5A.91.91 0 017.5 15.214L10.714 12 7.5 8.786A.91.91 0 018.786 7.5L12 10.714 15.214 7.5z"
        fill="#757575"
      />
      <mask
        id="close-circle-2_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={20}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12zm19.091 0a9.09 9.09 0 11-18.182 0 9.09 9.09 0 0118.182 0zm-5.877-4.5A.91.91 0 0116.5 8.786L13.286 12l3.214 3.214a.91.91 0 01-1.286 1.286L12 13.286 8.786 16.5A.91.91 0 017.5 15.214L10.714 12 7.5 8.786A.91.91 0 018.786 7.5L12 10.714 15.214 7.5z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#close-circle-2_svg__a)" fill="currentColor">
        <path d="M1.091 1.09H22.91V22.91H1.091z" />
        <path d="M1.091 1.091h21.818v21.818H1.091z" />
      </g>
    </Icon>
  )
}
export default SvgCloseCircle2
