import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgForSale: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M20.45 4h-5.67a.51.51 0 00-.5.5.5.5 0 00.5.5H20v5.17a3.351 3.351 0 01-.84 2.48l-8 7.9a1.76 1.76 0 01-1.25.51 1.78 1.78 0 01-1.25-.51l-4-4A2.26 2.26 0 014 14.91a2.23 2.23 0 01.66-1.59c0-.05 5.37-5.14 6.56-6.34a.481.481 0 000-.7.5.5 0 00-.71 0C9.28 7.46 4 12.54 3.91 12.6a3.26 3.26 0 000 4.62l4 4a2.7 2.7 0 002 .81 2.771 2.771 0 002-.8l8-7.94A4.32 4.32 0 0021 10.17V4.5a.5.5 0 00-.55-.5z" />
        <path d="M17 10a2 2 0 10-1.41-.58A1.94 1.94 0 0017 10zm-1-2a1 1 0 112 0 1 1 0 01-2 0zM10.86 11.84l.63-.63a1.48 1.48 0 00-2-.19L9 10.5l-.49.5.51.51a1.49 1.49 0 00.13 2.06c.63.64 1.32.53 2.23.09.71-.35 1.09-.42 1.39-.12a.76.76 0 01-.06 1.16.88.88 0 01-1.37 0l-.63.62a1.61 1.61 0 002.28.15l.52.51.49-.48-.56-.5a1.53 1.53 0 00-.09-2.15c-.57-.58-1.18-.58-2.26-.06-.68.3-1 .42-1.37.08a.67.67 0 010-1 .74.74 0 011.14-.03z" />
      </g>
    </Icon>
  )
}
export default SvgForSale
