import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosVan: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.99 12.531c0 .281-.122.875-.73.875h-.426M12.113 11l-.03-2.938M19.443 15.844h1.064c.243 0 .487 0 .73-.063a1.499 1.499 0 001.247-1.687 1.395 1.395 0 00-.213-.594l-.03-.031M7.704 15.844h8.728M22.271 13.469s-.973-.063-.973-.719v-1h.882"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.507 15.844h-.76c-.67 0-1.247-.563-1.247-1.281v-2.094c0-.25.122-.469.304-.594a8.435 8.435 0 011.886-1c.212-.094.365-.219.517-.406l1.946-2.625A2.056 2.056 0 017.795 7h13.411c.578 0 1.035.469 1.035 1.063v5.406M10.684 12.625h.548M13.482 12.625h-.578"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.618 8.063l.03 2.53c0 .407-.334.75-.73.75H5.029M4.997 17c.857 0 1.551-.713 1.551-1.594 0-.88-.694-1.594-1.55-1.594-.857 0-1.552.714-1.552 1.594 0 .88.695 1.594 1.551 1.594z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.997 17c.857 0 1.551-.713 1.551-1.594 0-.88-.694-1.594-1.55-1.594-.857 0-1.552.714-1.552 1.594 0 .88.695 1.594 1.551 1.594zM17.953 17c.856 0 1.55-.713 1.55-1.594 0-.88-.694-1.594-1.55-1.594-.857 0-1.551.714-1.551 1.594 0 .88.694 1.594 1.55 1.594z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.953 17c.856 0 1.55-.713 1.55-1.594 0-.88-.694-1.594-1.55-1.594-.857 0-1.551.714-1.551 1.594 0 .88.694 1.594 1.55 1.594z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosVan
