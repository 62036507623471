import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTentTrailer: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <path d="M8.561 9.25h2.707v2H8.561zM13.658 9.25h1.78v4h-1.78z" strokeLinejoin="round" />
        <circle cx={10.988} cy={14.75} r={1.5} />
      </g>
      <path
        d="M22 13.25a.5.5 0 00-1 0zm-1 3a.5.5 0 001 0zm-18.5-5H2a.5.5 0 00.47.5zm19 .25l.03.5a.5.5 0 00.47-.5zM6.634 16.25a.5.5 0 001 0zm14.1-6.684l.116-.486zm-17.467-.5L3.15 8.58zM21 13.25v1.5h1v-1.5zm0 1.5v1.5h1v-1.5zm.5-.5h-4.17v1h4.17zm-4.17 0h-5.098v1h5.097zm-7.879 0h-.927v1h.927zm-.927 0h-1.39v1h1.39zm-.853-6.5h4.097v-1H7.671zm4.097 0h4.171v-1h-4.17zm4.171 0h.39v-1h-.39zm-9.385.014L3.15 8.58l.233.972 3.404-.816zM2 10.039v1.211h1v-1.211zm5.17 4.248V11.5h-1v2.787zm0-2.787V8.25h-1v3.25zm-4.7.25l4.17.25.06-1-4.17-.25zm14.36-3.5v.5h1v-.5zm.383.986l3.404.816.233-.972-3.404-.816zM21 10.54v.961h1v-.961zM16.83 8.75v3h1v-3zm0 3v3h1v-3zm4.64-.75l-4.17.25.06 1 4.17-.25zM6.634 14.75v1.5h1v-1.5zm13.983-4.698a.5.5 0 01.383.487h1a1.5 1.5 0 00-1.15-1.46zM3.15 8.58A1.5 1.5 0 002 10.039h1a.5.5 0 01.383-.487zm13.18-.83a.5.5 0 01.5.5h1a1.5 1.5 0 00-1.5-1.5zm-8.66-1a1.5 1.5 0 00-1.5 1.5h1a.5.5 0 01.5-.5zm-.536 7.5c.02 0 .037.016.037.037h-1c0 .532.431.963.963.963z"
        fill="#3e4153"
      />
    </Icon>
  )
}
export default SvgTentTrailer
