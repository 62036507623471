import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgJobsBackground: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#jobs-background_svg__clip0_662_281)">
        <path
          d="M24 48.015c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z"
          fill="#F3F3F9"
        />
        <path
          d="M36.492 31.898l-.312-4.174a.632.632 0 00-.67-.577.626.626 0 00-.576.67l.318 4.222a.626.626 0 01-.62.722l-21.159.009a.619.619 0 01-.46-.2.626.626 0 01-.164-.484l.473-7.169 2.547.546v2.307a.625.625 0 00.625.625h2.5a.624.624 0 00.625-.625v-1.46l4.256.824c.09.017.182.015.27-.007l4.224-1.056v1.7a.625.625 0 00.625.624h2.5a.624.624 0 00.625-.625v-2.42l3.864-.715a.625.625 0 00.508-.682l-.629-5.839a1.87 1.87 0 00-1.858-1.594h-5.026c.002-.028.016-.052.016-.08V14.1a1.332 1.332 0 00-1.33-1.33h-7.34a1.332 1.332 0 00-1.33 1.33v2.339c0 .028.015.052.017.08h-5.017a1.867 1.867 0 00-1.852 1.612l-.644 5.82a.626.626 0 00.49.68l.098.02-.484 7.344a1.876 1.876 0 001.87 2.024h.002l21.158-.009a1.873 1.873 0 001.424-.655 1.84 1.84 0 00.436-1.458zM18.37 27.145h-1.25v-3.75h1.25v3.75zm12.5 0h-1.25v-3.75h1.25v3.75zM20.244 16.44l.08-2.42 7.42.081v2.339a.082.082 0 01-.023.057.08.08 0 01-.057.023l-7.42-.08zm-6.863 1.857a.617.617 0 01.613-.527h20.01a.621.621 0 01.619.506l.563 5.236-3.067.567V22.77a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625v2.013l-4.392 1.098-4.358-.844V22.77a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625v1.415l-3.066-.657.578-5.231z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="jobs-background_svg__clip0_662_281">
          <path fill="#fff" transform="translate(0 .015)" d="M0 0h48.004v48.004H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgJobsBackground
