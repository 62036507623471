import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWhatsappSmall: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 8.04c0 1.5.4 3 1.2 4.3l.2.3-.8 2.9 3-.8.3.2c3.8 2.3 8.7 1 11-2.8 2.3-3.8 1-8.7-2.8-11-3.8-2.3-8.7-1-11 2.8C.4 5.14 0 6.54 0 8.04zm3.9-4.2c.2-.2.4-.3.6-.3H5s.4 0 .5.4c.2.5.7 1.7.7 1.8.1.1.1.3 0 .4-.1.1-.1.3-.2.4l-.4.4c-.1.1-.2.3-.1.5.4.6.8 1.2 1.3 1.7.6.5 1.2.9 1.9 1.2.2.1.4.1.5-.1.2-.2.6-.7.8-.9.2-.2.3-.2.5-.1l1.6.8c.2.1.4.2.5.3.1.4 0 .8-.1 1.1-.2.6-1.2 1.1-1.6 1.1-.4 0-.9.2-2.9-.6-2.4-1-4-3.5-4.1-3.6-.1-.2-1-1.3-1-2.5.2-.7.5-1.4 1-2z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgWhatsappSmall
