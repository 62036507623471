import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconCategoryRealEstate: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.004 48.004" {...props}>
      <circle cx={24} cy={24} r={24} fill="currentColor" />
      <path
        fill="#373373"
        d="M33.424 26.556h-.002a.625.625 0 00-.625.624l-.017 5.576-4.968-.001v-4.726a3.75 3.75 0 00-7.5 0v4.724h-5.05l.028-5.569a.625.625 0 00-.622-.628h-.003a.625.625 0 00-.625.622l-.03 6.196a.625.625 0 00.625.628l18.768.004a.625.625 0 00.625-.623l.019-6.2a.625.625 0 00-.623-.627zM21.562 28.03a2.5 2.5 0 015 0v4.723h-5z"
      />
      <path
        fill="#373373"
        d="M36.302 23.854L24.456 12.971a.625.625 0 00-.845 0l-4.55 4.18v-2.524a.625.625 0 00-.624-.625h-3.75a.625.625 0 00-.625.625v7.031a.58.58 0 00.014.072l-2.312 2.124a.625.625 0 00.846.92L24.034 14.28l11.422 10.495a.625.625 0 00.846-.92zm-20.99-8.602h2.5v2.969a.592.592 0 00.013.065l-2.513 2.309z"
      />
      <path
        fill="#373373"
        d="M26.64 19.705a2.578 2.578 0 10-2.578 2.578 2.581 2.581 0 002.578-2.578zm-3.907 0a1.328 1.328 0 111.329 1.328 1.33 1.33 0 01-1.329-1.328z"
      />
    </Icon>
  )
}
export default SvgIconCategoryRealEstate
