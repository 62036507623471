import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBaths: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.607 9.454c0 .26.202.47.45.47.25 0 .451-.21.451-.47a.461.461 0 00-.45-.47.46.46 0 00-.451.47zM11.165 8.579c0 .259.202.47.452.47a.46.46 0 00.45-.47.46.46 0 00-.45-.47.461.461 0 00-.452.47zM13.035 8.109a.46.46 0 00.45-.47.46.46 0 00-.45-.47.46.46 0 00-.451.47c0 .26.202.47.45.47z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.435 17.96H8.596c-2.337 0-3.787-1.008-3.787-2.63v-1.892c0-.27.3-.5.654-.5h12.105c.355 0 .654.23.654.5v.69H8.021a.49.49 0 00-.48.5c0 .275.215.5.48.5h10.201v.202c0 1.622-1.45 2.63-3.787 2.63zm-2.94-11.954l-2.01 1.197c-.06-.508.17-1.028.621-1.297a1.213 1.213 0 011.388.1zm6.073 5.932H7.376c0-.01.006-.02.006-.03V5.836c0-.747.33-1.125.98-1.125.298 0 .695.073.884.62a2.378 2.378 0 00-.439 2.852.486.486 0 00.417.252.47.47 0 00.238-.066l3.009-1.792a.515.515 0 00.178-.682c-.524-.955-1.609-1.369-2.578-1.054-.308-.714-.898-1.13-1.708-1.13-1.433 0-1.941 1.144-1.941 2.125v6.072c0 .01.005.02.006.03h-.965c-.89 0-1.614.673-1.614 1.5v1.892c0 1.265.713 2.688 2.573 3.31v1.191c0 .276.215.5.48.5a.49.49 0 00.48-.5v-.96a8 8 0 001.214.088h5.839c.44 0 .843-.03 1.214-.088v.96c0 .276.215.5.48.5a.49.49 0 00.48-.5V18.64c1.86-.62 2.573-2.044 2.573-3.309v-1.892c0-.827-.724-1.5-1.614-1.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgBaths
