import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgEdit: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.3 15.7L1 16l.8-5.9c0-.2.1-.5.3-.6l8.2-8.2c.4-.4 1.1-.4 1.5 0l3.9 3.9c.4.4.4 1.1 0 1.5L7 15.4c-.2.2-.5.3-.7.3zM2.1 10l2.7 2.7M9.1 2.4l5.5 5.5"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgEdit
