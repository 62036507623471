import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVeteranFriendly: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 5.7c2.2 0 2.7 2.3 0 6.2C9.3 8 9.9 5.7 12 5.7zm6 12.9c-1.9-1.8-3.5-3.4-4.6-4.8C17.6 7.8 16 4 12 4s-5.6 3.8-1.4 9.8c-1.2 1.4-2.7 3-4.6 4.8L7.6 20c1.8-1.5 3.2-3 4.4-4.4 1.2 1.4 2.6 2.9 4.4 4.4l1.6-1.4z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgVeteranFriendly
