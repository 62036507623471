import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosPickup: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.694 14.036h1.89c.532-.058.916-.462.916-.982v-1.848c0-.173-.118-.289-.295-.289h-8.89V8.635a.648.648 0 00-.65-.635H9.829c-.354 0-.68.144-.945.375l-1.743 1.907a1.89 1.89 0 01-.945.433c-.266.058-2.185.173-3.19.26-.59.058-1.004.52-1.004 1.097v.693H1.5v.578c0 .433.295.78.738.866l1.3.203M12.162 11.986h.473"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.044 16c.816 0 1.477-.647 1.477-1.444 0-.798-.661-1.444-1.477-1.444-.815 0-1.476.646-1.476 1.444 0 .797.66 1.444 1.476 1.444z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.044 16c.816 0 1.477-.647 1.477-1.444 0-.798-.661-1.444-1.477-1.444-.815 0-1.476.646-1.476 1.444 0 .797.66 1.444 1.476 1.444zM17.006 16c.816 0 1.477-.647 1.477-1.444 0-.798-.661-1.444-1.477-1.444-.815 0-1.476.646-1.476 1.444 0 .797.66 1.444 1.476 1.444z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.006 16c.816 0 1.477-.647 1.477-1.444 0-.798-.661-1.444-1.477-1.444-.815 0-1.476.646-1.476 1.444 0 .797.66 1.444 1.476 1.444zM3.036 11.928s.059.837-.65.837h-.384M22.323 12.419s-.827-.058-.827-.665v-.78M8.264 10.917h5.05M7.762 14.758h7.768"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosPickup
