import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotificationTip: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} fill="#3b97e2" r={10} />
      <g clipRule="evenodd" fill="#fff" fillRule="evenodd">
        <path d="M11.981 7.893a.488.488 0 01-.484-.492v-.909c0-.272.217-.492.484-.492.268 0 .484.22.484.492v.91a.487.487 0 01-.484.491zM16.613 12.435a.488.488 0 01-.484-.488.488.488 0 01.48-.495l.904-.009h.004c.265 0 .482.219.484.49s-.213.494-.48.496l-.905.007zM15.454 9.104a.476.476 0 01-.337-.139.498.498 0 01-.01-.696l.58-.606a.478.478 0 01.684-.009c.19.189.196.5.01.696l-.58.605a.48.48 0 01-.346.149zM8.508 9.104a.48.48 0 01-.347-.149l-.58-.605a.5.5 0 01.01-.696.48.48 0 01.685.009l.579.606a.5.5 0 01-.01.696.478.478 0 01-.337.139zM6.484 12.435a.488.488 0 01-.483-.488.487.487 0 01.48-.495l.865-.009h.004c.266 0 .482.219.484.49a.489.489 0 01-.48.496l-.866.007zM10.242 16.5c0 .01.005.019.007.029v.03c.199.835.926 1.441 1.729 1.441.804 0 1.53-.606 1.728-1.441.003-.01 0-.019.002-.029 0-.01.006-.019.007-.03l.027-.62h-3.528zM11.979 8.904c-1.596 0-2.895 1.397-2.895 3.114 0 .267.03.511.086.728.078.403.216.652.391.965.025.053.054.106.085.161l.112.204c.036.068.072.134.114.2.216.383.314.56.315.964l.001.034h3.582l.001-.048c0-.392.101-.58.332-.98.044-.084.1-.179.161-.286l.048-.088a3.16 3.16 0 00.084-.157c.175-.317.314-.566.387-.949.061-.237.091-.482.091-.748 0-1.717-1.299-3.114-2.895-3.114z" />
      </g>
    </Icon>
  )
}
export default SvgNotificationTip
