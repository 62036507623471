import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgYouTube: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#4b4881">
        <path d="M12 0C5.394 0 0 5.394 0 12s5.394 12 12 12 12-5.394 12-12S18.606 0 12 0zm7.34 13.798a3.234 3.234 0 01-3.23 3.23H7.89a3.234 3.234 0 01-3.23-3.23v-3.633a3.234 3.234 0 013.23-3.23h8.257a3.234 3.234 0 013.23 3.23v3.633z" />
        <path d="M14.643 11.707l-3.524-1.909c-.146-.073-.66.037-.66.184v3.743c0 .147.514.257.66.183l3.707-1.834c.147-.074-.037-.294-.183-.367z" />
      </g>
    </Icon>
  )
}
export default SvgYouTube
