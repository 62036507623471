import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPlayButton: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse cx={22.5} cy={23} rx={22.5} ry={23} fill="#3E4153" fillOpacity={0.85} />
      <path
        d="M32.909 21.268c1.332.77 1.332 2.694 0 3.464L19.518 32.47c-1.334.77-3.001-.191-3.001-1.732V15.262c0-1.54 1.667-2.503 3-1.732l13.392 7.738z"
        fill="#fff"
      />
    </Icon>
  )
}
export default SvgPlayButton
