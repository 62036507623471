import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconCategoryServices: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.004 48.004" {...props}>
      <circle cx={24} cy={24} r={24} fill="currentColor" />
      <g fill="#373373">
        <path d="M33.595 17.937a.625.625 0 00-1.026-.222l-3.736 3.717-1.894-.376-.351-1.862 3.76-3.74a.625.625 0 00-.212-1.025 6.073 6.073 0 00-7.853 7.964c-6.843 4.97-7.059 5.186-7.14 5.268a3.714 3.714 0 105.252 5.252c.078-.079.26-.26 3.935-5.256a.625.625 0 10-1.007-.74c-3.195 4.342-3.737 5.029-3.812 5.112a2.522 2.522 0 01-3.485 0 2.463 2.463 0 01-.01-3.474c.117-.106 1.074-.848 7.417-5.452a.626.626 0 00.188-.793 4.82 4.82 0 015.087-6.987l-3.237 3.219a.624.624 0 00-.173.559l.491 2.601a.624.624 0 00.492.497l2.634.524a.61.61 0 00.563-.17l3.207-3.19a4.817 4.817 0 01-6.899 5.068.625.625 0 10-.548 1.123 6.07 6.07 0 008.357-7.617z" />
        <circle cx={17.745} cy={30.256} r={0.781} />
      </g>
    </Icon>
  )
}
export default SvgIconCategoryServices
