import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSnowmobile: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153" strokeLinecap="round" strokeLinejoin="round">
        <path d="M2.191 15.043s-.43.43 0 1.292c.367.734 1.52.846 1.7.86a.64.64 0 00.047.001h17.299a1 1 0 00.599-.2l.664-.496M10.804 7.988V4.84H9.59a1 1 0 00-.762.353l-2.76 3.251m4.736-.457l-.43 2.61m.43-2.61H9.5 12m-5.933.457l-1.802 2.883c-.214.342-.207.778.017 1.114.315.472.81.793 1.37.886l1.707.284h12.919a1.336 1.336 0 00-.25-1.542l-.152-.151a3.267 3.267 0 00-2.147-.953l-7.356-.368M6.067 8.445l.816.816a4 4 0 002.171 1.117l1.32.22" />
        <path d="M19.416 11.029l.862-4.307c-.689-.688-1.868-.267-1.965.702l-.188 1.882H15.54a.861.861 0 00-.861.862v.43M6.928 13.613L6 15.5m0 0c-.937 0-1.812.468-2.332 1.248L3.5 17M6 15.5h.394a3 3 0 012.497 1.336L9 17M17.263 14.043L17.5 15.5m0 0h2A1.5 1.5 0 0121 17h-8.5a2.194 2.194 0 012.081-1.5z" />
      </g>
    </Icon>
  )
}
export default SvgSnowmobile
