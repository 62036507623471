import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMastercard: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <clipPath id="mastercard_svg__a">
        <path d="M4 7h15v9.286H4z" />
      </clipPath>
      <rect
        fill="#fff"
        height={13.5}
        rx={1.75}
        stroke="#d2d3d7"
        strokeWidth={0.5}
        width={21.5}
        x={1.25}
        y={5.25}
      />
      <g clipPath="url(#mastercard_svg__a)">
        <path d="M13.529 15.293H9.473v-7.3h4.056z" fill="#ff5f00" />
        <path
          d="M9.727 11.643a4.638 4.638 0 011.771-3.65A4.632 4.632 0 004.704 9.18a4.648 4.648 0 001.903 6.638 4.629 4.629 0 004.891-.525 4.632 4.632 0 01-1.77-3.65z"
          fill="#eb001b"
        />
        <path
          d="M19 11.643a4.648 4.648 0 01-2.61 4.175 4.63 4.63 0 01-4.89-.525 4.64 4.64 0 001.771-3.65 4.648 4.648 0 00-1.771-3.65 4.631 4.631 0 016.794 1.187c.462.738.706 1.592.706 2.463z"
          fill="#f79e1b"
        />
      </g>
    </Icon>
  )
}
export default SvgMastercard
