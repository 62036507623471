import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCart: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 16.695c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zM16.5 16.695c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zM18 14.695H9c-1 0-1.3-.2-1.3-.8 0-.2 0-.3.1-.5l.2-.4 9.7-1c1.1-.1 1.9-.9 2.2-1.9l1.1-3.8c.1-.2-.2-.4-.4-.5H5.5m0-.1l2.5 7.4-2.5-7.4zM2 4.695s2.3-2.1 3.5 1"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgCart
