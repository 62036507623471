import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFacebookMessengerOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.383 20.196a.537.537 0 01-.055.019l-.015.006v-.001a.618.618 0 01-.188.032.625.625 0 01-.197-1.216l-.004-.011c3.393-1.118 5.826-4.117 5.826-7.65 0-4.487-3.917-8.125-8.75-8.125-4.832 0-8.75 3.638-8.75 8.125 0 2.5 1.221 4.727 3.135 6.216.01.012.013.026.027.037.358.276.588.71.588 1.195v1.84l2.123-1.449.003.004a.625.625 0 011 .498.62.62 0 01-.333.548L7.61 21.792a1.19 1.19 0 01-1.86-.98V18.69C3.464 16.973 2 14.335 2 11.375 2 6.197 6.477 2 12 2s10 4.197 10 9.375c0 4.064-2.76 7.52-6.617 8.82zm-8.874-6.583a.662.662 0 01.114-.91l3.847-3.068a.614.614 0 01.88.117l1.916 2.566 3.351-2.672a.614.614 0 01.88.116c.212.284.161.691-.113.91l-3.848 3.068a.613.613 0 01-.88-.117l-1.916-2.566-3.351 2.672a.613.613 0 01-.88-.116zm6.116 5.887a.625.625 0 110 1.25.625.625 0 010-1.25z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgFacebookMessengerOutline
