import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosAlloyWheels: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 21.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.727a1.727 1.727 0 100-3.454 1.727 1.727 0 000 3.454zM14.677 2.932a.767.767 0 00-1.036.604V7.941c0 .518.345.864.864.864.172 0 .345 0 .431-.087l2.591-1.468m2.418 7.6c.432.26.95.086 1.21-.345.086-.087.086-.173.086-.26a10.236 10.236 0 000-4.577c-.087-.432-.605-.777-1.037-.604-.086 0-.172.086-.259.086l-3.627 2.073c-.432.259-.518.777-.345 1.209.086.172.172.259.345.345l3.627 2.073zm-5.354 6.304a9.322 9.322 0 003.973-2.331.835.835 0 000-1.21l-.173-.172-3.627-2.073c-.432-.259-.95-.086-1.21.346-.086.172-.086.259-.086.431v4.146c0 .518.432.863.864.863h.259zM5.436 18.91c1.123 1.123 2.505 1.9 3.973 2.245a.767.767 0 001.036-.604V16.145c0-.518-.345-.863-.863-.863-.173 0-.346 0-.432.086l-3.627 2.073c-.432.259-.605.777-.346 1.209.173.086.173.173.26.26zM9.41 2.932a10.1 10.1 0 00-3.973 2.245.835.835 0 000 1.21l.173.172 3.627 2.073c.432.259.95.086 1.21-.346.086-.172.086-.259.086-.431V3.709a.865.865 0 00-.864-.864c-.086 0-.173.087-.259.087zM4.055 9.236c-.432-.259-.95-.086-1.21.346-.086.086-.086.173-.086.259a10.238 10.238 0 000 4.577c.086.432.605.777 1.036.605.087 0 .173-.087.26-.087l3.627-2.072c.432-.26.518-.778.345-1.21a.674.674 0 00-.345-.345L4.055 9.236z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosAlloyWheels
