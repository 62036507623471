import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

type ListingPriceTextProps = {
  hasPriceDrop?: boolean
  displayInlinePrice?: boolean
}

export const ListingPriceContainer = styled.div<ListingPriceTextProps>(
  ({ theme, hasPriceDrop, displayInlinePrice }) => `
  align-items: ${displayInlinePrice ? 'center' : 'flex-start'};
  display: flex;
  flex-direction: ${displayInlinePrice ? 'row' : 'column'};
  gap: ${theme.spacing.defaultSmall};

  & > p:nth-child(2) {
    text-decoration: ${hasPriceDrop ? 'line-through' : 'none'};
  }

  ${MEDIA_QUERIES(theme).medium}{
    align-items: center;
    flex-direction: row;
  }
`
)

export const AutosListingPriceContainer = styled.div<ListingPriceTextProps>(
  ({ theme }) => `
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  column-gap: ${theme.spacing.mini};

  ${MEDIA_QUERIES(theme).medium}{
    align-items: center;
    flex-direction: row;
  }
`
)

export const ListingPriceText = styled(BodyText)(
  ({ theme }) => `
  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body.large)};
  }
`
)

export const AutosListingPriceText = styled(BodyText)(
  ({ theme }) => `
  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.headline.xLarge)};
  }
`
)

export const AutosListingOriginalPriceText = styled(BodyText)`
  text-decoration: line-through;
`
