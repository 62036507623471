import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSafety: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.45 13.806L9.666 12.02a.507.507 0 00-.345-.155.47.47 0 00-.363.155.499.499 0 00-.166.357c0 .127.055.246.166.357l1.926 1.932c.162.162.35.242.566.242.215 0 .404-.08.566-.242l4.038-4.038a.51.51 0 00.155-.347.473.473 0 00-.155-.367.499.499 0 00-.357-.165.498.498 0 00-.356.165l-3.891 3.89zm1.05 7.411a1.74 1.74 0 01-.567-.1c-1.968-.75-3.532-2.022-4.692-3.816C6.08 15.507 5.5 13.565 5.5 11.475V7.192c0-.34.098-.647.295-.923.197-.275.449-.475.755-.6l5.385-2a1.66 1.66 0 01.565-.106c.182 0 .37.036.566.106l5.384 2c.306.125.558.325.755.6.197.276.295.584.295.923v4.283c0 2.09-.58 4.032-1.74 5.826-1.16 1.794-2.725 3.066-4.693 3.816a1.74 1.74 0 01-.567.1zm0-.942c1.733-.55 3.167-1.65 4.3-3.3s1.7-3.483 1.7-5.5V7.173a.596.596 0 00-.106-.346.637.637 0 00-.298-.23l-5.385-2a.563.563 0 00-.211-.04.563.563 0 00-.211.04l-5.385 2a.637.637 0 00-.298.23.596.596 0 00-.106.346v4.302c0 2.017.567 3.85 1.7 5.5s2.567 2.75 4.3 3.3z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSafety
