import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgConditionSalvage: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <path
          d="M13.5 8.012L18 5s-3.084-1.895-6.16-.5c-2.19.993-3.34 6-3.34 6l-5.172 3.879a1 1 0 00-.179 1.427l1.97 2.448a1 1 0 001.372.179L11.84 14.5s4.36 1.09 6.16-.628c2.485-2.372 2-5.86 2-5.86l-4.76 2.93z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx={7.5} cy={14.5} r={1} />
      </g>
    </Icon>
  )
}
export default SvgConditionSalvage
