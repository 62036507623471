import styled from 'styled-components'

export const RoundedChip = styled.div(
  ({ theme }) => `
  display: inline-flex;
  border-radius: ${theme.borderRadius.xLarge}; 
  align-items: center;
  padding: ${theme.spacing.mini} ${theme.spacing.defaultSmall};
  border: 1px solid ${theme.colors.grey.light3};
  background-color: ${theme.colors.white};

  &:hover, &:focus-within {
    background-color: ${theme.colors.purple.light5};
  }

  &:focus-within {
    border-color: ${theme.colors.blue.primary};
    box-shadow: ${theme.boxShadow['shadow-1']};
   
    > a {
    outline: none;
    }
  }
`
)
