import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPriceDrop: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M35.833 36.667H4.166a.834.834 0 01-.833-.834V4.167c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834v31.666c0 .46-.373.834-.833.834z"
          fill="#ececee"
        />
        <path
          d="M26.477 7.567c.918.155 1.491.782 1.536 1.865H26.75c-.018-.51-.338-.791-.773-.791-.447 0-.719.246-.719.683 0 .482.345.637 1.119.908 1.355.455 1.836 1.02 1.836 1.975 0 1.1-.726 1.765-1.736 1.9V15h-.865v-.9c-1.145-.173-1.739-.955-1.765-2.028h1.265c.026.628.363.965 1.001.965.527 0 .837-.3.837-.719 0-.428-.337-.675-1.128-.938-1.155-.373-1.829-.882-1.829-1.947 0-.981.6-1.72 1.619-1.873v-.893h.865zM31.596 7.567c.92.155 1.492.782 1.538 1.865H31.87c-.018-.51-.337-.791-.773-.791-.445 0-.718.246-.718.683 0 .482.345.637 1.118.908 1.355.455 1.837 1.02 1.837 1.975 0 1.1-.727 1.765-1.737 1.9V15h-.865v-.9c-1.145-.173-1.737-.955-1.765-2.028h1.265c.028.628.365.965 1.002.965.526 0 .836-.3.836-.719 0-.428-.337-.675-1.128-.938-1.155-.373-1.828-.882-1.828-1.947 0-.981.6-1.72 1.618-1.873v-.893h.865z"
          fill="#37a864"
        />
        <g fill="#c5c6cb">
          <path d="M15.483 22.469c1.335.225 2.167 1.137 2.232 2.708H15.88c-.027-.74-.488-1.148-1.123-1.148-.647 0-1.044.355-1.044.99 0 .7.502.925 1.625 1.322 1.969.66 2.669 1.478 2.669 2.865 0 1.598-1.057 2.563-2.524 2.761v1.295h-1.255v-1.308c-1.663-.252-2.521-1.388-2.563-2.947h1.838c.039.912.527 1.402 1.452 1.402.767 0 1.217-.437 1.217-1.045 0-.62-.49-.977-1.639-1.36-1.678-.542-2.656-1.282-2.656-2.827 0-1.426.871-2.496 2.351-2.721V21.16h1.255zM22.918 22.469c1.335.225 2.167 1.137 2.232 2.708h-1.835c-.027-.74-.489-1.148-1.124-1.148-.646 0-1.043.355-1.043.99 0 .7.502.925 1.625 1.322 1.968.66 2.668 1.478 2.668 2.865 0 1.598-1.056 2.563-2.523 2.761v1.295h-1.255v-1.308C20 31.702 19.14 30.566 19.1 29.007h1.838c.038.912.527 1.402 1.452 1.402.766 0 1.216-.437 1.216-1.045 0-.62-.49-.977-1.64-1.36-1.676-.542-2.655-1.282-2.655-2.827 0-1.426.872-2.496 2.352-2.721V21.16h1.255zM30.352 22.469c1.335.225 2.166 1.137 2.233 2.708h-1.837c-.026-.74-.488-1.148-1.123-1.148-.647 0-1.043.355-1.043.99 0 .7.503.925 1.625 1.322 1.968.66 2.668 1.478 2.668 2.865 0 1.598-1.057 2.563-2.523 2.761v1.295h-1.254v-1.308c-1.665-.252-2.523-1.388-2.565-2.947h1.839c.038.912.526 1.402 1.453 1.402.765 0 1.215-.437 1.215-1.045 0-.62-.49-.977-1.638-1.36-1.679-.542-2.655-1.282-2.655-2.827 0-1.426.87-2.496 2.351-2.721V21.16h1.254z" />
        </g>
        <path
          d="M32.683 28.333H10.648a.648.648 0 01-.648-.65v-.366c0-.36.29-.65.648-.65h22.035c.359 0 .65.29.65.65v.366a.65.65 0 01-.65.65z"
          fill="#8e909b"
        />
      </g>
    </Icon>
  )
}
export default SvgPriceDrop
