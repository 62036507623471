import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCashlessPayment: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22 7.124c-.001-.43-.178-.843-.492-1.148a1.703 1.703 0 00-1.183-.476H2.419a.426.426 0 00-.296.119.4.4 0 000 .575.426.426 0 00.296.119h17.906a.807.807 0 01.123.024l-3.356 3.348a.406.406 0 00-.12.289.396.396 0 00.127.286.419.419 0 00.299.115.43.43 0 00.294-.124l3.412-3.404a.778.778 0 01.058.277v9.752a.8.8 0 01-.245.573.851.851 0 01-.591.238H6.92a.852.852 0 01-.591-.238.8.8 0 01-.246-.574v-3.657a.4.4 0 00-.122-.287.425.425 0 00-.593 0 .4.4 0 00-.122.287v3.657c0 .43.177.843.49 1.148.315.304.74.476 1.184.476l13.405.001c.443 0 .869-.172 1.183-.476.314-.305.49-.718.491-1.148V7.124z"
        fill="currentColor"
      />
      <path
        d="M6.902 8.343a.396.396 0 00-.123-.287.42.42 0 00-.296-.12H3.152a.426.426 0 00-.296.12.4.4 0 000 .574.426.426 0 00.296.12h3.33a.43.43 0 00.297-.12.405.405 0 00.123-.287zM6.064 10.78a.396.396 0 00-.123-.287.42.42 0 00-.296-.119H4a.425.425 0 00-.296.12.4.4 0 000 .574.425.425 0 00.296.119h1.646a.43.43 0 00.296-.12.405.405 0 00.123-.287zM13.492 14.379a1.091 1.091 0 01-.848-.29 1.002 1.002 0 01-.313-.818h-1.042c-.031.49.14.973.475 1.341.335.37.807.594 1.313.625v.84h.747v-.84a1.841 1.841 0 001.252-.566c.325-.342.497-.793.48-1.257 0-.93-.507-1.43-1.843-1.886-.83-.304-1.216-.483-1.216-1.037a.753.753 0 01.24-.61.8.8 0 01.635-.213.905.905 0 01.682.255.85.85 0 01.249.666h1.05a1.665 1.665 0 00-.4-1.188 1.766 1.766 0 00-1.129-.6v-.84h-.747v.831c-.455.035-.878.24-1.18.572a1.68 1.68 0 00-.442 1.207c0 1.028.664 1.493 1.798 1.877.893.295 1.262.554 1.262 1.037a.856.856 0 01-.3.675.914.914 0 01-.723.219z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgCashlessPayment
