import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosTrim: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.576 18.412c3.438 4.176 9.66 4.83 13.836 1.31 4.176-3.439 4.83-9.66 1.31-13.836-.164-.246-.327-.41-.573-.655m-7.287 13.427a6.779 6.779 0 01-6.795-6.796 6.779 6.779 0 016.795-6.795 6.779 6.779 0 016.796 6.795m-6.796-3.11v6.303m-3.11-3.193h6.303m-8.678-3.93c-2.129 3.112-1.31 7.369 1.801 9.498a6.846 6.846 0 009.334-1.556m3.356-8.023C18.658 2.857 12.845.646 7.851 2.857 2.857 5.067.646 10.88 2.856 15.792"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosTrim
