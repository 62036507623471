import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosTrailerHitch: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.527 18.46V9.71m18.019.547v7.657-7.657zM7.786 18.46h1.908c.636 0 1.06.438 1.06 1.094v1.97c0 .656-.424 1.093-1.06 1.093H7.786c-.635 0-1.06-.437-1.06-1.094v-1.969c0-.547.425-1.094 1.06-1.094zM28.665 5.99v2.734c0 .328-.318.657-.636.657H7.15c-.317 0-.635-.329-.635-.657V5.99c0-.328.318-.656.636-.656h17.064M25.486 18.46h2.12c.635 0 1.06.438 1.06 1.094v1.97c0 .656-.425 1.093-1.06 1.093h-2.12c-.636 0-1.06-.437-1.06-1.094v-1.969c0-.547.53-1.094 1.06-1.094zM11.707 15.835a8.009 8.009 0 0111.553 0m-.954 5.907c0 2.735-2.12 4.923-4.77 4.923-2.65 0-4.769-2.188-4.769-4.923s2.12-4.923 4.77-4.923c1.06 0 2.12.438 2.967 1.094a5.04 5.04 0 011.802 3.829zm-3.18 0c0 .875-.742 1.64-1.59 1.64-.847 0-1.59-.765-1.59-1.64 0-.875.743-1.641 1.59-1.641.212 0 .319 0 .53.11l1.06 1.53zm-12.824-.11l-2.968-2.516 2.968 2.517z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosTrailerHitch
