import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFacebookMessengerSmall: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0C3.6 0 0 3.3 0 7.4c0 2.3 1.2 4.4 3 5.8V16l2.7-1.5c.7.2 1.5.3 2.3.3 4.4 0 8-3.3 8-7.4C16 3.3 12.4 0 8 0zm.8 10l-2-2.2-4 2.2 4.4-4.6 2.1 2.2 3.9-2.2L8.8 10z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgFacebookMessengerSmall
