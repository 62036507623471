import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCheckmarkSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 12l3 3M17 9l-6 6"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgCheckmarkSolid
