import { useTheme } from 'styled-components'

import { type BadgeProps, Badge, BadgeVariant } from '@/ui/atoms/badge'

export const AutosBadgeBase = (props: BadgeProps) => {
  const { colors } = useTheme()

  return (
    <Badge
      variant={BadgeVariant.LIGHT3}
      color={colors.grey.primary}
      backgroundColor={colors.grey.light5}
      weight="medium"
      {...props}
    />
  )
}
