import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconCategoryPets: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.004 48.004" {...props}>
      <circle cx={24} cy={24} r={24} fill="currentColor" />
      <path
        fill="#373373"
        d="M24.65 20.823a.988.988 0 00-.672-.196 1.065 1.065 0 00-.756.317l-5.506 5.679a4.429 4.429 0 000 6.091c.058.063.119.123.181.18a4.151 4.151 0 005.82-.165l.275-.272.26.257c.06.063.12.123.182.18a4.156 4.156 0 005.831-.175 4.404 4.404 0 00-.025-6.152zm4.71 11.032a2.903 2.903 0 01-4.075.125l-.416-.413.936-.93a.625.625 0 10-.88-.887l-1.43 1.419a.581.581 0 00-.043.064l-.629.622a2.903 2.903 0 01-4.074.125l-.126-.124a3.179 3.179 0 01-.004-4.368l5.383-5.553 5.342 5.503.001.002a3.16 3.16 0 01.016 4.415zm-9.11-11.599a3.125 3.125 0 10-3.125-3.125 3.129 3.129 0 003.125 3.125zm0-5a1.875 1.875 0 11-1.875 1.875 1.877 1.877 0 011.875-1.875zm7.5 5a3.125 3.125 0 10-3.125-3.125 3.129 3.129 0 003.125 3.125zm0-5a1.875 1.875 0 11-1.875 1.875 1.877 1.877 0 011.875-1.875zm-8.75 7.5a3.125 3.125 0 10-3.125 3.125A3.129 3.129 0 0019 22.756zm-3.125 1.875a1.875 1.875 0 111.875-1.875 1.877 1.877 0 01-1.875 1.875zm16.25-5.001a3.125 3.125 0 103.125 3.126 3.129 3.129 0 00-3.125-3.125zm0 5A1.875 1.875 0 1134 22.757a1.877 1.877 0 01-1.875 1.875z"
      />
    </Icon>
  )
}
export default SvgIconCategoryPets
