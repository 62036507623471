import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVisible: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5 1C4.2 1 1 5.9 1 7.5 1 9.1 4.2 14 10.5 14S20 9.1 20 7.5C20 5.9 16.8 1 10.5 1z"
        stroke="currentColor"
        strokeMiterlimit={10}
      />
      <path
        d="M10.5 10a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        stroke="currentColor"
        strokeMiterlimit={10}
      />
    </Icon>
  )
}
export default SvgVisible
