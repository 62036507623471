import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVisualAid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.793 7.074a.775.775 0 010-1.552.777.777 0 010 1.552zm0-2.574C2.803 4.5 2 5.306 2 6.298c0 .991.804 1.798 1.793 1.798a1.798 1.798 0 000-3.596zM8.985 7.585c.709 0 1.283-.576 1.283-1.287 0-.71-.574-1.287-1.283-1.287a1.286 1.286 0 000 2.574zM16.111 7.074a.776.776 0 010-1.552.776.776 0 010 1.552zm0-2.574a1.798 1.798 0 000 3.596c.99 0 1.793-.807 1.793-1.798 0-.992-.803-1.798-1.793-1.798zM20.716 7.585c.71 0 1.284-.576 1.284-1.287 0-.71-.575-1.287-1.284-1.287a1.286 1.286 0 000 2.574zM3.793 12.776a.775.775 0 010-1.552.776.776 0 010 1.552zm0-2.574C2.803 10.202 2 11.01 2 12c0 .992.804 1.798 1.793 1.798a1.798 1.798 0 000-3.596zM8.985 13.287c.709 0 1.283-.576 1.283-1.287 0-.71-.574-1.287-1.283-1.287-.71 0-1.284.577-1.284 1.287 0 .711.575 1.287 1.284 1.287zM16.111 10.714c-.709 0-1.284.575-1.284 1.287 0 .71.575 1.286 1.284 1.286.71 0 1.284-.576 1.284-1.287 0-.71-.575-1.286-1.284-1.286zM20.716 10.714c-.709 0-1.284.575-1.284 1.287 0 .71.575 1.286 1.284 1.286.71 0 1.284-.576 1.284-1.287 0-.71-.575-1.286-1.284-1.286zM3.793 18.478a.775.775 0 010-1.552.776.776 0 010 1.552zm0-2.574c-.99 0-1.793.806-1.793 1.798 0 .991.804 1.798 1.793 1.798a1.798 1.798 0 000-3.596zM8.984 18.478a.776.776 0 010-1.552.776.776 0 010 1.552zm0-2.574a1.798 1.798 0 000 3.596 1.798 1.798 0 000-3.596zM16.111 16.416a1.286 1.286 0 000 2.574c.71 0 1.284-.577 1.284-1.288 0-.711-.575-1.287-1.284-1.287zM20.716 16.416a1.286 1.286 0 000 2.574c.71 0 1.284-.577 1.284-1.288 0-.711-.575-1.287-1.284-1.287z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgVisualAid
