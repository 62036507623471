import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgInflatableBoat: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <path
          d="M2.029 11.25a6.2 6.2 0 01-.103-.888c-.115-1.955 1.89-3.28 3.794-2.824 2.038.487 4.587.962 6.78.962 1.71 0 3.879-.517 5.598-1.025 1.733-.513 3.836.074 3.9 1.88L22 9.5c0 .78-.451 1.721-.924 2.494-.614 1.002-1.753 1.506-2.928 1.506H5.195c-1.083 0-2.167-.412-2.745-1.327-.196-.31-.36-.635-.421-.923z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2 10s6.5 1 10 1c7 0 9.5-2 9.5-2" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx={18.5} cy={10.3} r={0.5} />
        <circle cx={12.3} cy={11} r={0.5} />
        <circle cx={5.5} cy={10.5} r={0.5} />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="M9 17h4M2 17h2M17 17h6" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgInflatableBoat
