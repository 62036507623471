import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotificationFavourite: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="notification-favourite_svg__a"
        height={9}
        maskUnits="userSpaceOnUse"
        width={10}
        x={7}
        y={7}
      >
        <path
          clipRule="evenodd"
          d="M15.773 8.752a2.434 2.434 0 00-3.513-.004l-.267.263-.26-.258a2.434 2.434 0 00-3.52 0 2.603 2.603 0 00.002 3.581l3.402 3.51a.509.509 0 00.336.15.552.552 0 00.401-.122l3.404-3.506a2.588 2.588 0 00.015-3.614z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <circle cx={12} cy={12} fill="#ffb92e" r={10} />
      <path
        clipRule="evenodd"
        d="M15.773 8.752a2.434 2.434 0 00-3.513-.004l-.267.263-.26-.258a2.434 2.434 0 00-3.52 0 2.603 2.603 0 00.002 3.581l3.402 3.51a.509.509 0 00.336.15.552.552 0 00.401-.122l3.404-3.506a2.588 2.588 0 00.015-3.614z"
        fill="#000111"
        fillRule="evenodd"
      />
      <g fill="#fff" mask="url(#notification-favourite_svg__a)">
        <path d="M6 6h12v12H6z" />
        <path d="M6 6h12v12H6z" />
      </g>
    </Icon>
  )
}
export default SvgNotificationFavourite
