import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTractor: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={19.613} cy={16.613} r={2.113} stroke="#3e4153" />
      <circle cx={7.193} cy={15.516} r={2.984} stroke="#3e4153" />
      <path
        d="M15.467 10.29l-.455.207a.5.5 0 00.455.293zm-8.773.048a.5.5 0 10.998.063zM14.596 6a.5.5 0 100-1zM6.758 5a.5.5 0 000 1zm10.277 1.14v-.5a.5.5 0 00-.5.5zM22 13.5l.416.277a.5.5 0 00.084-.277zm-3.772-6.86a.5.5 0 000-1zM22 11.5h.5a.5.5 0 00-.223-.416zm-1.77-1.016l.03-.5zm.495.166l-.278.416zm-4.802-.567l-2.178-4.79-.91.414 2.177 4.79zM7 5.47l-.307 4.87.998.062L8 5.53zM10 17h7.5v-1H10zm3.29-12H9.806v1h3.484zM9.806 5H7.5v1h2.306zm-.5.5v5.661h1V5.5zm10.955 4.485l-3.196-.194-.06.998 3.195.194zm-3.226-.195h-1.568v1h1.568zM13.29 6h1.306V5H13.29zM6.758 6H7.5V5h-.742zm10.777 4.29V6.14h-1v4.15zm-.5-3.65h1.193v-1h-1.193zm4.381 8.637l1-1.5-.832-.554-1 1.5zM22.5 13.5v-2h-1v2zm-.223-2.416l-1.275-.85-.555.832 1.276.85zm-2.077-.101a.5.5 0 01.247.083l.555-.832a1.5 1.5 0 00-.741-.25z"
        fill="#3e4153"
      />
      <path
        d="M15.903 10.29L14 12.903h-2.888S10.178 10.29 6.5 10.5c-2.883.165-4.012 3.144-4.365 4.423-.082.3.148.577.458.577H4"
        stroke="#3e4153"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgTractor
