import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFacebook: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M12 0C5.394 0 0 5.394 0 12s5.394 12 12 12 12-5.394 12-12S18.606 0 12 0zm3.23 7.266c0 .11-.074.184-.184.184H13.65a.643.643 0 00-.624.623v1.468h1.982c.11 0 .184.074.184.184l-.184 2.055c0 .073-.073.147-.183.147h-1.799v6.825c0 .11-.073.184-.183.184h-2.495c-.11 0-.184-.074-.184-.184V11.963H8.917c-.11 0-.183-.073-.183-.183V9.725c0-.11.073-.184.183-.184h1.248V7.56a2.498 2.498 0 012.496-2.496h2.348c.11 0 .184.074.184.184v2.018z"
        fill="#4b4881"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgFacebook
