/**
 * Returns the dollar price in cents
 *
 * @param value
 * @returns number
 */
export const dollarToCents = (value: number): number => {
  return value * 100
}

/**
 * Returns the cents price in dollars
 *
 * @param value
 * @returns number
 */
export const centsToDollar = (value: number): number => {
  return value / 100
}
