import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgChatOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="chat-outline_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 20.1c4.97 0 9-3.828 9-8.55C21 6.828 16.97 3 12 3s-9 3.828-9 8.55a8.21 8.21 0 001.498 4.725.517.517 0 01.065.474l-1.28 3.482c-.13.35.158.71.527.649 1.294-.214 3.417-.655 4.749-1.26a.54.54 0 01.397-.021c.951.324 1.976.501 3.044.501z"
        />
      </mask>
      <path
        d="M8.559 19.62l.414.91-.414-.91zm.397-.021l-.323.946.323-.946zM3.81 20.88l.163.986-.163-.986zm.688-4.605l-.82.573.82-.573zM20 11.55c0 4.122-3.533 7.55-8 7.55v2c5.474 0 10-4.228 10-9.55h-2zM12 4c4.467 0 8 3.428 8 7.55h2C22 6.228 17.474 2 12 2v2zm-8 7.55C4 7.428 7.533 4 12 4V2C6.526 2 2 6.228 2 11.55h2zm1.317 4.151A7.21 7.21 0 014 11.55H2a9.21 9.21 0 001.678 5.298l1.64-1.146zm-1.096 4.875l1.28-3.482-1.877-.69-1.28 3.482 1.877.69zm3.924-1.867c-1.2.546-3.206.97-4.499 1.184l.327 1.973c1.297-.214 3.536-.67 5-1.337l-.828-1.82zM12 19.1a8.41 8.41 0 01-2.72-.448l-.647 1.893c1.054.36 2.188.555 3.367.555v-2zm-3.027 1.43a.46.46 0 01-.34.015l.646-1.893a1.54 1.54 0 00-1.134.057l.828 1.82zm-6.629-.644c-.382 1.04.472 2.172 1.63 1.98l-.328-1.973c.42-.069.7.345.575.683l-1.877-.69zm1.334-3.038a.483.483 0 01-.054-.444l1.878.69c.177-.482.09-1-.185-1.393l-1.639 1.147z"
        fill="currentColor"
        mask="url(#chat-outline_svg__a)"
      />
    </Icon>
  )
}
export default SvgChatOutline
