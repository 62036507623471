import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAtvOther: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <path
          d="M6.462 14.607l1.004-.772m-2.008.335l.67-1.339m8.365 1.776l-1.338-1.08M11 12H9l-.196-1.717H3.92l-.805.206a2.254 2.254 0 00-1.003 1.876v.44h3.68s.88.057 1.34.36c.555.37.849 1.48 1.003 2.163h4.016l1.597-2.01c.167-.211.361-.402.606-.513.538-.243 1.656-.563 3.146 0 1.837.695 2.5 2.523 2.5 2.523h2v-5.045l-5.5-.129h-2.6a2 2 0 00-1.3.48z"
          strokeLinejoin="round"
        />
        <circle cx={2.677} cy={2.677} r={2.177} transform="matrix(-1 0 0 1 18.732 13.835)" />
        <circle cx={1.147} cy={1.147} r={0.647} transform="matrix(-1 0 0 1 17.202 15.365)" />
        <circle cx={2.677} cy={2.677} r={2.177} transform="matrix(-1 0 0 1 7.354 13.835)" />
        <circle cx={1.147} cy={1.147} r={0.647} transform="matrix(-1 0 0 1 5.824 15.365)" />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="M5.458 10.154l1.848-3.696A3 3 0 019.989 4.8h4.173a1 1 0 011 1v4.354" />
          <path d="M8.804 10.489l1.339-2.343-.67-.334 1.339.669" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgAtvOther
