import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUtilities: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.765 17.7a.489.489 0 00-.484-.493c-2.653 0-4.812-2.193-4.812-4.887 0-3.356 3.529-7.922 4.611-9.241a.248.248 0 01.195-.095c.04-.007.129.013.197.096.339.42 1.592 2.336 3.439 5.252.144.23.444.298.67.148a.497.497 0 00.144-.68c-1.095-1.731-3.009-4.728-3.503-5.342A1.212 1.212 0 0010.28 2h-.006c-.363 0-.705.163-.94.449C7.882 4.222 4.5 8.735 4.5 12.319c0 3.239 2.593 5.873 5.78 5.873.268 0 .485-.221.485-.493z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.443 13.003a.492.492 0 00-.496-.48.49.49 0 00-.473.503c.02.819.69 1.896 1.436 2.304a.484.484 0 00.657-.2.496.496 0 00-.197-.666c-.44-.241-.917-.992-.927-1.46zM13.228 17.9l1.645-2.572a.497.497 0 00.02-.503.483.483 0 00-.426-.258h-2.969l5.496-5.671-1.911 3.621a.5.5 0 00.01.485c.088.149.246.24.416.24h2.331L13.228 17.9zm5.788-5.642h-2.697l3.123-5.917a.496.496 0 00-.14-.628.479.479 0 00-.632.05l-8.674 8.952a.496.496 0 00-.1.535.483.483 0 00.445.301h3.233l-3.639 5.69a.498.498 0 00.101.649.478.478 0 00.648-.033l8.673-8.758a.497.497 0 00.106-.537.485.485 0 00-.447-.304z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgUtilities
