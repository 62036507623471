import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCommunityBackground: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#community-background_svg__clip0_235_655)">
        <path
          d="M24 48.03c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z"
          fill="#F3F3F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.502 23.901l3.531.73c1.466.309 3.469.732 3.469 3.206v1.823a.624.624 0 01-.625.625h-2.5a.625.625 0 010-1.25h1.875v-1.198c0-1.249-.618-1.59-2.474-1.983l-4.027-.832a.625.625 0 01-.5-.612v-2.158a.625.625 0 01.356-.564 3.551 3.551 0 002.052-3.157v-2.26a3.424 3.424 0 00-3.35-3.486h-.363c-.647 0-1.279.194-1.815.555a.624.624 0 01-.696-1.038 4.49 4.49 0 012.51-.767h.363a4.676 4.676 0 014.601 4.736v2.26a4.791 4.791 0 01-2.407 4.097V23.9zm-1.923 4.16a.627.627 0 01.244.005h.002c1.552.328 3.677.776 3.677 3.39v1.954a.625.625 0 01-.625.625h-18.75a.626.626 0 01-.625-.625v-1.953c0-2.615 2.125-3.063 3.677-3.391h.002l3.821-.79V25.85a5.086 5.086 0 01-2.58-4.363v-2.421a4.964 4.964 0 014.886-5.03h.388a4.964 4.964 0 014.885 5.03v2.42a5.104 5.104 0 01-2.934 4.552.624.624 0 01-.86-.772.625.625 0 01.32-.356 3.848 3.848 0 002.224-3.423v-2.421a3.714 3.714 0 00-3.635-3.78h-.388a3.714 3.714 0 00-3.635 3.78v2.42a3.848 3.848 0 002.224 3.424.625.625 0 01.355.564v2.312a.625.625 0 01-.499.613l-4.317.891c-1.98.418-2.684.808-2.684 2.168v1.328h17.5v-1.328c0-1.36-.704-1.75-2.686-2.168a.626.626 0 01-.215-1.137.624.624 0 01.228-.09z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="community-background_svg__clip0_235_655">
          <path fill="#fff" transform="translate(0 .03)" d="M0 0h48.004v48.004H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgCommunityBackground
