import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAlsoIncluded: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.008 12.617a.492.492 0 00-.492.492v4.915c0 .272-.22.492-.491.492H5.974a.493.493 0 01-.491-.492V5.976c0-.272.221-.492.492-.492h4.935a.492.492 0 000-.984H5.976c-.814 0-1.476.661-1.476 1.476v12.048c0 .814.662 1.476 1.476 1.476h12.049c.813 0 1.475-.662 1.475-1.476V13.11a.492.492 0 00-.492-.492z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.279 9.665a.492.492 0 000 .984h4.09v4.09a.491.491 0 10.984 0v-4.09h4.09a.491.491 0 100-.984h-4.09v-4.09a.49.49 0 10-.984 0v4.09h-4.09z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAlsoIncluded
