import { GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'

/**
 * Function that returns the page search type
 *
 * @returns The explicit enum values were defined to help TS validating only those 2 responses are returned, not any of the "GA_PAGE_TYPE" values
 * */
export const getSearchPageType = (
  searchKeyword?: string | null
): GA_PAGE_TYPE.SEARCH_AD | GA_PAGE_TYPE.BROWSE_AD => {
  if (searchKeyword) {
    return GA_PAGE_TYPE.SEARCH_AD
  }

  return GA_PAGE_TYPE.BROWSE_AD
}
