import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSendMessageWeb: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.907 12.446c.061.028.13.043.203.043h7.398A.49.49 0 0013 12a.49.49 0 00-.492-.489H5.11a.493.493 0 00-.203.044L1.584 4.27c-.344-.753.432-1.528 1.19-1.19l18.193 8.1a.896.896 0 010 1.638l-18.193 8.1c-.758.337-1.534-.438-1.19-1.19l3.323-7.283z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSendMessageWeb
