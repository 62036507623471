import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCreditCvv: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect fill="#d2d3d7" height={14} rx={2} width={22} x={1} y={5} />
      <path d="M1 8.111h22v2.667H1z" fill="#6f727f" />
      <rect fill="#fff" height={2.667} rx={1.333} width={18.515} x={2.742} y={13.444} />
      <rect
        height={3.5}
        rx={1.75}
        stroke="#f1454f"
        strokeWidth={0.5}
        width={6.252}
        x={14.973}
        y={13.028}
      />
    </Icon>
  )
}
export default SvgCreditCvv
