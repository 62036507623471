import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgDate: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.12 20.154V9.18M5.68 4.154H2.495C1.697 4.154 1 4.872 1 5.692v14.77C1 21.282 1.697 22 2.494 22h21.012c.797 0 1.494-.718 1.494-1.538V5.692c0-.82-.697-1.538-1.494-1.538H5.681zM1 8.974h21.81H1zm1.992 4.308H24.8 2.99zM1 17.59h21.81H1zm4.78 4.308v-11.18 11.18zm4.78-1.744V9.18v10.974zm4.78 1.744v-11.18 11.18z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.477 6.308c.996-.103 1.693-1.129 1.594-2.154.1-1.026-.598-2.051-1.594-2.154-.996.103-1.693 1.128-1.593 2.154h1.494"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.664 6.308c.996-.103 1.693-1.129 1.593-2.154.1-1.026-.597-2.051-1.593-2.154-.996.103-1.693 1.128-1.594 2.154h1.494M12.95 6.308c.996-.103 1.693-1.129 1.593-2.154.1-1.026-.597-2.051-1.593-2.154-.996.103-1.693 1.128-1.593 2.154h1.493"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.137 6.308c.996-.103 1.693-1.129 1.593-2.154.1-1.026-.597-2.051-1.593-2.154-.996.103-1.693 1.128-1.593 2.154h1.493"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.423 6.308c.996-.103 1.693-1.129 1.594-2.154.1-1.026-.598-2.051-1.594-2.154-.996.103-1.693 1.128-1.593 2.154h1.494"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgDate
