import { type OrganizationJsonLdProps } from 'next-seo'
import { JSON_LD_SCHEMA } from '../constants'

export const organizationSeo: OrganizationJsonLdProps = ({
  '@context': JSON_LD_SCHEMA,
  '@type': 'Organization',
  name: 'Kijiji Canada',
  alternateName: 'Kijiji',
  url: 'https://www.kijiji.ca/',
  sameAs: [
    'https://www.facebook.com/Kijiji.ca/',
    'https://twitter.com/Kijiji',
    'https://plus.google.com/+KijijiCanada',
    'https://www.youtube.com/user/kijijicanadacorp',
    'https://www.linkedin.com/company/kijiji-canadasmarketplace',
    'https://www.wikidata.org/wiki/Q1741191',
  ],
  logo: 'https://box-static.ca-kijiji-production.classifiedscloud.io/current/img/Kijiji-Canada-Logo-1.jpg',

})
