import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgModal: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 3h9C19.878 3 21 4.122 21 5.5v9c0 1.378-1.122 2.5-2.5 2.5a.5.5 0 010-1c.827 0 1.5-.673 1.5-1.5v-9c0-.827-.673-1.5-1.5-1.5h-9C8.673 4 8 4.673 8 5.5a.5.5 0 01-1 0C7 4.122 8.122 3 9.5 3zm5 17c.827 0 1.5-.673 1.5-1.5v-9c0-.827-.673-1.5-1.5-1.5h-9C4.673 8 4 8.673 4 9.5v9c0 .827.673 1.5 1.5 1.5h9zm-9-13h9C15.878 7 17 8.122 17 9.5v9c0 1.378-1.122 2.5-2.5 2.5h-9A2.503 2.503 0 013 18.5v-9C3 8.122 4.122 7 5.5 7z"
        fill="currentColor"
      />
      <mask
        id="modal_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={18}
        height={18}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 3h9C19.878 3 21 4.122 21 5.5v9c0 1.378-1.122 2.5-2.5 2.5a.5.5 0 010-1c.827 0 1.5-.673 1.5-1.5v-9c0-.827-.673-1.5-1.5-1.5h-9C8.673 4 8 4.673 8 5.5a.5.5 0 01-1 0C7 4.122 8.122 3 9.5 3zm5 17c.827 0 1.5-.673 1.5-1.5v-9c0-.827-.673-1.5-1.5-1.5h-9C4.673 8 4 8.673 4 9.5v9c0 .827.673 1.5 1.5 1.5h9zm-9-13h9C15.878 7 17 8.122 17 9.5v9c0 1.378-1.122 2.5-2.5 2.5h-9A2.503 2.503 0 013 18.5v-9C3 8.122 4.122 7 5.5 7z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#modal_svg__a)" fill="currentColor">
        <path d="M0 0h24v24H0z" />
        <path d="M0 0h24v24H0V0z" />
      </g>
    </Icon>
  )
}
export default SvgModal
