import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAdChoicesLogo: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd" {...props}>
      <path
        fill="#00aecd"
        d="M1.087 0L.792.051.534.188.313.376.147.633l-.11.325L0 1.334v9.921l.018.479.092.393.166.274.203.206.24.102.295.034.331-.034.369-.12.387-.154v-.017l1.124-.581.221-.154.221-.274.074-.359-.018-.137-.166-.308-.295-.154h-.387l-.111.017-.368.137-.313-.034-.221-.154-.147-.257-.055-.376V2.771l.055-.376.166-.257.258-.137.314-.017.368.12.055.017L9.671 5.73l.313.222.147.257.018.257-.147.257-.295.222-.055.035-4.2 2.018V6.62l-.11-.359-.295-.239-.313-.068-.387.103-.258.274-.073.291v3.387l.111.325.276.257.332.171.147.051.313.017.221-.103 6.576-3.318.332-.188.276-.206.203-.205.129-.205.055-.222-.018-.222-.111-.239-.203-.222-.295-.239-.35-.205L2.173.257 1.787.085 1.418 0zm5.225 4.789l-.092-.325-.258-.274-.405-.086-.35.068-.295.257-.092.359.074.342.276.257.387.103.368-.086.276-.239z"
      />
    </Icon>
  )
}
export default SvgAdChoicesLogo
