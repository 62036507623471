import ExternalLinkIcon from '@kijiji/icons/src/icons/ExternalLink'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { ImageYAMS } from '@/components/shared/image/ImageYAMS'
import {
  AutosCrossPromoListingBottomBarWrapper,
  AutosListingCardLogoContainer,
} from '@/components/srp/listing-card/styled'
import { Flex } from '@/ui/atoms/flex'

type AutosCrossPromoListingBottomBarTypes = {
  /**
   * Logo src
   */
  logoSrc: string
}

export const AutosCrossPromoListingBottomBar = ({
  logoSrc,
}: AutosCrossPromoListingBottomBarTypes) => {
  const theme = useTheme()
  const { t } = useTranslation('srp')

  return (
    <AutosCrossPromoListingBottomBarWrapper
      flexGrow={1}
      justifyContent="space-between"
      gap={theme.spacing.defaultSmall}
    >
      <AutosListingCardLogoContainer>
        <ImageYAMS fill src={logoSrc} alt={t('cross_promo.kijiji_autos.dealer_logo_alt')} />
      </AutosListingCardLogoContainer>

      <Flex alignItems="center" gap={theme.spacing.defaultSmall}>
        <Image
          src="/next-assets/images/kijiji-autos-logo.svg"
          alt={t('cross_promo.kijiji_autos.logo_alt')}
          width={80}
          height={16}
        />
        <ExternalLinkIcon width={24} height={24} color={theme.colors.grey.light1} />
      </Flex>
    </AutosCrossPromoListingBottomBarWrapper>
  )
}
