import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMoreInfo: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.078 19.557c-.625.916-1.328 1.756-2.11 2.443 2.891-.687 5.548-2.137 7.735-4.046C17.938 17.65 22 14.214 22 10.015 22 5.588 17.547 2 12 2S2 5.588 2 10.015c0 3.359 2.578 6.26 6.25 7.405.469.152.86.229 1.328.381"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.547 14.595l-.469.306m-1.64.763c-1.094.382-2.266.61-3.438.61-.469 0-.86 0-1.328-.076l4.765-.534z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgMoreInfo
