import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLeaseTakeover: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.425 15.903v3.42H4.18V6.29h11.245v1.096M6.7 9.516h6.205M6.7 11.451h6.205M6.7 13.322h6.205M9.803 16.226h3.49"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.68 21H2.5V7.968M19.044 4.29l1.68.968M19.755 3l1.745.968M14.326 12.355l3.943-6.71 1.68.968-3.878 6.71-2.132 1.741M13.939 15.065l-.646 1.16"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgLeaseTakeover
