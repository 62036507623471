import { ATTRIBUTES, FOR_SALE_BY_VALUES } from '@/features/attributes/constants/attributes'
import { type Listing } from '@/types/search'

/**
 * Checks if listing is a dealer listing based on its attributes
 */
export const isDealerListing = (attributes?: Listing['attributes']): boolean => {
  const filteredAttributes = (attributes?.all ?? []).filter((attr) => !!attr)

  return (
    filteredAttributes.find(
      (attribute) => attribute && attribute.canonicalName === ATTRIBUTES.FOR_SALE_BY
    )?.canonicalValues?.[0] === FOR_SALE_BY_VALUES.DEALER
  )
}
