import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgImage: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.5 19.5h-17c-.6 0-1-.4-1-1v-13c0-.6.4-1 1-1h17c.6 0 1 .4 1 1v13c0 .6-.4 1-1 1z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 11.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM6.5 17.5L12 12l3.5 3.5M14 13.5l2-2 2 2"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgImage
