import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgHidden: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 4.1c2.2 0 3.9 1.8 3.9 3.9 0 .3 0 .6-.1.9l2.8 2.8C17.2 10.4 18 8.8 18 8c0-1.5-3-6-9-6-1 0-1.9.1-2.7.3l1.8 1.8H9zM10.9 8.5L8.5 6.1 2.7.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2C1.1 5.1 0 7.1 0 8c0 1.5 3 6 9 6 1.5 0 2.8-.3 3.9-.7l2.4 2.4c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-5.8-5.8zM9 11.9c-2.2 0-3.9-1.8-3.9-3.9 0-.7.2-1.4.6-2l1.5 1.5c0 .2-.1.3-.1.5 0 1.1.9 2 2 2 .2 0 .3 0 .5-.1l1.5 1.5c-.7.3-1.4.5-2.1.5z"
        fill="currentColor"
      />
      <path
        d="M9 4.1c2.2 0 3.9 1.8 3.9 3.9 0 .3 0 .6-.1.9l2.8 2.8C17.2 10.4 18 8.8 18 8c0-1.5-3-6-9-6-1 0-1.9.1-2.7.3l1.8 1.8H9z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgHidden
