import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAmenities: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.402 17.576a.5.5 0 01-.387.186H5.984a.499.499 0 01-.386-.186.486.486 0 01-.11-.383C6 13.865 8.738 11.448 12 11.448c3.263 0 6.002 2.417 6.511 5.745a.48.48 0 01-.108.383zm1.08-.541C18.896 13.208 15.75 10.43 12 10.43c-3.75 0-6.897 2.778-7.482 6.605-.066.43.055.867.332 1.202.285.345.698.543 1.134.543h12.031c.437 0 .85-.198 1.135-.543.277-.335.398-.773.332-1.202zM18.641 19.982H5.358a.501.501 0 00-.492.509c0 .28.22.509.492.509h13.283a.501.501 0 00.492-.51c0-.28-.22-.508-.492-.508zM10.559 8.615h.948v.982a.5.5 0 00.492.51.5.5 0 00.492-.51v-.982h.95a.501.501 0 00.492-.509c0-.28-.22-.509-.492-.509h-2.882a.501.501 0 00-.492.51c0 .28.22.508.492.508zM6.208 10.107h1.025a.501.501 0 00.492-.51c0-.28-.22-.509-.492-.509H6.208a.501.501 0 00-.492.51c0 .28.22.509.492.509zM8.128 6.535a.482.482 0 00.695 0 .522.522 0 000-.72l-.724-.75a.48.48 0 00-.696 0 .52.52 0 000 .72l.725.75zM12 5.08a.501.501 0 00.492-.51V3.51c0-.282-.22-.51-.492-.51a.501.501 0 00-.492.51v1.06c0 .281.22.51.492.51zM15.506 6.64c.126 0 .252-.05.348-.15l.724-.75a.522.522 0 000-.72.479.479 0 00-.695 0l-.725.75a.523.523 0 000 .72.486.486 0 00.348.15zM16.274 9.598c0 .28.22.509.492.509h1.026a.501.501 0 00.492-.51.5.5 0 00-.492-.509h-1.026a.501.501 0 00-.492.51z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAmenities
