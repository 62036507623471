import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMore: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="more_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={10}
        width={20}
        height={4}
      >
        <path
          d="M4 10a2 2 0 11-.001 4.001A2 2 0 014 10zm8 0a2 2 0 11-.001 4.001A2 2 0 0112 10zm8 0a2 2 0 11-.001 4.001A2 2 0 0120 10z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#more_svg__a)" fill="currentColor">
        <path d="M4 10a2 2 0 11-.001 4.001A2 2 0 014 10zm8 0a2 2 0 11-.001 4.001A2 2 0 0112 10zm8 0a2 2 0 11-.001 4.001A2 2 0 0120 10z" />
        <path d="M24 0H0v24h24V0z" />
        <path d="M24 0H0v24h24V0z" />
      </g>
    </Icon>
  )
}
export default SvgMore
