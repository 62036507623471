export const JSON_LD_SCHEMA = 'https://schema.org'

export const JSON_LD_SCHEMA_URLS = {
  BASE: JSON_LD_SCHEMA,
  AUTOS: {
    ALL_WHEEL: `${JSON_LD_SCHEMA}/AllWheelDriveConfiguration`,
    FOUR_WHEEL: `${JSON_LD_SCHEMA}/FourWheelDriveConfiguration`,
    FRONT_WHEEL: `${JSON_LD_SCHEMA}/FrontWheelDriveConfiguration`,
    REAR_WHEEL: `${JSON_LD_SCHEMA}/RearWheelDriveConfiguration`,
  },
  NEW_CONDITION: `${JSON_LD_SCHEMA}/NewCondition`,
  USED_CONDITION: `${JSON_LD_SCHEMA}/UsedCondition`,
}

export const JSON_LD_SCHEMA_TYPES = {
  VEHICLE: 'Vehicle', // https://schema.org/Vehicle
  MOTORCYCLE: 'Motorcycle', // https://schema.org/Motorcycle
  CAR: 'Car', // https://schema.org/Car
  PRODUCT: 'Product', // https://schema.org/Product
  SERVICE: 'Service', // https://schema.org/Service
  REAL_ESTATE: 'SingleFamilyResidence', // https://schema.org/SingleFamilyResidence
}

export const JSON_LD_ORGANIZATION_ID = 'srp-organization-json-ld'
export const KIJIJI_LOGO =
  'https://box-static.ca-kijiji-production.classifiedscloud.io/current/img/Kijiji-Canada-Logo-1.jpg'
