import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconCategoryCarsAndVehicles: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.004 48.004" {...props}>
      <circle cx={24} cy={24} r={24} fill="currentColor" />
      <path
        fill="#373373"
        d="M32.78 19.4l-.677-3.036a.625.625 0 00-1.22.272l.643 2.884a36.686 36.686 0 01-7.663.73 33.148 33.148 0 01-7.472-.737l1.327-4.713c.21-.722 1.046-.8 1.395-.8h7.304a.625.625 0 000-1.25h-7.304a2.52 2.52 0 00-2.597 1.705l-1.394 4.952a2.712 2.712 0 00-2.379 2.718V31.5a1.183 1.183 0 001.25 1.25h2.5a1.335 1.335 0 001.032-.486 1.048 1.048 0 00.218-.824V29h12.5v2.5a1.309 1.309 0 001.413 1.25h2.342a1.327 1.327 0 001.027-.485 1.049 1.049 0 00.218-.825v-9.315A2.717 2.717 0 0032.78 19.4zm-18.787 2.725a1.518 1.518 0 011.632-1.517 31.629 31.629 0 008.238.892 34.948 34.948 0 008.492-.892 1.518 1.518 0 011.638 1.517v5.625h-20zm2.5 9.375h-2.5V29h2.5zm17.5 0h-2.337c-.087 0-.158-.025-.163-.006V29h2.5z"
      />
      <path
        fill="#373373"
        d="M29.618 26.5a.625.625 0 00.625-.625v-1.25c0-.578.047-.625.625-.625h1.25a.625.625 0 000-1.25h-1.25a1.648 1.648 0 00-1.875 1.875v1.25a.625.625 0 00.625.625zm-11.875-1.875v1.25a.625.625 0 001.25 0v-1.25a1.648 1.648 0 00-1.875-1.875h-1.25a.625.625 0 000 1.25h1.25c.578 0 .625.047.625.625z"
      />
    </Icon>
  )
}
export default SvgIconCategoryCarsAndVehicles
