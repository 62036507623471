import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMemberSince: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 20.889h17.5V9.778H3.25v11.11z"
        fill="#F3F3F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.375 10.889H2.625c-.345 0-.625-.249-.625-.556 0-.306.28-.555.625-.555h13.75c.345 0 .625.249.625.555 0 .307-.28.556-.625.556zM8.88 7.556c-.344 0-.624-.25-.624-.556V2.556c0-.307.28-.556.625-.556s.625.249.625.556V7c0 .307-.28.556-.625.556zM15.165 7.556c-.345 0-.625-.25-.625-.556V2.556c0-.307.28-.556.625-.556s.625.249.625.556V7c0 .307-.28.556-.625.556z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 19.222c0 .919-.841 1.667-1.875 1.667H5.125c-1.034 0-1.875-.748-1.875-1.667V7c0-.919.841-1.667 1.875-1.667h13.75c1.034 0 1.875.748 1.875 1.667v12.222zm-1.875-15H5.125C3.402 4.222 2 5.47 2 7v12.222C2 20.753 3.402 22 5.125 22h13.75C20.598 22 22 20.753 22 19.222V7c0-1.531-1.402-2.778-3.125-2.778z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgMemberSince
