import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgProfileOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18 16.44c1.8.36 3 .84 3 2.64V21H3v-1.92c0-1.92 1.32-2.28 3-2.64l4.2-.84v-2.28c-1.44-.72-2.52-2.16-2.52-3.84V7.2c0-2.28 1.8-4.2 4.08-4.2h.36c2.28 0 4.08 1.92 4.08 4.2v2.4c0 1.68-1.08 3-2.52 3.6"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgProfileOutline
