import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgListings: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.188 21H5.813A2.816 2.816 0 013 18.187V5.813A2.816 2.816 0 015.813 3h12.375A2.816 2.816 0 0121 5.813v5.625a.563.563 0 01-1.125 0V5.811a1.69 1.69 0 00-1.688-1.687H5.813a1.69 1.69 0 00-1.687 1.688v12.375c0 .93.757 1.687 1.688 1.687h12.375a1.69 1.69 0 001.687-1.688v-2.25a.563.563 0 011.125 0v2.25A2.816 2.816 0 0118.187 21z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.813 15.375H6.936a.563.563 0 010-1.125h7.875a.563.563 0 010 1.125zM11.438 17.625h-4.5a.563.563 0 010-1.125h4.5a.563.563 0 010 1.125z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgListings
