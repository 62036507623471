import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAmex: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <clipPath id="amex_svg__a">
        <rect height={14} rx={2} width={22} x={1} y={5} />
      </clipPath>
      <g clipPath="url(#amex_svg__a)" clipRule="evenodd" fillRule="evenodd">
        <path d="M1 5h22v14H1z" fill="#016fd0" />
        <path
          d="M14.072 16.785v-5.017l8.928.009v1.385l-1.032 1.103L23 15.378v1.415h-1.647l-.876-.966-.87.97z"
          fill="#fff"
        />
        <path
          d="M14.668 16.235V12.32h3.32v.902h-2.245v.612h2.19v.887h-2.19v.6h2.245v.914z"
          fill="#016fd0"
        />
        <path
          d="M17.97 16.235l1.838-1.96-1.837-1.955h1.422l1.122 1.241 1.125-1.24H23v.03l-1.797 1.924 1.798 1.904v.056h-1.375l-1.142-1.253-1.13 1.253z"
          fill="#016fd0"
        />
        <path
          d="M14.488 7.315h2.152l.756 1.717V7.315h2.657l.458 1.287.46-1.287H23v5.017H12.277z"
          fill="#fff"
        />
        <g fill="#016fd0">
          <path d="M14.895 7.86l-1.737 3.912h1.191l.328-.783h1.776l.324.783h1.224l-1.73-3.911zm.15 2.25l.52-1.244.52 1.245z" />
          <path d="M17.984 11.771V7.86l1.675.006.862 2.404.867-2.41H23v3.911l-1.037.01V9.093l-.98 2.678h-.946l-1-2.686v2.686z" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgAmex
