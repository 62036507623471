import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLaptopBrand: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.721 13.727h1.39c.069 0 .138-.07.138-.141V5.692c0-.071-.07-.141-.139-.141H9.082m-1.875 0H5.82c-.07 0-.14.07-.14.14v7.895c0 .07.07.14.14.14h9.027"
        stroke="currentColor"
        strokeWidth={0.705}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.569 4H19.43c.209 0 .417.211.417.423v10.502c0 .211-.209.423-.417.423H4.57c-.208 0-.417-.212-.417-.423V4.423c0-.282.14-.423.417-.423zM9.083 10.696h5.834M2 17.604h20v1.62a.776.776 0 01-.764.776H2.764A.776.776 0 012 19.225v-1.622 0zm7.083-8.811h5.834-5.834z"
        stroke="currentColor"
        strokeWidth={0.705}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.917 17.604h4.166c.209 0 .417.211.417.423v.211c0 .212-.208.423-.417.423H9.917c-.209 0-.417-.211-.417-.423v-.211c0-.282.208-.423.417-.423z"
        stroke="currentColor"
        strokeWidth={0.705}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgLaptopBrand
