import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSize: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3 18L21 6M4.965 13.04L3 18M15.734 6H21h-5.266zm3.3 4.96L21 6l-1.965 4.96zM8.267 18H3h5.266z"
        stroke="currentColor"
        strokeWidth={0.611}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgSize
