import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgRealEstate: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M19.54 14.05a.487.487 0 00-.5.49L19 19h-4v-3.78a3 3 0 00-6 0V19H5v-4.45a.5.5 0 00-1-.01v5a.5.5 0 00.304.462.491.491 0 00.196.038h15a.491.491 0 00.5-.49v-5a.5.5 0 00-.46-.5zm-9.49 1.17a2 2 0 114 0V19h-4z" />
        <path d="M21.84 11.88l-9.47-8.7a.5.5 0 00-.68 0L8.05 6.52v-2a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v5.63l-1.85 1.7a.503.503 0 00.68.74L12 4.22l9.13 8.4a.5.5 0 00.34.13.49.49 0 00.37-.16.498.498 0 000-.71zM5.05 5h2v2.38l-2 1.85z" />
        <path d="M14.11 8.56a2.06 2.06 0 10-4.121.02 2.06 2.06 0 004.121-.02zm-3.11 0a1.06 1.06 0 112.12.02A1.06 1.06 0 0111 8.56z" />
      </g>
    </Icon>
  )
}
export default SvgRealEstate
