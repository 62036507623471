import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgExpandPlus: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12zm19.091 0a9.09 9.09 0 11-18.182 0 9.09 9.09 0 0118.182 0zm-10-5.455a.91.91 0 111.819 0v4.546h4.545a.91.91 0 110 1.818H12.91v4.545a.91.91 0 11-1.819 0V12.91H6.546a.91.91 0 110-1.818h4.545V6.545z"
        fill="#757575"
      />
      <mask
        id="expand-plus_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={20}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12zm19.091 0a9.09 9.09 0 11-18.182 0 9.09 9.09 0 0118.182 0zm-10-5.455a.91.91 0 111.819 0v4.546h4.545a.91.91 0 110 1.818H12.91v4.545a.91.91 0 11-1.819 0V12.91H6.546a.91.91 0 110-1.818h4.545V6.545z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#expand-plus_svg__a)" fill="currentColor">
        <path d="M1.09 1.09H22.91V22.91H1.09z" />
        <path d="M1.091 1.091h21.818v21.818H1.091z" />
      </g>
    </Icon>
  )
}
export default SvgExpandPlus
