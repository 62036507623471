import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTransmission: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M20 6.667a1.667 1.667 0 10-2.292 1.544v3.664h-4.583V8.211a1.666 1.666 0 10-1.25 0v3.664H7.292V8.211a1.666 1.666 0 10-1.25 0v8.578a1.666 1.666 0 101.25 0v-3.664h4.583v3.664a1.666 1.666 0 101.25 0v-3.664h5.833V8.211A1.666 1.666 0 0020 6.667z"
        fill="#2d2f3b"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgTransmission
