import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconCategoryJobs: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.004 48.004" {...props}>
      <circle cx={24} cy={24} r={24} fill="currentColor" />
      <path
        fill="#373373"
        d="M36.492 31.883l-.312-4.174a.632.632 0 00-.67-.576.625.625 0 00-.576.67l.318 4.222a.627.627 0 01-.62.722l-21.159.008a.619.619 0 01-.46-.2.626.626 0 01-.164-.484l.473-7.169 2.547.546v2.308a.625.625 0 00.625.625h2.5a.625.625 0 00.625-.625v-1.46l4.256.823a.616.616 0 00.27-.007l4.224-1.056v1.7a.625.625 0 00.625.625h2.5a.625.625 0 00.625-.625v-2.42l3.864-.716a.625.625 0 00.508-.681l-.629-5.84a1.87 1.87 0 00-1.858-1.593h-5.026c.002-.028.016-.052.016-.08v-2.34a1.332 1.332 0 00-1.33-1.33h-7.34a1.332 1.332 0 00-1.33 1.33v2.34c0 .028.015.052.017.08h-5.017a1.866 1.866 0 00-1.852 1.612l-.644 5.819a.625.625 0 00.49.68l.098.02-.484 7.344a1.878 1.878 0 001.87 2.024h.002l21.158-.008a1.873 1.873 0 001.424-.655 1.842 1.842 0 00.436-1.46zM18.37 27.13h-1.25v-3.75h1.25zm12.5 0h-1.25v-3.75h1.25zM20.244 16.425l.08-2.42 7.42.081v2.34a.08.08 0 01-.08.08zm-6.863 1.857a.617.617 0 01.613-.526h20.01a.621.621 0 01.619.505l.563 5.236-3.067.568v-1.31a.625.625 0 00-.625-.624h-2.5a.625.625 0 00-.625.625v2.012l-4.392 1.098-4.358-.844v-2.266a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625v1.414l-3.066-.657z"
      />
    </Icon>
  )
}
export default SvgIconCategoryJobs
