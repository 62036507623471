import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTrailerCargo: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.917 23.754a2.417 2.417 0 11-4.833 0 2.417 2.417 0 014.833 0zM13.959 22.962h3.166M14 23.754a2.417 2.417 0 11-4.833 0 2.417 2.417 0 014.833 0z"
        stroke="#3E4153"
        strokeWidth={1.5}
      />
      <path
        d="M32.125 20.587a.75.75 0 00-1.5 0h1.5zm-1.5 4.75a.75.75 0 101.5 0h-1.5zm0-4.75v2.375h1.5v-2.375h-1.5zm0 2.375v2.375h1.5v-2.375h-1.5zm.75-.75h-4.057v1.5h4.057v-1.5zm-4.057 0H21.88v1.5h5.438v-1.5zm-18.11 0H7.334v1.5H9.21v-1.5zM7.834 11.837h8.797v-1.5H7.834v1.5zm8.797 0h8.016v-1.5H16.63v1.5zm8.016 0h1.672v-1.5h-1.672v1.5zm-17.063.833v-.583h-1.5v.583h1.5zm0 9.792v-4.646h-1.5v4.646h1.5zm0-4.646V12.67h-1.5v5.146h1.5zm18.985-5.73v1.376h1.5v-1.375h-1.5zm0 1.376v4.75h1.5v-4.75h-1.5zm0 4.75v4.75h1.5v-4.75h-1.5zm-.25-6.375a.25.25 0 01.25.25h1.5a1.75 1.75 0 00-1.75-1.75v1.5zm-18.485-1.5a1.75 1.75 0 00-1.75 1.75h1.5a.25.25 0 01.25-.25v-1.5zm-.5 11.875a.25.25 0 01.25.25h-1.5c0 .69.56 1.25 1.25 1.25v-1.5z"
        fill="#3E4153"
      />
    </Icon>
  )
}
export default SvgTrailerCargo
