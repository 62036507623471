import { FC, HTMLAttributes } from 'react'

import { VisuallyHidden } from '@/ui/atoms/visually-hidden'

import { BodyText } from '../body-text'

export type FieldsetProps = {
  /**
   * Label of the fieldset
   */
  legend: string
} & HTMLAttributes<HTMLFieldSetElement>

/**
 * @description Group of Checkbox + Error fields.
 */
export const Fieldset: FC<FieldsetProps> = ({ legend, children, ...rest }) => {
  return (
    <fieldset {...rest}>
      <VisuallyHidden>
        <BodyText as="legend">{legend}</BodyText>
      </VisuallyHidden>

      {children}
    </fieldset>
  )
}
