import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgKijijiCentral: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="kijiji-central_svg__a"
        height={20}
        maskUnits="userSpaceOnUse"
        width={20}
        x={13}
        y={12}
      >
        <path
          clipRule="evenodd"
          d="M30.992 25.898a1.027 1.027 0 11.001-2.05 1.027 1.027 0 010 2.05zm-7.951-.244a3.41 3.41 0 01-3.407-3.406 3.41 3.41 0 013.407-3.406 3.41 3.41 0 013.406 3.406 3.41 3.41 0 01-3.406 3.406zm-.939-11.74c.001-.567.46-1.025 1.026-1.026.566 0 1.025.46 1.026 1.026 0 .566-.46 1.025-1.026 1.026-.566 0-1.026-.46-1.026-1.026zm-3.489 16.454a1.027 1.027 0 010-2.052 1.027 1.027 0 010 2.052zm-3.699-8.586c-.566 0-1.025-.46-1.026-1.026a1.027 1.027 0 111.026 1.026zm14.965-5.164c.567 0 1.026.46 1.026 1.025a1.027 1.027 0 01-2.052 0c0-.566.46-1.025 1.026-1.026zm1.113 6.341c-.663 0-1.28.344-1.626.91l-2.132-.705a4.27 4.27 0 00-.404-2.933l1.755-1.182c.352.327.814.508 1.294.508a1.914 1.914 0 10-1.913-1.914c0 .236.045.462.123.67l-1.755 1.182a4.287 4.287 0 00-2.791-1.51l.03-2.212a1.913 1.913 0 10-.888.001l-.03 2.2a4.292 4.292 0 00-3.733 3.067l-2.1-.385a1.912 1.912 0 00-3.822.1 1.913 1.913 0 003.664.773l2.1.385c-.01.111-.016.223-.017.334 0 1.377.65 2.599 1.658 3.385l-1.184 1.896a1.912 1.912 0 00-2.521 1.813 1.913 1.913 0 103.275-1.344l1.183-1.895c.585.288 1.23.439 1.883.44a4.291 4.291 0 003.914-2.536l2.132.704a1.913 1.913 0 101.906-1.752z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <circle cx={22.5} cy={22.5} fill="#6f727f" r={22.5} />
      <path
        clipRule="evenodd"
        d="M30.992 25.898a1.027 1.027 0 11.001-2.05 1.027 1.027 0 010 2.05zm-7.951-.244a3.41 3.41 0 01-3.407-3.406 3.41 3.41 0 013.407-3.406 3.41 3.41 0 013.406 3.406 3.41 3.41 0 01-3.406 3.406zm-.939-11.74c.001-.567.46-1.025 1.026-1.026.566 0 1.025.46 1.026 1.026 0 .566-.46 1.025-1.026 1.026-.566 0-1.026-.46-1.026-1.026zm-3.489 16.454a1.027 1.027 0 010-2.052 1.027 1.027 0 010 2.052zm-3.699-8.586c-.566 0-1.025-.46-1.026-1.026a1.027 1.027 0 111.026 1.026zm14.965-5.164c.567 0 1.026.46 1.026 1.025a1.027 1.027 0 01-2.052 0c0-.566.46-1.025 1.026-1.026zm1.113 6.341c-.663 0-1.28.344-1.626.91l-2.132-.705a4.27 4.27 0 00-.404-2.933l1.755-1.182c.352.327.814.508 1.294.508a1.914 1.914 0 10-1.913-1.914c0 .236.045.462.123.67l-1.755 1.182a4.287 4.287 0 00-2.791-1.51l.03-2.212a1.913 1.913 0 10-.888.001l-.03 2.2a4.292 4.292 0 00-3.733 3.067l-2.1-.385a1.912 1.912 0 00-3.822.1 1.913 1.913 0 003.664.773l2.1.385c-.01.111-.016.223-.017.334 0 1.377.65 2.599 1.658 3.385l-1.184 1.896a1.912 1.912 0 00-2.521 1.813 1.913 1.913 0 103.275-1.344l1.183-1.895c.585.288 1.23.439 1.883.44a4.291 4.291 0 003.914-2.536l2.132.704a1.913 1.913 0 101.906-1.752z"
        fill="#c5c6cb"
        fillRule="evenodd"
      />
      <g fill="#fff" mask="url(#kijiji-central_svg__a)">
        <path d="M11 10h24v24H11z" />
        <path d="M11 10h24v24H11z" />
      </g>
    </Icon>
  )
}
export default SvgKijijiCentral
