import { type GetListingQuery } from '@kijiji/generated/graphql-types'
import {
  JSON_LD_SCHEMA,
  JSON_LD_SCHEMA_TYPES,
  JSON_LD_SCHEMA_URLS,
  KIJIJI_LOGO,
} from '@kijiji/seo/constants'
import { add, format } from 'date-fns'
import { type TFunction } from 'next-i18next'

import { getRealEstateAttributes } from '@/components/srp/search-list/getRealEstateListingAttributes'
import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { getLocationPath } from '@/domain/location/getLocationPath'
import { getAttributesCanonicalValuesDictionary } from '@/features/attributes/utils/getAttributesCanonicalValuesDictionary'
import {
  getAutosSeoAttributes,
  getAutosStructuredMarkup,
} from '@/features/seo/utils/getAutosStructuredMarkup'
import { getLocationMarkup } from '@/features/seo/utils/getLocationMarkup'
import { getProductSchemaType } from '@/features/seo/utils/getProductSchemaType'
import { getRealEstateStructuredMarkup } from '@/features/seo/utils/getRealEstateStructuredMarkup'
import { type Listing } from '@/types/search'

/**
 * it only supports Autos and its subcategories
 *
 * @param listing Could be the generic listing or the one returned from search
 * @param t i18n translation function
 * @param position if this listing is part of a list of other listings we can define its position
 * @returns structured markup for a single listing
 */
export const getSingleListingStructuredMarkup = ({
  includeMarkup,
  listing,
  position,
  t,
}: {
  includeMarkup?: { location?: boolean; offerDateDetails?: boolean }
  listing: Listing | GetListingQuery['listing']
  position?: number
  t: TFunction
}) => {
  /** It should only return the markup if the listing has a price amount */
  if (!isAmountPrice(listing?.price)) return

  const {
    title,
    description,
    imageUrls,
    price,
    url,
    categoryId: listingCategoryId,
    location,
  } = listing

  const mainImage = imageUrls?.[0]

  const attributesDictionary = getAttributesCanonicalValuesDictionary(listing.attributes?.all)
  const nearestIntersection =
    'nearestIntersection' in location ? location.nearestIntersection : undefined

  const attributes = getAutosSeoAttributes(attributesDictionary, t)
  const realEstateAttributes = getRealEstateAttributes(attributesDictionary, nearestIntersection)
  // TODO: move this into poster info
  const companyLogo = attributesDictionary['company-logo']?.[0]

  const productSchemaType = getProductSchemaType(listingCategoryId, attributes.hasVin)

  const isVehicle =
    productSchemaType === JSON_LD_SCHEMA_TYPES.CAR ||
    productSchemaType === JSON_LD_SCHEMA_TYPES.MOTORCYCLE ||
    productSchemaType === JSON_LD_SCHEMA_TYPES.VEHICLE

  const isRealEstate = productSchemaType === JSON_LD_SCHEMA_TYPES.REAL_ESTATE

  const isService = productSchemaType === JSON_LD_SCHEMA_TYPES.SERVICE

  const locationMarkup = includeMarkup?.location
    ? getLocationMarkup({
        address: location.address,
        coordinates: 'coordinates' in location ? location.coordinates : undefined,
        locationPath: getLocationPath(listing.location.id),
        name: location.name,
      })
    : {}

  const offerDateDetails = includeMarkup?.offerDateDetails
    ? {
        validFrom: listing.activationDate
          ? format(listing.activationDate, 'yyyy-mm-dd')
          : undefined,
        validThrough: listing.activationDate
          ? format(add(listing.activationDate, { months: 1 }), 'yyyy-mm-dd')
          : undefined,
      }
    : {}

  const structuredMarkup = {
    // minimum required properties for product snippets, merchant listings & services
    '@context': JSON_LD_SCHEMA_URLS.BASE,
    '@type': productSchemaType,
    name: title,
    description,
    image: mainImage ?? KIJIJI_LOGO,
    offers:
      !isService && !isRealEstate
        ? {
            '@type': 'Offer',
            availability: `${JSON_LD_SCHEMA}/InStock`,
            price: isAmountPrice(price) ? price.amount : undefined,
            priceCurrency: 'CAD',
            ...offerDateDetails,
            ...locationMarkup,
          }
        : null,

    url,
    logo: companyLogo, // some listings have a company logo in the attributes

    // additional properties applicable only to vehicle & car listings
    ...(isVehicle && { ...getAutosStructuredMarkup(attributes) }),
    ...(isRealEstate && { ...getRealEstateStructuredMarkup(realEstateAttributes, location) }),
  }

  /**
   * If this element is part of a list and has a position, this function will return an list item
   * Or else, it will return the product markup
   */
  if (position) {
    const itemListElement = { position, item: structuredMarkup, '@type': 'ListItem' }
    return itemListElement
  }

  return structuredMarkup
}
