import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCarsOverview: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.968 11.716a.28.28 0 00-.281.277.28.28 0 00.281.278.28.28 0 00.281-.278.28.28 0 00-.28-.277zm-.844.277c0-.46.378-.832.844-.832.466 0 .844.372.844.832 0 .46-.378.833-.844.833a.838.838 0 01-.844-.833zM13.968 13.936a.28.28 0 00-.281.277.28.28 0 00.281.278.28.28 0 00.281-.277.28.28 0 00-.28-.278zm-.844.277c0-.46.378-.832.844-.832.466 0 .844.373.844.832 0 .46-.378.833-.844.833a.838.838 0 01-.844-.832z"
        fill="currentColor"
      />
      <path
        d="M19.875 7.278v-.28a.275.275 0 00-.164-.252 9.911 9.911 0 00-3.839-.65 11.689 11.689 0 00-3.878.606 13.266 13.266 0 00-3.943-.537 4.372 4.372 0 01-1.888-.39 4.317 4.317 0 01-1.529-1.16.281.281 0 00-.314-.102.281.281 0 00-.195.264v2.474c-.3.007-.587.13-.797.342-.21.212-.328.497-.328.794l.057 9.976c0 .302.122.59.338.804.216.213.509.333.814.333l15.674-.022c.305-.01.594-.138.803-.358.21-.22.322-.512.314-.813V8.412c-.001-.297-.119-.581-.33-.793a1.159 1.159 0 00-.795-.341zm-.563-.093v10.517a11.417 11.417 0 00-3.364-.436 13.948 13.948 0 00-3.667.455V7.191c1.16-.37 2.373-.552 3.591-.54 1.17-.02 2.334.16 3.44.534zM4.687 5.475A5.022 5.022 0 008.051 6.72c1.24-.013 2.474.148 3.668.48v10.552a22.298 22.298 0 00-3.66-.486 4.72 4.72 0 01-1.841-.4 4.667 4.667 0 01-1.53-1.086V5.476zm-.479 13.47a.594.594 0 01-.416-.171.578.578 0 01-.173-.412l-.057-9.977c.001-.149.06-.292.165-.399a.59.59 0 01.398-.177v8.07c0 .06.02.117.055.165.476.554 1.067 1 1.734 1.307a5.146 5.146 0 002.13.47c1.236.065 2.466.232 3.675.5v.613l-7.51.01zm16.229-.638a.58.58 0 01-.149.42.596.596 0 01-.405.196l-7.602.01v-.632a13.176 13.176 0 013.667-.48 9.462 9.462 0 013.528.53.284.284 0 00.364-.119.276.276 0 00.035-.133V7.836a.59.59 0 01.398.176c.105.108.163.25.164.4v9.895z"
        fill="currentColor"
      />
      <path
        d="M13.969 10.605a.851.851 0 00.779-.514.822.822 0 00-.183-.907.847.847 0 00-.92-.18.841.841 0 00-.378.306.824.824 0 00.105 1.051.85.85 0 00.597.244zm0-1.11a.284.284 0 01.26.172.274.274 0 01-.062.302.283.283 0 01-.48-.196c0-.074.03-.144.083-.196a.283.283 0 01.199-.082zM15.937 10.05h1.969a.275.275 0 00.199-.474.283.283 0 00-.2-.08h-1.968a.283.283 0 00-.199.08.275.275 0 000 .393.283.283 0 00.2.081zM15.937 12.27h1.969a.276.276 0 00.199-.474.283.283 0 00-.2-.08h-1.968a.283.283 0 00-.199.081.275.275 0 000 .393.283.283 0 00.2.08zM15.937 14.491h1.969c.075 0 .146-.03.199-.081a.275.275 0 000-.393.283.283 0 00-.2-.081h-1.968a.283.283 0 00-.199.081.276.276 0 000 .393.283.283 0 00.2.081z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgCarsOverview
