import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotificationBrowse: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="notification-browse_svg__a"
        height={17}
        maskUnits="userSpaceOnUse"
        width={16}
        x={16}
        y={15}
      >
        <path
          clipRule="evenodd"
          d="M22.223 27.446c-2.88 0-5.223-2.343-5.223-5.223S19.343 17 22.223 17s5.223 2.343 5.223 5.223-2.343 5.223-5.223 5.223zm9.632 3.703l-4.874-4.924a6.193 6.193 0 001.465-4.002A6.23 6.23 0 0022.223 16C18.791 16 16 18.792 16 22.223s2.791 6.223 6.223 6.223a6.191 6.191 0 004.054-1.512l4.867 4.918a.5.5 0 00.711-.703z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <circle cx={24} cy={24} fill="#4b4881" r={24} />
      <g fill="#3e4153">
        <path
          clipRule="evenodd"
          d="M22.223 27.446c-2.88 0-5.223-2.343-5.223-5.223S19.343 17 22.223 17s5.223 2.343 5.223 5.223-2.343 5.223-5.223 5.223zm9.632 3.703l-4.874-4.924a6.193 6.193 0 001.465-4.002A6.23 6.23 0 0022.223 16C18.791 16 16 18.792 16 22.223s2.791 6.223 6.223 6.223a6.191 6.191 0 004.054-1.512l4.867 4.918a.5.5 0 00.711-.703z"
          fillRule="evenodd"
        />
        <g mask="url(#notification-browse_svg__a)">
          <path d="M12 12h24v24H12z" />
          <path d="M12 12h24v24H12z" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgNotificationBrowse
