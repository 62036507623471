import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgServicesBackground: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#services-background_svg__clip0_662_283)">
        <path
          d="M24 48.022c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z"
          fill="#F3F3F9"
        />
        <path d="M17.745 31.06a.781.781 0 100-1.563.781.781 0 000 1.562z" fill="currentColor" />
        <path
          d="M33.594 17.96a.626.626 0 00-1.025-.222l-3.737 3.716-1.893-.376-.352-1.861 3.76-3.74a.625.625 0 00-.211-1.025 6.072 6.072 0 00-7.854 7.963c-6.842 4.97-7.058 5.187-7.14 5.268a3.714 3.714 0 105.253 5.252c.077-.078.26-.26 3.935-5.255a.625.625 0 10-1.007-.741c-3.196 4.342-3.738 5.029-3.813 5.113a2.522 2.522 0 01-3.484 0 2.463 2.463 0 01-.01-3.475c.117-.106 1.074-.848 7.416-5.452a.625.625 0 00.188-.793 4.821 4.821 0 015.087-6.987l-3.236 3.22a.624.624 0 00-.173.558l.491 2.601a.625.625 0 00.492.497l2.634.524a.61.61 0 00.562-.17l3.207-3.19a4.816 4.816 0 01-6.898 5.068.625.625 0 10-.548 1.123 6.07 6.07 0 008.356-7.617z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="services-background_svg__clip0_662_283">
          <path fill="#fff" transform="translate(0 .022)" d="M0 0h48.004v48.004H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgServicesBackground
