import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotificationMessage: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="notification-message_svg__a"
        height={9}
        maskUnits="userSpaceOnUse"
        width={10}
        x={7}
        y={7}
      >
        <path
          clipRule="evenodd"
          d="M7.917 15.5c.01-.05.499-5.44 4.62-5.44h.247V8.256c0-.137.078-.261.2-.313a.31.31 0 01.352.073l3.236 3.4a.352.352 0 010 .48l-3.292 3.505a.311.311 0 01-.351.073.34.34 0 01-.2-.314l-.026-1.922-1.312.013c-.365 0-.71.028-1.028.085-1.869.332-2.446 2.164-2.446 2.164z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <circle cx={12} cy={12} fill="#4b4881" r={10} />
      <path
        clipRule="evenodd"
        d="M7.917 15.5c.01-.05.499-5.44 4.62-5.44h.247V8.256c0-.137.078-.261.2-.313a.31.31 0 01.352.073l3.236 3.4a.352.352 0 010 .48l-3.292 3.505a.311.311 0 01-.351.073.34.34 0 01-.2-.314l-.026-1.922-1.312.013c-.365 0-.71.028-1.028.085-1.869.332-2.446 2.164-2.446 2.164z"
        fill="#3e4153"
        fillRule="evenodd"
      />
      <g fill="#fff" mask="url(#notification-message_svg__a)">
        <path d="M5 5h14v14H5z" />
        <path d="M5 5h14v14H5z" />
      </g>
    </Icon>
  )
}
export default SvgNotificationMessage
