import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAtv: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="atv_svg__a" fill="#fff">
        <circle cx={4.3} cy={16.2} r={1} />
      </mask>
      <mask id="atv_svg__b" fill="#fff">
        <circle cx={20.2} cy={16.2} r={1} />
      </mask>
      <path d="M6.96 16.23a2.73 2.73 0 11-5.46.001 2.73 2.73 0 015.46 0z" stroke="#3e4153" />
      <circle cx={4.3} cy={16.2} fill="#3e4153" r={1} />
      <path
        d="M4.3 16.2v2a2 2 0 002-2zm0 0h-2a2 2 0 002 2zm0 0v-2a2 2 0 00-2 2zm0 0h2a2 2 0 00-2-2z"
        fill="#3e4153"
        mask="url(#atv_svg__a)"
      />
      <circle cx={20.2} cy={16.2} fill="#3e4153" r={1} />
      <path
        d="M20.2 16.2v2a2 2 0 002-2zm0 0h-2a2 2 0 002 2zm0 0v-2a2 2 0 00-2 2zm0 0h2a2 2 0 00-2-2z"
        fill="#3e4153"
        mask="url(#atv_svg__b)"
      />
      <g stroke="#3e4153">
        <path d="M22.96 16.23a2.73 2.73 0 11-5.46.001 2.73 2.73 0 015.46 0z" />
        <path d="M6 14.154l2-1.077m-3.5.423l1-2.577m12.5 3.23l-2-1.615" strokeLinejoin="round" />
        <path d="M11 5H8.5l-2 2.154" strokeLinecap="round" />
        <path
          d="M11.5 10.923l-2-1.615V7.154H5c-1.414 0-1.5 1.615-1.5 1.615l-1 .539v1.615H5s1.315.084 2 .539c.831.551 1.27 1.132 1.5 2.153.138.613 0 1.616 0 1.616h6l.5-1.077s.71-1.303 1.5-2.154c.5-.539 2-.539 2-.539h4V7.692H16l-2 3.231z"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M16.978 6.225a.5.5 0 10-.956-.296zm-.5 1.615l.5-1.615-.956-.296-.5 1.616z"
        fill="#3e4153"
      />
    </Icon>
  )
}
export default SvgAtv
