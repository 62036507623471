import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSort: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.262 15.202a.75.75 0 00-1.024 1.096l3.75 3.5a.749.749 0 001.024 0l3.75-3.5a.75.75 0 10-1.024-1.096L8.25 17.524V4.75a.75.75 0 10-1.5 0v12.774l-2.488-2.322zM17.012 4.202a.75.75 0 00-1.024 0l-3.75 3.5a.75.75 0 001.024 1.096l2.488-2.322V19.25a.75.75 0 101.5 0V6.476l2.488 2.322a.75.75 0 101.024-1.096l-3.75-3.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSort
