import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgEndDate: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.22 21.692V9.18M5.78 4.154H2.494C1.697 4.154 1 4.872 1 5.692v14.77C1 21.282 1.697 22 2.494 22h21.012c.797 0 1.494-.718 1.494-1.538V5.692c0-.82-.697-1.538-1.494-1.538H5.78zM1.1 8.974h21.809-21.81zm2.09 4.308H25 3.19zM1.1 17.59h21.809-21.81zm4.78 4.307v-11.18 11.18zm4.78-1.743V9.179v10.975zm4.78 1.743v-11.18 11.18z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.577 6.308c.896 0 1.593-.923 1.593-2.154C8.17 2.924 7.473 2 6.577 2s-1.594.923-1.594 2.154h1.494M9.863 6.308c.896 0 1.593-.923 1.593-2.154C11.456 2.924 10.76 2 9.863 2S8.27 2.923 8.27 4.154h1.493M13.05 6.308c.896 0 1.593-.923 1.593-2.154 0-1.23-.697-2.154-1.593-2.154s-1.594.923-1.594 2.154h1.494M16.337 6.308c.896 0 1.593-.923 1.593-2.154 0-1.23-.697-2.154-1.593-2.154-.897 0-1.693.923-1.693 2.154h1.493M19.523 6.308c.896 0 1.593-.923 1.593-2.154 0-1.23-.697-2.154-1.593-2.154s-1.593.923-1.593 2.154h1.494"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.54 17.59h4.68v4.308h-4.68V17.59z" fill="currentColor" />
    </Icon>
  )
}
export default SvgEndDate
