import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBusiness: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.5 5.5h1c.6 0 1 .4 1 1s-.4 1-1 1h-5c-.6 0-1-.4-1-1s.4-1 1-1h1M13.5 5.5c.4-.7-.7-2-1.5-2s-1.9 1.3-1.5 2M9 13.5l2 2 4-4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 13.5v5c0 1.1-.9 2-2 2h-11c-1.1 0-2-.9-2-2V8.3c0-1 .8-1.8 1.8-1.8h1.8M15.5 6.5h2.1c1 0 1.8.8 1.8 1.8v1.2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgBusiness
