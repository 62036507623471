import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgJobs: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22 18.31L21.74 15a.501.501 0 10-1 .07l.26 3.35a.51.51 0 01-.28.533.488.488 0 01-.21.047H3.58a.5.5 0 01-.5-.54l.38-5.74 2 .44V15a.5.5 0 00.5.5H8a.5.5 0 00.5-.5v-1.16l3.4.66h.22l3.38-.85V15a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.93l3.09-.57A.51.51 0 0022 12l-.5-4.67A1.5 1.5 0 0020 6h-4V4.07A1.07 1.07 0 0014.93 3H9.06A1.06 1.06 0 008 4.07V6H4a1.51 1.51 0 00-1.49 1.29L2 12a.49.49 0 00.39.54h.08l-.39 5.87A1.51 1.51 0 003.58 20h16.93a1.47 1.47 0 001.13-.53 1.419 1.419 0 00.36-1.16zM7.5 14.5h-1v-3h1zm10 0h-1v-3h1zM9 5.94L9.06 4l5.94.07v1.87s0 .06-.07.06zM3.5 7.43A.51.51 0 014 7h16a.51.51 0 01.5.41l.5 4.19-2.45.45V11a.51.51 0 00-.5-.5H16a.5.5 0 00-.5.5v1.61l-3.5.88-3.5-.67V11a.51.51 0 00-.5-.5H6a.5.5 0 00-.5.5v1.14L3 11.61z"
        fill="#3e4153"
      />
    </Icon>
  )
}
export default SvgJobs
