import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgInstagram: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#4b4881">
        <path d="M12.11 9.651c-1.211 0-2.202.991-2.202 2.202s.991 2.202 2.202 2.202 2.202-.99 2.202-2.202c0-1.174-.991-2.202-2.202-2.202z" />
        <path d="M14.825 6.275H9.211a2.882 2.882 0 00-2.9 2.9v5.614a2.882 2.882 0 002.9 2.9h5.614c1.615 0 2.9-1.285 2.9-2.9V9.174c0-1.614-1.322-2.899-2.9-2.899zM12.11 15.34a3.446 3.446 0 01-3.45-3.45 3.446 3.446 0 013.45-3.45 3.446 3.446 0 013.45 3.45 3.446 3.446 0 01-3.45 3.45zm3.633-6.312c-.44 0-.808-.367-.808-.808s.367-.807.808-.807.807.367.807.807c.037.44-.33.808-.807.808z" />
        <path d="M12 0C5.394 0 0 5.394 0 12s5.394 12 12 12 12-5.394 12-12c0-6.642-5.394-12-12-12zm7.083 14.862a4.198 4.198 0 01-4.22 4.22H9.1a4.198 4.198 0 01-4.22-4.22V9.101A4.197 4.197 0 019.1 4.88h5.761a4.198 4.198 0 014.22 4.22z" />
      </g>
    </Icon>
  )
}
export default SvgInstagram
