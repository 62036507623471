import { z } from "zod";
import { JSON_LD_SCHEMA } from "../constants";

const CrumbSchema = z.object({
  label: z.string(),
  href: z.string().optional(),
});

type Crumb = z.infer<typeof CrumbSchema>;

const replaceAmpersands = (strings: string[]): string[] => {
  return strings.map((str) => str.replace(/&/g, 'and'));
};

export const breadcrumbsSeo = (crumbs: Crumb[]) => {
  return {
    '@context': JSON_LD_SCHEMA,
    '@type': 'BreadcrumbList',
    itemListElement: crumbs.map((crumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: { '@id': crumb.href ?? '', name: replaceAmpersands([crumb.label])[0] },
    })),
  };
};
