import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAccessibility: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.299 10.64l1.051 3.06m10.842 11.983l-3.995-8.038h-6.145l-.702-3.944m0 0l5.136-.571m-8.558 3.424s-4.576 4.697-1.144 9.13c3.432 4.432 10.843 1.14 10.843 1.14"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M14.865 7.575a3.075 3.075 0 11-6.15 0 3.075 3.075 0 016.15 0z"
        stroke="currentColor"
      />
    </Icon>
  )
}
export default SvgAccessibility
