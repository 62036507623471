import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgDropdownCollapsed: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.854 8.896a.5.5 0 10-.708.708l4.5 4.5a.5.5 0 00.708 0l4.5-4.5a.5.5 0 00-.708-.708L12 13.043 7.854 8.896z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgDropdownCollapsed
