import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCanoe: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 9l.384 3.073c.186 1.49 1.194 2.746 2.66 3.074A36.78 36.78 0 0012 16c2.87 0 5.551-.272 7.46-.531 1.71-.233 2.985-1.626 3.182-3.34L23 9M1 9s2.393 2.087 4.125 2.546c2.582.682 4.19 1.272 6.875 1.272 2.685 0 4.347-.437 6.875-1.272C20.698 10.943 23 9 23 9M1 9s3.35 1.111 4.813 1.273c2.062.227 4.845.636 6.187.636s5.5-.409 6.875-.636S23 9 23 9M14 18.5v3l2.5-1zm0 0L11.5 12"
        stroke="#3e4153"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 14.5a.5.5 0 000 1zm2 1h.5v-1H3zm-2 0h2v-1H1z" fill="#3e4153" />
      <path d="M21 15h2" stroke="#3e4153" strokeLinecap="round" />
    </Icon>
  )
}
export default SvgCanoe
