import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgJewelleryType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.455 13.079h13.09-13.09zm5.522 5.241l-2.522-7.593 2.522 7.593zm-.545-7.055L12 8.98l-1.568 2.285zm4.909.134l.75-2.284-.75 2.284zm-3.136 9.542l1.909-5.913-1.91 5.912zm2.522-7.862l-1.636-2.486 1.636 2.486zM12 6.494V3v3.494zm-3.955-.336l-.477-1.613.477 1.613zM4.91 8.577L3 5.621l1.91 2.956zm11.046-2.419l.477-1.613-.477 1.613zm3.136 2.419L21 5.621l-1.91 2.956zm-2.727.806l2.113 3.293c.205.268.137.672-.068.94l-5.727 7.123a.857.857 0 01-1.16.067l-.136-.134-5.795-7.123c-.205-.268-.205-.604-.068-.94l2.113-3.293c.137-.201.41-.403.682-.403h7.296c.34.067.545.202.75.47z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgJewelleryType
