import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgParking: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.617 10.677c-.066.521-.549.893-1.128.822h-.869V9.463l.911-.014c.059-.008.12-.01.178-.008.254.01.49.119.662.307.172.187.26.43.25.742a.975.975 0 01-.004.187zm.49-1.605a1.938 1.938 0 00-1.655-.617l-1.337.015a.502.502 0 00-.495.5v3.029a.5.5 0 00.5.5l1.307-.004a1.956 1.956 0 002.183-1.694c.016-.127.019-.255.01-.326a1.937 1.937 0 00-.513-1.403z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.175 13.485a.5.5 0 00-.5.51l.06 3.385H9.59L9.48 7.5h3.024c1.03-.039 1.886.26 2.481.832.54.52.83 1.246.84 2.101 0 1.828-1.291 3.026-3.28 3.052h-1.37zm5.65-3.054v-.006c-.012-1.13-.41-2.104-1.147-2.813-.793-.762-1.9-1.152-3.19-1.112h-3.11a.92.92 0 00-.9.93l.116 10.455a.5.5 0 00.5.495h2.15a.502.502 0 00.5-.509l-.06-3.386h.866c2.557-.033 4.275-1.663 4.275-4.054z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.848 5.152C14.073 1.93 8.376 2.377 5.15 6.15a8.989 8.989 0 00-2 4.161c-.222 1.163-.197 2.41.071 3.707.203.984.893 2.254.923 2.307a.499.499 0 10.879-.478c-.007-.012-.648-1.192-.821-2.03-.242-1.17-.266-2.287-.07-3.32A8.001 8.001 0 015.912 6.8c2.866-3.353 7.93-3.75 11.286-.886 3.356 2.865 3.754 7.925.886 11.28a7.952 7.952 0 01-5.459 2.777 7.94 7.94 0 01-5.827-1.893.5.5 0 10-.65.761 8.943 8.943 0 006.555 2.13 8.948 8.948 0 006.143-3.127c3.225-3.773 2.778-9.465-.998-12.689z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgParking
