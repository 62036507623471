import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPage: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19 13.5v4c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2v-11c0-1.1.9-2 2-2h6"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 9.5h5l-5-5v5z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgPage
