import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'

/**
 * Map attributes to a dictionary form with canonical name and canonical value-only
 *
 * This was needed to centralize some functions used for Search.
 * Eventually we should move to fetch the individual attributes from ANVIL or using the generic getAttributesDictionary
 */
export const getAttributesCanonicalValuesDictionary = (
  attributes?: (Pick<ListingAttributeV2, 'canonicalName' | 'canonicalValues'> | null)[] | null
): Record<string, string[]> => {
  return (
    attributes?.reduce((acc, curr) => {
      if (!curr?.canonicalValues?.[0]) return acc

      return { ...acc, [curr.canonicalName]: curr.canonicalValues }
    }, {}) ?? {}
  )
}
