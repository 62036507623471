import styled from 'styled-components'

import { Fieldset } from '@/ui/atoms/fieldset'

export const RadioFieldset = styled(Fieldset)(
  ({ $gap }: { $gap: string }) => `
  display: grid;
  gap: ${$gap};
`
)
