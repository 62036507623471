import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPhoneCarrier: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.298 14.75l1.468-.617m-1.468-1.855l.574-.247-.574.247z"
        stroke="currentColor"
        strokeWidth={0.632}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.125c.635 0 1.15-.498 1.15-1.112 0-.615-.515-1.113-1.15-1.113-.634 0-1.148.498-1.148 1.113 0 .614.514 1.112 1.149 1.112z"
        fill="currentColor"
      />
      <path
        d="M12 20.5l-1.723-3.524-2.617 1.67 4.276-10.633L12 20.5zm0 0V8.013l4.404 10.694-2.68-1.73L12 20.5zm0-7.79l-.83-.37m.83 2.844l-1.596-.68M12 17.656l-2.043-.865m5.426-.556l-2.17.927M6.128 3.5c-2.17 2.658-2.17 6.43 0 9.025m1.85-7.665a4.985 4.985 0 000 6.367m1.98-1.422a2.675 2.675 0 010-3.647M17.871 3.5c2.17 2.658 2.17 6.43 0 9.025M15.957 4.86a4.833 4.833 0 010 6.367m-1.914-1.422a2.675 2.675 0 000-3.647"
        stroke="currentColor"
        strokeWidth={0.632}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgPhoneCarrier
