import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPostAd: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <path d="M2.5 19.5v-11a1 1 0 011-1h2.882a1 1 0 00.894-.553l1.448-2.894a1 1 0 01.894-.553h4.828a1 1 0 01.848.47l1.912 3.06a1 1 0 00.848.47H20.5a1 1 0 011 1v11a1 1 0 01-1 1h-17a1 1 0 01-1-1z" />
        <circle cx={12} cy={13} r={4} />
      </g>
    </Icon>
  )
}
export default SvgPostAd
