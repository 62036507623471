import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUrgent: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M35.833 13.333H4.166a.834.834 0 01-.833-.833V4.167c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834V12.5c0 .46-.373.833-.833.833z"
          fill="#ececee"
        />
        <path
          d="M14.583 11.667H5.417A.417.417 0 015 11.25V5.417c0-.23.187-.417.417-.417h9.166c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417z"
          fill="#c5c6cb"
        />
        <path
          d="M35.833 25H4.166a.834.834 0 01-.833-.833v-8.334c0-.46.373-.833.833-.833h31.667c.46 0 .833.373.833.833v8.334c0 .46-.373.833-.833.833z"
          fill="#ececee"
        />
        <path
          d="M14.583 23.333H5.417A.417.417 0 015 22.917v-5.834c0-.23.187-.416.417-.416h9.166c.23 0 .417.186.417.416v5.834c0 .23-.187.416-.417.416z"
          fill="#c5c6cb"
        />
        <path
          d="M35.833 36.667H4.166a.834.834 0 01-.833-.834V27.5c0-.46.373-.833.833-.833h31.667c.46 0 .833.373.833.833v8.333c0 .46-.373.834-.833.834z"
          fill="#ececee"
        />
        <path
          d="M14.583 35H5.417A.417.417 0 015 34.583V28.75c0-.23.187-.417.417-.417h9.166c.23 0 .417.187.417.417v5.833c0 .23-.187.417-.417.417z"
          fill="#c5c6cb"
        />
        <path d="M10.833 15H6.06l-2.727 2.727V22.5z" fill="#37a864" />
      </g>
    </Icon>
  )
}
export default SvgUrgent
