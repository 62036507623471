/**
 * Truncate text to a maximum length
 * @param text - The text to truncate
 * @param maxLength - The maximum length of the text
 * @returns The truncated text
 * @example
 * truncateText('This is a long text', 10) // 'This is a...'
 * truncateText('This is a short text', 20) // 'This is a short text'
 */
export const truncateText = (text: string, maxLength: number) => {
  return text?.length > maxLength ? `${text.substring(0, maxLength).trim()}...` : text.trim()
}
