import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCallOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.634 15.02l.008.01.009.01c.85.92 1.496 1.55 1.944 1.987.184.179.334.325.452.447.216.222.307.342.349.427.028.058.041.112.026.237l-.004.03v.032c0 .17-.105.408-.426.701-.313.286-.766.563-1.296.807-1.067.492-2.32.792-3.078.792-.8 0-1.929-.45-3.233-1.281-1.284-.818-2.666-1.956-3.954-3.225-1.287-1.269-2.468-2.656-3.354-3.965-.895-1.323-1.452-2.51-1.563-3.39-.04-.344.004-.85.136-1.438a9.39 9.39 0 01.598-1.782c.263-.579.569-1.082.885-1.432.327-.363.596-.487.785-.487.124 0 .192.02.269.064.1.058.229.166.452.39.107.106.23.234.375.384.44.457 1.08 1.12 2.065 2.03l.01.008.009.008c.312.26.293.742-.128.982l-.011.006-.011.007c-.706.454-1.094.753-1.309.967a1.221 1.221 0 00-.252.34.713.713 0 00-.069.314v.023l.002.022c.062.68.453 1.473.966 2.243.525.787 1.222 1.615 1.977 2.377.755.76 1.579 1.467 2.363 2.005.768.528 1.554.934 2.224 1.025l.034.005h.034c.349 0 .622-.187.852-.433.227-.242.475-.606.777-1.11h0l.005-.009c.236-.413.817-.446 1.082-.128z"
        stroke="currentColor"
      />
    </Icon>
  )
}
export default SvgCallOutline
