import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgInstagramSmall: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.1 1.4h3.2c.8 0 1.2.2 1.5.3.4.2.6.3.9.6.3.3.4.6.6.9.1.3.2.7.3 1.5v6.4c0 .8-.2 1.2-.3 1.5-.2.4-.3.6-.6.9-.3.3-.6.4-.9.6-.3.1-.7.2-1.5.3H4.9c-.8 0-1.2-.2-1.5-.3-.4-.2-.6-.3-.9-.6-.3-.3-.4-.6-.6-.9-.1-.3-.2-.7-.3-1.5V7.9 4.7c0-.8.2-1.2.3-1.5.2-.4.3-.6.6-.9.3-.3.6-.4.9-.6.3-.1.7-.2 1.5-.3h3.2zm0-1.4H4.8c-.9 0-1.4.2-2 .4-.5.2-1 .5-1.4 1-.5.4-.7.9-1 1.4-.2.5-.3 1.1-.4 2v6.6c0 .9.2 1.4.4 2 .2.5.5 1 .9 1.4.4.4.9.7 1.4.9.5.2 1.1.3 2 .4 1-.1 1.2-.1 3.4-.1h3.3c.8 0 1.4-.2 2-.4.5-.2 1-.5 1.4-.9.4-.4.7-.9.9-1.4.2-.5.3-1.1.4-2V8 4.7c0-.8-.2-1.4-.4-2-.2-.5-.5-1-.9-1.4-.4-.4-.9-.7-1.4-.9-.5-.2-1.1-.3-2-.4H8.1z"
        fill="currentColor"
      />
      <path
        d="M8.1 3.9C5.8 3.9 4 5.7 4 8s1.8 4.1 4.1 4.1 4.1-1.8 4.1-4.1-1.8-4.1-4.1-4.1zm0 6.8c-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7zM12.4 4.7a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgInstagramSmall
