import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWhatsappOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.104 14.656l-2.022-.38c-.345-.065-.574-.415-.513-.781.06-.367.388-.612.733-.547l2.21.415c.739.15 1.226.863 1.09 1.608l-.011.049a2.528 2.528 0 01-.525.99c-1.442 1.658-4.862 1.18-7.638-1.067-2.777-2.247-3.859-5.413-2.416-7.07.343-.395.8-.666 1.333-.822.044-.012.087-.028.132-.034l.002-.001a1.344 1.344 0 011.442.817l.787 2.11a.597.597 0 01-.37.77.62.62 0 01-.79-.359l-.723-1.939a.278.278 0 00-.34-.148c-.2.095-.375.22-.515.38-.998 1.149.013 3.554 2.261 5.373 2.248 1.818 4.88 2.363 5.878 1.216.062-.072.113-.148.159-.228a.236.236 0 00.026-.073.248.248 0 00-.19-.28zm-12.229.469a.625.625 0 11-1.25 0 .625.625 0 011.25 0zM12 22a9.942 9.942 0 01-4.435-1.04l-.058-.027a1.716 1.716 0 00-1.223-.122l-.025.006-1.814.503-.02.004a1.42 1.42 0 01-1.748-1.746l.008-.046.53-1.907h.035a.625.625 0 111.25 0h.013l-.02.073a.597.597 0 01-.053.19l-.561 2.021c0 .002.002 0 .002.001a.17.17 0 00.2.212v.003l1.86-.517.01-.001a2.955 2.955 0 012.105.203c.006.003.013.004.02.006A8.698 8.698 0 0012 20.75 8.75 8.75 0 0020.75 12a8.75 8.75 0 10-17.5 0c0 .183.01.365.02.545l-.028.003c.003.025.008.05.008.077a.625.625 0 11-1.233-.138A9.347 9.347 0 012 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgWhatsappOutline
