import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgContract: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-.91a9.09 9.09 0 100-18.18 9.09 9.09 0 000 18.18zM18.364 12a.91.91 0 00-.91-.91H6.547a.91.91 0 100 1.82h10.909a.91.91 0 00.909-.91z"
        fill="#757575"
      />
      <mask
        id="contract_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={20}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-.91a9.09 9.09 0 100-18.18 9.09 9.09 0 000 18.18zM18.364 12a.91.91 0 00-.91-.91H6.547a.91.91 0 100 1.82h10.909a.91.91 0 00.909-.91z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#contract_svg__a)" fill="currentColor">
        <path d="M1.09 1.09H22.91V22.91H1.09z" />
        <path d="M1.091 1.091h21.818v21.818H1.091z" />
      </g>
    </Icon>
  )
}
export default SvgContract
