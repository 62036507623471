import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAgreementType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.847 5.152C14.074 1.93 8.383 2.377 5.159 6.15a8.993 8.993 0 00-1.925 7.88c.077.331.37 1.483.934 2.362a.5.5 0 00.842-.539c-.476-.744-.734-1.756-.802-2.049A7.996 7.996 0 015.918 6.8c2.866-3.353 7.926-3.75 11.28-.886 3.353 2.864 3.75 7.925.886 11.28-2.865 3.353-7.924 3.75-11.28.884a.5.5 0 00-.649.761 8.929 8.929 0 006.55 2.13 8.941 8.941 0 006.14-3.127c3.222-3.773 2.775-9.465-.998-12.689z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.001 8.317a.5.5 0 00-.5.5v3.244l.002.012c.001.038.013.073.022.11.007.027.009.055.02.08.011.024.03.043.044.065.021.033.041.067.07.094l.007.01 2.819 2.55a.502.502 0 00.706-.036.5.5 0 00-.035-.706l-2.655-2.401V8.817a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAgreementType
