import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosSalvaged: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 21.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.672a6.672 6.672 0 100-13.344 6.672 6.672 0 000 13.344z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.378 12c0 .798-.653 1.45-1.45 1.45-.798 0-1.45-.652-1.45-1.45 0-.797.652-1.45 1.45-1.45M12.943 8.23c-.29-.291-.58-.436-.943-.436s-.653.145-.943.435M8.664 9.97c-.363.217-.58.435-.725.797a1.31 1.31 0 00.145 1.016M9.026 14.538c.073.363.218.726.508.87.29.218.653.218 1.015.218M13.45 15.626c.363.073.726 0 1.016-.217.29-.218.435-.508.507-.87M15.844 11.783c.145-.363.217-.726.145-1.016-.073-.29-.363-.58-.726-.798"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosSalvaged
