import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosDrivetrain: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.962 18.437a5.627 5.627 0 100-11.255 5.627 5.627 0 000 11.255z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.145 3.15l-.38-.15M22 9.552a10.08 10.08 0 00-4.106-5.422m-.685 1.883l1.521 1.506c-.532.301-.76.979-.38 1.506s.988.753 1.52.452l.61 2.109c-.61 0-1.142.527-1.142 1.13 0 .602.533 1.13 1.141 1.13l-.608 2.108c-.533-.301-1.217-.15-1.521.377-.304.527-.152 1.205.38 1.506l-1.52 1.506c-.305-.527-.99-.753-1.522-.452-.532.302-.76.98-.38 1.507l-2.13.602c0-.602-.532-1.13-1.14-1.13-.608 0-1.14.527-1.14 1.13l-2.13-.602c.304-.528.076-1.206-.38-1.507m-1.597.527L5.27 17.912c.532-.301.76-.979.456-1.506s-.988-.753-1.52-.377l-.61-2.108c.61 0 1.142-.528 1.142-1.13 0-.603-.533-1.13-1.141-1.13l.608-2.109c.533.302 1.217.15 1.521-.452.304-.527.152-1.205-.456-1.506l1.52-1.506c.305.527.99.753 1.522.452.532-.302.76-.98.38-1.507l2.13-.602c0 .602.532 1.13 1.14 1.13.608 0 1.14-.528 1.14-1.13l2.13.602c-.304.528-.076 1.205.38 1.507M4.586 20.096c.152.15.304.302.532.452l-.532-.452zM2 15.88c.38 1.054.837 2.033 1.52 2.937L2 15.879z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosDrivetrain
