import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgChevronLeftSmall: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 8.995l3.5 3.5M13.5 12.495l-3.5 3.5"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgChevronLeftSmall
