import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgChevronDouble: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="chevron-double_svg__a"
        height={16}
        maskUnits="userSpaceOnUse"
        width={12}
        x={6}
        y={4}
      >
        <path
          clipRule="evenodd"
          d="M7.854 10.354a.502.502 0 01-.708 0 .502.502 0 010-.708l4.5-4.5a.502.502 0 01.708 0l4.5 4.5a.502.502 0 01-.708.708L12 6.207zM12 17.793l4.146-4.146a.5.5 0 01.707.707l-4.5 4.5a.498.498 0 01-.706 0l-4.5-4.5a.5.5 0 01.707-.707z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <path
        clipRule="evenodd"
        d="M7.854 10.354a.502.502 0 01-.708 0 .502.502 0 010-.708l4.5-4.5a.502.502 0 01.708 0l4.5 4.5a.502.502 0 01-.708.708L12 6.207zM12 17.793l4.146-4.146a.5.5 0 01.707.707l-4.5 4.5a.498.498 0 01-.706 0l-4.5-4.5a.5.5 0 01.707-.707z"
        fill="#3e4153"
        fillRule="evenodd"
      />
      <g mask="url(#chevron-double_svg__a)">
        <path d="M0 0h24v24H0z" fill="#3e4153" />
      </g>
    </Icon>
  )
}
export default SvgChevronDouble
