import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTwitterSmall: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 1.7c-.6.3-1.2.4-1.9.5.7-.4 1.2-1.1 1.4-1.9-.6.4-1.3.7-2.1.8C12.8.4 11.9 0 11 0 9.2 0 7.7 1.5 7.7 3.4c0 .3 0 .5.1.8-2.6-.1-5-1.5-6.7-3.5-.2.5-.4 1.1-.4 1.7 0 1.2.6 2.2 1.5 2.8-.5 0-1-.2-1.5-.4 0 1.6 1.1 3 2.6 3.3-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5 1 3.2 1.5 5 1.5 6 0 9.3-5.1 9.3-9.6v-.4c.7-.4 1.3-1 1.7-1.6z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgTwitterSmall
