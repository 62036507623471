import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPinterest: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 0C5.394 0 0 5.394 0 12s5.394 12 12 12 12-5.394 12-12c-.037-6.606-5.394-12-12-12zm4.587 13.761c-1.578 1.945-4.147 2.092-5.32.881-.074-.11-.148-.183-.258-.293 0 .036-.037.073-.037.11-.55 2.202-.623 2.715-1.21 3.743-.294.477-.588.954-.955 1.394-.036.037-.073.11-.146.11-.074 0-.11-.11-.11-.146a9.319 9.319 0 01-.11-2.019c.036-.88.146-1.174 1.284-5.981 0-.074 0-.147-.037-.184-.257-.734-.33-1.504-.073-2.238.513-1.652 2.385-1.762 2.715-.404.184.844-.33 1.945-.734 3.56-.33 1.32 1.248 2.275 2.57 1.32 1.247-.88 1.724-3.045 1.614-4.586-.184-3.046-3.523-3.707-5.652-2.716-2.422 1.138-2.972 4.147-1.871 5.504.147.184.257.294.183.478-.073.293-.146.55-.22.844-.073.22-.22.293-.44.183-.404-.147-.734-.404-1.028-.77-.917-1.138-1.21-3.45.037-5.358 1.358-2.129 3.89-3.01 6.202-2.753 2.752.33 4.514 2.202 4.844 4.367.22.844.11 3.266-1.248 4.954z"
        fill="#4b4881"
      />
    </Icon>
  )
}
export default SvgPinterest
