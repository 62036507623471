import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgIconCategoryVacationRentals: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.004 48.004" {...props}>
      <circle cx={24} cy={24} r={24} fill="currentColor" />
      <path
        fill="#373373"
        d="M28.4 34.006h-5.664a6.176 6.176 0 001.539-8.36c.008-.019.028-.028.034-.047l1.422-4.539a4.418 4.418 0 012.306 1.803c.003.006.008.009.011.014a.619.619 0 00.08.101.61.61 0 00.073.085.63.63 0 00.102.056.608.608 0 00.1.055l.016.002.014.008a.62.62 0 00.162.021c.006 0 .011-.006.017-.006a.62.62 0 00.269-.064 5.503 5.503 0 013.54-.358 5.762 5.762 0 012.956 2.073.625.625 0 001.034-.703 6.945 6.945 0 00-3.673-2.578 6.47 6.47 0 00-3.31.048 11.512 11.512 0 00-.697-6.654 11.166 11.166 0 015.003 4.516.625.625 0 001.078-.634 12.418 12.418 0 00-7.539-5.736l-.005-.002c-.02-.005-.038-.013-.058-.018a12.47 12.47 0 00-13.694 5.33.626.626 0 00.83.885 5.239 5.239 0 013.566-.338 5.058 5.058 0 012.889 2.023c.004.008.013.013.018.02a.51.51 0 00.29.233.603.603 0 00.09.042h.02c.007.001.011.006.018.007a.675.675 0 00.104.008 97.462 97.462 0 01.156-.027.605.605 0 00.138-.056c.016-.01.036-.01.052-.022a4.373 4.373 0 012.833-.447l-1.178 3.756a6.238 6.238 0 00-10.567 4.503 6.193 6.193 0 00.972 3.314.613.613 0 00.163.258 6.24 6.24 0 005.115 2.678H28.4a.625.625 0 000-1.25zM18.23 17.758a6.446 6.446 0 00-2.523-.151 11.212 11.212 0 019.532-3.609 10.31 10.31 0 00-4.182 5.36 6.348 6.348 0 00-2.827-1.6zm4.063 1.867a8.973 8.973 0 014.644-5.128c1.644 1.924 1.528 4.95 1.29 6.652a6.282 6.282 0 00-2.712-1.476l-.003-.002h-.005l-.023-.008a6.282 6.282 0 00-3.191-.038zm-.634 13.612a8.517 8.517 0 00-.321-7.022 4.767 4.767 0 012.102.456 4.963 4.963 0 01-1.781 6.566zm-1.174-8.213a6.694 6.694 0 00-1.222-1.004 5.002 5.002 0 012.742.983 6.703 6.703 0 00-1.52.02zm-3.237-.677a9.179 9.179 0 011.755 1.02 8.636 8.636 0 00-1.371.59 7.434 7.434 0 00-3.482 4.13 4.943 4.943 0 013.098-5.74zm-2.263 7.58a6.504 6.504 0 013.245-4.873 7.051 7.051 0 011.737-.664c2.012 2.988.529 6.55.055 7.515a4.967 4.967 0 01-5.037-1.979z"
      />
      <path fill="#373373" d="M35.9 29.006h-8.125a.625.625 0 000 1.25H35.9a.625.625 0 100-1.25z" />
    </Icon>
  )
}
export default SvgIconCategoryVacationRentals
