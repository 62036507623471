import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgYoutubeSmall: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.4 1.1c-.5-.8-.9-.9-1.9-1-1 0-3.4-.1-5.5-.1S3.5 0 2.5.1 1.1.3.6 1.1C.2 1.8 0 3.2 0 5.6s.2 3.7.7 4.5c.5.8.9.9 1.9 1 1 0 3.4.1 5.5.1s4.5 0 5.5-.1 1.4-.2 1.9-1 .7-2.1.7-4.5c-.2-2.4-.4-3.8-.8-4.5z"
        fill="currentColor"
      />
      <path d="M6.4 2.2v5.9l4.5-3-4.5-2.9z" fill="#fff" />
    </Icon>
  )
}
export default SvgYoutubeSmall
