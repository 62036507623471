import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgListingNoImage: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M69.733 65.852H12.27c-.9 0-1.612-.298-2.097-.784-.485-.485-.783-1.196-.783-2.097V19.03c0-.9.298-1.612.783-2.098.485-.485 1.196-.783 2.097-.783h57.463c.9 0 1.612.298 2.097.784.486.485.784 1.196.784 2.097V62.97c0 .9-.298 1.612-.784 2.098-.485.485-1.196.783-2.097.783z"
        fill="#ECECEE"
        stroke="currentColor"
      />
      <path
        d="M25.79 39.31c3.719 0 6.761-3.042 6.761-6.76 0-3.719-3.042-6.76-6.76-6.76s-6.76 3.041-6.76 6.76c0 3.718 3.042 6.76 6.76 6.76zM22.41 59.591L41.001 41l11.831 11.83M47.762 46.07l6.76-6.76 6.76 6.76"
        stroke="currentColor"
        strokeWidth={0.989}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.608 72.727H19.145c-.9 0-1.612-.298-2.097-.784-.485-.485-.783-1.196-.783-2.097V25.904c0-.9.298-1.612.783-2.098.485-.485 1.196-.783 2.097-.783h57.463c.9 0 1.612.298 2.097.784.486.485.784 1.196.784 2.097v43.942c0 .9-.298 1.612-.784 2.097-.485.486-1.196.784-2.097.784z"
        fill="#ECECEE"
        stroke="currentColor"
      />
      <path
        d="M32.666 46.185c3.718 0 6.76-3.042 6.76-6.76 0-3.719-3.042-6.76-6.76-6.76s-6.76 3.041-6.76 6.76c0 3.718 3.042 6.76 6.76 6.76zM29.285 66.466l18.591-18.591 11.831 11.83M54.637 52.946l6.76-6.76 6.76 6.76"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgListingNoImage
