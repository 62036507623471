import {
  ATVS_SNOWMOBILES_CATEGORIES,
  AUTOMOTIVE_SERVICES_CATEGORIES,
  BOATS_WATERCRAFT_CATEGORIES,
  CARS_VEHICLES_CATEGORIES,
  isAnyRealEstateCategory,
  isAutosCategory,
  MOTORCYCLES_CATEGORIES,
  RVS_CAMPERS_TRAILERS_CATEGORIES,
  VEHICLES_PART_ACCESSORIES_CATEGORIES,
} from '@kijiji/category'
import { JSON_LD_SCHEMA_TYPES } from '@kijiji/seo/constants'

export const getProductSchemaType = (listingCategoryId: number, hasVin: boolean) => {
  const isVehicleParts = VEHICLES_PART_ACCESSORIES_CATEGORIES.includes(listingCategoryId)
  const isCar = isAutosCategory(listingCategoryId)
  const isRV = RVS_CAMPERS_TRAILERS_CATEGORIES.includes(listingCategoryId)
  const isMotorcycle = MOTORCYCLES_CATEGORIES.includes(listingCategoryId)
  const isHeavyEquipment = listingCategoryId === CARS_VEHICLES_CATEGORIES.HEAVY_EQUIPMENT
  const isBoatsWatercraft = BOATS_WATERCRAFT_CATEGORIES.includes(listingCategoryId)
  const isAtvSnowmobile = ATVS_SNOWMOBILES_CATEGORIES.includes(listingCategoryId)
  const isFarmingEquipment = listingCategoryId === CARS_VEHICLES_CATEGORIES.FARMING_EQUIPMENT
  const isAutomotiveServicesCategory = AUTOMOTIVE_SERVICES_CATEGORIES.includes(listingCategoryId)
  const isRealEstate = isAnyRealEstateCategory(listingCategoryId)

  switch (true) {
    case isRealEstate:
      return JSON_LD_SCHEMA_TYPES.REAL_ESTATE

    case isCar:
      return hasVin ? JSON_LD_SCHEMA_TYPES.CAR : JSON_LD_SCHEMA_TYPES.VEHICLE

    case isMotorcycle:
      return JSON_LD_SCHEMA_TYPES.MOTORCYCLE

    case isRV:
    case isBoatsWatercraft:
    case isAtvSnowmobile:
      return JSON_LD_SCHEMA_TYPES.VEHICLE

    case isAutomotiveServicesCategory:
      return JSON_LD_SCHEMA_TYPES.SERVICE

    case isVehicleParts:
    case isHeavyEquipment:
    case isFarmingEquipment:
    default:
      return JSON_LD_SCHEMA_TYPES.PRODUCT
  }
}
