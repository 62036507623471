import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgProfessionalAlt: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 20V10M2 20h12M12 13H7v7h5v-7z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8 21s-.8-.7-1.6-1.6c-.8-.9-1.6-2.2-1.6-3.2 0-.9.4-1.7 1-2.3.6-.6 1.4-.9 2.2-.9.8 0 1.7.3 2.2.9.6.6 1 1.3 1 2.3 0 1.1-.8 2.3-1.6 3.2-.8.9-1.6 1.6-1.6 1.6z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 16.2c0-.3-.1-.7-.4-.9-.2-.2-.5-.4-.9-.4-.3 0-.7.1-.9.4-.2.2-.4.5-.4.9 0 .3.1.7.4.9.2.2.5.4.9.4M3 8.4C3 9.3 4 10 5.1 10s2.1-.7 2.1-1.6V6H3v2.4zM7.3 8.4c0 .9 1 1.6 2.1 1.6s2.1-.7 2.1-1.6V6H7.3v2.4zM11.5 8.4c0 .9 1 1.6 2.1 1.6s2.1-.7 2.1-1.6V6h-4.2v2.4zM15.7 8.4c0 .9 1 1.6 2.1 1.6s2.1-.7 2.1-1.6V6h-4.2v2.4zM19 11v-1M11.5 3H7L3 6M11.5 3H16l4 3"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgProfessionalAlt
