import { FC, HTMLAttributes, ReactNode } from 'react'

import { ListingImageBadgeWrapper, ListingImageWrapper } from './styled'

export type ListingImageProps = {
  /**
   * Size of the image
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Content of the badge on the side of the image
   * If nothing is passed, no badge will be shown
   * The library already controls text & icon size
   */
  badge?: ReactNode | string
  /**
   * The <img /> tag
   * It will have default styling to fit in the container.
   */
  imageComponent: ReactNode
} & HTMLAttributes<HTMLDivElement>

export const ListingImage: FC<ListingImageProps> = ({
  imageComponent,
  size = 'medium',
  badge,
  ...rest
}) => {
  return (
    <ListingImageWrapper hasImg={!!imageComponent} size={size} {...rest}>
      {size !== 'small' && badge && (
        <ListingImageBadgeWrapper>{badge}</ListingImageBadgeWrapper>
      )}

      {imageComponent}
    </ListingImageWrapper>
  )
}
