import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCarfax: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M35.833 36.667H4.166a.834.834 0 01-.833-.834V4.167c0-.46.373-.834.833-.834h31.667c.46 0 .833.374.833.834v31.666c0 .46-.373.834-.833.834z"
          fill="#ececee"
        />
        <path
          d="M32.916 33.333H7.083a.417.417 0 01-.417-.416v-22.5c0-.23.187-.417.417-.417h25.833c.23 0 .417.187.417.417v22.5c0 .23-.187.416-.417.416z"
          fill="#fff"
        />
        <path
          d="M18.333 26.667a.837.837 0 01-.59-.244L14.41 23.09a.836.836 0 010-1.18.836.836 0 011.18 0l2.743 2.745 6.077-6.078a.836.836 0 011.18 0 .836.836 0 010 1.18l-6.667 6.666a.837.837 0 01-.59.244z"
          fill="#37a864"
        />
        <g fill="#c5c6cb">
          <path d="M22.5 8.333h1.666a1.667 1.667 0 010 3.334h-8.333a1.667 1.667 0 010-3.334h1.666M22.5 8.333C23.118 7.098 21.382 5 20 5s-3.118 2.098-2.5 3.333" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgCarfax
