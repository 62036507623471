import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSaveOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.5 3.5A.5.5 0 015 3h14a.5.5 0 01.5.5v16.793L12.707 13.5a1 1 0 00-1.414 0L4.5 20.293V3.5z"
        stroke="currentColor"
      />
    </Icon>
  )
}
export default SvgSaveOutline
