import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFilters: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path stroke="currentColor" strokeLinecap="round" d="M4 5h16" />
      <circle cx={8} cy={5} r={1} fill="currentColor" stroke="currentColor" />
      <path stroke="currentColor" strokeLinecap="round" d="M4 12h16" />
      <circle cx={12} cy={12} r={1} fill="currentColor" stroke="currentColor" />
      <path stroke="currentColor" strokeLinecap="round" d="M4 19h16" />
      <circle cx={16} cy={19} r={1} fill="currentColor" stroke="currentColor" />
    </Icon>
  )
}
export default SvgFilters
