import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFreebiesBackground: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#freebies-background_svg__clip0_662_279)">
        <path
          d="M24 48.007c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z"
          fill="#F3F3F9"
        />
        <path
          d="M34.63 22.763a.623.623 0 00-.624.625v3.125H24.63v-6.25h8.747a.625.625 0 000-1.25h-5.416a5.442 5.442 0 001.695-1.032 2.941 2.941 0 00.91-2.11 3.095 3.095 0 00-1.968-2.912 3.142 3.142 0 00-1.259-.217 2.763 2.763 0 00-2.017.95 7.24 7.24 0 00-1.282 2.563 7.242 7.242 0 00-1.282-2.562 2.764 2.764 0 00-2.017-.95 3.134 3.134 0 00-2.327.916 3.095 3.095 0 00-.9 2.211 2.941 2.941 0 00.91 2.112 5.44 5.44 0 001.695 1.03h-5.486a1.88 1.88 0 00-1.878 1.879v12.494a1.88 1.88 0 001.878 1.878h18.744a1.88 1.88 0 001.878-1.878v-9.997a.626.626 0 00-.625-.625zm-8.36-8.254a1.526 1.526 0 011.116-.517l.065-.002c.499.004.977.202 1.332.553a1.842 1.842 0 01.535 1.313 1.7 1.7 0 01-.525 1.221 8.152 8.152 0 01-3.994 1.465 9.062 9.062 0 011.47-4.033zm-7.506 1.347a1.842 1.842 0 01.535-1.313c.355-.351.833-.55 1.333-.553l.064.002a1.526 1.526 0 011.117.517 9.062 9.062 0 011.47 4.034 8.128 8.128 0 01-3.995-1.466 1.7 1.7 0 01-.524-1.221zm-4.13 4.407h8.747v6.25h-9.375v-5.622a.629.629 0 01.628-.628zm-.628 13.122v-5.622h9.375v6.25h-8.747a.629.629 0 01-.628-.628zm19.372.628H24.63v-6.25h9.375v5.622a.629.629 0 01-.628.628z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="freebies-background_svg__clip0_662_279">
          <path fill="#fff" transform="translate(0 .007)" d="M0 0h48.004v48.004H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgFreebiesBackground
