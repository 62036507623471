import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMoreVertical: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="more-vertical_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={4}
        height={20}
      >
        <path
          d="M.5 18a1.5 1.5 0 113.001.001A1.5 1.5 0 01.5 18zm0-8a1.5 1.5 0 113.001.001A1.5 1.5 0 01.5 10zm0-8a1.5 1.5 0 113.001.001A1.5 1.5 0 01.5 2z"
          fill="#fff"
          stroke="#373373"
        />
      </mask>
      <g mask="url(#more-vertical_svg__a)" fill="#373373">
        <path d="M0 18a2 2 0 114.001.001A2 2 0 010 18zm0-8a2 2 0 114.001.001A2 2 0 010 10zm0-8a2 2 0 114.001.001A2 2 0 010 2z" />
        <path d="M-10-2v24h24V-2h-24z" />
        <path d="M-10-2v24h24V-2h-24z" />
      </g>
    </Icon>
  )
}
export default SvgMoreVertical
