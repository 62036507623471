import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgConditionCertifiedPreOwned: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.937 3.799l.015.012.016.01c.184.118.383.287.633.5l.096.082c.282.24.612.513.98.736h0l.007.004c.333.195.708.26 1.042.29.17.015.344.023.507.029l.049.002c.15.005.292.01.433.02.626.045 1.085.172 1.403.641h0c.43.63.465 1.117.505 1.682l.005.063c.027.882.352 1.737.924 2.42.53.706.808 1.245.907 1.694.092.425.031.8-.2 1.213-.151.261-.386.478-.662.717l-.047.04c-.117.101-.245.211-.355.321a1.635 1.635 0 00-.348.477h0l-.003.008c-.222.522-.229.997-.234 1.384l-.002.11c-.008.406-.033.766-.258 1.209h0l-.004.01a2.435 2.435 0 01-1.447 1.252h0l-.004.001c-.223.075-.456.065-.782.042l-.044-.003c-.283-.02-.686-.05-1.061.107h0l-.005.002c-.515.223-.956.577-1.316.867l-.045.036c-.401.322-.68.533-.943.61h0l-.011.004c-.452.145-.94.145-1.392 0h0l-.007-.002c-.252-.077-.53-.291-.938-.62l-.02-.017c-.37-.296-.826-.663-1.357-.88-.375-.157-.776-.128-1.059-.107-.015 0-.03.002-.044.003-.326.023-.56.033-.782-.042h0l-.008-.003c-.65-.205-1.17-.647-1.438-1.24l-.004-.01-.005-.01c-.225-.443-.25-.803-.258-1.209l-.002-.11c-.005-.387-.012-.862-.234-1.384h0l-.003-.008a1.635 1.635 0 00-.348-.477c-.11-.11-.238-.22-.355-.32l-.047-.041c-.275-.238-.509-.454-.66-.713-.22-.412-.278-.788-.183-1.214.1-.451.38-.991.91-1.698a3.962 3.962 0 00.924-2.419l.005-.063c.04-.565.076-1.051.505-1.681h0c.32-.472.78-.603 1.405-.65.148-.01.298-.017.455-.023h.025c.163-.007.336-.014.505-.03.332-.028.705-.09 1.037-.276h0l.002-.002c.377-.214.711-.493.994-.735l.126-.11c.238-.205.426-.367.6-.48l.017-.01.015-.011c.258-.195.59-.299.934-.299.344 0 .676.104.934.299z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path d="M8 12l3 2 5-4" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  )
}
export default SvgConditionCertifiedPreOwned
