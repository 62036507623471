import { type ListingFragment } from '@kijiji/generated/graphql-types'
import { priceFromCents } from '@kijiji/money'
import { JSON_LD_SCHEMA } from '../constants'
import { z } from 'zod'

const ProductCarouselSeoSchema = z.object({
  name: z.string(),
  url: z.string(),
  image: z.string(),
  price: z.number().optional(),
  description: z.string().optional(),
})

export type ProductSeoProps = z.infer<typeof ProductCarouselSeoSchema>

/**
 * Transforms an array of ListingFragment objects to an array of ProductSeoProps.
 *
 * @param {ListingFragment[]} listings - Array of Listing objects from GraphQL.
 * @returns {ProductSeoProps[]} Array of ProductSeoProps for SEO use.
 */
export const transformListingsToProductSeo = (listings: ListingFragment[], defaultImageUrl: string): ProductSeoProps[] => {
  return listings.map((listing) => ({
    name: listing.title,
    url: listing.url,
    image: listing.imageUrls[0] ?? defaultImageUrl,
    price: listing.price && 'amount' in listing.price ? listing.price.amount : undefined,
    description: listing.description ?? undefined,
  }))
}

/**
 * Converts an array of ProductSeoProps to SEO-compatible JSON-LD format.
 * This function creates structured data for a list of products, which can be used
 * to enhance search engine understanding and representation of the products.
 *
 * @param {ProductSeoProps[]} products - Array of ProductSeoProps objects.
 * @returns {object[]} Array of JSON-LD product objects in ListItem format.
 *
 * @example
 * const products = [
 *   { name: "Product 1", url: "https://example.com/1", image: "https://example.com/1.jpg", price: 1000 },
 *   { name: "Product 2", url: "https://example.com/2", image: "https://example.com/2.jpg", price: 2000 }
 * ];
 * const jsonLd = productCarouselJsonLd(products);
 * // jsonLd will be an array of ListItem objects with structured product data
 */
export const productCarouselJsonLd = (products: ProductSeoProps[]): object[] => {
  const filteredProducts = products.filter((product) => product.price !== undefined)
  const itemListElement = filteredProducts.map((product, index) => {
    const structuredMarkup = {
      '@context': JSON_LD_SCHEMA,
      type: 'Product',
      name: product.name,
      description: product.description,
      image: product.image,
      url: product.url,
      offers: {
        '@type': 'Offer',
        price: product.price
          ? `${priceFromCents(product.price).dollars}.${priceFromCents(product.price).cents}`
          : undefined,
        priceCurrency: 'CAD',
        availability: 'https://schema.org/InStock',
      },
    }

    return {
      '@type': 'ListItem',
      position: index + 1,
      item: structuredMarkup,
    }
  })

  return itemListElement
}
