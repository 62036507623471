import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFemale: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="female_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#female_svg__a)">
        <path
          d="M11.5 20.5v-2h-2v-1h2v-3.023c-1.278-.144-2.348-.69-3.209-1.638C7.431 11.891 7 10.774 7 9.49c0-1.389.486-2.567 1.459-3.536C9.43 4.984 10.61 4.5 12 4.5c1.389 0 2.569.484 3.541 1.453C16.514 6.922 17 8.1 17 9.488c0 1.286-.43 2.403-1.291 3.351a4.944 4.944 0 01-3.209 1.638V17.5h2v1h-2v2h-1zm.503-7c1.106 0 2.048-.39 2.828-1.172.78-.782 1.169-1.726 1.169-2.831 0-1.106-.39-2.048-1.172-2.828-.782-.78-1.726-1.169-2.831-1.169-1.106 0-2.048.39-2.828 1.172C8.39 7.454 8 8.398 8 9.503c0 1.106.39 2.048 1.172 2.828.782.78 1.726 1.169 2.831 1.169z"
          fill="#3E4153"
        />
      </g>
    </Icon>
  )
}
export default SvgFemale
