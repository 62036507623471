import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgChevronDown: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 23" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="chevron-down_svg__a" height={6} maskUnits="userSpaceOnUse" width={11} x={6} y={8}>
        <path
          clipRule="evenodd"
          d="M12.104 13.754l4.5-4.22a.449.449 0 000-.662.524.524 0 00-.707 0L11.75 12.76 7.604 8.872a.524.524 0 00-.707 0 .453.453 0 00-.147.331c0 .12.049.24.147.332l4.5 4.219a.524.524 0 00.707 0z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <path
        clipRule="evenodd"
        d="M12.104 13.754l4.5-4.22a.449.449 0 000-.662.524.524 0 00-.707 0L11.75 12.76 7.604 8.872a.524.524 0 00-.707 0 .453.453 0 00-.147.331c0 .12.049.24.147.332l4.5 4.219a.524.524 0 00.707 0z"
        fill="#3e4153"
        fillRule="evenodd"
      />
      <g mask="url(#chevron-down_svg__a)">
        <path d="M0 .063h24v22.5H0z" fill="#3e4153" />
      </g>
    </Icon>
  )
}
export default SvgChevronDown
