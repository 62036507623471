import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgExpand: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.36 5.5h2.88M18.24 5.5v3M18.24 5.5L14.88 9M8.64 18.5H5.76M5.76 18.5v-3M5.76 18.5L9.12 15"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgExpand
