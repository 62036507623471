import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVacationRentals: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#3e4153">
        <path d="M15.52 20H11a5 5 0 002-4 4.89 4.89 0 00-.8-2.68l1.13-3.63a3.53 3.53 0 011.85 1.44l.06.08.06.07.08.05h.25c.07.015.141.015.21 0a4.41 4.41 0 012.9-.33 4.61 4.61 0 012.36 1.66.5.5 0 00.903-.189.51.51 0 00-.073-.37A5.54 5.54 0 0019 10.05a5.279 5.279 0 00-2.65 0 9.11 9.11 0 00-.56-5.321 8.92 8.92 0 014 3.61.5.5 0 00.86-.5 9.92 9.92 0 00-6-4.59 10 10 0 00-11 4.27.5.5 0 00.06.61.51.51 0 00.61.09A4.27 4.27 0 017.13 8a4.07 4.07 0 012.31 1.59l.08.08.06.06H10l.11-.05a3.47 3.47 0 012.27-.36l-.95 3A5 5 0 003 16c.001.943.272 1.866.78 2.66a.39.39 0 00.13.2A5 5 0 008 21h7.5a.5.5 0 00.02-1zM7.38 7a5.05 5.05 0 00-2-.12A9 9 0 0113 4a8.2 8.2 0 00-3.35 4.29A5 5 0 007.38 7zm3.25 1.5a7.219 7.219 0 013.72-4.1c1.32 1.54 1.22 4 1 5.32a5 5 0 00-2.17-1.18 5 5 0 00-2.55-.04zm-.5 10.89a6.85 6.85 0 00-.26-5.62 3.64 3.64 0 011.68.37A3.86 3.86 0 0112 16a4 4 0 01-1.87 3.39zm-.94-6.57a5.278 5.278 0 00-1-.8 3.91 3.91 0 012.19.78 4.993 4.993 0 00-1.19.02zm-2.59-.54c.496.217.965.489 1.4.81-.376.129-.74.29-1.09.48a5.94 5.94 0 00-2.79 3.3A4.14 4.14 0 014 16a4 4 0 012.6-3.72zm-1.81 6.06a5.21 5.21 0 012.59-3.9 5.656 5.656 0 011.39-.53c1.61 2.39.43 5.24.05 6A3.568 3.568 0 018 20a4 4 0 01-3.21-1.66z" />
        <path d="M21.52 16H15a.5.5 0 000 1h6.5a.5.5 0 00.02-1z" />
      </g>
    </Icon>
  )
}
export default SvgVacationRentals
