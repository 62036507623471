import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNoImage: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.5 12.6H2c-.6 0-1-.5-1-1V2c0-.6.4-1 1-1h13.5c.6 0 1 .4 1 1v9.6c0 .5-.5 1-1 1z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1 4.3l1.5.2c.2 0 .4.2.4.4l-.9 10.8c0 .2-.2.4-.4.4L4 14.7"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgNoImage
