import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTikTok: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 0C5.394 0 0 5.394 0 12s5.394 12 12 12 12-5.394 12-12c-.037-6.606-5.394-12-12-12zm5.908 10.679h-.33c-1.248 0-2.422-.624-3.083-1.651v5.688a4.182 4.182 0 01-4.183 4.183c-2.312 0-4.184-1.871-4.184-4.183S8 10.532 10.312 10.532h.257v2.055c-.074 0-.184-.036-.257-.036-1.174 0-2.129.954-2.129 2.128s.955 2.128 2.129 2.128 2.239-.917 2.239-2.128l.036-9.651h1.982c.183 1.761 1.614 3.155 3.413 3.302v2.349z"
        fill="#4b4881"
      />
    </Icon>
  )
}
export default SvgTikTok
