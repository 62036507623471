import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWebsite: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.356 4.286H14.28a.643.643 0 010-1.286h6.077a.643.643 0 010 1.286z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.357 10.363a.643.643 0 01-.643-.643V3.643a.643.643 0 011.286 0V9.72a.643.643 0 01-.643.643z"
        fill="currentColor"
      />
      <mask
        id="website_svg__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={12}
        y={3}
        width={9}
        height={9}
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M12.116 3H21v8.883h-8.884V3z" fill="#fff" />
      </mask>
      <g mask="url(#website_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.76 11.883a.645.645 0 01-.455-1.098l7.598-7.597a.643.643 0 01.909.909l-7.598 7.599a.646.646 0 01-.455.187z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.571 21H6.428A3.432 3.432 0 013 17.571V6.429A3.432 3.432 0 016.428 3H9.72a.643.643 0 010 1.286H6.428a2.145 2.145 0 00-2.142 2.143v11.142c0 1.182.96 2.143 2.142 2.143H17.57a2.146 2.146 0 002.143-2.143V14.28a.642.642 0 111.286 0v3.291A3.433 3.433 0 0117.571 21z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgWebsite
