import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWhatsappCircle: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#whatsapp-circle_svg__clip0_4316_8251)"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
          fill="#57BB63"
        />
        <path
          d="M12.029 18.489a6.537 6.537 0 01-3.155-.806l-3.612 1.148 1.177-3.474a6.41 6.41 0 01-.936-3.343c0-3.576 2.922-6.475 6.526-6.475 3.604 0 6.525 2.899 6.525 6.475 0 3.576-2.921 6.475-6.525 6.475zm0-11.919c-3.026 0-5.486 2.442-5.486 5.444 0 1.191.388 2.294 1.044 3.192l-.685 2.021 2.108-.67c.867.57 1.904.9 3.019.9 3.025 0 5.486-2.441 5.486-5.443S15.054 6.57 12.029 6.57zm3.295 6.935c-.04-.066-.147-.106-.307-.185-.16-.08-.947-.463-1.093-.516-.147-.053-.253-.08-.36.08a9.159 9.159 0 01-.507.621c-.093.106-.186.12-.346.04-.16-.08-.675-.247-1.286-.787a4.778 4.778 0 01-.89-1.1c-.093-.158-.01-.244.07-.323.072-.071.16-.185.24-.278.08-.093.107-.159.16-.265.053-.106.027-.198-.013-.278-.04-.08-.36-.86-.494-1.178-.133-.318-.266-.265-.36-.265-.093 0-.2-.013-.306-.013a.59.59 0 00-.427.198c-.146.16-.56.543-.56 1.324s.574 1.536.654 1.642c.08.105 1.106 1.76 2.732 2.396 1.626.635 1.626.423 1.92.397.293-.027.946-.384 1.08-.755.132-.37.132-.688.093-.755z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="whatsapp-circle_svg__clip0_4316_8251">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgWhatsappCircle
