import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSmoking: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.999 15.933h4.806v-1.884H4.999v1.884zm10.996-2.903c-.277 0-.5.229-.5.51v2.393h-4.692v-1.884h2.755c.275 0 .5-.227.5-.51a.505.505 0 00-.5-.509H4.499c-.275 0-.499.229-.499.51v2.902c0 .282.224.51.5.51h11.495c.275 0 .499-.229.499-.51V13.54a.505.505 0 00-.5-.51zM17.654 12.982c-.276 0-.5.228-.5.51v2.999c0 .28.224.509.5.509.275 0 .499-.228.499-.51v-2.999a.505.505 0 00-.5-.51zM19.314 12.982c-.276 0-.5.228-.5.51v2.999c0 .28.224.509.5.509s.5-.228.5-.51v-2.999a.505.505 0 00-.5-.51zM15.619 5.656a.511.511 0 00-.333-.634.5.5 0 00-.624.34c-.026.088-.631 2.196.957 3.804 1.532 1.552 1.643 2.834 1.643 2.84a.502.502 0 00.498.483l.028-.001a.505.505 0 00.471-.536c-.004-.067-.109-1.657-1.936-3.508-1.155-1.17-.723-2.723-.704-2.788z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.361 8.444c-1.185-1.201-1.004-2.787-.994-2.866a.507.507 0 00-.427-.573.5.5 0 00-.563.434c-.012.088-.272 2.155 1.28 3.727 1.482 1.502 1.348 2.689 1.341 2.73a.509.509 0 00.494.593c.24 0 .451-.178.492-.427.011-.07.25-1.72-1.623-3.618z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSmoking
