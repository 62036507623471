import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgEntryMileage: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#2d2f3b">
        <path d="M5.632 6.632c3.509-3.51 9.219-3.51 12.728 0 3.509 3.509 3.509 9.219 0 12.728a.748.748 0 01-1.06 0 .75.75 0 010-1.06c2.923-2.925 2.923-7.683 0-10.608-2.925-2.924-7.683-2.924-10.608 0-2.924 2.925-2.924 7.683 0 10.607a.75.75 0 11-1.06 1.06c-3.51-3.508-3.51-9.218 0-12.727z" />
        <path d="M11.466 8.776a.73.73 0 01-.22-.53.738.738 0 01.127-.418.79.79 0 01.335-.275.753.753 0 011.038.693.738.738 0 01-.128.417.754.754 0 01-1.04.205.79.79 0 01-.112-.092zM8.923 15.66a.834.834 0 01.245.163.758.758 0 01.163.818.821.821 0 01-.163.244.767.767 0 01-.53.218.769.769 0 01-.532-.218.781.781 0 01-.205-.385.759.759 0 01.32-.77.846.846 0 01.13-.07.88.88 0 01.14-.042.808.808 0 01.292 0c.048.01.095.025.14.043zM17.276 13.526a.802.802 0 00.162-.243.739.739 0 00-.162-.817.751.751 0 00-1.28.53.753.753 0 00.897.734.687.687 0 00.268-.112.703.703 0 00.115-.092zM6.716 12.466a.774.774 0 011.06 0c.14.14.22.332.22.53a.753.753 0 01-.898.735.916.916 0 01-.14-.043.776.776 0 01-.242-.162.753.753 0 010-1.06zM15.208 15.618a.728.728 0 00-.27.112.752.752 0 00-.333.623c0 .198.08.393.218.532a.758.758 0 001.06 0c.14-.14.22-.335.22-.532s-.08-.39-.217-.53a.767.767 0 00-.678-.205zM9.26 9.22c.028.04.05.086.07.13a.71.71 0 01.057.285.77.77 0 01-.219.533.767.767 0 01-.53.218c-.2 0-.392-.08-.532-.218a.77.77 0 01-.205-.677.904.904 0 01.042-.14.83.83 0 01.163-.245.782.782 0 011.062 0c.032.035.065.075.093.115zM16.276 9.776a.75.75 0 10-1.06-1.06l-.383.382a.04.04 0 01-.005.004l-.005.004-.004.005a.045.045 0 01-.004.005l-2.025 2.025a2.002 2.002 0 00-2.794 1.835c0 1.103.897 2 2 2a2.002 2.002 0 001.847-2.767z" />
      </g>
    </Icon>
  )
}
export default SvgEntryMileage
