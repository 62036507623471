import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosPrice: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 21.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M12.291 16.041a2.59 2.59 0 00.812-.196c.233-.107.43-.243.588-.406.163-.163.285-.352.364-.567a2 2 0 00.126-.721 1.273 1.273 0 00-.483-1.036 2.01 2.01 0 00-.525-.315c-.2-.089-.415-.17-.644-.245zm-.231-8.092a2.703 2.703 0 00-.791.182c-.22.093-.401.21-.546.35s-.252.299-.322.476a1.44 1.44 0 00-.105.546c0 .224.04.42.119.588.08.163.189.308.329.434s.303.236.49.329.39.177.609.252zm.553 3.402c.336.107.667.222.994.343s.62.278.882.469.471.432.63.721c.163.285.245.642.245 1.071 0 .42-.07.814-.21 1.183s-.345.693-.616.973a3.11 3.11 0 01-.987.686c-.387.173-.83.275-1.33.308l-.084 1.225a.343.343 0 01-.105.231.321.321 0 01-.245.105h-.462l.105-1.575a4.593 4.593 0 01-1.547-.42 4.264 4.264 0 01-1.197-.868l.378-.574c.037-.051.084-.093.14-.126s.117-.049.182-.049c.089 0 .189.044.301.133s.25.189.413.301c.168.112.364.222.588.329.229.107.502.18.819.217l.252-3.717a13.842 13.842 0 01-.966-.329 3.218 3.218 0 01-.847-.483 2.32 2.32 0 01-.602-.749c-.15-.299-.224-.674-.224-1.127a2.6 2.6 0 01.784-1.862c.257-.252.572-.457.945-.616.373-.163.8-.254 1.281-.273l.07-1.008a.386.386 0 01.098-.238.33.33 0 01.252-.105h.462l-.091 1.379c.485.06.905.187 1.26.378.36.191.677.425.952.7l-.301.462c-.093.14-.2.21-.322.21a.525.525 0 01-.245-.084 8.898 8.898 0 00-.343-.21 3.385 3.385 0 00-.469-.231 2.682 2.682 0 00-.609-.168z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgAutosPrice
