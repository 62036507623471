import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCarfaxOneOwner: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 120 50" {...props}>
      <path
        fill="#00A944"
        fillRule="evenodd"
        d="M3.967 0h112.066a3.95 3.95 0 012.805 1.172A4.017 4.017 0 01120 4v42a4.017 4.017 0 01-1.162 2.828A3.95 3.95 0 01116.033 50H3.967a3.95 3.95 0 01-2.805-1.172A4.017 4.017 0 010 46V4a4.017 4.017 0 011.162-2.828A3.95 3.95 0 013.967 0z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M68.192 29.67v1.94h-.625v-1.94h-.724v-.5h2.073v.5h-.724zm3.213 1.94V30l-.585 1.35h-.407L69.818 30v1.59h-.555v-2.42h.674l.665 1.45.654-1.45h.674v2.44h-.525zm-55.051-3.83c-2.926 0-5.048 2-5.048 4.7a4.803 4.803 0 001.5 3.485 4.694 4.694 0 003.549 1.275 4.704 4.704 0 003.544-1.279 4.78 4.78 0 001.109-1.584 4.815 4.815 0 00.394-1.897c0-2.73-2.123-4.7-5.048-4.7zm0 2.54a1.971 1.971 0 011.404.684 2.005 2.005 0 01.48 1.496 2.015 2.015 0 01-.472 1.51 1.987 1.987 0 01-1.412.69 2.057 2.057 0 01-1.448-.682 2.094 2.094 0 01-.536-1.518 2.076 2.076 0 01.548-1.528 2.042 2.042 0 011.475-.652h-.04zm17.137 6.79l2.975-9.24h-3.045l-1.358 6.06-1.389-6.06H27.7l-1.369 6.05-1.388-6.06H21.71l2.975 9.24h3.134l1.31-5.52 1.269 5.52 3.094.01zm12.823 0v-9.24h-2.678v4.74l-3.67-4.74h-2.538v9.24h2.767v-4.72l3.62 4.72h2.499zm9.392-6.92v-2.32H47.97v9.24h7.934v-2.32h-4.89v-1.15h4.295v-2.32h-4.294v-1.13h4.69zm6.853 6.92h3.44l-1.814-3a3.18 3.18 0 001.128-1.257 3.212 3.212 0 00.33-1.663c0-2.11-1.428-3.31-3.967-3.31h-4.354v9.24h3.035v-2.4h1.13l1.101 2.4-.03-.01zm-2.242-4.72v-2.2h1.408a.985.985 0 01.731.32 1.002 1.002 0 01.261.76 1.008 1.008 0 01-.245.786.992.992 0 01-.747.334h-1.408zm-41.405-17.9a4.487 4.487 0 011.809 1.695 4.539 4.539 0 01.64 2.405 4.57 4.57 0 01-.637 2.42 4.518 4.518 0 01-1.812 1.71 5.379 5.379 0 01-2.578.62 5.496 5.496 0 01-2.589-.61A4.54 4.54 0 0111.95 21a4.71 4.71 0 01-.644-2.44 4.5 4.5 0 01.63-2.407 4.448 4.448 0 011.81-1.693 5.497 5.497 0 012.588-.6 5.488 5.488 0 012.578.63zm-3.52 2.22a2.073 2.073 0 00-.714.78 2.265 2.265 0 00-.268 1.11 2.367 2.367 0 00.268 1.12 2.103 2.103 0 00.714.79 1.803 1.803 0 00.991.29 1.676 1.676 0 00.992-.29 1.995 1.995 0 00.684-.79 2.478 2.478 0 00.248-1.12 2.407 2.407 0 00-.248-1.11 1.915 1.915 0 00-.684-.78 1.666 1.666 0 00-.992-.28 1.892 1.892 0 00-.991.28zm13.557 6.5l-3.6-4.71v4.71h-2.767V14h2.539l3.64 4.72V14h2.736v9.23l-2.548-.02zm7.25-6.91v1.13h4.274v2.31h-4.275v1.15h4.83v2.32h-7.864V14h7.745v2.3h-4.71z"
      />
    </Icon>
  )
}
export default SvgCarfaxOneOwner
