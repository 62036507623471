import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSaveSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 3.5a1 1 0 011-1h14a1 1 0 011 1v16.793a.5.5 0 01-.854.353l-6.792-6.792a.5.5 0 00-.708 0l-6.792 6.792A.5.5 0 014 20.293V3.5z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSaveSolid
