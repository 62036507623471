import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFuelHybrid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.356 19.774L7.731 17.4a6.03 6.03 0 003.361.984 7.116 7.116 0 005.068-2.21c3.052-3.048 3.803-10.964 3.837-11.299a.8.8 0 00-.873-.872c-.335.03-8.243.78-11.298 3.832-2.856 2.86-2.572 6.457-1.227 8.433l-2.374 2.375a.8.8 0 001.131 1.131zM8.958 8.97c2.068-2.067 7.167-2.961 9.324-3.251-.293 2.155-1.183 7.252-3.253 9.322-2.235 2.232-4.758 1.967-6.137 1.198l3.667-3.667a.8.8 0 00-1.131-1.132L7.76 15.106c-.769-1.379-1.034-3.905 1.197-6.136z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgFuelHybrid
