import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosHatchback: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.094 10.295l-.036-1.4M4.061 15.668H1.712c-.392 0-.712-.341-.712-.757V13.7c0-.38.142-1.363.783-1.741.748-.416 3.168-1.022 4.094-1.173.142-.038.25-.076.392-.151.712-.492 4.307-2.687 5.695-2.876 2.777-.34 5.59-.34 8.402-.038.997.076 1.46 1.173 1.958 2.043.213.454.32.946.356 1.476v.832c0 .681.32.908.32 1.514 0 .416-.107.832-.356 1.172M8.867 15.668h9.007"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.152 11.014c6.194 0 9.505-.984 10.11-1.097.285-.076.925-1.022 1.032-2.081M21.362 11.582h1.14M19.369 16.5c1.003 0 1.816-.864 1.816-1.93 0-1.065-.813-1.929-1.816-1.929-1.003 0-1.816.864-1.816 1.93 0 1.065.813 1.93 1.816 1.93z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.369 16.5c1.003 0 1.816-.864 1.816-1.93 0-1.065-.813-1.929-1.816-1.929-1.003 0-1.816.864-1.816 1.93 0 1.065.813 1.93 1.816 1.93zM1.107 13.738l1.14-.378c.284-.114.533-.265.747-.454M14.634 12.414h.783M5.557 16.5c1.002 0 1.815-.864 1.815-1.93 0-1.065-.813-1.929-1.815-1.929-1.003 0-1.816.864-1.816 1.93 0 1.065.813 1.93 1.816 1.93z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.557 16.5c1.002 0 1.815-.864 1.815-1.93 0-1.065-.813-1.929-1.815-1.929-1.003 0-1.816.864-1.816 1.93 0 1.065.813 1.93 1.816 1.93z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosHatchback
