import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSendMessageApp: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.168 12L2.076 4.427c-.16-.395-.06-.846.252-1.138.314-.294.78-.372 1.174-.195l17.875 7.959c.38.169.624.54.623.947 0 .408-.243.779-.623.948L3.503 20.907c-.395.176-.86.099-1.183-.202l-.01-.01a1.026 1.026 0 01-.234-1.122L5.168 12zm-2.185 7.928a.083.083 0 00.018.09.088.088 0 00.097.018l17.875-7.96a.084.084 0 00.05-.077.082.082 0 00-.05-.075L3.098 3.964a.086.086 0 00-.095.017.082.082 0 00-.02.091l3.075 7.533h6.095c.27 0 .488.214.488.478a.484.484 0 01-.488.479H5.99l-3.008 7.366z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSendMessageApp
