import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWanted: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#wanted_svg__clip0_808_275)" fill="currentColor">
        <path d="M4.5 9c-.3 0-.5.2-.5.5v3c0 .3.2.5.5.5s.5-.2.5-.5v-3c0-.3-.2-.5-.5-.5zM7.5 9H6.4c-.3 0-.5.2-.5.5s.2.5.5.5h1.1c.3 0 .5-.2.5-.5S7.8 9 7.5 9z" />
        <path d="M19 10.5c0-.8-.4-1.5-1.1-1.8l-.9-.5V5.1c0-.5-.3-.9-.7-1.1-.5-.1-1 0-1.3.4l-.2.2c-1.4 1.5-3 3.2-4.9 3.4-.2-.6-.8-1-1.4-1h-4c-.7 0-1.3.5-1.5 1.2-.4.3-1 1-1 2.8 0 1.8.6 2.5 1 2.8.2.7.8 1.2 1.5 1.2H5c-.1 2.4-.6 4.1-.6 4.2v.1c0 .9.6 1.7 1.5 1.7h3c.8 0 1.5-.8 1.5-1.7 0-.1 0-.2-.1-.3l-1.2-1.6.4-.9c0-.1.1-.2 0-.3l-.4-1.4c.4-.2.6-.5.8-.8 1.9.2 3.4 1.8 4.7 3.2l.4.4c.2.2.5.4.8.4.1 0 .3 0 .4-.1.4-.2.7-.6.7-1.1V14l1-.6c.6-.4 1-1 1-1.8v-1.1h.1zm-15 3c0-.2-.1-.4-.3-.5-.1 0-.7-.2-.7-2 0-1.7.6-2 .6-2 .2 0 .4-.2.4-.5 0-.2.2-.5.5-.5h4c.3 0 .5.3.5.5v5c0 .3-.2.5-.5.5h-4c-.3 0-.5-.2-.5-.5zm5.4 6c-.1.3-.3.5-.5.5h-3c-.3 0-.5-.3-.5-.6.1-.4.5-2.1.6-4.4h2.1l.4 1.3-.4 1c-.1.2-.1.4 0 .5l1.3 1.7zm6.5-2.6c0 .1 0 .1-.1.1h-.1l-.4-.4c-1.4-1.6-3-3.3-5.3-3.6V9c2.3-.3 4.1-2.2 5.5-3.7l.2-.3s.1-.1.1 0c0 0 .1 0 .1.1v11.8zm2.1-5.2c0 .4-.2.7-.5.9l-.5.3V9.3l.5.2c.3.2.5.6.5 1v1.2zM19 6.5c.1 0 .3 0 .4-.1l1.1-1.1c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0l-1.1 1.1c-.2.2-.2.5 0 .7 0 .1.2.1.3.1zM19.4 15.7c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l.9.9c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7l-1-.9zM21.5 10.5h-1c-.3 0-.5.2-.5.5s.2.5.5.5h1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z" />
      </g>
      <defs>
        <clipPath id="wanted_svg__clip0_808_275">
          <path fill="#fff" transform="translate(2 4)" d="M0 0h20v17H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
export default SvgWanted
