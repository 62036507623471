import { type ReactNode } from 'react'

import { type FavouriteButtonSize } from '@/components/shared/listing/FavouriteButton'
import { useMediaQuery } from '@/hooks/client-only/useMediaQuery'

type GetMediaFavouriteButtonSizeType = {
  children: ({ size }: { size: FavouriteButtonSize }) => ReactNode
}

export const GetMediaFavouriteButtonSize = ({ children }: GetMediaFavouriteButtonSizeType) => {
  const { isMediumScreen, isLargeScreen } = useMediaQuery()
  return children({ size: isMediumScreen || isLargeScreen ? 'xLarge' : 'large' })
}
