import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { useEnsureClient } from '@/components/shared/client-render'

/* Fixes issue where the query provides the incorrect width on initial render */
const hasWindow = typeof window !== 'undefined'
const getWidth = () => (hasWindow ? window.innerWidth : 0)

export type MediaQueryHookResponse = {
  isSmallScreen: boolean
  isMediumScreen: boolean
  isLargeScreen: boolean
}

export const useMediaQuery = (): MediaQueryHookResponse => {
  useEnsureClient()

  const [browserWidth, setBrowserWidth] = useState(getWidth())
  const { breakpoints } = useTheme()

  useEffect(() => {
    const handleResize = () => {
      const width = getWidth()
      setBrowserWidth(width)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isSmallScreen = browserWidth < breakpoints.medium
  const isMediumScreen = browserWidth >= breakpoints.medium && browserWidth < breakpoints.large
  const isLargeScreen = browserWidth >= breakpoints.large

  return { isSmallScreen, isMediumScreen, isLargeScreen }
}
