import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAutosFuelType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.037 20H2.5m9.658-9.448H6.38V9.03l5.78 1.523zM6.38 6.82h5.78v1.524l-5.78-1.524z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.384 5.067L21.5 8.724V12l-2.058-1.295V9.333M4.954 4h8.63c.554 0 .95.381.95.914V20H4.004V4.914c0-.457.396-.914.95-.914zm1.821 10.057v.686-.686zm2.534-.762v1.448-1.448zm2.454-.838v2.286-2.286zm3.008-1.295l1.662.99c.555.305.871.915.871 1.524V16.8c0 1.143.95 1.981 2.059 1.981 1.108 0 2.058-.914 2.058-1.981V12l-6.65-.838z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgAutosFuelType
