import AdditionalOptionsIcon from '@kijiji/icons/src/icons/AdditionalOptions'
import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { BodyText } from '@/ui/atoms/body-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

type AutosBadgeContainerProps = {
  $horizontalOverflow: boolean
  hasDescription: boolean
}
export const AutosBadgesContainer = styled.div<AutosBadgeContainerProps>(
  ({ theme, $horizontalOverflow, hasDescription }) => `
  column-gap: ${theme.spacing.defaultSmall};
  display: flex;
  ${
    $horizontalOverflow
      ? `
    flex: 1; 
    overflow-x: auto; 
    z-index: ${zIndexRegistry.CARD_ELEMENTS};
    
    /* Fallback to hide the scrollbar for browsers that doesn't support -webkit-scrollbar */ 
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    
    ${MEDIA_QUERIES(theme).xLarge} {
      overflow-x: visible;
    }

    ${
      hasDescription &&
      `
    margin-right: ${theme.spacing.xLarge};
    padding-right: ${theme.spacing.mini};

    ${MEDIA_QUERIES(theme).medium} {
      margin-right: ${theme.spacing.husky};
      padding-right: ${theme.spacing.defaultSmall};
    }
    `
    }
      `
      : 'flex-wrap: wrap;'
  }
`
)

export const ListingOptions = styled(BodyText)(
  ({ theme }) => `
  align-items: center;
  display: flex;

  & svg {
    height: ${theme.spacing.large};
    margin-right: ${theme.spacing.mini};
    width: ${theme.spacing.large};
  }
`
)

export const OptionCustomIcon = styled(AdditionalOptionsIcon)<{ color: string }>(
  ({ color }) => `
  & > path:first-child {
    fill: ${color};
  }
`
)
