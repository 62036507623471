import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgPersonalWatercraft: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3.224 10.082l4.483-1.725a2 2 0 011.613.078l.843.422m-6.939 1.225L2 12.122m1.224-2.04h5.307M2 12.122h20m-20 0l2.16 1.44a2 2 0 00.625.277l2.93.732h12.469a1 1 0 001-1v-.224M22 12.122v.409c0 .45-.365.816-.816.816M22 12.122l-2.449-1.224s.914-.899 1.225-1.633c1.125-2.658-7.347.817-7.347.817l-1.633-.409-1.633-.816M20 13.347h1.184m-11.02-4.49l1.224-2.04 1.224.816L10.163 6M3 18h6M12 18h3.923M19 18h2" />
      </g>
    </Icon>
  )
}
export default SvgPersonalWatercraft
