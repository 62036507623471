import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCompany: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.571 7.625h-6.89v1.071h-3.362V7.625H3.43C2.672 7.625 2 8.339 2 9.143v11.34C2 21.285 2.672 22 3.429 22H20.57c.757 0 1.429-.714 1.429-1.518V9.142c0-.803-.672-1.517-1.429-1.517z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.379 16.821H12m-7.731 2.5h5.294v-8.214H4.27v8.214zM10.32 2h3.361v6.786H10.32V2zM12 11.107h7.48v2.5H12v-2.5z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgCompany
