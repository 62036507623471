import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgTrailerToyHauler: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#3e4153">
        <path d="M8.5 10.5h4v2h-4zM15.5 10.5h3v4h-3z" strokeLinejoin="round" />
        <circle cx={8.75} cy={16.084} r={1.25} />
        <circle cx={12.25} cy={16.084} r={1.25} />
        <path d="M10 16.5h1" strokeLinejoin="round" />
      </g>
      <path
        d="M1.863 16.52a.5.5 0 00.274.96zm18.637.98a.5.5 0 001 0zm-4.544-9.132l-.474.158zm3.08.168l-.006.5zM5.5 16H6a.5.5 0 00-.637-.48zm13-.071h-5v1h5zM7.5 16H6v1h1.5zm-1-7h3V8h-3zM11 7.5h3.058v-1H11zM9.5 9H16V8H9.5zm6.494 0l3.036.036.012-1L16.006 8zm-.512-.474l.044.132.948-.316-.044-.133zm6.018 3.01v3.893h1v-3.893zM6 16V9.5H5V16zm-.637-.48l-3.5 1 .274.96 3.5-1zM18.5 16.928H21v-1h-2.5zm2-.5V17.5h1v-1.071zm1-1a.5.5 0 01-.5.5v1a1.5 1.5 0 001.5-1.5zM14.059 7.5a1.5 1.5 0 011.423 1.026l.948-.317A2.5 2.5 0 0014.058 6.5zm4.97 1.536a2.5 2.5 0 012.471 2.5h1a3.5 3.5 0 00-3.458-3.5zM10 8.5a1 1 0 011-1v-1a2 2 0 00-2 2zM6.5 8A1.5 1.5 0 005 9.5h1a.5.5 0 01.5-.5zM6 16H5a1 1 0 001 1z"
        fill="#3e4153"
      />
    </Icon>
  )
}
export default SvgTrailerToyHauler
