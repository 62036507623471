import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgEditAlt2: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.336 2.669a1.996 1.996 0 012.833 0L21.7 6.2c.782.782.78 2.053 0 2.833l-9.764 9.764-.21.076-.57.207a1845.173 1845.173 0 00-6.084 2.216l-.11.04-.013.005-.142.04c-1.295.268-2.234-.838-1.846-2.032.218-.59.47-1.261.748-2l.042-.114a872.447 872.447 0 011.6-4.214l.152-.396.076-.198 9.757-9.758zm.707.707l-9.607 9.607s-.372.971-.871 2.285l.413.414L8.7 18.403l.37.37 2.317-.84 9.607-9.607a1.005 1.005 0 000-1.418l-3.531-3.532a.996.996 0 00-1.419 0zM7.96 19.177l-2.812-2.811c-.416 1.099-.868 2.3-1.25 3.33-.127.394.132.825.707.706 1.163-.426 2.327-.85 3.355-1.225z"
        fill="#D8D8D8"
      />
      <mask
        id="edit-alt-2_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={21}
        height={20}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.336 2.669a1.996 1.996 0 012.833 0L21.7 6.2c.782.782.78 2.053 0 2.833l-9.764 9.764-.21.076-.57.207a1845.173 1845.173 0 00-6.084 2.216l-.11.04-.013.005-.142.04c-1.295.268-2.234-.838-1.846-2.032.218-.59.47-1.261.748-2l.042-.114a872.447 872.447 0 011.6-4.214l.152-.396.076-.198 9.757-9.758zm.707.707l-9.607 9.607s-.372.971-.871 2.285l.413.414L8.7 18.403l.37.37 2.317-.84 9.607-9.607a1.005 1.005 0 000-1.418l-3.531-3.532a.996.996 0 00-1.419 0zM7.96 19.177l-2.812-2.811c-.416 1.099-.868 2.3-1.25 3.33-.127.394.132.825.707.706 1.163-.426 2.327-.85 3.355-1.225z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#edit-alt-2_svg__a)" fill="currentColor">
        <path d="M0 0h24v24H0z" />
        <path d="M0 0h24v24H0z" />
      </g>
    </Icon>
  )
}
export default SvgEditAlt2
