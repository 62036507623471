import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBannerGooglePlay: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" {...props}>
      <linearGradient
        id="banner-google-play_svg__a"
        gradientTransform="matrix(1 0 0 -1 0 187)"
        gradientUnits="userSpaceOnUse"
        x1={24.12}
        x2={5.5}
        y1={176.97}
        y2={158.35}
      >
        <stop offset={0} stopColor="#00a0ff" />
        <stop offset={0.01} stopColor="#00a1ff" />
        <stop offset={0.26} stopColor="#00beff" />
        <stop offset={0.51} stopColor="#00d2ff" />
        <stop offset={0.76} stopColor="#00dfff" />
        <stop offset={1} stopColor="#00e3ff" />
      </linearGradient>
      <linearGradient
        id="banner-google-play_svg__b"
        gradientTransform="matrix(1 0 0 -1 0 187)"
        gradientUnits="userSpaceOnUse"
        x1={37.48}
        x2={10.62}
        y1={164.44}
        y2={164.44}
      >
        <stop offset={0} stopColor="#ffe000" />
        <stop offset={0.41} stopColor="#ffbd00" />
        <stop offset={0.78} stopColor="orange" />
        <stop offset={1} stopColor="#ff9c00" />
      </linearGradient>
      <linearGradient
        id="banner-google-play_svg__c"
        gradientTransform="matrix(1 0 0 -1 0 187)"
        gradientUnits="userSpaceOnUse"
        x1={27.48}
        x2={2.23}
        y1={161.89}
        y2={136.64}
      >
        <stop offset={0} stopColor="#ff3a44" />
        <stop offset={1} stopColor="#c31162" />
      </linearGradient>
      <linearGradient
        id="banner-google-play_svg__d"
        gradientTransform="matrix(1 0 0 -1 0 187)"
        gradientUnits="userSpaceOnUse"
        x1={8.03}
        x2={19.3}
        y1={186.44}
        y2={175.16}
      >
        <stop offset={0} stopColor="#32a071" />
        <stop offset={0.07} stopColor="#2da771" />
        <stop offset={0.48} stopColor="#15cf74" />
        <stop offset={0.8} stopColor="#06e775" />
        <stop offset={1} stopColor="#00f076" />
      </linearGradient>
      <rect height={44} rx={7.5} width={149} x={0.5} y={0.5} />
      <path
        d="M142 1a7 7 0 017 7v29a7 7 0 01-7 7H8a7 7 0 01-7-7V8a7 7 0 017-7zm0-1H8a8 8 0 00-8 8v29a8 8 0 008 8h134a8 8 0 008-8V8a8 8 0 00-8-8z"
        fill="#a6a6a6"
      />
      <path
        d="M52.55 11.73a3 3 0 01-.83 2.27 3.23 3.23 0 01-2.45 1 3.43 3.43 0 01-3.46-3.48A3.43 3.43 0 0149.28 8a3.43 3.43 0 011.37.28 2.75 2.75 0 011 .74l-.59.59a2.25 2.25 0 00-1.82-.79 2.57 2.57 0 00-2.59 2.66 2.62 2.62 0 004.45 1.92 2.1 2.1 0 00.59-1.4h-2.41v-.8h3.22a2.82 2.82 0 01.05.53zM57.66 9h-3v2.11h2.73v.8h-2.76V14h3v.82h-3.85V8.13h3.89zm3.61 5.79h-.86V9h-1.85v-.87h4.58V9h-1.87zm5.17 0V8.13h.86v6.66zm4.65 0h-.86V9h-1.85v-.87H73V9h-1.91zm10.52-.86a3.46 3.46 0 01-4.88 0 3.4 3.4 0 01-1-2.47 3.4 3.4 0 011-2.47 3.45 3.45 0 014.88 0 3.4 3.4 0 011 2.46 3.41 3.41 0 01-1 2.48zm-4.25-.56a2.56 2.56 0 003.62 0 2.61 2.61 0 00.74-1.91A2.61 2.61 0 0081 9.55a2.56 2.56 0 00-3.62 0 2.61 2.61 0 00-.74 1.91 2.61 2.61 0 00.72 1.91zm6.43 1.42V8.13h1l3.24 5.18V8.13h.86v6.66H88l-3.35-5.43v5.43z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={0.2}
      />
      <path
        d="M75.54 24.5a4.72 4.72 0 104.74 4.72 4.65 4.65 0 00-4.74-4.72zm0 7.58a2.87 2.87 0 112.66-2.86 2.73 2.73 0 01-2.66 2.86zM65.2 24.5a4.72 4.72 0 104.74 4.72 4.65 4.65 0 00-4.74-4.72zm0 7.58a2.87 2.87 0 112.66-2.86 2.73 2.73 0 01-2.66 2.86zM52.91 26v2h4.79a4.18 4.18 0 01-1.09 2.52 4.9 4.9 0 01-3.7 1.47 5.33 5.33 0 010-10.65 5.11 5.11 0 013.61 1.43l1.41-1.41a7 7 0 00-5-2 7.33 7.33 0 100 14.66A6.7 6.7 0 0058 31.89a6.62 6.62 0 001.73-4.68 6.52 6.52 0 00-.08-1.21zm50.28 1.56a4.39 4.39 0 00-4-3 4.49 4.49 0 00-4.45 4.72A4.62 4.62 0 0099.42 34a4.69 4.69 0 003.93-2.09l-1.61-1.07a2.7 2.7 0 01-2.32 1.3 2.4 2.4 0 01-2.29-1.43L103.4 28zm-6.44 1.57a2.59 2.59 0 012.47-2.75 1.83 1.83 0 011.75 1zm-5.13 4.58h2.07V19.78h-2.07zm-3.4-8.1h-.07a3.27 3.27 0 00-2.49-1.05 4.72 4.72 0 000 9.44 3.22 3.22 0 002.49-1.07h.07v.68c0 1.81-1 2.77-2.52 2.77a2.61 2.61 0 01-2.38-1.68l-1.81.75a4.5 4.5 0 004.18 2.79c2.43 0 4.49-1.43 4.49-4.92v-8.53h-2zm-2.38 6.53a2.87 2.87 0 010-5.72 2.66 2.66 0 012.52 2.88 2.64 2.64 0 01-2.51 2.78zm27.06-12.3h-5v13.82h2.1V28.4h2.89a4.31 4.31 0 100-8.62zm.05 6.69H110v-4.81h3a2.38 2.38 0 110 4.76zm12.8-2a3.89 3.89 0 00-3.69 2.12l1.84.77a2 2 0 011.89-1 2 2 0 012.21 1.72v.14a4.58 4.58 0 00-2.16-.54c-2 0-4 1.09-4 3.12a3.2 3.2 0 003.44 3.05 2.92 2.92 0 002.64-1.36h.08v1.07h2v-5.3c0-2.47-1.87-3.84-4.25-3.84zm-.25 7.6c-.68 0-1.62-.34-1.62-1.18 0-1.07 1.18-1.48 2.2-1.48a3.69 3.69 0 011.89.46 2.51 2.51 0 01-2.47 2.15zm11.74-7.3l-2.37 6h-.07l-2.46-6h-2.23l3.69 8.41-2.11 4.68h2.16l5.69-13.08zm-18.64 8.83h2.07V19.78h-2.07z"
        fill="#fff"
      />
      <path
        d="M11.51 8.73a2.22 2.22 0 00-.51 1.56v24.54a2.22 2.22 0 00.51 1.56l.08.08 13.75-13.75v-.32L11.59 8.65z"
        fill="url(#banner-google-play_svg__a)"
      />
      <path
        d="M29.92 27.31l-4.58-4.58v-.33l4.58-4.58.1.06L35.46 21c1.55.88 1.55 2.32 0 3.2L30 27.25z"
        fill="url(#banner-google-play_svg__b)"
      />
      <path
        d="M30 27.25l-4.69-4.69-13.8 13.83a1.81 1.81 0 002.31.07z"
        fill="url(#banner-google-play_svg__c)"
      />
      <path
        d="M30 17.87L13.82 8.66a1.81 1.81 0 00-2.31.07l13.83 13.83z"
        fill="url(#banner-google-play_svg__d)"
      />
      <path
        d="M29.92 27.15l-16.1 9.15a1.85 1.85 0 01-2.22 0l-.08.08.08.08a1.85 1.85 0 002.22 0L30 27.25z"
        opacity={0.2}
      />
      <path
        d="M11.51 36.23a2.22 2.22 0 01-.51-1.56v.16a2.22 2.22 0 00.51 1.56l.08-.08zM35.46 24l-5.53 3.14.1.1 5.43-3.09a2 2 0 001.16-1.6A2.07 2.07 0 0135.46 24z"
        opacity={0.12}
      />
      <path
        d="M13.82 8.83l21.64 12.3a2.07 2.07 0 011.16 1.44A2 2 0 0035.46 21L13.82 8.66C12.27 7.78 11 8.51 11 10.29v.16c0-1.77 1.27-2.51 2.82-1.62z"
        fill="#fff"
        opacity={0.25}
      />
    </Icon>
  )
}
export default SvgBannerGooglePlay
